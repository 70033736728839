import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"iframe iframe_full pa-0 h-100"},[_c('SocketWatch',{attrs:{"tables":['tasks']},on:{"loadData":_vm.loadData}}),_c(VCard,{attrs:{"dark":""}},[_c(VCardTitle,{staticClass:"text-h6"},[_vm._v(" Просроченные задачи дизайнерам ")]),(!_vm.tasksLoaded && (!_vm.tasks || _vm.tasks.length === 0))?_c(VSkeletonLoader,{staticClass:"pl-3 pr-3",attrs:{"type":"table-thead, table-row-divider@3","types":_vm.skeletonTypesIframe}}):_vm._e(),(_vm.tasksLoaded && (!_vm.tasks || _vm.tasks.length === 0))?_c('div',{staticClass:"infoNoItems"},[_vm._v(" Нет задач 3 ")]):_vm._e(),(_vm.tasks && _vm.tasks.length > 0)?_c(VDataTable,{attrs:{"headers":_vm.tableHeadres,"items":_vm.tasks,"dense":!_vm.isMobile,"items-per-page":15,"options":_vm.options,"hide-default-footer":""},on:{"update:options":function($event){return _vm.$emit('updOptions', $event)},"pagination":function($event){return _vm.$emit('updPagination', $event)}},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('tr',{style:(item.color && item.color[_vm.currentUser.id] ?
								 `background-color: ${item.color[_vm.currentUser.id]}` : ''),on:{"click":function($event){return _vm.openTask(item)}}},[_c('td',{staticStyle:{"width":"35%"}},[_vm._v(" "+_vm._s(_vm.$sliceStr(item.title || item.description, 60))+" ")]),_c('td',[(item.numberOrder)?_c('span',[_vm._v(_vm._s(item.numberOrder))]):_c('span',[_vm._v("Не указан")])]),_c('td',[_c('div',{staticClass:"d-flex align-center"},_vm._l((item.whoTo),function(whoTo,whoTo_index){return _c('div',{key:whoTo_index,staticClass:"mr-1"},[(whoTo_index < 3)?_c('my-mini-avatar',{key:whoTo.id,attrs:{"user":item.who_to_data[whoTo_index]}}):_vm._e(),(whoTo_index === 3 && item.whoTo.length > 3)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(item.whoTo.length - 3)+") ")]):_vm._e()],1)}),0)]),_c('td',[_c('span',{style:(`${_vm.$colorDeadline(new Date(item.deadline))}`)},[_vm._v(" "+_vm._s(_vm.$formatDateTime(new Date(item.deadline)))+" ")])]),_c('td',[_c('span',[(item && item.archive)?[_vm._v("В архиве")]:(item && item.print)?[_vm._v("В печать")]:(!item || !item.accepted)?[_vm._v("Ожидает")]:(item && item.ready)?[_vm._v("Готово")]:[_vm._v("В работе")]],2)])])]}}],null,false,1967534048)}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }