<template>
    <v-row justify="center">
        <v-dialog fullscreen v-model="dialog">
            <div style="height: 100vh; background-color: black; position: relative;" class="d-flex align-center"
                v-if="image || images">
                <v-btn style="position: absolute; top: 15px; right: 15px;" icon color="#bf4037" @click="close()">
                    <v-icon large>mdi-close</v-icon>
                </v-btn>

                <v-btn v-if="onDownload" style="position: absolute; right: 55px; top: 16px;" icon color="white"
                    @click="onDownload">
                    <v-icon size="33">mdi-download</v-icon>

                </v-btn>
                <template v-if="image && !images">
                    <v-img :src="$formatFileUrl(image)" class="grey lighten-2" style="min-height: 300px;">
                        <template v-slot:placeholder>
                            <v-row class="fill-height ma-0" align="center" justify="center">
                                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                            </v-row>
                        </template>
                    </v-img>
                </template>
                <template v-if="images">
                    <v-carousel v-model="activImg" :show-arrows="images.length > 1" hide-delimiters>
                        <v-carousel-item v-for="(img, index_img) in images" :key="index_img">
                            <template v-slot:default>
                                <div style="height: 100%;" class="d-flex align-center">
                                    <v-img :src="$formatFileUrl(img)" max-height="650" contain>
                                        <template v-slot:placeholder>
                                            <v-row class="fill-height ma-0" align="center" justify="center">
                                                <v-progress-circular indeterminate
                                                    color="grey lighten-5"></v-progress-circular>
                                            </v-row>
                                        </template>
                                    </v-img>
                                </div>
                            </template>
                        </v-carousel-item>
                    </v-carousel>
                </template>
            </div>
        </v-dialog>
    </v-row>
</template>

<script>
export default {
    props: {
        image: {
            type: String,
            default: ''
        },
        images: {
            type: Array,
            default: null
        },
        onDownload: {
            type: Function,
            default: null
        }
    },
    data: () => ({
        dialog: true,
    }),
    created() {
        if (this.images && this.images.length > 0 && this.image) {
            let indexImg = this.images.findIndex(x => x === this.image)
            this.activImg = indexImg
        }
        let html = document.getElementsByTagName('html')[0]
        html.classList.add('overflow-y-hidden')
    },
    beforeDestroy() {
        let html = document.getElementsByTagName('html')[0]
        html.classList.remove('overflow-y-hidden')
    },
    methods: {
        close() {
            this.$emit('close', false)
        }
    }
}
</script>
