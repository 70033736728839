// Notifications
async function sendNotifNewCommentOrder(_this, comment) {
  let whoTo = [_this.item.creator.id];
  // Создателю не отправляем
  let indexIID = whoTo.findIndex((x) => x === _this.user.uid);
  if (indexIID != -1) {
    whoTo.splice(indexIID, 1);
  }

  let title = `${_this.user.displayName} написал комментарий`;
  let text = `К заказу ${_this.item.number}: '${comment}'`;

  if (whoTo && whoTo.length > 0) {
    for (let to of whoTo) {
      await _this.notify({
        to,
        title: title,
        text: text,
        action: "newCommentToOrder",
        payload: {
          orderId: _this.item_id,
        },
      });
    }
  }
}

async function sendNotifNewCommentManufactory(_this, comment) {
  let manager = _this.item.manager.id;
  let prodDirs = _this.$store.getters["users/getProdDirs"].map(({ id }) => id);
  let whoTo = [manager, ...prodDirs, _this.item.montagers].map((id) =>
    Number(id)
  );
  let indexIID = whoTo.findIndex((x) => x === _this.user.id);
  if (indexIID != -1) {
    whoTo.splice(indexIID, 1);
  }
  let title = `${_this.user.displayName} написал комментарий`;
  let text = `Производство ${_this.item.client.name}: '${comment}'`;

  if (whoTo && whoTo.length > 0) {
    console.log(whoTo);
    for (let to of whoTo) {
      if (to) {
        await _this.notify({
          to,
          title: title,
          text: text,
          action: "newCommentToManufactory",
          payload: {
            taskId: _this.item_id,
          },
        });
      }
    }
  }
}

async function sendNotifNewCommentMontage(_this, comment) {
  let manager = _this.item.manager.uid || _this.item.manager.id;
  let whoTo = [manager, ..._this.item.montagers];

  // убираем создателя из получателей
  let indexIID = whoTo.findIndex((x) => x === _this.user.uid);
  if (indexIID != -1) {
    whoTo.splice(indexIID, 1);
  }

  let title = `${_this.user.displayName} написал комментарий`;
  let text = `Монтаж: '${comment}'`;

  if (whoTo && whoTo.length > 0) {
    for (let to of whoTo) {
      await _this.notify({
        to,
        title: title,
        text: text,
        action: "montage",
        payload: {
          montageId: _this.item_id,
        },
      });
    }
  }
}
async function sendNotifNewCommentTask(_this, comment) {
  let whoTo = [..._this.item.whoTo];
  if (!whoTo.includes(_this.item.from.id)) {
    whoTo = [...whoTo, _this.item.from.id];
  }
  // убираем создателя из получателей
  let indexIID = whoTo.findIndex((x) => x === _this.user.uid);
  if (indexIID != -1) {
    whoTo.splice(indexIID, 1);
  }

  if (whoTo && whoTo.length > 0) {
    for (let to of whoTo) {
      await _this.notify({
        to,
        title: `${_this.user.displayName} написал комментарий`,
        text: `К задаче ${_this.item.numberOrder}: '${comment}'`,
        action: "newCommentToTask",
        payload: {
          taskId: _this.item_id,
        },
      });
    }
  }
}

export {
  sendNotifNewCommentOrder,
  sendNotifNewCommentManufactory,
  sendNotifNewCommentMontage,
  sendNotifNewCommentTask,
};
