import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,{attrs:{"justify":"center"}},[_c(VDialog,{attrs:{"scrollable":"","width":"290","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{staticClass:"selectPerformers",attrs:{"width":"290"}},[_c(VCardTitle,[_c('span',[_vm._v("Выберите исполнителей")])]),_c(VCardText,{staticStyle:{"max-height":"300px"}},[_c(VRadioGroup,{staticClass:"selectPerformers_radio ma-0 pa-0",attrs:{"error-messages":_vm.errorBrig,"label":"Бригады:","column":""}},[_vm._l((_vm.brigs),function(brigItem,index_brig){return [_c('div',{key:index_brig,staticClass:"mb-2"},[_c(VCheckbox,{staticClass:"pa-0 ma-0",attrs:{"color":"rgb(224, 166, 119)","label":brigItem.displayName,"value":brigItem.id},on:{"change":function($event){return _vm.changeBrig(brigItem)}},model:{value:(_vm.brig),callback:function ($$v) {_vm.brig=$$v},expression:"brig"}})],1)]})],2),_c(VRadioGroup,{key:_vm.updateKey,staticClass:"selectPerformers_radio ma-0 mt-4 pa-0",attrs:{"column":"","label":"Монтажники:"}},[_vm._l((_vm.users),function(montager,index_montager){return [_c('div',{key:index_montager,staticClass:"mb-2"},[_c(VCheckbox,{key:`check_${index_montager}`,staticClass:"pa-0 ma-0",attrs:{"color":"rgb(224, 166, 119)","label":`${montager.displayName}${montager.brig ? `(${montager.brig.displayName})` : ''}`,"value":montager.id},on:{"change":function($event){return _vm.changeMontagers(montager)}},model:{value:(_vm.montagers),callback:function ($$v) {_vm.montagers=$$v},expression:"montagers"}})],1)]})],2)],1),_c(VCardActions,[_c(VBtn,{attrs:{"color":"orange","text":""},on:{"click":function($event){return _vm.close()}}},[_vm._v("Отмена")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"blue","text":""},on:{"click":function($event){return _vm.go()}}},[_vm._v("Продолжить")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }