<template>
    <v-navigation-drawer v-if="stateNavDrawer" v-model="drawer" :mini-variant="miniDrawer && !$vuetify.breakpoint.mobile"
        :temporary="$vuetify.breakpoint.mobile" dark app fixed color="#424242"
        style="border: solid #ffffff1f; border-width: 0 thin 0 0;" :width="isMobile ? 256 : 190">
        <v-list-item v-if="!$vuetify.breakpoint.mobile" style="background-color: rgb(224, 166, 119);" class="px-2"
            @click="miniDrawer = !miniDrawer">
            <v-list-item-avatar size="30">
                <v-icon v-if="miniDrawer">mdi-chevron-right</v-icon>
                <v-icon v-else>mdi-chevron-left</v-icon>
            </v-list-item-avatar>
            <v-list-item-content class="text-truncate">
                M4 CRM
            </v-list-item-content>
        </v-list-item>
        <!-- <v-divider v-if="!$vuetify.breakpoint.mobile" /> -->
        <v-list nav dense>
            <template v-for="(route, index_route) in routes">
                <v-list-item v-if="route.meta && route.meta.navDrawer" :key="index_route"
                    :to="route.path ? route.path : '/'" link class="d-flex align-center"
                    style="min-height: 30px !important;">
                    <v-list-item-icon style="align-self: center;" class="my-0 mr-3">
                        <v-icon :dense="!isMobile">{{ route.meta.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ route.meta.text }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
import router from '../../router';

export default {
    data: () => ({
        isMobile: isMobile,
        firstPaint: true,
        secondPaint: false,
    }),
    created() {
        if (this.$vuetify.breakpoint.mobile && this.drawer) {
            this.drawer = false;
        }
    },
    computed: {
        routes() {
            let routes = this.$store.getters['templ/getRoutes'];
            routes = routes.filter((x) => x.meta.navDrawer);
            const sortedRoutes = routes.sort(function (a, b) {
                return a.meta.order - b.meta.order;
            });

            // У дизайнера при первичной отрисовке не появлялись страницы
            //   if (this.$store.getters['user/getUser'].role === 'designer' && this.secondPaint) {
            //     this.$router.push(this.$router.currentRoute);
            //   } else if (this.firstPaint) {
            //     this.secondPaint = true;
            //     this.firstPaint = false;
            //   } else if (this.secondPaint) {
            //     this.secondPaint = false;
            //     this.firstPaint = false;
            //   }
            return sortedRoutes;
        },
        user() {
            return this.$store.getters['user/getUser'];
        },
        stateNavDrawer() {
            return this.$route.name != 'Login' && this.user;
        },
        drawer: {
            get() {
                return this.$store.getters['templ/getDrawer'];
            },
            set(val) {
                this.$store.commit('templ/setDrawer', val);
            },
        },
        miniDrawer: {
            get() {
                return this.$store.getters['templ/getMiniDrawer'];
            },
            set(val) {
                this.$store.commit('templ/setMiniDrawer', val);
            },
        },
    },
};
</script>

<style lang="scss">
.v-navigation-drawer__content {
    width: calc(100% - 1px) !important;
}
</style>
