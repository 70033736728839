import api from "@/utils/api";

export default {
    namespaced: true,
    state: {
        orders: ["orders"],
        categorys: [],
        products: [],
        positions: [],
        selectedCategory: null,
        selectedPosition: null,
        selectedProduct: null,
    },
    getters: {
        categorysObj: (state) => _.keyBy(state.categorys, "id"),
        productsObj: (state) => _.keyBy(state.products, "id"),
    },
    mutations: {
        SET_ORDERS(state, payload) {
            state.orders = payload;
        },

        SET_CATEGORYS(state, payload) {
            state.categorys = payload;
        },

        SET_PRODUCTS(state, payload) {
            state.products = payload;
        },

        SET_POSITIONS(state, payload) {
            state.positions = payload;
        },

        UPDATE_CATEGORY(state, payload) {
            if (payload && payload.id) {
                const item = state.categorys.find((item) => item.id === payload.id);
                Object.assign(item, payload);
            }
        },

        UPDATE_PRODUCT(state, payload) {
            if (payload && payload.id) {
                const item = state.products.find((item) => item.id === payload.id);
                Object.assign(item, payload);
            }
        },

        UPDATE_POSITION(state, payload) {
            if (payload && payload.id) {
                const item = state.positions.find((item) => item.id === payload.id);
                Object.assign(item, payload);
            }
        },

        ADD_CATEGORY(state, payload) {
            state.categorys = [...state.categorys, payload];
        },

        ADD_PRODUCT(state, payload) {
            state.products = [...state.products, payload];
        },

        ADD_POSITION(state, payload) {
            state.positions = [...state.positions, payload];
        },

        REMOVE_POSITION(state, id) {
            state.positions = state.positions.filter(
                (position) => position.id !== id
            );
        },

        REMOVE_PRODUCT(state, id) {
            state.products = state.products.filter((product) => product.id !== id);
        },

        REMOVE_CATEGORY(state, id) {
            state.categorys = state.categorys.filter(
                (category) => category.id !== id
            );
        },

        SET_SELECTED_CATEGORY(state, payload) {
            state.selectedCategory = payload;
        },

        SET_SELECTED_POSITION(state, payload) {
            state.selectedPosition = payload;
        },

        SET_SELECTED_PRODUCT(state, payload) {
            state.selectedProduct = payload;
        },
    },
    actions: {
        async loadOrders({ commit }) {
            commit("templ/setLoading", true, { root: true });
            let { data, status } = await api.post("orders/get-list", {
                limit: 10000,
                offset: 0,
                query_and: [{ input: "del", value: false }],
            });
            // console.log(data.data[0])
            if (status == 200 && data.status) {
                commit("SET_ORDERS", data.data || []);
            }
            commit("templ/setLoading", false, { root: true });
        },
        //
        async loadCategorys({ state, commit, dispatch, getters }, obj) {
            commit("templ/setLoading", true, { root: true });

            let { data, status } = await api.post("category/get-list", {
                limit: 10000,
                offset: 0,
                query_and: [{ input: "del", value: false }],
            });
            // console.log({ data, status, obj }, 'loadCategorys')
            if (status == 200 && data.status) {
                commit(
                    "SET_CATEGORYS",
                    data.data.sort((a, b) => a.sort - b.sort) || []
                );
            }

            commit("templ/setLoading", false, { root: true });
        },

        async updateCategory({ state, commit, dispatch, getters }, obj) {
            commit("templ/setLoading", true, { root: true });

            let { data, status } = await api.put("category/update", obj);
            if (status == 200 && data.status) {
                commit("UPDATE_CATEGORY", data.category);
                dispatch("socket/sendSocket", ["categorys"], {
                    root: true,
                });
            }

            commit("templ/setLoading", false, { root: true });
        },

        async createCategory({ state, commit, dispatch, getters }, obj) {
            commit("templ/setLoading", true, { root: true });

            let { data, status } = await api.post("category/create", obj);
            // console.log({ data, status , obj }, 'createCategory')
            if (status == 200 && data.status) {
                commit("ADD_CATEGORY", data.category);
                // console.log(data.category, 'data.category')
                dispatch("socket/sendSocket", ["categorys"], {
                    root: true,
                });
            }

            commit("templ/setLoading", false, { root: true });
        },

        async deleteCategory({ state, commit, dispatch, getters }, { id }) {
            commit("templ/setLoading", true, { root: true });
            let { data, status } = await api.put("category/update", {
                id,
                del: true,
            });
            // console.log({ data, status }, 'deleteCategory')
            if (status == 200 && data.status) {
                commit("REMOVE_CATEGORY", id);
                dispatch("socket/sendSocket", ["categorys"], {
                    root: true,
                });
            }

            commit("templ/setLoading", false, { root: true });
        },

        async sortCategorys({ commit }, items) {
            const data = items.map((item, index) => {
                item.sort = index;
                const { id, sort } = item;
                api.put("category/update", { id, sort });
                return item;
            });
            commit("SET_CATEGORYS", data);
        },

        setSelectedCategory({ state, commit, dispatch, getters }, value) {
            commit("SET_SELECTED_CATEGORY", value);
        },

        async loadProducts({ state, commit, dispatch, getters }) {
            commit("templ/setLoading", true, { root: true });

            const query_and = [{ input: "del", value: false }];

            if (state.selectedCategory && state.selectedCategory.id) {
                query_and.push({
                    input: "category_id",
                    value: state.selectedCategory.id,
                });
            }

            let { data, status } = await api.post("product/get-list", {
                limit: 10000,
                offset: 0,
                query_and,
            });
            // console.log({ data, status }, 'loadProducts')
            if (status == 200 && data.status) {
                commit(
                    "SET_PRODUCTS",
                    data.data.sort((a, b) => a.sort - b.sort)
                );
            }

            commit("templ/setLoading", false, { root: true });
        },

        async updateProduct({ state, commit, dispatch, getters }, obj) {
            commit("templ/setLoading", true, { root: true });

            let { data, status } = await api.put("product/update", obj);
            // console.log({ data, status, obj }, 'updateProduct')
            if (status == 200 && data.status) {
                commit("UPDATE_PRODUCT", data.product);
                dispatch("socket/sendSocket", ["products"], {
                    root: true,
                });
            }

            commit("templ/setLoading", false, { root: true });
        },

        async updateCountProduct(
            { state, commit, dispatch, getters },
            {
                count,
                type,
                id,
                who_id,
                whomid,
                comment,
                orderNumber,
                internal,
                name,
                unit,
            }
        ) {
            commit("templ/setLoading", true, { root: true });

            const product = await api.get(`product/get/${id}`);
            let productCount;
            let category_id;
            if (product.status == 200 && product.data.status) {
                productCount = product.data.data.count;
                category_id = product.data.data.category_id;
            }
            // console.log(type === 'plus'? productCount + count : productCount - count)
            let { data, status } = await api.put("product/update", {
                id,
                count: type === "plus" ? productCount + count : productCount - count,
            });

            if (status == 200 && data.status) {
                commit("UPDATE_PRODUCT", data.product);
            } else {
                alert("err");
            }

            let position = await api.post("position/create", {
                product_id: id,
                category_id,
                who_id,
                whomid,
                orderNumber,
                internal,
                comment,
                type,
                count,
                unit,
                name,
                date: new Date(),
            });

            if (position.status == 200 && position.data.status) {
                commit("ADD_POSITION", position.data.position);
            }

            commit("templ/setLoading", false, { root: true });
        },

        async createProduct({ state, commit, dispatch, getters }, obj) {
            commit("templ/setLoading", true, { root: true });

            let { data, status } = await api.post("product/create", obj);
            console.log({ data, status, obj }, "createProduct");
            console.log("check: " + JSON.stringify(obj))
            if (status == 200 && data.status) {
                commit("ADD_PRODUCT", data.product);

                const product = data.product;

                let position = await api.post("position/create", {
                    product_id: product.id,
                    category_id: product.category_id,
                    who_id: obj.who_id,
                    comment: product.comment,
                    type: "plus",
                    count: product.count,
                    unit: `${product.unit}----${product.name}`,
                    name: product.name,
                    date: new Date(),
                });

                if (position.status == 200 && position.data.status) {
                    commit("ADD_POSITION", position.data.position);
                }

                dispatch("socket/sendSocket", ["products"], {
                    root: true,
                });
            }

            commit("templ/setLoading", false, { root: true });
        },

        async sortProducts({ state, commit, dispatch, getters }, products) {
            const data = products.map((product, index) => {
                product.sort = index;
                const { id, sort } = product;
                api.put("product/update", { id, sort });
                return product;
            });
            commit("SET_PRODUCTS", data);
        },

        async deleteProduct({ state, commit, dispatch, getters }, product) {
            const { id, name, unit, count, category_id, who_id } = product;
            commit("templ/setLoading", true, { root: true });
            let { data, status } = await api.put("product/update", { id, del: true });
            // console.log({ data, status, id }, 'deleteProduct')
            if (status == 200 && data.status) {
                commit("REMOVE_PRODUCT", id);
                dispatch("socket/sendSocket", ["products"], {
                    root: true,
                });
                let position = await api.post("position/create", {
                    product_id: id,
                    category_id,
                    who_id,
                    comment: "Позиция удалена",
                    type: "minus",
                    count,
                    unit: `${unit}----${name}`,
                    date: new Date(),
                });
            }

            dispatch("socket/sendSocket", ["positions", "products"], {
                root: true,
            });

            commit("templ/setLoading", false, { root: true });
        },

        setSelectedProduct({ state, commit, dispatch, getters }, value) {
            commit("SET_SELECTED_PRODUCT", value);
        },

        async loadPositions({ state, commit, dispatch, getters }, obj) {
            commit("templ/setLoading", true, { root: true });

            const query_and = [{ input: "del", value: false }];

            if (state.selectedProduct && state.selectedProduct.id) {
                query_and.push({
                    input: "product_id",
                    value: state.selectedProduct.id,
                });
            }

            let { data, status } = await api.post("position/get-list", {
                limit: 10000,
                offset: 0,
                sort: "date",
                query_and,
            });

            // console.log({ data, status, obj }, 'loadPositions')
            if (status == 200 && data.status) {
                commit("SET_POSITIONS", data.data || []);
            }

            commit("templ/setLoading", false, { root: true });
            return data.data || [];
        },

        async updatePosition({ state, commit, dispatch, getters }, obj) {
            commit("templ/setLoading", true, { root: true });

            let { data, status } = await api.put("position/update", obj);
            // console.log({ data, status, obj }, 'updatePosition')
            if (status == 200 && data.status) {
                commit("UPDATE_POSITION", data.position);
                dispatch("socket/sendSocket", ["positions"], {
                    root: true,
                });
            }
            commit("templ/setLoading", false, { root: true });
        },

        async createPosition({ state, commit, dispatch, getters }, obj) {
            commit("templ/setLoading", true, { root: true });

            let { data, status } = await api.post("position/create", obj);
            // console.log({ data, status, obj }, 'createPosition')
            if (status == 200 && data.status) {
                commit("ADD_POSITION", data.position);
                dispatch("socket/sendSocket", ["positions"], {
                    root: true,
                });
            }

            commit("templ/setLoading", false, { root: true });
        },

        async deletePosition({ state, commit, dispatch, getters }, { id }) {
            commit("templ/setLoading", true, { root: true });
            let { data, status } = await api.put("position/update", {
                id,
                del: true,
            });
            // console.log({ data, status, id }, 'deletePosition')
            if (status == 200 && data.status) {
                commit("REMOVE_POSITION", id);
                dispatch("socket/sendSocket", ["positions"], {
                    root: true,
                });
            }
            commit("templ/setLoading", false, { root: true });
        },

        setSelectedPosition({ state, commit, dispatch, getters }, value) {
            commit("SET_SELECTED_POSITION", value);
        },
    },
};
