export function parseIfString(val) {
  let value = val;

  if (typeof value === "string") {
    value = JSON.parse(value);
    if (typeof value === "string") {
      // if JSON.parse receives over-stringified string,  ran it through JSON.parse twice to get an object
      value = JSON.parse(value);
    }
  }

  return value;
}
