import { montagesStatus } from "@/CONSTS/montages";
import api, { apiRoutes as aR } from "@/utils/api/api";
import { sortByDateAsc, sortByDateDesc } from "../../../utils/helpers/sorts";
import filterMontages from "./filter";
import loadedMontages from "./loaded";

const url = process.env.VUE_APP_SERVICE_URL;

export default {
    namespaced: true,
    state: {
        // montages
        montages: [],
        unassignedMontages: [],
        managerUnassignedMontages: [],
        assignedMontages: [],
        montagesForMontager: [],
        montagesForMontagerProfile: [],
        // loaded montages
        ...loadedMontages.state,
        // filters
        ...filterMontages.state,
        // pagination all montages
        montagesPage: 1,
        montagesPrev: null,
        montagesNext: null,
        maxMontagesCount: 0,
        // pagination user montages
        montagesForMontagerPage: 1,
        montagesForMontagerLastPage: 1,
        limit: 10000,
        offset: 0,
    },
    getters: {
        // montages
        getMontages: (state) => {
            return sortByDateDesc(state.montages);
        },
        getUnassignedMontages: (state) => {
            const sortedMontages = sortByDateAsc(state.unassignedMontages);
            return sortedMontages;
        },
        getUnassignedManagerMontages: (state) => {
            const sortedMontages = sortByDateAsc(state.managerUnassignedMontages);
        },
        getAssignedMontages: (state) => {
            let montages = state.assignedMontages;
            if (montages) {
                for (let keyForBrig in montages) {
                    let sortedMontages = [];

                    let montagesWithOrder = montages[keyForBrig].montages.filter((x) => {
                        return x.order !== undefined;
                    });
                    let montagesWithoutOrder = montages[keyForBrig].montages.filter(
                        (x) => x.order === undefined
                    );
                    montagesWithOrder = montagesWithOrder.sort((a, b) => {
                        return a.order - b.order;
                    });

                    sortedMontages = [...montagesWithOrder, ...montagesWithoutOrder];
                    montages[keyForBrig].montages = sortedMontages;
                }
                return montages;
            }
            return [];
        },
        getMontagesForMontager: (state) => {
            const sortedMontages = state.montagesForMontager.sort(function (a, b) {
                return new Date(b.date) - new Date(a.date);
            });
            return sortedMontages;
        },
        getListMontagesPageForMontager: (state, getters) => {
            let montages = getters.getMontagesForMontager;
            let pageMontages = state.montagesForMontagerPage;

            let montagesPages = [];
            for (let i = 0; i < montages.length; i += 10) {
                montagesPages.push(montages.slice(i, i + 10));
            }

            let montagesArr = montagesPages[pageMontages - 1];

            return montagesArr ? montagesArr : [];
        },
        getMontagesForMontagerProfile: (state) => {
            let montages = state.montagesForMontagerProfile;
            let sortedMontages = [];
            let montagesWithOrder = montages.filter((x) => x.order != undefined);
            let montagesWithoutOrder = montages.filter((x) => x.order === undefined);
            montagesWithOrder = montagesWithOrder.sort(function (a, b) {
                return a.order - b.order;
            });
            sortedMontages = [...montagesWithOrder, ...montagesWithoutOrder];
            return sortedMontages;
        },
        // filters
        ...filterMontages.getters,
        // loaded montages
        ...loadedMontages.getters,
        // pagination all montages
        getMontagesPage: (state) => {
            return state.montagesPage;
        },
        getMontagesPrev: (state) => {
            return state.montagesPrev;
        },
        getMontagesNext: (state) => {
            return state.montagesNext;
        },
        getMaxMontagesCount: (state) => {
            return state.maxMontagesCount;
        },
        // pagination user montages
        getMontagesForMontagerPage: (state) => {
            return state.montagesForMontagerPage;
        },
        getMontagesForMontagerLastPage: (state) => {
            return state.montagesForMontagerLastPage;
        },
        getOffset(state) {
            return state.offset;
        },
        getLimit(state) {
            return state.limit;
        },
    },
    mutations: {
        // montages
        setMontages(state, payload) {
            state.montages = payload;
        },
        setUnassignedMontages(state, payload) {
            state.unassignedMontages = payload;
        },
        setManagerUnassignedMontages(state, payload) {
            state.managerUnassignedMontages = payload;
        },
        setAssignedMontages(state, payload) {
            state.assignedMontages = payload;
        },
        setMontagesForMontager(state, payload) {
            state.montagesForMontager = payload;
        },
        setMontagesForMontagerProfile(state, payload) {
            state.montagesForMontagerProfile = payload;
        },
        updAssignedMontagesForOrder(state, payload) {
            if (payload.brig) {
                let montagesForBrig = state.assignedMontages.find(
                    (item) => item.brig.id === payload.brig
                );
                let montages = montagesForBrig.montages;
                if (payload.montage && payload.montage.id) {
                    let item = montages.find((item) => item.id === payload.montage.id);
                    Object.assign(item, payload.montage);
                }
            }
        },
        // filters
        ...filterMontages.mutations,
        // loaded montages
        ...loadedMontages.mutations,
        // pagination all montages
        setMontagesPaginationPage(state, payload) {
            state.montagesPage = payload;
        },
        setMontagesPagination(state, payload) {
            state[`montages${payload.action}`] = payload.cursor;
        },
        setMaxMontagesPaginationCount(state, payload) {
            state.maxMontagesCount = payload;
        },
        // pagination user montages
        setMontagesForMontagerPaginationPage(state, payload) {
            state.montagesForMontagerPage = payload;
        },
        setMontagesForMontagerPaginationLastPage(state, payload) {
            state.montagesForMontagerLastPage = payload;
        },
        addMontage(state, montage) {
            let montages = state.montages;
            montages.push(montage);
            state.montages = montages;
        },
        addUnassignedMontage(state, montage) {
            let montages = state.unassignedMontages;
            montages.push(montage);
            state.unassignedMontages = montages;
        },
        SET_LIMIT(state, payload) {
            state.limit = payload;
        },
        SET_OFFSET(state, payload) {
            state.offset = payload;
        },
    },
    actions: {
        async loadMontageById({ commit, dispatch, getters }, id) {
            commit("templ/setLoading", true, {
                root: true,
            });

            let queryStore = await api.get(aR.montagesGet, id).then((response) => {
                if (response.status == 200) {
                    return response.data.data;
                }
            });

            let doc = await dispatch("montageSerializer", {
                ...queryStore,
                id: queryStore.id,
            });

            commit("templ/setLoading", false, {
                root: true,
            });
            return doc;
        },
        async loadMontagesForMontagerProfile(
            { commit, dispatch, getters, state },
            data
        ) {
            commit("templ/setLoading", true, {
                root: true,
            });

            let queryStore = await api
                .post(aR.montagesGetList, {
                    limit: state.limit,
                    offset: state.offset,
                    query_and: [
                        {
                            input: "del",
                            value: false,
                        },
                        data.id
                            ? {
                                input: "montagers",
                                value: `\"${data.id}\"`,
                            }
                            : null,
                    ],
                })
                .then(async (response) => {
                    if (response.status == 200) {
                        let montagesArr = [];

                        commit("setMaxMontagesPaginationCount", response.data.count);

                        let montages = response.data.data || [];

                        if (data.date) {
                            montages =
                                montages.filter(
                                    (el) =>
                                        el.date.split("T")[0] ==
                                        data.date.toISOString().split("T")[0]
                                ) || [];
                        }

                        if (!Array.isArray(montages)) {
                            montages = [montages];
                        }

                        montages.map(async (el) => {
                            let montage = await dispatch("montageSerializer", el);
                            montagesArr.push(montage);
                        });

                        commit("setMontagesForMontagerProfile", montagesArr);
                        commit("templ/setLoading", false, {
                            root: true,
                        });
                    }
                });
        },
        async loadMontagesForMontager({ commit, dispatch, getters, state }, data) {
            commit("templ/setLoading", true, {
                root: true,
            });

            if (data.month) {
                let date = new Date(data.month),
                    y = date.getFullYear(),
                    m = date.getMonth();
                let firstDay = new Date(y, m, 1, 0);
                let lastDay = new Date(y, m + 1, 0, 23, 59);
            }
            await api
                .post(aR.montagesGetList, {
                    limit: state.limit,

                    offset: state.offset,
                    query_and: [
                        {
                            input: "status",
                            value: "ready",
                        },
                        data.id
                            ? {
                                input: "montagers",
                                value: String(data.id),
                            }
                            : null,
                        data.month
                            ? {
                                input: "date",
                                value: data.month,
                            }
                            : null,
                    ],
                })
                .then(async (response) => {
                    if (response.status == 200 && response.data.status) {
                        let montagesArr = [];
                        commit("setMaxMontagesPaginationCount", response.data.count);
                        for (let doc of response.data.data || []) {
                            let montage = await dispatch("montageSerializer", {
                                ...doc,
                                id: doc.id,
                            });
                            montagesArr.push(montage);
                        }
                        montagesArr = montagesArr.filter((x) => !x.del);
                        let newMontagesArr = [];
                        for (let i = 0; i < montagesArr.length; i += 10) {
                            newMontagesArr.push(montagesArr.slice(i, i + 10));
                        }
                        commit(
                            "setMontagesForMontagerPaginationLastPage",
                            newMontagesArr.length
                        );

                        commit("setMontagesForMontager", montagesArr);
                        commit("templ/setLoading", false, {
                            root: true,
                        });
                    }
                });
        },
        async loadAssignedMontages({ commit, dispatch, getters, state }) {
            commit("templ/setLoading", true, {
                root: true,
            });
            let filterByDate = getters.getFilterAssignedMontagesByDate;

            let queryStore = await api.post(aR.montagesGetList, {
                limit: state.limit,
                offset: state.offset,
                query: [
                    filterByDate
                        ? {
                            input: "date",
                            value: filterByDate.toISOString().split("T")[0],
                        }
                        : null,
                ],
                strict: [
                    {
                        input: "del",
                        value: false,
                    },
                ],
                strict_or: [
                    {
                        input: "status",
                        value: "assigned",
                    },
                    {
                        input: "status",
                        value: "work",
                    },
                    {
                        input: "status",
                        value: "no_work",
                    },
                    {
                        input: "status",
                        value: "ready",
                    },
                ],
            });

            if (filterByDate) {
                queryStore = queryStore.data.data.filter((el) => {
                    let date = el.date.split("T")[0];
                    if (date == filterByDate.toISOString().split("T")[0]) {
                        return el;
                    }
                });
                const f = queryStore.sort((a, b) => {
                    console.log(a.order, b.order);
                    return a.order - b.order;
                });
                console.log(f, queryStore);
            }

            let brigsArr = [];
            let brigs = await api
                .post(aR.brigsGetList, {
                    limit: 100,
                    offset: 0,
                    query: [
                        {
                            input: "del",
                            value: false,
                        },
                    ],
                })
                .then((response) => {
                    if (response.status == 200) {
                        return response.data.data || [];
                    }
                });

            brigs.forEach(async (el) => {
                let brig = el;
                let obj = {
                    brig: brig,
                    montages: [],
                };
                brigsArr.push(obj);
            });

            queryStore.forEach(async (el) => {
                let montage = await dispatch("montageSerializer", el);
                let brigIndex = brigsArr.findIndex(
                    (x) => x.brig && x.brig.id === montage.brig_id
                );
                if (brigIndex != -1) {
                    brigsArr[brigIndex].montages.push(montage);
                }
            });

            commit("setAssignedMontages", brigsArr);
            commit("templ/setLoading", false, {
                root: true,
            });
            commit("setAssignedMontagesLoaded", true);
        },
        async loadMontages({ commit, dispatch, getters, state }, pagination) {
            commit("templ/setLoading", true, {
                root: true,
            });

            let tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 1);
            let toDay = new Date(tomorrow.toISOString().substr(0, 10));
            let filterByDate = getters.getFilterMontagesByDate;
            if (filterByDate) {
                filterByDate = filterByDate.toISOString().split("T")[0];
            }
            let filterByClient = getters.getFilterMontagesByClient;
            if (filterByClient && filterByClient.id) {
                filterByClient = filterByClient.id;
            }
            let filterByStatus = getters.getFilterMontagesByStatus;

            let filterByManager = getters.getFilterMontagesByManager;
            if (filterByManager && filterByManager.id) {
                filterByManager = filterByManager.id;
            }

            let queryStore = await api.post(aR.montagesGetList, {
                limit: state.limit,
                offset: state.offset,
                query: [
                    filterByDate
                        ? {
                            input: "date",
                            value: filterByDate,
                        }
                        : null,
                ],
                strict: [
                    filterByStatus
                        ? {
                            input: "status",
                            value: filterByStatus,
                        }
                        : null,
                ],
                query_and: [
                    {
                        input: "del",
                        value: false,
                    },
                    filterByClient
                        ? {
                            input: "client_id",
                            value: filterByClient,
                        }
                        : null,
                    filterByManager
                        ? {
                            input: "manager_id",
                            value: filterByManager,
                        }
                        : null,
                ],
            });
            commit("setMaxMontagesPaginationCount", queryStore.data.count);
            queryStore = queryStore.data.data || [];
            if (queryStore.length > 0 && queryStore[0]) {
                commit("setMontagesPagination", {
                    action: "Prev",
                    cursor: queryStore[0],
                });
            }
            if (queryStore.length === 20 && queryStore[queryStore.length - 1]) {
                commit("setMontagesPagination", {
                    action: "Next",
                    cursor: queryStore[queryStore.length - 1],
                });
            }

            let montagesArr = [];

            queryStore.forEach(async (el) => {
                let montage = await dispatch("montageSerializer", el);
                montagesArr.push(montage);
            });

            commit("setMontages", montagesArr);
            commit("templ/setLoading", false, {
                root: true,
            });
            commit("setMontagesLoaded", true);
        },
        async loadUnassignedMontages({ commit, dispatch, getters }) {
            commit("templ/setLoading", true, {
                root: true,
            });

            let filterByClient = getters.getFilterUnassignedMontagesByClient;
            if (filterByClient && filterByClient.id) {
                filterByClient = filterByClient.id;
            }

            let filterByManager = getters.getFilterUnassignedMontagesByManager;

            if (filterByManager && filterByManager.id) {
                filterByManager = filterByManager.id;
            }

            let filterByDate = getters.getFilterUnassignedMontagesByDate;
            if (filterByDate) {
                filterByDate = filterByDate.toISOString().split("T")[0];
            }
            let queryStore = await api.post(aR.montagesGetList, {
                limit: 10000,
                offset: 0,
                query: [
                    filterByDate
                        ? {
                            input: "date",
                            value: filterByDate,
                        }
                        : null,
                ],
                query_and: [
                    {
                        input: "del",
                        value: false,
                    },
                    {
                        input: "status",
                        value: "unassigned",
                    },
                    filterByManager
                        ? {
                            input: "manager_id",
                            value: filterByManager,
                        }
                        : null,
                    filterByClient
                        ? {
                            input: "client_id",
                            value: filterByClient,
                        }
                        : null,
                ],
            });
            queryStore = queryStore.data.data || [];
            let montagesArr = [];
            queryStore.forEach(async (el) => {
                let montage = await dispatch("montageSerializer", el);
                montagesArr.push(montage);
            });

            commit("setUnassignedMontages", montagesArr);
            commit("templ/setLoading", false, {
                root: true,
            });
            commit("setUnassignedMontagesLoaded", true);
        },
        async loadManagerUnassignedMontages({ commit, dispatch }) {
            commit("templ/setLoading", true, {
                root: true,
            });

            let manager = this.getters["user/getUser"];

            let queryStore = await api.post(aR.montagesGetList, {
                limit: 10000,
                offset: 0,
                query_and: [
                    {
                        input: "del",
                        value: false,
                    },
                    {
                        input: "manager",
                        value: manager.id,
                    },
                    {
                        input: "status",
                        value: "unassigned",
                    },
                ],
            });
            queryStore = queryStore.data.data || [];
            let montagesArr = [];
            queryStore.forEach(async (el) => {
                let montage = await dispatch("montageSerializer", el);
                montagesArr.push(montage);
            });
            commit("setManagerUnassignedMontages", montagesArr);
            commit("templ/setLoading", false, {
                root: true,
            });
            commit("setManagerMontagesLoaded", true);
        },
        async createMontage({ commit, rootState, dispatch }, data) {
            commit("templ/setLoading", true, {
                root: true,
            });
            const obj = {
                ...data,
                del: false,
            };
            if (!obj.numberOrder) {
                let number = await dispatch("getNewNumberMontage");
                obj.numberOrder = `m${number}`;
            }

            if (obj.client) {
                obj.client_id = obj.client;
                delete obj.client;
            }

            // manager
            if (obj.manager) {
                obj.manager_id = obj.manager;
                delete obj.manager;
            }

            // brig
            if (obj.brig) {
                obj.brig_id = obj.brig.id;
                delete obj.brig;
            }
            if (obj.tasks) {
                obj.tasks = JSON.stringify(obj.tasks);
            }
            if (obj.montagers) {
                obj.montagers = obj.montagers.map((el) => {
                    el = String(el);
                    return el;
                });
            }
            obj.images = String(obj.images);
            obj.montagersInfo = JSON.stringify(obj.montagersInfo);
            obj.priceForBrig = String(obj.priceForBrig);

            let montage = await api
                .post(aR.montagesCreate, obj)
                .then(async (response) => {
                    if (response.status == 200) {
                        dispatch("socket/sendSocket", ["montages"], {
                            root: true,
                        });

                        // отправляем монтажникам инфу о том что на них назначен монтаж
                        let date = obj.date.toLocaleString("ru", {
                            day: "numeric",
                            month: "short",
                        });
                        let title = `Назначен монтаж на ${date}`;
                        let text = `Клиент: ${response.data.montages.client_data.name}`;
                        if (obj.montagers && obj.montagers.length > 0) {
                            for (let to of obj.montagers) {
                                await dispatch(
                                    "notify/notify",
                                    {
                                        to,
                                        title: title,
                                        text: text,
                                        action: "assignedMontage",
                                        payload: {
                                            montageId: response.data.montages.id,
                                        },
                                    },
                                    {
                                        root: true,
                                    }
                                );
                            }
                        }
                        // }

                        return response.data.montages;
                    }
                });
            // TODO
            if (montage.status == "unassigned") {
                let serializedMontage = await dispatch("montageSerializer", montage);
                commit("addUnassignedMontage", serializedMontage);
            } else {
                let serializedMontage = await dispatch("montageSerializer", montage);
                commit("addMontage", serializedMontage);
            }
            commit("templ/setLoading", false, {
                root: true,
            });
        },
        async updateMontage({ commit, rootState, rootGetters, dispatch }, obj) {
            commit("templ/setLoading", true, {
                root: true,
            });
            let user = rootGetters["user/getUser"];
            let action = obj.action || null;
            let manager = obj.manager || null;
            let oldData = obj.oldData || null;
            let id = obj.id;
            obj = obj.data;

            if (obj.client) {
                obj.client_id = obj.client;
                delete obj.client;
            }

            // manager
            if (manager) {
                obj.manager_id = manager.id;
            }

            // brig
            if (obj.brig) {
                obj.brig_id = obj.brig.id;
                delete obj.brig;
            }
            if (obj.montagers) {
                obj.montagers = obj.montagers.map((el) => {
                    el = String(el);
                    return el;
                });
            }
            if (obj.tasks) {
                obj.tasks = JSON.stringify(obj.tasks);
            }

            obj.priceForBrig = String(obj.priceForBrig);

            if (obj.images) {
                obj.images = String(obj.images);
            }

            obj.montagersInfo = JSON.stringify(obj.montagersInfo);
            const put = { ...obj };

            console.log(obj, "obj", manager);
            const res = await api.put(aR.montagesUpdate, {
                id,
                ...put,
            });

            const data = res.data.montages;
            console.log(data, "data");

            if (action === "assigned" || action === "unassigned") {
                // отправляем монтажникам и менеджеру инфу о том что монтаж назначен или отменён
                let whoTo = [];
                if (action === "assigned") {
                    whoTo = [...obj.montagers, manager.id];
                } else if (action === "unassigned" && oldData && oldData.montagers) {
                    whoTo = [...oldData.montagers, manager.id];
                }
                let indexIID = whoTo.findIndex((x) => x === user.id);
                if (indexIID != -1) {
                    whoTo.splice(indexIID, 1);
                }

                let date = obj.date ? obj.date : new Date(oldData.date);
                date = date.toLocaleString("ru", { day: "numeric", month: "short" });
                let title = "";
                let text = "";
                if (action === "assigned") {
                    title = `Назначен монтаж на ${date}`;
                    text = `Клиент: ${data.client_data.name}`;
                } else if (action === "unassigned") {
                    title = `Монтаж на ${date}, отменён`;
                    text = `Клиент: ${oldData.client.name}`;
                }

                if (whoTo && whoTo.length > 0) {
                    for (let to of whoTo) {
                        await dispatch(
                            "notify/notify",
                            {
                                to,
                                title: title,
                                text: text,
                                action: `montage`,
                                payload: {
                                    montageId: id,
                                },
                            },
                            { root: true }
                        );
                    }
                }
            }

            if (["no_work", "work", "ready"].includes(obj.status)) {
                let date = new Date(oldData.date);
                date = date.toLocaleString("ru", { day: "numeric", month: "short" });
                let title = `Монтаж для ${oldData.client.name} на ${date}`;
                let text = `Обновился статус: ${montagesStatus[obj.status]}`;
                await dispatch(
                    "notify/notify",
                    {
                        to: manager.uid || manager.id,
                        title: title,
                        text: text,
                        action: `montage`,
                        payload: {
                            montageId: id,
                        },
                    },
                    { root: true }
                );
            }

            dispatch("socket/sendSocket", ["montages"], {
                root: true,
            });
            commit("templ/setLoading", false, {
                root: true,
            });
            return data;
        },
        async deleteMontage({ commit, rootState, dispatch }, data) {
            commit("templ/setLoading", true, {
                root: true,
            });
            data.del = true;
            if (data.client) {
                data.client_id = data.client.id;
                delete data.client;
            }

            // manager
            if (data.manager) {
                data.manager_id = data.manager.id;
                delete data.manager;
            }

            // brig
            if (data.brig) {
                data.brig_id = data.brig.id;
                delete data.brig;
            }
            if (data.tasks) {
                data.tasks = JSON.stringify(data.tasks);
            }
            data.images = String(data.images);
            data.montagersInfo = JSON.stringify(data.montagersInfo);

            await api
                .put(aR.montagesUpdate, data)
                .then(async () => {
                    console.log("Монтаж удален");
                    dispatch("socket/sendSocket", ["montages"], {
                        root: true,
                    });
                    if (data.status == "unassigned") {
                        await dispatch("loadUnassignedMontages");
                    }
                })
                .catch((err) => {
                    commit(
                        "templ/setError",
                        "Ошибка удаления монтажа, попробуйте позже."
                    );
                    console.log(err);
                })
                .finally(() => {
                    commit("templ/setLoading", false, {
                        root: true,
                    });
                });
        },
        montageSerializer({ state, dispatch }, ref) {
            let montage = ref;
            if (montage.manager_data) {
                montage = {
                    ...montage,
                    manager: montage.manager_data,
                };
                delete montage.manager_data;
            }
            if (montage.tasks) {
                // JSON brokes when bumps into non-printing characters

                try {
                    montage.tasks = JSON.parse(montage.tasks.replace(/\n/g, "\\n"));
                } catch (error) {
                    console.log(
                        "Illegal character in montage.task: " + montage.numberOrder
                    );
                }
            }
            if (montage.montagersInfo) {
                montage.montagersInfo = JSON.parse(montage.montagersInfo);
            }
            if (montage.brig_data) {
                montage = {
                    ...montage,
                    brig: montage.brig_data,
                };
                delete montage.brig_data;
            }

            if (montage.client_data) {
                montage = {
                    ...montage,
                    client: montage.client_data,
                };
                delete montage.client_data;
            }
            if (montage.images && montage.images !== "undefined") {
                montage.images = montage.images.split(",");
            } else {
                montage.images = [];
            }
            if (montage.priceForBrig) {
                montage.priceForBrig = Number(montage.priceForBrig);
            }

            return montage;
        },
        async getNewNumberMontage() {
            let toDay = new Date();
            let year = String(toDay.getFullYear()).substring(2);
            let month = toDay.getMonth() + 1;

            if (month < 10) {
                month = `0${month}`;
            }

            let date = new Date(),
                y = date.getFullYear(),
                m = date.getMonth();
            let firstDay = new Date(y, m, 1, 0);
            let lastDay = new Date(y, m + 1, 0, 23, 59);

            let numberInMonth = "01";

            let queryStore = await api
                .post(aR.montagesGetList, {
                    limit: 1,
                    offset: 0,
                    sort: "date",
                })
                .then((response) => {
                    if (response.status == 200) {
                        return response.data;
                    }
                });

            let lengthList = queryStore.count;
            lengthList = lengthList + 1;

            if (lengthList < 10) {
                numberInMonth = `0${lengthList}`;
            } else {
                numberInMonth = lengthList;
            }

            return `${year}${month}${numberInMonth}`;
        },
        setMontagesFromUser({ commit, dispatch }, user) {
            let montages = user.montages || [];
            montages = montages.map((montage) => {
                if (montage.manager_data) {
                    montage = {
                        ...montage,
                        manager: montage.manager_data,
                    };
                    delete montage.manager_data;
                }
                if (montage.tasks) {
                    montage.tasks = JSON.parse(montage.tasks);
                }
                if (montage.montagersInfo) {
                    montage.montagersInfo = JSON.parse(montage.montagersInfo);
                }
                if (montage.brig_data) {
                    montage = {
                        ...montage,
                        brig: montage.brig_data,
                    };
                    delete montage.brig_data;
                }

                if (montage.client_data) {
                    montage = {
                        ...montage,
                        client: montage.client_data,
                    };
                    delete montage.client_data;
                }
                if (montage.images) {
                    montage.images = montage.images.split(",");
                }
                if (montage.priceForBrig) {
                    montage.priceForBrig = Number(montage.priceForBrig);
                }
                return montage;
            });

            commit("setMontagesForMontager", montages);
            return montages;
        },
    },
};