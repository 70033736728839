<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="400px">
      <v-card>
        <v-card-title class="headline"></v-card-title>

        <v-card-text v-if="text" style="font-size: 1.2em;">
          {{ text }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="red darken-1"
            text
            @click="close()"
          >
            Закрыть
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: false
    }
  },
  data: () => ({
    dialog: true,
  }),
  methods: {
    close() {
      this.$emit('close', false)
    }
  }
}
</script>
