var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.user)?_c('div',[_c('SocketWatch',{attrs:{"tables":[
    'users', 
    'orders', 
    'montages', 
    'manufactory', 
    'hourlyWorks', 
    'deductions',
    'premiums',
    'prepayments',
    ]},on:{"loadData":_vm.loadData}}),(_vm.isMobile)?_c('MobPage'):_c('PcPage')],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }