<template>
  <div class="pageTasks" :class="{ 'pa-3': !isMobile }">
    <SocketWatch :tables="['tasks']" @loadData="loadData" />
    <v-card :tile="isMobile">
      <div class="d-flex align-center px-4">
        <v-row class="page_filterTable mt-3">
          <v-col cols="2">
            <v-text-field
              dense
              color="rgb(224, 166, 119)"
              v-model="filterArchiveTasksByMonthText"
              @click="selectMonth()"
              label="Месяц"
							clearable
            />
          </v-col>
					<v-col v-if="currentUser && currentUser.role != 'designer'"  cols="2">
            <v-autocomplete
              dense
              color="rgb(224, 166, 119)"
              v-model="filterArchiveTasksByTypeTask"
              @change="$store.dispatch('tasks/loadArchiveTasks')"
              label="Задачи"
              :items="typeTasksArr"
              return-object
              clearable
            />
          </v-col>
					<v-col v-if="currentUser && currentUser.role === 'admin'" cols="2">
            <v-autocomplete
              dense
              color="rgb(224, 166, 119)"
              v-model="filterArchiveTasksByManager"
              @change="$store.dispatch('tasks/loadArchiveTasks')"
              label="Менеджер"
              :items="managers"
              item-text="displayName"
              return-object
              clearable
            />
          </v-col>
					<v-col cols="1">
            <v-text-field
              dense
              color="rgb(224, 166, 119)"
              v-model="filterArchiveTasksByNumberOrder"
							@change="$store.dispatch('tasks/loadArchiveTasks')"
              label="Номер заказа"
            />
          </v-col>
					<!-- <v-col cols="2">
            <v-autocomplete
              dense
              color="rgb(224, 166, 119)"
              v-model="filterArchiveTasksByClient"
              @change="$store.dispatch('tasks/loadArchiveTasks')"
              label="Клиент"
              :items="clients"
              item-text="name"
              return-object
              clearable
            />
          </v-col> -->
					<v-col v-if="filterArchiveTasksByTypeTask && filterArchiveTasksByTypeTask.value === 'designer'" cols="2">
            <v-autocomplete
              dense
              color="rgb(224, 166, 119)"
              v-model="filterArchiveTasksByDesigner"
              @change="$store.dispatch('tasks/loadArchiveTasks')"
              label="Дизайнер"
              :items="designers"
              item-text="displayName"
              return-object
              clearable
            />
          </v-col>
        </v-row>
      </div>
      <v-divider />
      <v-skeleton-loader
        v-if="!tasksLoaded && (!tasks || tasks.length === 0)"
        class="pl-3 pr-3"
        type="table-thead, table-row-divider@3"
        :types="skeletonTypesIframe"
      />
      <div 
        v-if="tasksLoaded && (!tasks || tasks.length === 0)"
        class="pa-3 d-flex justify-center"
        :class="{ 'infoNoItems': !isMobile }"
      >
        Нет задач
      </div>
			<v-data-table
				v-if="tasks && tasks.length > 0"
				:footer-props="{
					'items-per-page-options': [5, 10, 15, 20, 30, 40]
				}"
				:headers="tableHeadres"
				:items="tasks"
				:items-per-page="30"
 				:dense="!isMobile"
				@click:row="openTask($event)"
			>
				<template v-slot:[`item.title`]="{ item }">
					{{ $sliceStr(item.title || item.description, 60) }}
				</template>
				<template v-slot:[`item.from`]="{ item }">
					<div class="d-flex align-center">
						<my-mini-avatar :user="item.from_data"/>
					</div>
				</template>
				<template v-slot:[`item.whoTo`]="{ item }">
					<div class="d-flex align-center">
						<div v-for="(whoTo, whoTo_index) in item.whoTo" :key="whoTo_index" class="mr-1">
							<my-mini-avatar
								v-if="whoTo_index < 3"
								:user="item.who_to_data[whoTo_index]"
							/>
							<span
								v-if="whoTo_index === 3 && item.whoTo.length > 3"
								class="grey--text text-caption"
							>
								(+{{ item.whoTo.length - 3 }})
							</span>
						</div>
					</div>
				</template>
				<template v-slot:[`item.deadline`]="{ item }">
					<span
						:style="
							`${$colorDeadline(new Date(item.deadline))}`"
					>
						{{ $formatDateTime(new Date(item.deadline)) }}
					</span>
				</template>
				<template v-slot:[`item.status`]="{ item }">
					<span>
						<template v-if="item && item.archive">В архиве</template>
								<template v-else-if="item && item.print">В печать</template>
								<template v-else-if="!item || !item.accepted">Ожидает</template>
								<template v-else-if="item && item.ready">Готово</template>
								<template v-else>В работе</template>
					</span>
				</template>
			</v-data-table>
    </v-card>
  </div>
</template>

<script>
import { isMobile } from 'mobile-device-detect'
import { typeTasksArr} from '@/helpers'
import TaskModal from  '@/components/Modals/Task/Index'
import SelectMonth from '@/components/Modals/SelectMonth'

export default {
  name: 'AllTasks',
  data: () => ({
    isMobile: isMobile,
		typeTasksArr: typeTasksArr,
    skeletonTypesIframe: {
      'table-thead': 'heading@4',
      'table-row': 'table-cell@4'
    },
    openFilters: false
  }),
  async beforeRouteLeave(to, from, next) {
    this.$store.commit('tasks/resetAllFilterArchiveTasks')
		this.$store.dispatch('tasks/loadArchiveTasks')
    next()
  },
  created() {
		this.loadData()
  },
  computed: {
		tableHeadres() {
			return [
				{
					text: 'Задача',
					align: 'start',
					sortable: false,
					value: 'title',
					width: '47%'
				},
				{
					text: 'Номер заказа',
					align: 'start',
					sortable: false,
					value: 'numberOrder',
				},
				{
					text: 'От кого',
					align: 'start',
					sortable: false,
					value: 'from',
				},
				{
					text: 'Кому',
					align: 'start',
					sortable: false,
					value: 'whoTo',
				},
				{
					text: 'Дедлайн',
					align: 'start',
					sortable: false,
					value: 'deadline',
				},
				{
					text: 'Статус',
					align: 'start',
					sortable: false,
					value: 'status',
				},
			]
		},
		currentUser() {
			return this.$store.getters['user/getUser']
		},
		managers() {
      return this.$store.getters['users/getManagers']
    },
		// clients() {
    //   return this.$store.getters['clients/getClients']
    // },
		designers() {
			return this.$store.getters['users/getDesignerUsers']
    },
		filterArchiveTasksByMonthText: {
			get() {
				let month = this.filterArchiveTasksByMonth
				if (month) {
					return String(new Date(month).toLocaleString('ru', {month: 'long', year: 'numeric'}))
				}
				return null
			},
			set(val) {
				this.filterArchiveTasksByMonth = val
				this.$store.dispatch('tasks/loadArchiveTasks')
			}
    },
    filterArchiveTasksByMonth: {
      get() { return this.$store.getters['tasks/getFilterArchiveTasksByMonth'] },
      set(val) { this.$store.commit('tasks/setFilterArchiveTasksByMonth', val) }
    },
		filterArchiveTasksByManager: {
      get() { return this.$store.getters['tasks/getFilterArchiveTasksByManager'] },
      set(val) { this.$store.commit('tasks/setFilterArchiveTasksByManager', val) }
    },
		filterArchiveTasksByNumberOrder: {
      get() { return this.$store.getters['tasks/getFilterArchiveTasksByNumberOrder'] },
      set(val) { this.$store.commit('tasks/setFilterArchiveTasksByNumberOrder', val) }
    },
		filterArchiveTasksByTypeTask: {
      get() { return this.$store.getters['tasks/getFilterArchiveTasksByTypeTask'] },
      set(val) { this.$store.commit('tasks/setFilterArchiveTasksByTypeTask', val) }
    },
		filterArchiveTasksByClient: {
      get() { return this.$store.getters['tasks/getFilterArchiveTasksByClient'] },
      set(val) { this.$store.commit('tasks/setFilterArchiveTasksByClient', val) }
    },
		filterArchiveTasksByDesigner: {
      get() { return this.$store.getters['tasks/getFilterArchiveTasksByDesigner'] },
      set(val) { this.$store.commit('tasks/setFilterArchiveTasksByDesigner', val) }
    },
		tasks() {
			return this.$store.getters['tasks/getArchiveTasks']
		},
    tasksLoaded() {
      // return this.$store.getters['montages/getUnassignedMontagesLoaded']
			return true
    }
  },
  methods: {
    loadData() {
      this.$store.dispatch('tasks/loadArchiveTasks')
      this.$store.dispatch('users/loadManagers')
      // this.$store.dispatch('clients/loadClients')
    },
		async selectMonth() {
      let modalMonth = await this.$showModal({
        component: SelectMonth,
        isPersistent: true,
        props: {
          title: 'Выберите месяц',
          selectMonth: this.filterArchiveTasksByMonth
        }
      })
      if (modalMonth) {
        this.filterArchiveTasksByMonth = modalMonth
				this.$store.dispatch('tasks/loadArchiveTasks')
      }
    },
		async openTask(task) {
      let readyTask = task;

if(!Array.isArray(task.images) && task.images.length !== 0) {
  readyTask = {...task ,images: task.images.split(',')};
} else if(task.images.length === 0){
  readyTask = {...task, images: []}
}

      await this.$showModal({
        component: TaskModal,
        isPersistent: true,
        props: {
          edit: true,
          data: readyTask
        }
      })
    }
	}
}
</script>
