import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"pa-0 materials"},[[_c(VCardTitle,{staticClass:"py-2 pr-2"},[_c(VListItemTitle,{style:(`max-width: calc(100% - ${_vm.selectMaterial ? 40 : 32}px);`)},[(_vm.selectedCategory)?_c('span',[_vm._v(_vm._s(_vm.selectedCategory.title))]):_c('span',[_vm._v("Выберите категорию")])]),_c(VSpacer),(_vm.currentUser.role === 'admin')?[_c(VBtn,{attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.editMaterial()}}},[_c(VIcon,[_vm._v("mdi-pencil")])],1)]:_vm._e()],2),_c(VDivider)],(_vm.selectMaterial)?_c('Material',{attrs:{"material":_vm.selectMaterial}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }