<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      max-width="600px"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Добавить изображение с устройства</span>
          <v-spacer />
          <v-btn icon color="rgb(224, 166, 119)" @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="formAddImageByUrl">
              <v-row>
                <v-col cols="12">
                  <v-file-input
                    v-model="file"
                    accept="image/*"
                    color="rgb(224, 166, 119)"
                    prepend-icon="mdi-camera"
                    label="Выберите изображение"
                    :rules="$fieldRules.field"
                    required
                    :loading="uploadingLocalImage || uploadingImage"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="
              !file || !fileRemote || uploadingLocalImage || uploadingImage
            "
            color="orange"
            text
            @click="handleAdd"
          >
            <span>Добавить</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div ref="file" style="display: none"></div>
  </v-row>
</template>

<script>
const fileUrl = process.env.VUE_APP_FILE_URL;
// Mixins
import { UploadImageMixin } from "@/mixins/uploadImage";
//

export default {
  mixins: [UploadImageMixin],
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data: () => ({
    dialog: true,
    errors: {},
    file: null,
    fileRemote: null,
    uploadingLocalImage: false,
    uploadingImage: false,
  }),
  watch: {
    file(newVal) {
      if (newVal && newVal.name) {
        this.uploadImage(newVal, "faq");
      }
    },
  },
  created() {
    // Mixins
    const _this = this;
    this.UIMprops = {
      uploadingImage: true,
      onUpload: (filePath) => {
        _this.fileRemote = filePath;
      },
    };
  },
  methods: {
    async handleAdd() {
      this.$emit("close", { url: this.fileRemote });
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.$emit("close");
      }, 200);
    },
  },
};
</script>
