import api from "@/utils/api";
import { parseIfString } from "../../../utils/json";
import filterClients from "./filter";

export default {
  namespaced: true,
  state: {
    clients: [],
    client: null,
    searchClients: "",
    // filters
    ...filterClients.state,
    filterClients: [],
    tableClients: [],
    limit: 0,
    offset: 0,
    count: 0,
  },
  getters: {
    getClients: (state) => {
      let clients = state.clients;
      let search = state.searchClients.toLowerCase();
      if (search) {
        clients = clients.filter((x) => {
          if (x.name) {
            let name = x.name.toLowerCase();
            if (name.includes(search)) {
              return true;
            }
          }
          if (x.surname) {
            let surname = x.surname.toLowerCase();
            if (surname.includes(search)) {
              return true;
            }
          }
          if (x.contacts) {
            for (let contact of x.contacts) {
              let contactName = contact.name.toLowerCase();
              if (contactName.includes(search)) {
                return true;
              }

              let phonenumber = String(contact.phonenumber);
              if (phonenumber.length > 0) {
                phonenumber = phonenumber.replace("(", "");
                phonenumber = phonenumber.replace(")", "");
                phonenumber = phonenumber.replace("-", "");
                phonenumber = phonenumber.replace("-", "");
                phonenumber = phonenumber.replace(/\s/g, "");
                if (phonenumber.includes(search)) {
                  return true;
                }
              }
            }
          }
          return false;
        });
      }
      const sortedClients = clients.sort(function(a, b) {
        // return new Date(b.created) - new Date(a.created);
        let nameA = a.name.toLowerCase(),
          nameB = b.name.toLowerCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      return sortedClients;
    },
    getClient: (state) => state.client,
    getFilterClients: (state) => state.filterClients,
    getTableClients: (state) => state.tableClients,
    getSearchClients: (state) => {
      return state.searchClients;
    },
    count: (state) => state.count,
    // filters
    ...filterClients.getters,
  },
  mutations: {
    setClients(state, payload) {
      state.clients = payload;
    },
    setClient(state, payload) {
      state.client = payload;
    },
    setFilterClients(state, payload) {
      state.filterClients = payload;
    },
    setTableClients(state, payload) {
      state.tableClients = payload;
    },
    setSearchClients(state, payload) {
      state.searchClients = payload;
    },
    updateClient(state, client) {
      let clients = state.clients.map((el) => {
        if (el.id == client.id) {
          el = client;
        }
        return el;
      });
      state.clients = clients;
      // state.client = client;
    },
    // updateFilterClients(state, client) {
    //   let filterClients = state.filterClients.map(el => {
    //     if (el.id === client.id) {
    //       el = client
    //     }
    //     return el
    //   })
    //   state.filterClients = filterClients;
    // },
    pushClient(state, client) {
      let clients = state.clients;
      clients.push(client);
      state.clients = clients;
    },
    deleteClient(state, client) {
      let clients = state.clients.filter((el) => el.id != client.id);

      state.clients = clients;
    },
    SET_LIMIT(state, payload) {
      state.limit = payload;
    },
    SET_OFFSET(state, payload) {
      state.offset = payload;
    },
    SET_COUNT(state, payload) {
      state.count = payload;
      // console.log('SET_COUNT',payload)
    },
    // filters
    ...filterClients.mutations,
  },
  actions: {
    async loadClients({ commit, getters, state }) {
      commit("templ/setLoading", true, {
        root: true,
      });
      // let users = await api.post('users/get-list', {
      //   limit: state.limit,
      //   offset: state.offset
      // }).then(response => {
      //   if (response.status == 200) {
      //     return response.data.data
      //   }
      // })

      let queryAnd = [
        {
          input: "del",
          value: false,
        },
      ];

      let filterByManager = getters.getFilterClientByManager;
      if (filterByManager && filterByManager.id) {
        queryAnd.push({
          input: "creator_id",
          value: filterByManager.id,
        });
      }

      let searchClients = state.searchClients;
      if (searchClients.length !== 0) {
        queryAnd.push({
          input: "name",
          value: searchClients,
        });
      }

      let queryStore = await api.post("clients/get-list", {
        limit: state.limit,
        offset: state.offset,
        query_and: queryAnd,
      });

      commit("SET_COUNT", queryStore.data.count);
      queryStore = queryStore.data.data || [];
      queryStore = queryStore.map((el) => {
        if (el.contacts) {
          el.contacts = parseIfString(el.contacts);
        }
        return el;
      });
      commit("setClients", queryStore || []);
      commit("templ/setLoading", false, {
        root: true,
      });
    },

    async loadTableClients({ commit, getters, state }) {
      commit("templ/setLoading", true, {
        root: true,
      });
      // let users = await api.post('users/get-list', {
      //   limit: state.limit,
      //   offset: state.offset
      // }).then(response => {
      //   if (response.status == 200) {
      //     return response.data.data
      //   }
      // })

      let queryAnd = [
        {
          input: "del",
          value: false,
        },
      ];

      let filterByManager = getters.getFilterClientByManager;
      if (filterByManager && filterByManager.id) {
        queryAnd.push({
          input: "creator_id",
          value: filterByManager.id,
        });
      }

      let searchClients = state.searchClients;
      if (searchClients.length !== 0) {
        queryAnd.push({
          input: "name",
          value: searchClients,
        });
      }

      let queryStore = await api.post("/clients/get-list-table", {
        limit: state.limit,
        offset: state.offset,
        query_and: queryAnd,
      });

      commit("SET_COUNT", queryStore.data.count);
      queryStore = queryStore.data.data || [];
      queryStore = queryStore.map((el) => {
        if (el.contacts) {
          el.contacts = parseIfString(el.contacts);
        }
        return el;
      });
      commit("setTableClients", queryStore || []);
      commit("templ/setLoading", false, {
        root: true,
      });
    },

    async loadClient({ commit, dispatch, rootState }, number) {
      console.log("====", "loadClient");
      commit("templ/setLoading", true, { root: true });
      let queryStore = await api.get("clients/get", number);

      queryStore = queryStore.data.data || {};

      if (queryStore.contacts) {
        queryStore.contacts = parseIfString(queryStore.contacts);
      }
      console.log(queryStore);
      commit("setClient", queryStore || null);

      commit("templ/setLoading", false, { root: true });

      return queryStore;
    },

    async loadFilterClients({ commit, getters, state }) {
      commit("templ/setLoading", true, {
        root: true,
      });

      let queryStore = await api.get("/clients/get-list-filter");

      queryStore = queryStore.data.data || [];

      commit("setFilterClients", queryStore || []);
      commit("templ/setLoading", false, {
        root: true,
      });
    },

    async createClient({ commit, dispatch, rootGetters }, payload) {
      commit("templ/setLoading", true, {
        root: true,
      });
      let user = rootGetters["user/getUser"];
      // let user = await api.get('users/get','7')
      // let userRef = usersCollection.doc(user.uid)
      // let userRef = await axios.get(`${url}/users/get/${user.uid}`)

      let data = {
        ...payload,
        created: new Date(),
        del: false,
      };
      // data.address = JSON.stringify(user.address)
      if (data.creator) {
        // let creator = usersCollection.doc(data.creator.uid)
        // let creatorData = await axios.get(`${url}/users/get/${data.creator.id}`)
        data = {
          ...data,
          creator_id: user.id,
          // creatorData: data.creator.id
          // creatorData: {
          // 	uid: data.creator.uid,
          // 	del: data.creator.del,
          // 	displayName: data.creator.displayName,
          // 	email: data.creator.email,
          // 	location: data.creator.location
          // },
        };
      } else {
        // data = {
        // 	...data,
        // 	creator: userRef,
        // 	creatorData: {
        // 		uid: user.uid,
        // 		del: user.del,
        // 		displayName: user.displayName,
        // 		email: user.email,
        // 		location: user.location
        // 	}
        // }
        data = {
          ...data,
          creator_id: user.id,
          // creatorData: {
          //     uid: user.id,
          //     del: user.del,
          //     displayName: user.displayName,
          //     email: user.email,
          //     location: user.location
          // }
        };
      }

      // let newClient = await clientsCollection.add(data);
      // console.log(data)
      if (data.contacts) {
        data.contacts = JSON.stringify(data.contacts);
      }
      let newClient = await api
        .post("clients/create", {
          ...data,
        })
        .then((response) => {
          if (response.status == 200) {
            return response.data.clients;
          }
        });
      dispatch("socket/sendSocket", ["clients"], {
        root: true,
      });
      commit("pushClient", newClient);
      commit("templ/setLoading", false, {
        root: true,
      });
      return newClient;
    },
    async updateClient({ commit, rootGetters, dispatch }, payload) {
      commit("templ/setLoading", true, {
        root: true,
      });
      // let user = await api.get(`${url}/users/get/7`).then(response => {
      //     if(response.status == 200){
      //         return response.data.data
      //     }
      // })
      // let user = rootGetters['user/getUser']
      // let userRef = usersCollection.doc(user.uid)

      let data = {
        ...payload.data,
      };
      // data.address = JSON.stringify(user.address)
      if (data.creator) {
        // let creator = usersCollection.doc(data.creator.uid)
        // if(obj.creator){

        // }
        data = {
          ...data,
          creator_id: data.creator.id,
          // creatorData: {
          //     uid: data.creator.uid,
          //     del: data.creator.del,
          //     displayName: data.creator.displayName,
          //     email: data.creator.email,
          //     location: data.creator.location
          // },
          // creatorData: data.creator.id
        };
      } else {
        data = {
          ...data,
          // creator: userRef,
          // creator: String(user.id),
          // creatorData: {
          //     uid: user.uid,
          //     del: user.del,
          //     displayName: user.displayName,
          //     email: user.email,
          //     location: user.location
          // }
          // creatorData: user.id
        };
      }
      if (data.contacts) {
        data.contacts = JSON.stringify(data.contacts);
      }
      if (Object.keys(data).length > 0) {
        // await clientsCollection.doc(payload.id).update(data)

        await api
          .put("clients/update", {
            id: payload.id,
            ...data,
          })
          .then((response) => {
            console.log("Клиент обновлён");
            if (response.data.clients.contacts) {
              response.data.clients.contacts = parseIfString(
                response.data.clients.contacts
              );
            }
            commit("updateClient", response.data.clients);
            // commit('updateFilterClients', response.data.clients)
            commit("templ/setLoading", false, {
              root: true,
            });
          })
          .catch((err) => {
            commit(
              "templ/setError",
              "Ошибка обновления клиента, попробуйте позже."
            );
            console.log(err);
          });
        dispatch("socket/sendSocket", ["clients"], {
          root: true,
        });
      }
      commit("templ/setLoading", false, {
        root: true,
      });
    },
    async deleteClient({ commit, rootState, dispatch }, data) {
      commit("templ/setLoading", true, {
        root: true,
      });
      // await clientsCollection.doc(data.id).update({ del: true })
      await api
        .delete("clients/delete", {
          id: data.id,
        })
        .then(() => {
          console.log("Клиент удалён");
          commit("deleteClient", data);
          commit("templ/setLoading", false, {
            root: true,
          });
        })
        .catch((err) => {
          commit(
            "templ/setError",
            "Ошибка удаления клиента, попробуйте позже."
          );
          console.log(err);
        });
      dispatch("socket/sendSocket", ["clients"], {
        root: true,
      });
    },
  },
};
