<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" fullscreen transition="dialog-bottom-transition">
      <v-card :tile="isMobile">
        <v-card-text>
          <v-container class="pa-0">
            <v-form ref="formCategory">
              <v-row>
                <v-col cols="12">
                  <TextEditor v-model="form.material" />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-container class="pa-0 d-flex">
            <v-spacer></v-spacer>
            <v-btn color="grey" text @click="close()">
              <span>Отмена</span>
            </v-btn>
            <v-btn color="orange" text @click="save()">
              <span>Сохранить</span>
            </v-btn>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import TextEditor from "@/components/Faq/Pages/components/TextEditor/Index.vue";
import { Role, RoleText } from "@/CONSTS/roles";
import { isMobile } from "mobile-device-detect";

export default {
  components: {
    TextEditor,
  },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
    available: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data: () => ({
    isMobile: isMobile,
    role: Role,
    roleText: RoleText,
    dialog: true,
    errors: {},
    form: {
      title: "",
      available: [],
      material: "",
    },
  }),
  computed: {
    userRoles() {
      let items = Object.values(this.role);
      let indexAdmin = items.findIndex((x) => x === "admin");
      if (indexAdmin != -1) {
        items.splice(indexAdmin, 1);
      }
      return items;
    },
  },
  created() {
    if (this.data && this.data.id) {
      this.form.title = this.data.title;
      this.form.material = this.data.material;
      this.form.available = this.data.available;
    } else {
      this.form.available = this.available;
    }
  },
  methods: {
    async save() {
      const errors = [];
      const validate = await this.$refs.formCategory.validate();
      errors.push(validate);

      if (!errors.includes(false)) {
        this.$emit("close", this.form);
      }
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.$emit("close");
      }, 200);
    },
  },
};
</script>

<style lang="scss">
.material-editor {
}
</style>
