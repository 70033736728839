import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VForm,{ref:"formNewTask",staticClass:"d-flex align-center",staticStyle:{"width":"100%"}},[_c(VRow,[_c(VCol,{staticClass:"py-0"},[_c(VTextField,{ref:"title",attrs:{"dense":"","color":"rgb(224, 166, 119)","label":"Задача мне","rules":_vm.$fieldRules.field},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.save()}},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1),_c(VCol,{staticClass:"py-0",attrs:{"cols":"2"}},[_c(VTextField,{ref:"deadline",attrs:{"dense":"","color":"rgb(224, 166, 119)","value":_vm.deadlineText,"label":"Дедлайн","clearable":"","rules":_vm.$fieldRules.date},on:{"click":function($event){return _vm.selectDate()},"click:clear":function($event){return _vm.clearDate()}}})],1)],1),_c(VBtn,{staticClass:"ml-3",attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.save()}}},[_c(VIcon,{attrs:{"size":"22"}},[_vm._v("mdi-keyboard-return")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }