import { func, usersCollection, brigsCollection, locationsCollection } from '@/plugins/firebase'
import api from '@/utils/api'
export default {
    namespaced: true,
    state: {
        brigs: []
    },
    getters: {
        getBrigs: state => {
            const sortedBrigs = state.brigs.sort(function(a,b){
                return new Date(b.created) - new Date(a.created);
            })
            return sortedBrigs
        },
    },
    mutations: {
        setBrigs(state, payload) {
            state.brigs = payload;
        },
        pushBrig(state, payload) {
            state.brigs.push(payload)
        },
        updateBrig(state, brig) {
            const brigIndex = state.brigs.findIndex(x => x.id === brig.id)
            if (brigIndex != -1) {
                Vue.set(state.brigs, brigIndex, brig)
            }
        },
        deleteBrigs(state, id) {
            const indexBrig = state.brigs.findIndex(x => x.id === id)
            if (indexBrig != -1) {
                state.brigs.splice(indexBrig, 1)
            }
        },
    },
    actions: {
        async loadBrigs({ commit, dispatch }) {
            commit('templ/setLoading', true, { root: true })
            await api.post('brigs/get-list',{
                limit: 10000,
                offset: 0,
                query: [{input: 'del', value: false}]
            }).then(response=>{
                if(response.status == 200){
                    let query = response.data.data || []
                    
                    query.forEach(el =>{
                        // if(el.users.length > 0){
                        //     el.users = JSON.parse(el.users)
                        // }
                        if(!el.users_data){
                            el.users_data = []
                        }
                    })
                    // console.log('brigs',query)
                    commit('setBrigs',query)
                    commit('templ/setLoading', false, { root: true })
                }
            })

            // brigsCollection.where('del', '!=', true).onSnapshot(async snapshot => {
            //     let brigsArr = []
            //     for (let doc of snapshot.docs) {
            //         let brig = await dispatch('brigSerializer', { ...doc.data(), id: doc.id })
            //         brigsArr.push(brig)
            //     }
            
            //     commit('setBrigs', brigsArr)
            //     commit('templ/setLoading', false, { root: true })
            // })
        },
        async createBrig({ commit, dispatch }, payload) {
            commit('templ/setLoading', true, { root: true })
            console.log(payload)

            const data = { ...payload, del: false }

            if (data.users && data.users.length > 0) {
                let refs = []
                for (let user of data.users) {
                    // let userRef = await usersCollection.doc(user.uid)
                    // refs.push(userRef)
                    refs.push(user.id)
                }
                data.users = refs
            }
            
            await api.post('brigs/create',data)
            // await brigsCollection.doc()
            //     .set(data)
            //     .catch(console.error);
            dispatch('socket/sendSocket', ['brigs'], {
                root: true
            })
            commit('templ/setLoading', false, { root: true })
            dispatch('users/loadUsers', null, { root: true })
        },
        async updateBrig({ commit, dispatch }, payload) {
            commit('templ/setLoading', true, { root: true })
            const data = { id: payload.id,...payload.data }
            // console.log(data)
            if (Object.keys(data).length > 0) {
                // if (data.users) {
                //     let refs = []
                //     for (let user of data.users) {
                //         let userRef = await usersCollection.doc(user.uid)
                //         refs.push(userRef)
                //     }
                //     data.users = refs
                // }
                // data = {id: payload.id,...data}
                // brigsCollection.doc(payload.id).update(data)
                if(data.users){
                    data.users = data.users.map(el => {
                        el = el.id
                        return el
                    })
                }
                await api.put('brigs/update',data)
                    .then(async () => {
                        console.log('Бригада обновлена')
                        await dispatch('loadBrigs')
                    }).catch((err) => {
                        commit('templ/setError', 'Ошибка обновления бригады, попробуйте позже.')
                        console.log(err)
                    }).finally(() => {
                        commit('templ/setLoading', false, { root: true })
                        dispatch('users/loadUsers', null, { root: true })
                    })
                    dispatch('socket/sendSocket', ['brigs'], {
                        root: true
                    })
            }
        },
        async deleteBrig({ commit, dispatch }, payload) {
            commit('templ/setLoading', true, { root: true })
            // await brigsCollection.doc(payload.id).update({ del: true })
            await api.delete('brigs/delete',{id:payload.id})
                .then(() => {
                    console.log('Бригада удалена')
                }).catch((err) => {
                    commit('templ/setError', 'Ошибка удаления бригады, попробуйте позже.')
                    console.log(err)
                }).finally(() => {
                    commit('templ/setLoading', false, { root: true })
                    dispatch('users/loadUsers', null, { root: true })
                })
                dispatch('socket/sendSocket', ['brigs'], {
                    root: true
                })
        },
        async brigSerializer({ state, dispatch }, ref) {
            let brig = ref
            // if (ref.id) {
            //   brig = ref
            // } else {
            //   let res = await ref.get()
            //   brig = { ...res.data(), id: res.id }
            // }

            let users = [ ...brig.users ]
            if (!brig.location) {
                brig = { ...brig, location: 'Не указан' }
            }

            brig.users = []

            for (let ref of users) {
                let user = await dispatch('users/userSerializer', ref, { root: true })
                if (user.del) {
                    user = { ...user, displayName: `${user.displayName}(Удалён)` }
                }
                brig.users.push(user)
            }

            return brig
        }
    }
}