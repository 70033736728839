// Main
import ViewHome from '@/views/home/ViewHome.vue'
import Home from '@/components/PagesByRole/Admin/Home.vue'

// Montages
import ViewMontages from '@/views/montages/ViewMontages.vue'
import UnassignedMontages from '@/components/Montages/Pages/UnassignedMontages.vue'
import AssignedMontages from '@/components/Montages/Pages/AssignedMontages.vue'
import Montages from '@/components/Montages/Pages/Montages.vue'


const adminPcRoutes = [
  {
    path: '/',
    component: ViewHome,
    meta: {
      order: 0,
      navDrawer: true,
      text: 'Главная',
      icon: 'mdi-home'
    },
    children: [
      { path: '', name: 'Home', component: Home },
    ]
  },
  {
    path: '/montages',
    component: ViewMontages,
    meta: {
      order: 4,
      navDrawer: true,
      text: 'Монтажи',
      icon: 'mdi-truck-minus',
      tabs: [
        { path: '/montages', text: 'Не назначенные' },
        { path: '/montages/assigned', text: 'Назначенные' },
        { path: '/montages/all', text: 'Все монтажи' }
      ]
    },
    children: [
      { path: '', name: 'UnassignedMontages', component: UnassignedMontages },
      { path: 'assigned', component: AssignedMontages },
      { path: 'all', component: Montages },
    ]
  }
]

export default adminPcRoutes
