import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"iframe iframe_widget iframeWidgetAssignedMontagesInBrig pa-1"},[_c('SocketWatch',{attrs:{"tables":['manufactory']},on:{"loadData":_vm.loadData}}),_c(VCard,[_c(VCardTitle,[_c(VIcon,{staticClass:"mr-2"},[_vm._v("mdi-archive-cog")]),_vm._v(" Задания в производство "),_c(VSpacer),_c(VBtn,{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.createTask()}}},[_c(VIcon,[_vm._v("mdi-plus")])],1)],1),_c(VDivider),(!_vm.tasksLoaded && (!_vm.tasks || _vm.tasks.length === 0))?_c(VSkeletonLoader,{staticClass:"pl-3 pr-3",attrs:{"type":"table-thead, table-row-divider@3","types":_vm.skeletonTypesIframe}}):_vm._e(),(_vm.tasksLoaded && (!_vm.tasks || _vm.tasks.length === 0))?_c('div',{staticClass:"infoNoItems"},[_vm._v(" Нет заданий ")]):_vm._e(),(_vm.tasks && _vm.tasks.length > 0)?_c(VSimpleTable,{attrs:{"dense":!_vm.isMobile},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Дедлайн")]),_c('th',{staticClass:"text-left"},[_vm._v("Клиент")]),_c('th',{staticClass:"text-left"},[_vm._v("Менеджер")])])]),_c('tbody',_vm._l((_vm.tasks),function(task){return _c('tr',{key:task.id,staticClass:"tableItemList",on:{"click":function($event){return _vm.editTask(task)}}},[_c('td',{staticStyle:{"min-width":"80px"}},[_vm._v(_vm._s(_vm.$formatDate(new Date(task.date))))]),_c('td',{staticStyle:{"min-width":"145px"}},[_vm._v(" "+_vm._s(task.client.name)+" ")]),_c('td',[_vm._v(_vm._s(task.manager.displayName))])])}),0)]},proxy:true}],null,false,3566707323)}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }