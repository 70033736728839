import { locationsCollection } from '@/plugins/firebase'
import api from '@/utils/api'
export default {
  namespaced: true,
  state: {
    locations: []
  },
  getters: {
    getLocations: state => {
      const sortedLocations = state.locations.sort(function(a,b){
        return a.order- b.order
      })
      return sortedLocations
    },
  },
  mutations: {
    setLocation(state, payload) {
      state.locations = payload
    }
  },
  actions: {
    async loadLocations({ commit }) {
      commit('templ/setLoading', true, { root: true })
      await api.post('locations/get-list',{
        limit: 10000,
        offset: 0,
        query: [ {input: 'del', value: false} ]
      }).then(response => {
        if(response.status == 200){
          // console.log(response.data.data, "response.data.data")
          commit('setLocation',response.data.data)
          commit('templ/setLoading',false, { root: 'true'})
        } 
      })
      // locationsCollection.where('del', '!=', true).get().then(snapshot => {
      //   let locationsArr = []
      
      //   snapshot.forEach(doc => {
      //     let location = { ...doc.data(), id: doc.id }
      //     locationsArr.push(location)
      //   })
      
      //   commit('setLocation', locationsArr)
      //   commit('templ/setLoading', false, { root: true })
      // })
    },
    async createLocations({ commit }, data ) {
      const res = await api.post('locations/create', data)
      console.log(res)
    }
  }
}