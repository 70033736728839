<template>
  <v-row>
    <v-col cols="4" class="mb-2">
      <v-card dark>
        <v-card-title class="text-h6">
          Количество заказов
        </v-card-title>
        <v-card-subtitle class="text-h2 green--text font-weight-black">
          {{ ordersAll }}
        </v-card-subtitle>
        <v-card-text>
          Сумма за месяц:
          <span class="font-weight-bold white--text"> {{ ordersReadySum }} руб.</span>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="4" class="mb-2">
      <v-card dark>
        <v-card-title class="text-h6">
          Cейчас в работе
        </v-card-title>
        <v-card-subtitle class="text-h2 green--text font-weight-black">
          {{ ordersWorking }}
        </v-card-subtitle>
        <v-card-text>
          Сумма:
          <span class="font-weight-bold white--text"> {{ ordersWorkingSum }} руб.</span>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="4" class="mb-2">
      <v-card dark>
        <v-card-title class="text-h6">
          Bыполненых заказов
        </v-card-title>
        <v-card-subtitle class="text-h2 green--text font-weight-black">
          {{ ordersReady }}
        </v-card-subtitle>

        <v-card-text>
          Сумма за месяц:
          <span class="font-weight-bold white--text"> {{ ordersReadySum }} руб.</span>
        </v-card-text>
      </v-card>
    </v-col>
    <!-- <v-col cols="12">
  <pre>{{ ordersAll }}</pre>
</v-col> -->
  </v-row>
</template>

<script>
export default {
  name: 'IndexUserHeader',
  props: {
    user: {
      type: Object,
      default: {},
    },
  },
  computed: {
    filterUserDataByMonthText() {
      let month = this.filterUserDataByMonth;
      return String(new Date(month).toLocaleString('ru', { month: 'long', year: 'numeric' }));
    },
    filterUserDataByMonth: {
      get() {
        return this.$store.getters['users/getFilterUserDataByMonth'];
      },
      set(val) {
        this.$store.commit('users/setFilterUserDataByMonth', val);
      },
    },
    earned() {
      if (!this.user.id) return 0;
      let earned = 0;

      let premiums = this.premiums;
      if (premiums && premiums.length > 0) {
        premiums.forEach((premiums) => {
          earned = Number(earned) + Number(premiums.cost);
        });
      }

      if (this.montages && this.montages.length > 0) {
        this.montages.forEach((montage) => {
          if (montage.montagersInfo) {
            if (montage.montagersInfo[this.user.id]) {
              if (montage.montagersInfo[this.user.id].costMontage) {
                earned = Number(earned) + Number(montage.montagersInfo[this.user.id].costMontage);
              }
            }
          }
        });
      }

      let tasks = this.tasks;
      if (tasks && tasks.length > 0) {
        tasks.forEach((task) => {
          if (task.montagersInfo[this.user.id] && task.montagersInfo[this.user.id].costWork) {
            earned = Number(earned) + Number(task.montagersInfo[this.user.id].costWork);
          }
        });
      }

      let orders = this.orders;
      if (orders && orders.length > 0) {
        orders.forEach((order) => {
          // fullCost
          let fullCost = 0;
          let percent = order.percentOfOrder || 0;
          order.works.forEach((work) => {
            fullCost = fullCost + Number(work.cost);
          });

          earned = Number(earned) + Number((fullCost * percent) / 100);
        });
      }

      let hourlyWorks = this.hourlyWorks;
      if (hourlyWorks && hourlyWorks.length > 0) {
        hourlyWorks.forEach((work) => {
          if (work.hourPayWorkMasters) {
            let ifHPWM = this.$ifHourPayWorkMasters(work, this.user);
            earned = Number(earned) + ifHPWM.cost;
          } else if (work.numberOfHours) {
            let rate = 0;
            if (work.rate) {
              rate = work.rate;
            }
            earned = Number(earned) + Number(work.numberOfHours) * rate;
          }
        });
      }

      return earned;
    },
    hourlyWorks() {
      return this.$store.getters['hourlyWorks/getTasks'];
    },
    montages() {
      return this.$store.getters['montages/getMontagesForMontager'];
    },
    tasks() {
      return this.$store.getters['manufactory/getTasksForMaster'];
      // return this.currentUser.role != 'admin' ? this.currentUser.manufactory || [] : this.user.manufactory || []
    },
    ordersAll() {
      return this.$store.getters['orders/count'];
    },
    ordersReady() {
      return this.$store.getters['orders/readyCount'];
    },

    ordersWorking() {
      return this.$store.getters['orders/allCount'] - this.$store.getters['orders/allReadyCount'];
    },

    ordersAllSum() {
      return this.$store.getters['orders/sum'];
    },
    ordersReadySum() {
      return this.$store.getters['orders/readySum'];
    },

    ordersWorkingSum() {
      return this.$store.getters['orders/allSum'] - this.$store.getters['orders/allReadySum'];
    },

    prepayments() {
      return this.$store.getters['prepayments/getPrepayments'];
      // return this.user.prepayments
    },
    deductions() {
      return this.$store.getters['deductions/getDeductions'];
      // return this.user.prepayments
    },
    premiums() {
      return this.$store.getters['premiums/getPremiums'];
      // return this.user.premiums
    },

    userRole() {
      return this.user.role;
    },
  },
};
</script>
