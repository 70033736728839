
// Main
import ViewHome from '@/views/home/ViewHome.vue'
import Home from '@/components/PagesByRole/Designer/Home.vue'

// Tasks
import ViewTasks from '@/views/tasks/ViewTasks.vue'
import Tasks from '@/components/Tasks/Pages/Index.vue'
import TasksArchive from '@/components/Tasks/Pages/TasksArchive.vue'

// Users
import ViewUsers from '@/views/users/ViewUsers.vue'
import User from '@/components/Users/Pages/User/Index.vue'

// Faq
import ViewFaq from '@/views/faq/ViewFaq.vue'
import Faq from '@/components/Faq/Pages/Index.vue'

const designerRoutes = [
  {
    path: '/',
    component: ViewHome,
    meta: {
      order: 0,
      navDrawer: true,
      text: 'Главная',
      icon: 'mdi-home'
    },
    children: [
      { path: '', name: 'Home', component: Home },
    ]
  },
	{
    path: '/tasks',
    component: ViewTasks,
    meta: {
      order: 2,
      navDrawer: true,
      text: 'Задачи',
      icon: 'mdi-message-text-clock-outline',
			tabs: [
				{ path: '/tasks', text: 'Задачи мне' },
				{ path: '/tasks/from-me', text: 'От меня' },
        { path: '/tasks/archive', text: 'Архив' },
      ]
    },
    children: [
			{ path: '', name: 'TasksMe', component: Tasks },
      { path: 'from-me', name: 'TasksFromMe', component: Tasks },
      { path: 'archive', name: 'TasksArchive', component: TasksArchive }
    ]
  },
	{
    path: '/profile',
    component: ViewUsers,
    meta: {
      order: 5,
      navDrawer: true,
      text: 'Финансы',
      icon: 'mdi-account'
    },
    children: [
      { path: '', name: 'Profile', component: User },
    ]
  },
	{
    path: '/faq',
    component: ViewFaq,
    meta: {
      order: 6,
      navDrawer: true,
      text: 'Помощникус',
      icon: 'mdi-book-open-variant'
    },
    children: [
      { path: '', name: 'Faq', component: Faq }
    ]
  }
]

export default designerRoutes
