// Controlling v-data-table pagination, cleaning pagination on unmount.

const PaginationMixin = {
  data: () => ({
    PM: {
      mounted: false,
    },
    // Opened variable
    paginationOptions: {
      page: 1,
      itemsPerPage: 30,
    },
  }),
  async beforeRouteLeave(to, from, next) {
    const props = this.PMProps;

    if (!props.noClean) {
      props.setLimit(0);
      props.setOffset(0);
    }
    next();
  },

  async mounted() {
    const props = this.PMProps;

    // initialize data
    this.paginationOptions.itemsPerPage = props.itemsPerPage;
    //

    props.setLimit(this.paginationOptions.itemsPerPage);
    props.onInit();
    this.PMInitRoot();
  },
  watch: {
    paginationOptions(newValue, oldValue) {
      const _this = this;
      const root = _this.PM;
      const props = _this.PMProps;

      // not execute when forse state
      if (root.events.includes(root.eventsEnum.forceState + "wpo")) {
        // remove received event
        root.events = root.events.filter(
          (e) => e !== root.eventsEnum.forceState + "wpo"
        );
        return;
      }
      // Not trigger when not changed or equal to initial value
      if (!root.isPaginationChanged(newValue, oldValue)) return;

      props.setLimit(newValue.itemsPerPage);
      props.setOffset(root.calcOffset(newValue.page, newValue.itemsPerPage));
      props.load();
    },
  },
  methods: {
    PMInitRoot() {
      // Init PM Namespaced variables
      const _this = this;
      const root = _this.PM;
      const props = _this.PMProps;

      // Events format - eventEnumName + first letters of position(watch, method etc) and method name
      root.events = [];

      // methods
      root.calcOffset = function (page, itemsPerPage) {
        return (page - 1) * itemsPerPage;
      };
      root.isPaginationChanged = function (
        newValue,
        oldValue = _this.paginationOptions
      ) {
        const props = _this.PMProps;
        // Returns true if changed

        const pageChanged = newValue.page !== oldValue.page;

        const itemsPerPageChanged =
          newValue.itemsPerPage !== oldValue.itemsPerPage;

        return pageChanged || itemsPerPageChanged;
      };

      root.forceState = function (newState) {
        // updating if changed
        if (!root.isPaginationChanged(newState)) return;

        // add event
        root.events.push(root.eventsEnum.forceState + "wpo");

        _this.paginationOptions = { ..._this.paginationOptions, ...newState };
        root.updateClientState(_this.paginationOptions);
      };

      root.updateClientState = function (newState) {
        props.setLimit(newState.itemsPerPage);
        props.setOffset(root.calcOffset(newState.page, newState.itemsPerPage));
      };

      root.eventsEnum = {
        forceState: "forceState",
      };

      root.mounted = true;
    },
  },
};

export { PaginationMixin };
