import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,{attrs:{"justify":"center"}},[_c(VDialog,{attrs:{"fullscreen":_vm.isMobile,"max-width":!_vm.isMobile ? '600px' : '',"transition":"dialog-bottom-transition","scrollable":!_vm.isMobile,"persistent":!_vm.isMobile},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{attrs:{"tile":_vm.isMobile}},[(_vm.isMobile)?_c(VToolbar,{staticStyle:{"position":"sticky","top":"0px","z-index":"20"},attrs:{"color":"rgb(224, 166, 119)","tile":""}},[(_vm.edit)?_c(VToolbarTitle,{staticClass:"white--text"},[_vm._v("Редактировние категории")]):_c(VToolbarTitle,{staticClass:"white--text"},[_vm._v("Новая категории")]),_c(VSpacer),_c(VBtn,{attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.close()}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1):_c(VCardTitle,[(_vm.edit)?_c('span',{staticClass:"headline"},[_vm._v("Редактировние категории")]):_c('span',{staticClass:"headline"},[_vm._v("Новая категории")]),_c(VSpacer),_c(VBtn,{attrs:{"icon":"","color":"rgb(224, 166, 119)"},on:{"click":function($event){return _vm.close()}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,[_c(VContainer,{class:{ 'pa-0': _vm.isMobile }},[_c(VForm,{ref:"formCategory"},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"color":"rgb(224, 166, 119)","label":"Название категории*","rules":_vm.$fieldRules.field,"required":""},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VSelect,{attrs:{"items":_vm.userRoles,"label":"Доступна для","multiple":"","color":"rgb(224, 166, 119)","item-color":"rgb(224, 166, 119)"},scopedSlots:_vm._u([{key:"selection",fn:function({ item, index }){return [(index < 3)?_c('span',[_c('span',[_vm._v(_vm._s(_vm.roleText[item]))]),(
                          _vm.form.available && index < _vm.form.available.length - 1
                        )?_c('span',{staticClass:"mr-1"},[_vm._v(",")]):_vm._e()]):_vm._e(),(index === 3)?_c('span',[_vm._v(" (+"+_vm._s(_vm.form.available.length - 3)+") ")]):_vm._e()]}},{key:"item",fn:function({ item, attrs, on }){return [_c(VListItem,_vm._g(_vm._b({scopedSlots:_vm._u([{key:"default",fn:function({ active }){return [_c(VListItemAction,[_c(VCheckbox,{attrs:{"color":"rgb(224, 166, 119)","ripple":false,"input-value":active}})],1),_c(VListItemContent,[_c(VListItemTitle,[_c(VRow,{attrs:{"no-gutters":"","align":"center"}},[_vm._v(" "+_vm._s(_vm.roleText[item])+" ")])],1)],1)]}}],null,true)},'v-list-item',attrs,false),on))]}}]),model:{value:(_vm.form.available),callback:function ($$v) {_vm.$set(_vm.form, "available", $$v)},expression:"form.available"}})],1)],1)],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"orange","text":""},on:{"click":function($event){return _vm.save()}}},[(_vm.edit)?_c('span',[_vm._v("Сохранить")]):_c('span',[_vm._v("Создать")])])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }