// Enums
export const Role = {
  admin: "admin",
  master: "master",
  manager: "manager",
  prodDir: "prodDir",
  designer: "designer",
};
export const RoleText = {
  [Role.admin]: "Администратор",
  [Role.master]: "Мастер",
  [Role.manager]: "Менеджер",
  [Role.prodDir]: "Начальник производства",
  [Role.designer]: "Дизайнер",
};
