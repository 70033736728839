import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemActionText } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VListItem,{staticClass:"itemDropListNotif",class:{ 'itemDropListNotif--noAccepted': !_vm.item.accepted },attrs:{"three-line":_vm.item.text.length > 49},on:{"click":function($event){return _vm.$openModalFromNotif(_vm.item)}}},[_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(_vm.item.title)}}),_c(VListItemSubtitle,{domProps:{"textContent":_vm._s(_vm.item.text)}})],1),_c(VListItemAction,[_c(VListItemActionText,{domProps:{"textContent":_vm._s(_vm.getLocalDate(_vm.item.date))}}),_c(VBtn,{staticStyle:{"right":"-8px"},attrs:{"icon":"","color":"grey"},on:{"click":function($event){$event.stopPropagation();return _vm.acceptedNotify(_vm.item)}}},[_c(VIcon,[_vm._v(" mdi-hand-okay ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }