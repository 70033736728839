<template>
	<v-row>
		<v-col cols="12" class="py-0">
			<v-form ref="newContact">
				<v-row>
					<v-col cols="6">
						<v-text-field
							ref="formName"
							@keyup.enter="newContact()"
							color="#e0a677"
							v-model="form.name"
							label="Имя"
							:rules="$fieldRules.field"
							required
						/>
					</v-col>
					<v-col cols="5">
						<v-text-field
							ref="formPhonenumber"
							@keyup.enter="newContact()"
							color="#e0a677"
							v-model="form.phonenumber"
							v-mask="'+7(###) ##-##-###'"
							placeholder="+7("
							label="Номер телефона"
							:rules="$fieldRules.phonenumber"
							required
						/>
					</v-col>
					<v-col cols="1" class="d-flex align-center pt-1">
						<v-btn 
							:disabled="!form.name || !form.phonenumber"
							icon small
							@click="newContact()"
						>
							<v-icon size="22">mdi-keyboard-return</v-icon>
						</v-btn>
					</v-col>
				</v-row>
			</v-form>
		</v-col>
		<v-col cols="12" class="pt-0">
			<v-data-table
				:headers="headers"
				:items="contacts"
				hide-default-header
				hide-default-footer
				item-key="name"
			>
				<template v-slot:[`item.action`]="{ item }">
					<div class="d-flex align-center justify-end">
						<v-checkbox
							class="newContact_checkbox"
							:readonly="item.main"
							color="#e0a677"
							:input-value="item.main"
							@change="setMainContact($event, item)"
						/>
						<v-btn 
							icon
							@click="removeContact(item)"
							class="mb-1"
						>
							<v-icon size="22" color="red">mdi-trash-can-outline</v-icon>
						</v-btn>
					</div>
				</template>
			</v-data-table>
		</v-col>
	</v-row>
</template>

<script>
import Confirm from  '@/components/Modals/Confirm'

export default {
	props: ['contacts'],
	data: () => ({
		selected: [],
		headers: [
			{ text: 'Имя', value: 'name' },
			{ text: 'Телефон', value: 'phonenumber' },
			{ text: 'Действия', value: 'action', align: 'end' },
		],
		form: {
			name: '',
			phonenumber: '+7('
		}
	}),
	methods: {
		newContact() {
			const validForm = this.$refs.newContact.validate()

			if (validForm) {
				this.$emit('newContact', { ...this.form })
				this.form.name = ''
				this.form.phonenumber = '+7('
				this.$refs.formName.blur()
				this.$refs.formPhonenumber.blur()
				this.$refs.newContact.resetValidation()
			}
		},
		async removeContact(item) {
			const res = await this.$showModal({
				component: Confirm,
				isPersistent: true,
				props: {
					title: `Удалить контакт`,
					text: `Действительно хотите удалить контакт: ${item.name}?`
				}
			})

			if (res) {
				this.$emit('removeContact', item)
			}
		},
		setMainContact(val, item) {
			this.$emit('setMainContact', { val, item })
		},
	}
}
</script>