<template>
	<div style="height: 100%;" class="d-flex align-start flex-column">
		<div style="width: 100%;" class="mb-auto">
			<v-card class="pa-0" tile flat>
				<v-card-title 
					class="pa-1 pl-0"
					style="font-size: 1.1em; line-height: 1.3em;"
				>
					Стоимость:
					<span class="pl-1" style="font-weight: 400;">
						{{ fullCost }}р.
					</span>
				</v-card-title>
			</v-card>
			<v-card class="pa-0" tile flat>
				<v-card-title 
					class="pa-1 pl-0"
					style="font-size: 1.1em; line-height: 1.3em;"
				>
					Оплачено:
					<span class="pl-1" style="font-weight: 400;">
						{{ paid }}р.
					</span>
				</v-card-title>
			</v-card>
			<v-card class="pa-0" tile flat>
				<v-card-title 
					class="pa-1 pl-0"
					style="font-size: 1.1em; line-height: 1.3em;"
				>
					Остаток:
					<span 
						class="pl-1"
						:style="`font-weight: 400; color: ${fullCost > paid ? 'red' : 'black'};`"
					>
						{{ fullCost - paid }}р.
					</span>
				</v-card-title>
			</v-card>
			<v-divider />
			<v-card class="pa-0 pt-3" tile flat>
				<v-text-field
          dense
					:disabled="order.archive"
          color="rgb(224, 166, 119)"
          :value="deadlineText"
          @click="$emit('selectDeadline')"
          label="Дедлайн"
          ref="deadline"
        />
			</v-card>
			<v-card class="pa-0" tile flat>
				<v-select
					dense
					:disabled="order.archive"
					color="rgb(224, 166, 119)"
					:value="form.status"
					label="Статус"
					:items="orderStatusArr"
					@change="$emit('updStatus', $event)"
				/>
			</v-card>
			<v-card class="pa-0" tile flat>
				<v-form ref="formPaid" class="d-flex align-center" style="width: 100%;">
					<v-row no-gutters class="pa-0">
						<v-col class="pa-0 pr-2" cols="8">
							<v-select
								dense
								:disabled="order.archive"
								color="rgb(224, 166, 119)"
								v-model="newPay.type"
								label="Оплата"
								:items="orderTypePayArr"
							/>
						</v-col>
						<v-col class="pa-0 pl-2" cols="4">
							<v-text-field
								dense
								:disabled="order.archive"
								color="rgb(224, 166, 119)"
								v-model="newPay.amount"
								label="Сумма"
								:rules="[...$fieldRules.cost, v => ((v && v <= fullCost - paid) && v > 0) || 'Ошибка' ]"
								required
								type="number"
							/>
						</v-col>
					</v-row>
					<v-btn :disabled="order.archive" icon small class="ml-3" @click="addPay()">
						<v-icon size="22">mdi-keyboard-return</v-icon>
					</v-btn>
				</v-form>
				<v-divider v-if="form.paid && form.paid.length > 0" />
				<v-simple-table v-if="form.paid && form.paid.length > 0" dense>
					<template v-slot:default>
						<tbody>
							<tr
								v-for="pay in form.paid"
								:key="pay.id"
							>
								<td class="px-1 text-left" style="font-size: 1em !important;">{{ pay.amount }}р.</td>
								<td 
									class="px-1 text-center"
									style="min-width: 80px; font-size: 1em !important;"
								>
									<span v-if="pay.date.seconds">{{ $formatDate(new Date(pay.date)) }}</span>
									<span v-else>{{ $formatDate(new Date(pay.date)) }}</span>
								</td>
								<td
									class="px-1 text-right"
									style="font-size: 1em !important;"
								>{{ orderTypePay[pay.type] }}</td>
								<td class="px-1 text-right">
									<v-btn @click.stop="deletePay(pay)" icon small color="#bf4037">
										<v-icon size="18">mdi-trash-can-outline</v-icon>
									</v-btn>
								</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>
			</v-card>
		</div>
		<div style="width: 100%;">
			<div
				v-if="
					['draft', 'ready', 'failure'].includes(form.status) &&
					(order.creator_id === user.id || user.role === 'admin')
				"
			>
				<div>
					<v-card-subtitle class="pa-0">Процент менеджера</v-card-subtitle>
					<div class="d-flex align-center">
						<v-btn
							class="px-0 mb-2"
							small
							text
							:disabled="order.archive || order.status === 'failure'"
							@click="$emit('updPercentOfOrder', 10)"
						>10%</v-btn>
						<v-btn
							class="px-0 mb-2"
							small
							text
							:disabled="order.archive || order.status === 'failure'"
							@click="$emit('updPercentOfOrder', 5)"
						>5%</v-btn>
						<v-btn
							class="px-0 mb-2"
							small
							text
							:disabled="order.archive || order.status === 'failure'"
							@click="$emit('updPercentOfOrder', 15)"
						>15%</v-btn>
						<v-text-field
							class="ml-2"
							:disabled="order.archive || order.status === 'failure'"
							dense
							color="rgb(224, 166, 119)"
							:value="percentOfOrder"
							@change="$emit('updPercentOfOrder', $event)"
							placeholder="Нулевой"
							ref="percentOfOrder"
							suffix="%"
						/>
					</div>
				</div>
				<v-btn
					v-if="
						['draft', 'ready', 'failure'].includes(form.status) &&
						(order.creator_id === user.id || user.role === 'admin') &&
						!order.archive
					"
					:disabled="order.archive || (form.status === 'ready' && fullCost != paid) || !entityReadiness"
					block
					color="#bc4037"
					class="mt-2 white--text"
					@click="clickToArchive()"
				>
					<v-icon left> 
						mdi-archive
					</v-icon>
					В архив
				</v-btn>
				<v-btn
					v-if="
						['draft'].includes(form.status) &&
						(order.creator_id === user.id || user.role === 'admin') &&
						order.archive
					"
					block
					color="green"
					class="mt-2 white--text"
					@click="$emit('update', { archive: false, archiveDate: null })"
				>
					<v-icon left>
						mdi-archive-refresh
					</v-icon>
					Убрать из архива
				</v-btn>
			</div>
			<!-- <v-btn
				v-if="user.role === 'admin'"
				block
				color="#bc4037"
				class="mt-2 white--text"
				@click="$emit('update', { del: true })"
			>
				<v-icon left>
					mdi-trash-can-outline
				</v-icon>
				Удалить
			</v-btn> -->
		</div>
	</div>
</template>

<script>
import Confirm from  '@/components/Modals/Confirm'
import { orderStatusArr, orderTypePayArr, orderTypePay } from '@/helpers'

export default {
	name: 'ModalOrderRightBar',
	props: [
		'order',
		'user',
		'form',
		'fullCost',
		'paid',
		'deadlineText',
		'percentOfOrder',
		'entityReadiness'
	],
  data: () => ({
    orderStatusArr: orderStatusArr,
    orderTypePayArr: orderTypePayArr,
    orderTypePay: orderTypePay,
		newPay: {
			type: 'cash',
			amount: ''
		}
  }),
	methods: {
		async clickToArchive() {

			if (!(this.percentOfOrder > 0)) {
				const res = await this.$showModal({
					component: Confirm,
					isPersistent: true,
					props: {
						title: `В архив`,
						text: `Вы указали нулевой процент за заказ. Отправить в архив?`
					}
				})
				if (res) {
					this.$emit('update', { archive: true, archiveDate: new Date() })
				}
			} else {
				this.$emit('update', { archive: true, archiveDate: new Date(), percentOfOrder: this.percentOfOrder })
			}
		},
		deletePay(pay) {
			this.$emit('deletePay', pay)
		},
		addPay() {
			let validate = this.$refs.formPaid.validate()
			if (validate) {
				this.$emit('newPay', this.newPay)
				this.newPay = {
					type: 'cash',
					amount: ''
				}
				this.$refs.formPaid.resetValidation()
			}
		}
	}
}
</script>