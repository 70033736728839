import filterTasks from "./filter";
import loadedTasks from "./loaded";

import api from "@/utils/api";

export default {
  namespaced: true,
  state: {
    allTasks: [],
    archiveTasks: [],
    // loaded
    ...loadedTasks.state,
    // filters
    ...filterTasks.state,
    limit: 0,
    offset: 0,
    count: 0,
  },
  getters: {
    getAllTasks: (state) => {
      let tasks = state.allTasks;
      let search = state.filterTasksByNumberOrder
        ? state.filterTasksByNumberOrder.toLowerCase()
        : null;

      if (search) {
        tasks = tasks.filter((x) => {
          if (x.numberOrder) {
            let numberOrder = x.numberOrder.toLowerCase();
            if (numberOrder.includes(search)) {
              return true;
            }
          }
          return false;
        });
      }

      //   const sortedTasks = tasks.sort(function(a,b){
      //     return a.deadline.toDate() - b.deadline.toDate();
      //   })
      //   return sortedTasks
      return tasks;
    },
    count: (state) => state.count,
    getArchiveTasks: (state) => {
      let tasks = state.archiveTasks;
      let search = state.filterArchiveTasksByNumberOrder
        ? state.filterArchiveTasksByNumberOrder.toLowerCase()
        : null;

      if (search) {
        tasks = tasks.filter((x) => {
          if (x.numberOrder) {
            let numberOrder = x.numberOrder.toLowerCase();
            if (numberOrder.includes(search)) {
              return true;
            }
          }
          return false;
        });
      }

      const sortedTasks = tasks.sort(function(a, b) {
        // return a.deadline.toDate() - b.deadline.toDate();
        return new Date(a.deadline) - new Date(b.deadline);
      });
      return sortedTasks;
    },
    // loaded
    ...loadedTasks.getters,
    // filters
    ...filterTasks.getters,
  },
  mutations: {
    setTasks(state, payload) {
      state.allTasks = payload;
    },
    setArchiveTasks(state, payload) {
      state.archiveTasks = payload;
    },
    addTask(state, task) {
      let tasks = state.allTasks;
      tasks.push(task);
      state.allTasks = tasks;
    },
    updateTask(state, task) {
      let tasks = state.allTasks.map((el) => {
        if (el.id == task.id) {
          el = task;
        }
        return el;
      });
      state.allTasks = tasks;
      // oldTask = task
      // console.log(oldTask)
    },
    SET_LIMIT(state, payload) {
      state.limit = payload;
    },
    SET_OFFSET(state, payload) {
      state.offset = payload;
    },
    SET_COUNT(state, payload) {
      state.count = payload;
    },
    // loaded
    ...loadedTasks.mutations,
    // filters
    ...filterTasks.mutations,
  },
  actions: {
    async loadTaskById({ commit, dispatch, getters }, id) {
      commit("templ/setLoading", true, {
        root: true,
      });

      // let queryStore = tasksCollection.doc(id)
      let queryStore = await api.get("tasks/get/", id);
      // let doc = await queryStore.get()
      let doc = queryStore.data.data;
      // doc = { ...doc.data(), id: doc.id }
      // el.from = users.find(user => user.id == el.from)
      doc.from_data ? (doc.from = doc.from_data) : "";
      doc.whoTo = doc.who_to_data || [];
      doc.whoToData = doc.who_to_data || [];
      doc.whoTo = doc.whoTo.map((whoTo) => {
        whoTo = whoTo.id;
        return whoTo;
      });
      doc.color ? (doc.color = JSON.parse(doc.color)) : "";
      doc.images ? (doc.images = doc.images.split(",")) : "";
      // el.whoToData = users.find(user => user.id == el.whoToData);
      commit("templ/setLoading", false, {
        root: true,
      });
      return doc;
    },
    async updateTask({ commit, rootGetters, dispatch }, obj) {
      commit("templ/setLoading", true, {
        root: true,
      });
      let user = rootGetters["user/getUser"];
      //   let doc = tasksCollection.doc(obj.id)
      //   await doc.update(obj.data)
      if (obj.data.color) {
        obj.data.color = JSON.stringify(obj.data.color);
      }
      if (obj.data.images) {
        obj.data.images = String(obj.data.images);
      }
      if (obj.data.whoToData) {
        delete obj.data.whoToData;
      }
      await api
        .put("tasks/update", {
          id: obj.id,
          ...obj.data,
        })
        .then(async (response) => {
          if (response.status == 200) {
            response.data.tasks.from_data
              ? (response.data.tasks.from = response.data.tasks.from_data)
              : "";
            response.data.tasks.whoTo = response.data.tasks.who_to_data || [];
            response.data.tasks.whoToData =
              response.data.tasks.who_to_data || [];
            response.data.tasks.color
              ? (response.data.tasks.color = JSON.parse(
                  response.data.tasks.color
                ))
              : "";
            // if(response.data.tasks.whoTo.length){
            response.data.tasks.whoTo = response.data.tasks.whoTo.map(
              (whoTo) => {
                whoTo = whoTo.id;
                return whoTo;
              }
            );
            // }
            commit("updateTask", response.data.tasks);

            const updatedTask = response.data.tasks;

            if (updatedTask && !updatedTask.archive && !obj.data.color) {
              let whoTo = [...updatedTask.whoTo];
              if (!whoTo.includes(updatedTask.from.id)) {
                whoTo = [...whoTo, updatedTask.from.id];
              }
              let indexIID = whoTo.findIndex((x) => x === user.id);
              if (indexIID != -1) {
                whoTo.splice(indexIID, 1);
              }

              let title = `${user.displayName} обновил(а) задачу ${updatedTask.title}`;
              let text = "Проверьте изменения";

              if (obj.data.ready != undefined) {
                if (obj.data.print && obj.data.ready === false) {
                  text = `Статус: в печать.`;
                } else {
                  text = `Статус: ${obj.data.ready ? "готово" : "в работе"}.`;
                }
              }

              if (obj.data.description != undefined) {
                text = `Изменилось описание`;
              }

              if (whoTo && whoTo.length > 0) {
                for (let to of whoTo) {
                  await dispatch(
                    "notify/notify",
                    {
                      to,
                      title: title,
                      text: text,
                      action: "updateTask",
                      payload: {
                        taskId: obj.id,
                      },
                    },
                    { root: true }
                  );
                }
              }
            }
            dispatch("socket/sendSocket", ["tasks"], { root: true });
          }
        });
      commit("templ/setLoading", false, {
        root: true,
      });
    },
    async loadArchiveTasks({ commit, dispatch, getters, rootGetters, state }) {
      commit("templ/setLoading", true, {
        root: true,
      });

      //   if (snapshots.loadArchiveTasks) {
      //     snapshots.loadArchiveTasks()
      //     snapshots.loadArchiveTasks = null
      //   }

      let user = rootGetters["user/getUser"];

      // let queryStore = tasksCollection
      // let queryStore2 = null

      let filterByMonth = getters.getFilterArchiveTasksByMonth;
      let filterByTypeTask = getters.getFilterArchiveTasksByTypeTask;
      let queryStore;
      let filterByManager = getters.getFilterArchiveTasksByManager;
      let filterByDesigner = getters.getFilterArchiveTasksByDesigner;
      let lastDay;
      if (filterByManager && filterByManager.id) {
        filterByManager = filterByManager.id;
      }

      if (filterByDesigner && filterByDesigner.id) {
        filterByDesigner = filterByDesigner.id;
      }

      let filterByClient = getters.getFilterArchiveTasksByClient;
      if (filterByClient && filterByClient.id) {
        filterByClient = filterByClient.id;
      }

      if (filterByMonth) {
        filterByMonth = filterByMonth.split("-");
        let date = new Date(filterByMonth[0], filterByMonth[1], 0);
        lastDay = date.getDate();
        filterByMonth = filterByMonth.join("-");
        queryStore = await api.post("tasks/get-list", {
          limit: state.limit,
          offset: state.offset,
          date_from: `${filterByMonth}-01 00:00:01.344+03:00`,
          date_to: `${filterByMonth}-${lastDay} 23:59:59.344+03:00`,
          query_and: [
            {
              input: "del",
              value: false,
            },
            {
              input: "archive",
              value: true,
            },
            user.role != "admin" && !filterByTypeTask
              ? {
                  input: "from_id",
                  value: user.id,
                }
              : null,
            filterByTypeTask && filterByTypeTask.value === "fromMe"
              ? ({
                  input: "design",
                  value: false,
                },
                {
                  input: "me_Task",
                  value: false,
                })
              : null,
            filterByTypeTask && filterByTypeTask.value === "designer"
              ? {
                  input: "design",
                  value: true,
                }
              : null,
            filterByManager
              ? {
                  input: "from_id",
                  value: filterByManager,
                }
              : null,
            filterByClient
              ? {
                  input: "client_id",
                  value: filterByClient,
                }
              : null,
          ],
        });
      } else {
        queryStore = await api.post("tasks/get-list", {
          limit: state.limit,
          offset: state.offset,
          query_and: [
            {
              input: "del",
              value: false,
            },
            {
              input: "archive",
              value: true,
            },
            user.role != "admin" && !filterByTypeTask
              ? {
                  input: "from_id",
                  value: user.id,
                }
              : null,
            filterByTypeTask && filterByTypeTask.value === "designer"
              ? {
                  input: "design",
                  value: true,
                }
              : null,
            filterByManager
              ? {
                  input: "from_id",
                  value: filterByManager,
                }
              : null,
            filterByTypeTask && filterByTypeTask.value === "fromMe"
              ? {
                  input: "from_id",
                  value: user.id,
                }
              : null,
            filterByClient
              ? {
                  input: "client_id",
                  value: filterByClient,
                }
              : null,
          ],
        });
      }
      commit("SET_COUNT", queryStore.data.count);
      if (user.role === "designer") {
        // queryStore = queryStore.where('whoTo', 'array-contains', user.uid)
        queryStore = queryStore.data.data.filter((el) =>
          el.whoTo.includes(user.id)
        );
      } else {
        if (filterByTypeTask) {
          if (filterByTypeTask.value === "me") {
            queryStore = queryStore.data.data.filter((el) =>
              el.whoTo.includes(user.id)
            );
          }
          if (filterByTypeTask.value === "designer") {
            let filterByDesigner = getters.getFilterArchiveTasksByDesigner;
            if (filterByDesigner && filterByDesigner.id) {
              // queryStore = queryStore.where('whoTo', 'array-contains', filterByDesigner.uid)
              queryStore = queryStore.data.data.filter((el) =>
                el.whoTo.includes(filterByDesigner.id)
              );
            }
          }
        } else if (user.role != "admin") {
          queryStore = queryStore.data.data.filter((el) =>
            el.whoTo.includes(user.id)
          );
        }
      }

      queryStore.data ? (queryStore = queryStore.data.data) : [];
      // queryStore = queryStore.data.data || []
      if (queryStore.from) {
        queryStore = queryStore.map((el) => {
          el.from_data ? (el.from = el.from_data) : "";
          el.whoTo = el.who_to_data || [];
          el.whoToData = el.who_to_data || [];
          el.whoTo = el.whoTo.map((whoTo) => {
            whoTo = whoTo.id;
            return whoTo;
          });
          el.color ? (el.color = JSON.parse(el.color)) : "";
          el.images ? (el.images = el.images.split(",")) : "";
          // el.whoToData = users.find(user => user.id == el.whoToData);
          return el;
        });
      }
      commit("setArchiveTasks", queryStore);
      commit("templ/setLoading", false, {
        root: true,
      });
    },
    async loadAllTasks(
      { commit, dispatch, getters, rootGetters, state },
      currentTasks
    ) {
      commit("templ/setLoading", true, {
        root: true,
      });
      let user = rootGetters["user/getUser"];

      let filterByStatus = getters.getFilterTasksByStatus;
      let filterByClient = getters.getFilterTasksByClient;
      if (filterByClient && filterByClient.id) {
        filterByClient = filterByClient.id;
      }
      let filterByMonth = getters.getFilterTasksByMonth;
      let lastDay;
      let queryStore;
      let filterByManager = getters.getFilterTasksByManager;
      let filterByDesigner = getters.getFilterTasksByDesigner;

      if (filterByManager && filterByManager.id) {
        filterByManager = filterByManager.id;
      }
      if (filterByDesigner && filterByDesigner.id) {
        filterByDesigner = filterByDesigner.id;
      }
      const queryAnd = [];
      const queryDate = {};
      const query = [];

      if (filterByMonth) {
        filterByMonth = filterByMonth.split("-");
        let date = new Date(filterByMonth[0], filterByMonth[1], 0);
        lastDay = date.getDate();
        filterByMonth = filterByMonth.join("-");

        queryDate.date_from = `${filterByMonth}-01 00:00:01.344+03:00`;
        queryDate.date_to = `${filterByMonth}-${lastDay} 23:59:59.344+03:00`;
      }

      if (user.role === "designer") {
        if (currentTasks === "TasksFromMe") {
          queryAnd.push({
            input: "from_id",
            value: user.id,
          });
        } else {
          query.push({
            input: "who_to",
            value: `,${user.id},`,
          });
          query.push({
            input: "who_to",
            value: `[${user.id}]`,
          });
          query.push({
            input: "who_to",
            value: `,${user.id}]`,
          });
          query.push({
            input: "who_to",
            value: `[${user.id},`,
          });
        }
      } else {
        if (currentTasks === "TasksDesigner") {
          queryAnd.push({
            input: "design",
            value: true,
          });
          if (filterByManager) {
            queryAnd.push({
              input: "from_id",
              value: filterByManager,
            });
          }

          if (filterByDesigner) {
            query.push({
              input: "who_to",
              value: `,${filterByDesigner},`,
            });
            query.push({
              input: "who_to",
              value: `[${filterByDesigner}]`,
            });
            query.push({
              input: "who_to",
              value: `,${filterByDesigner}]`,
            });
            query.push({
              input: "who_to",
              value: `[${filterByDesigner},`,
            });
          }

          // if (filterByDesigner) {
          //   queryAnd.push({
          //     input: 'whoTo',
          //     value: filterByDesigner
          //   })
          // }
        }

        if (currentTasks === "TasksFromMe") {
          queryAnd.push({
            input: "design",
            value: false,
          });
          queryAnd.push({
            input: "me_task",
            value: false,
          });
          if (filterByManager) {
            queryAnd.push({
              input: "from_id",
              value: filterByManager,
            });
          }
        }

        if (currentTasks === "TasksMe") {
          if (filterByManager) {
            query.push({
              input: "who_to",
              value: `,${filterByManager},`,
            });
            query.push({
              input: "who_to",
              value: `[${filterByManager}]`,
            });
            query.push({
              input: "who_to",
              value: `,${filterByManager}]`,
            });
            query.push({
              input: "who_to",
              value: `[${filterByManager},`,
            });
          }
        }

        if (filterByClient) {
          queryAnd.push({
            input: "client_id",
            value: filterByClient,
          });
        }
      }

      if (filterByStatus) {
        if (filterByStatus === "printing") {
          queryAnd.push({
            input: "print",
            value: true,
          });
        } else {
          queryAnd.push({
            input: "print",
            value: false,
          });
          queryAnd.push({
            input: "ready",
            value: filterByStatus == "ready" ? true : false,
          });
        }
      }

      // if (filterByManager) {
      // 	query.push({ input: 'from_id', value: filterByManager })
      // }

      // console.log(queryAnd, 'query_and')

      queryStore = await api.post("tasks/get-list", {
        limit: state.limit,
        offset: state.offset,
        sort: "deadline ASC",
        ...queryDate,
        query,
        query_and: [
          {
            input: "del",
            value: false,
          },
          {
            input: "archive",
            value: false,
          },
          ...queryAnd,
        ],
      });
      // if (currentTasks === 'TasksDesigner' && filterByDesigner) {
      //   queryStore = queryStore.where('whoTo', 'array-contains', filterByDesigner);
      // }
      console.log(queryStore, "checkpoint");

      if (user.role === "designer") {
        // queryStore = queryStore.where('whoTo', 'array-contains', user.uid)
        // queryStore = await api.post('tasks/get-list',{
        //     limit: 10000,
        //     offset: 0,
        //     query: user.id? [{input: 'whoTo', value:user.id}] : ''
        // });
        // queryStore.data.data = queryStore.data.data.filter(el => el.whoTo.includes(user.id))
        // console.log('designer',queryStore)
      } else {
        // if (currentTasks === 'TasksDesigner') {
        // 	let filterByDesigner = getters.getFilterTasksByDesigner
        // 	if (filterByDesigner && filterByDesigner.id) {
        // 		// queryStore = queryStore.where('whoTo', 'array-contains', filterByDesigner.uid)
        // 		queryStore.data.data = queryStore.data.data.filter(el => el.whoTo.includes(filterByDesigner.id))
        // 	}
        // }
        // if (currentTasks === 'TasksMe') {
        // 	if (filterByManager && filterByManager.id) {
        // 		// queryStore = queryStore.where('whoTo', 'array-contains', filterByManager.uid)
        // 		queryStore.data.data = queryStore.data.data.filter(el => el.whoTo.includes(filterByManager.id))
        // 	}
        // }
      }
      if (filterByDesigner && filterByDesigner.id) {
        // queryStore = queryStore.where('whoTo', 'array-contains', filterByDesigner.uid)
        queryStore = queryStore.data.data.filter((el) => {
          console.log(el, filterByDesigner);
          return el.whoTo.includes(filterByDesigner.id);
        });
      }

      queryStore.data
        ? commit("SET_COUNT", queryStore.data.count)
        : commit("SET_COUNT", queryStore.length);

      queryStore.data ? (queryStore = queryStore.data.data) : queryStore;
      queryStore = queryStore.map((el) => {
        // el.from = users.find(user => user.id == el.from)
        el.from_data ? (el.from = el.from_data) : "";
        el.whoTo = el.who_to_data || [];
        el.whoToData = el.who_to_data || [];
        el.whoTo = el.whoTo.map((whoTo) => {
          whoTo = whoTo.id;
          return whoTo;
        });
        el.color ? (el.color = JSON.parse(el.color)) : "";
        el.images ? (el.images = el.images.split(",")) : "";
        return el;
      });

      commit("setTasks", queryStore);
      commit("templ/setLoading", false, {
        root: true,
      });
    },
    async getNewNumberTask({ state }) {
      let toDay = new Date();
      let year = String(toDay.getFullYear()).substring(2);
      let month = toDay.getMonth() + 1;

      if (month < 10) {
        month = `0${month}`;
      }

      let date = new Date(),
        y = date.getFullYear(),
        m = date.getMonth();
      // let firstDay = new Date(y, m, 1, 0)
      // let lastDay = new Date(y, m + 1, 0, 23, 59)

      // console.log(`Дата по UTC: ${date}`)
      // console.log(`Первый день(${firstDay}), последний день(${lastDay})`)

      let numberInMonth = "01";
      // let taskQueryStore = await tasksCollection.orderBy('date').startAt(firstDay).endAt(lastDay).get()
      let taskQueryStore = await api
        .post("tasks/get-list", {
          limit: state.limit,
          offset: state.offset,
        })
        .then((response) => {
          if (response.status == 200) {
            return response.data.count;
          }
        });
      // let lengthList = taskQueryStore.docs
      let lengthList = taskQueryStore + 1;

      if (lengthList < 10) {
        numberInMonth = `0${lengthList}`;
      } else {
        numberInMonth = lengthList;
      }
      return `${year}${month}${numberInMonth}`;
    },
  },
};
