// Status
export const montagesStatus = {
  assigned: "Назначен",
  unassigned: "Не назначен",
  work: "В работе",
  no_work: "Не выполнен",
  ready: "Готов",
};
export const montagesStatusArr = [
  { value: "assigned", text: "Назначен" },
  { value: "unassigned", text: "Не назначен" },
  { value: "work", text: "В работе" },
  { value: "no_work", text: "Не выполнен" },
  { value: "ready", text: "Готов" },
];