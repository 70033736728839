<template>
  <div v-if="user">
    <SocketWatch :tables="[
      'users', 
      'orders', 
      'montages', 
      'manufactory', 
      'hourlyWorks', 
      'deductions',
      'premiums',
      'prepayments',
      ]" @loadData="loadData" />
    <MobPage v-if="isMobile" />
    <PcPage v-else />
  </div>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
const MobPage = () => import('./MobPage.vue')
const PcPage = () => import('./PcPage.vue')

export default {
  name: 'User',
  data: () => ({
    isMobile: isMobile
  }),
  components: {
    MobPage,
    PcPage
  },
  async created() {
    // if (!this.user || (this.user && this.currentUser.role === 'admin') && (!this.users || this.users.length === 0)) {
    //   this.$store.dispatch('users/loadUsers')
		// 	this.loadData()
    // }
		// this.$store.dispatch('users/loadUsers')
		// await this.$store.dispatch('users/getCurUser', this.uid)
    this.loadData()
  },
	async beforeRouteLeave(to, from, next) {
    this.setDefVal()
    next()
  },
  methods: {
    async loadData() {
			this.$store.dispatch('orders/loadOrdersForManager', { id: this.uid, month: this.filterUserDataByMonth })
      // this.$store.getters['user/getUser']
      const response = await this.$store.dispatch('users/getCurUser', this.uid)
      const user = response.data.data
      this.$store.commit('orders/setOrders', user.orders)
      this.$store.commit('montages/setMontages', user.montages)
      // this.$store.commit('manufactory/setTasks',this.user.manufactory)

      // if(user.hourly_works){
      //   user.hourly_works.forEach(el => {
      //     if(el.tasks && !Array.isArray(el.tasks)){
      //       el.tasks = JSON.parse(el.tasks)
      //     }
      //   })
      // }

      // this.$store.commit('hourlyWorks/setTasks', user.hourly_works)
      this.$store.dispatch('hourlyWorks/loadTasks', { id: this.uid, month: this.filterUserDataByMonth })

      // this.$store.commit('prepayments/setPrepayments', user.prepayments)
      this.$store.dispatch('prepayments/loadPrepayments', { id: this.uid, month: this.filterUserDataByMonth })

      // this.$store.commit('premiums/setPremiums', user.premiums)
      this.$store.dispatch('premiums/loadPremiums', { id: this.uid, month: this.filterUserDataByMonth })
      

      // this.$store.commit('deductions/setDeductions', user.deductions)
      this.$store.dispatch('deductions/loadDeductions', { id: this.uid, month: this.filterUserDataByMonth })

			this.$store.dispatch('montages/loadMontagesForMontager', { id: this.uid, month: this.filterUserDataByMonth })
			this.$store.dispatch('manufactory/loadTasksForMaster', { id: this.uid, month: this.filterUserDataByMonth })
			
    },
		setDefVal() {
			this.$store.commit('orders/setOrdersForManager', [])
			this.$store.commit('montages/setMontagesForMontager', [])
			this.$store.commit('manufactory/setTasksForMaster', [])
			this.$store.commit('hourlyWorks/setTasks', [])

			this.$store.commit('prepayments/setPrepayments', [])
			this.$store.commit('deductions/setDeductions', [])
			this.$store.commit('premiums/setPremiums', [])
    }
  },
  computed: {
    filterUserDataByMonth() {
      return this.$store.getters['users/getFilterUserDataByMonth']
    },
    uid() {
      if (this.currentUser && this.currentUser.role != 'admin') {
        return this.currentUser.id
      }
      return this.$route.params.uid
    },
    user() {
      if (this.currentUser && this.currentUser.role != 'admin') {
        return this.currentUser
      }
      // return this.$store.getters['users/getUser'](this.uid)
      return this.$store.getters['users/getCurUser']
    },
    users() {
      return this.$store.getters['users/getUsers']
    },
    currentUser() {
      // console.log(this.$store.getters['user/getUser'])
      return this.$store.getters['user/getUser']
    }
  },
  watch: {
    filterUserDataByMonth(newVal, oldVal) {
      this.loadData()
    }
  }
}
</script>
