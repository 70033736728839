import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,[(_vm.responseTo)?_c(VCol,{staticClass:"d-flex align-center pb-0",attrs:{"cols":"12"}},[_vm._v(" Ответ пользователю "+_vm._s(_vm.responseTo.fromData.displayName)+": "),_c('span',{staticClass:"ml-2"},[_c(VBtn,{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.resetResponseTo()}}},[_c(VIcon,{attrs:{"size":"20","color":"red"}},[_vm._v(" mdi-close ")])],1)],1)]):_vm._e(),_c(VCol,{staticClass:"d-flex align-center pb-0",attrs:{"cols":"12"}},[_c('my-mini-avatar',{attrs:{"user":_vm.user}}),_c(VTextarea,{staticClass:"ml-3 pr-4",attrs:{"dense":"","color":"rgb(224, 166, 119)","placeholder":"Напишите комментарий...","append-icon":"mdi-attachment","append-outer-icon":"mdi-keyboard-return","loading":_vm.uploadingImage,"auto-grow":"","rows":"1","row-height":"1"},on:{"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;return _vm.newComment.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;if(!$event.altKey)return null;_vm.commentField += '\n'}],"click:append":() => _vm.uploadImage(null, _vm.itemNumber),"click:append-outer":_vm.newComment},model:{value:(_vm.commentField),callback:function ($$v) {_vm.commentField=$$v},expression:"commentField"}})],1),_c('div',{ref:"file",staticStyle:{"display":"none"}}),_c(VCol,{staticClass:"py-0",attrs:{"cols":"12"}},[_c(VContainer,{staticClass:"pa-0",attrs:{"fluid":""}},[_c(VRow,_vm._l((_vm.filesCommentField),function(image,index_image){return _c(VCol,{key:index_image,staticClass:"d-flex child-flex",attrs:{"cols":"3"}},[_c('my-preview-image',{attrs:{"images":_vm.filesCommentField,"fb-path":image},on:{"delete":_vm.deleteImage}})],1)}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }