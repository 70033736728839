<template>

  <v-row class="ma-0 px-1 py-2">
    <v-col cols="12" class="pa-0">
      <v-row class="ma-0">
        <v-col cols="12" class="pa-0 px-3">
          <StateAdmin/>
        </v-col>
        <v-col cols="5" class="pa-0 px-1">
          <AssignedMontages />
        </v-col>
        <v-col cols="7" class="pa-0 px-1">
          <DesignTaskDeadline />
        </v-col>

        


        <!-- <v-col cols="" class="pa-0 px-1">
          <AssignedMontages />
        </v-col> -->
				<!-- <v-col cols="12" class="mb-2 pa-0">
          <WidgetTabsTask />
        </v-col>
				<v-col cols="12" class="mb-2 pa-0">
          <WidgetMyOrders />
        </v-col>
				<v-col cols="12" class="mb-2 pa-0">
          <ManagerTasks />
        </v-col>
				<v-col cols="12" class="mb-2 pa-0">
          <UnassignedMontages />
          <ManagerUnassignedMontages />
        </v-col> -->
				<!-- <v-col cols="12" class="mb-2 pa-0">
          <MyDraftOrders />
        </v-col> -->
      </v-row>
    </v-col>
    <!-- <v-col cols="3" class="pa-0 px-4">
      <v-row>
        <v-col cols="12" class="pa-0">
          <AllNotification />
        </v-col>
				<v-col cols="12" class="pa-0">
          <ConfirmationTasks />
        </v-col>
				<v-col cols="12" class="pa-0">
          <WidgetAssignedMontagesInBrig />
        </v-col>
      </v-row>
    </v-col> -->
  </v-row>
</template>

<script>
import WidgetMyOrders from '@/components/Orders/Widgets/MyOrders.vue'
// import MyDraftOrders from '@/components/Orders/Widgets/MyDraftOrders.vue'
// import UnassignedMontages from '@/components/Montages/Unassigned.vue'
import WidgetTabsTask from '@/components/Tasks/Widgets/TabsTask.vue'
import ManagerUnassignedMontages from '@/components/Montages/ManagerUnassignedMontages.vue'
import WidgetAssignedMontagesInBrig from '@/components/Montages/WidgetAssignedMontagesInBrig.vue'
// import AllNotification from '@/components/Notification/Widget/AllNotification.vue'
import ConfirmationTasks from '@/components/Manufactory/ConfirmationTasks.vue'
import ManagerTasks from '@/components/Manufactory/ManagerTasks.vue'
import StateAdmin from '@/components/State/StateAdmin.vue'
import AssignedMontages from '@/components/Montages/Widgets/AssignedMontages.vue'
import DesignTaskDeadline from '@/components/Tasks/Widgets/DesignTaskDeadline.vue'


export default {
  name: 'AdminHome',
  components: {
		WidgetMyOrders,
    StateAdmin,
    DesignTaskDeadline,
		// MyDraftOrders,
    // UnassignedMontages,
    AssignedMontages,
		WidgetTabsTask,
    ManagerUnassignedMontages,
    WidgetAssignedMontagesInBrig,
		// AllNotification,
    ConfirmationTasks,
		ManagerTasks
  }
}
</script>
