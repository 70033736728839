import Login from '../views/Login.vue'

const baseRoutes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  }
]

export default baseRoutes
