import { deductionsCollection, usersCollection } from '@/plugins/firebase'
import axios from 'axios'
import api from '@/utils/api'
const url = process.env.VUE_APP_SERVICE_URL

export default {
    namespaced: true,
    state: {
      deductions: []
    },
    getters: {
        getDeductions: state => {
            const sortedDeductions = state.deductions.sort(function(a,b){
                return new Date(b.date) - new Date(a.date);
            })
            return sortedDeductions
        }
    },
    mutations: {
        setDeductions(state, payload) {
            state.deductions = payload;
        },
        addDeduction(state, payload) {
            let deductions = state.deductions
            deductions.push(payload)
            state.deductions = deductions
        },
        delDeduction(state, id) {
            let deductions = state.deductions
            deductions = deductions.filter(el => el.id != id)
            state.deductions = deductions
        },
    },
    actions: {
        async deleteDeductions({ commit, rootState }, data) {
            commit('templ/setLoading', true, { root: true })
            // await deductionsCollection.doc(data.id).update({ del: true })
           
            await api.put('deductions/update', { id: data.id, del: true })
                .then(() => {
                    commit('delDeduction',data.id)
                    console.log('Удержание удалено')
                }).catch((err) => {
                    commit('templ/setError', 'Ошибка удаления удержания, попробуйте позже.')
                    console.log(err)
                }).finally(() => {
                    commit('templ/setLoading', false, { root: true })
                })
        },
        async createDeductions({ commit, dispatch, rootGetters }, data) {
            commit('templ/setLoading', true, { root: true })
            let filterDate = rootGetters['users/getFilterUserDataByMonth']
            let date = new Date(filterDate), y = date.getFullYear(), m = date.getMonth()
            filterDate = new Date(y, m + 1, 0, 23, 59)
            let toDay = new Date()

            if (filterDate.getMonth() != toDay.getMonth()) {
                date = new Date(filterDate.toISOString().substr(0, 10))
            } else {
                date = new Date(toDay.toISOString().substr(0, 10))
            }

            const obj = { 
                ...data,
                date: date
            }
            // await deductionsCollection.add(obj)
            await api.post('deductions/create',obj).then(response => {
                if(response.status == 200){
                    commit('addDeduction',response.data.deductions)
                }
            })
            commit('templ/setLoading', false, { root: true })
        },
        async loadDeductions({ commit, dispatch }, data) {
            commit('templ/setLoading', true, { root: true })
            
            // let userRef = await usersCollection.doc(data.uid)

            // let queryStore = deductionsCollection.where('user', '==', userRef)
            let queryStore = await api.post('deductions/get-list',{
                limit: 1000,
                offset: 0,
                query: [data.month ? { input: "date", value: data.month } : null],
                query_and: [
                    { input: 'user_id', value: data.id },
                    { input: 'del', value: false },
                    // 
                ],
                sort: "date",
            })
            queryStore = queryStore.data.data
            
            if (data.month) {
                let date = new Date(data.month), y = date.getFullYear(), m = date.getMonth()
                let firstDay = new Date(y, m, 1, 0)
                let lastDay = new Date(y, m + 1, 0, 23, 59)
                // queryStore = queryStore.orderBy('date').startAt(firstDay).endAt(lastDay);
            }
            // commit('setDeductions',queryStore)
            commit('templ/setLoading',false, { root: true})
            let deductionsArr = []
            queryStore.forEach(async(el) => {
                let deduction = el
                if(deduction.user){
                    console.log('deduction.user',deduction.user)
                }
                deductionsArr.push(deduction)
            })
            deductionsArr = deductionsArr.filter(x => !x.del)

            commit('setDeductions', deductionsArr)
            commit('templ/setLoading',false,{ root: true})
            // queryStore.onSnapshot(async snapshot => {
            //     let deductionsArr = []
            //     for (let deductionDoc of snapshot.docs) {
            //         let deduction = { ...deductionDoc.data(), id: deductionDoc.id }
            //         if (deduction.user) {
            //             let user = await dispatch('users/userSerializer', deduction.user, { root: true })
            //             deduction.user = user
            //         }
            //         deductionsArr.push(deduction)
            //     }
            //     deductionsArr = deductionsArr.filter(x => !x.del)
                
            //     commit('setDeductions', deductionsArr)
            //     commit('templ/setLoading', false, { root: true })
            // })
        },
    }
}