
import api from '@/utils/api'

export default {
  namespaced: true,
  state: {
    tasks: [],
    filterHourlyWorksByMonth: new Date().toISOString().substr(0, 7)
  },
  getters: {
    getFilterHourlyWorksByMonth: state => {
      return state.filterHourlyWorksByMonth
    },
    getTasks: state => {
      // console.log('state.tasks',state.tasks)
      let tasks = state.tasks ? state.tasks : []
      const sortedTasks = tasks.sort(function(a,b){
        return new Date(a.date) - new Date(b.date);
      })
      return sortedTasks
    },
  },
  mutations: {
    setTasks(state, payload) {
      state.tasks = payload
    },
    setFilterHourlyWorksByMonth(state, payload) {
      state.filterHourlyWorksByMonth = payload;
    },
    removeTask(state, payload) {
      if (payload.id) {
        let index = state.tasks.findIndex(item => item.id === payload.id)
        if (index != -1) {
          state.tasks.splice(index, 1)
        }
      }
    },
    updTask(state, payload) {
      if (payload.doc && payload.doc.id) {
        const item = state.tasks.find(item => item.id === payload.doc.id)
        Object.assign(item, payload.doc)
      }
    },
    pushTask(state, payload) {
      if (payload.doc && payload.doc.id) {
        if (!state.tasks.find(item => item.id === payload.doc.id)) {
          state.tasks.push(payload.doc)
        }
      }
    },
    addTask(state, payload) {
      let tasks = state.tasks
      tasks.push(payload)
      state.tasks = tasks
    },
    updateTask(state,payload){
      let tasks = state.tasks.map(el => {
        if(el.id == payload.id){
          el = payload
        }
        return el
      })
      state.tasks = tasks
      
    },
    deleteTask(state,payload){

    }
  },
  actions: {
    async loadTasks({ commit, dispatch, getters }, data) {
      // alert('WorkTasks')
      commit('templ/setLoading', true, { root: true })
      // let queryStore = hourlyWorksCollection
      let queryStore = await api.post('hourly-works/get-list',{
        limit: 10000, 
        offset: 0,
        // sort: 'date'
        query_and: [
          { input: "del", value: false },
          { input: "user_id", value: data.id },
          data.month ? { input: "date", value: data.month } : null,
        ]
      })

      queryStore = queryStore.data.data || []
      queryStore.forEach(el => {
        if(el.tasks){
          el.tasks = JSON.parse(el.tasks)
        }
        if(el.numberOfHours){
          el.numberOfHours = Number(el.numberOfHours)
        }
      })

      if (data.month) {
        let date = new Date(data.month), y = date.getFullYear(), m = date.getMonth()
        let firstDay = new Date(y, m, 1, 0)
        let lastDay = new Date(y, m + 1, 0, 23, 59)  
        // queryStore = queryStore.orderBy('date').startAt(firstDay).endAt(lastDay);
      }
      // queryStore = queryStore.data.data || []
      // queryStore = queryStore.filter(el => !el.del)
      // console.log('loadTasks', queryStore)
      commit('setTasks', queryStore)
      commit('templ/setLoading', false, { root: true })
      // await queryStore.onSnapshot(async snapshot => {
      //   let tasksArr = []
      //   for (let doc of snapshot.docs) {
      //     tasksArr.push({ ...doc.data(), id: doc.id })
      //   }
      //   tasksArr = tasksArr.filter(x => !x.del)
      
        // commit('setTasks', tasksArr)
        // commit('templ/setLoading', false, { root: true })
      // })
    },
    async checkDateTask({ commit }, data) {
      commit('templ/setLoading', true, { root: true })
      // let queryStore = hourlyWorksCollection
      let queryStore = await api.post('hourly-works/get-list',{
        limit: 1000,
        offset: 0
      })
      queryStore = queryStore.data.data
      if (data.date) {
        // queryStore = queryStore.where('date', '==', data.date)
        queryStore = queryStore.filter(el => el.date == data.date)
      }
      console.log('data',data)

      if (data.user) {
        // let userRef = null
        // if (data.user.firestore) {
        //   userRef = data.user
        // } else {
        //   userRef = usersCollection.doc(data.user.uid)
        // }
        // queryStore = queryStore.where('user', '==', userRef)
        console.log('data',data.user)
      }

      // return queryStore.get().then(async snapshot => {
      //   let tasksArr = []
      //   for (let doc of snapshot.docs) {
      //     tasksArr.push({ ...doc.data(), id: doc.id })
      //   }
      //   tasksArr = tasksArr.filter(x => !x.del)
      //   commit('templ/setLoading', false, { root: true })
      //   return tasksArr.length > 0
      // })
      queryStore = queryStore.filter(x => !x.del)
      commit('templ/setLoading', false, { root: true })
      return queryStore.length > 0
    },
    async updateTask({ commit, dispatch }, obj) {
      commit('templ/setLoading', true, { root: true })
      // let doc = hourlyWorksCollection.doc(obj.id)
      // await doc.update(obj.data)
      obj = {
        id: obj.id,
        ...obj.data
      }
      if(obj.numberOfHoursOnManufactory){
        obj.numberOfHoursOnManufactory = Number(obj.numberOfHoursOnManufactory)
      }
      if(obj.numberOfHoursOnMontage){
        obj.numberOfHoursOnMontage = Number(obj.numberOfHoursOnMontage)
      }
      if(obj.tasks){
        obj.tasks = JSON.stringify(obj.tasks)
      }
      obj.numberOfHours = String(obj.numberOfHours) || '0'
      await api.put('hourly-works/update', obj).then(response => {
        if(response.status == 200 && response.data.status){
          if(response.data.hourlyWorks.tasks){
            response.data.hourlyWorks.tasks = JSON.parse(response.data.hourlyWorks.tasks)
          }
          commit('updateTask',response.data.hourlyWorks)
        }
      })

      if (obj.del) {
        commit('removeTask', { id: obj.id })
      }

      if (obj.updState) {
        // doc = await doc.get()
        // doc = { ...doc.data(), id: doc.id }

        commit('updTask', { doc: obj })
      }
      commit('templ/setLoading', false, { root: true })
      dispatch('socket/sendSocket', ['hourlyWorks'], {
        root: true
      })
    },
    async createTask({ commit, dispatch }, data) {
      commit('templ/setLoading', true, { root: true })
      const obj = { 
        ...data
      }

      if(obj.tasks){
        obj.tasks = JSON.stringify(obj.tasks)
      }
      if(obj.numberOfHoursOnManufactory){
        obj.numberOfHoursOnManufactory = Number(obj.numberOfHoursOnManufactory)
      }
      if(obj.numberOfHoursOnMontage){
        obj.numberOfHoursOnMontage = Number(obj.numberOfHoursOnMontage)
      }
      obj.numberOfHours = String(obj.numberOfHours) || '0'
      // await hourlyWorksCollection.add(obj)
      await api.post('hourly-works/create', obj).then(response => {
        if(response.status == 200 && response.data.status){
          let task = response.data.hourlyWorks
          if(task.tasks){
            task.tasks = JSON.parse(task.tasks)
          }
          commit('addTask',task)
        }
      })
      dispatch('socket/sendSocket', ['hourlyWorks'], {
        root: true
      })
      commit('templ/setLoading', false, { root: true })
    },
    async deleteTask({commit}, id ){
      // alert(id)
      await api.put('hourly-works/update', { id, del: true }).then(response => {
        if(response.status == 200 && response.data){
          commit('removeTask', id)
          dispatch('socket/sendSocket', ['hourlyWorks'], {
            root: true
          })
        }
      })
    }
  }
}