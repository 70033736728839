<template>
  <div class="page pageManufactoryTasks" :class="{ 'pa-3': !isMobile }">
    <SocketWatch  :tables="['manufactory']" @loadData="loadData" />
    <v-card :tile="isMobile" :class="{ 'mb-3': !isMobile }">
      <div v-if="isMobile">
        <v-row class="page_filterTable ma-0 px-1">
          <v-col cols="8">
            <v-text-field
              color="rgb(224, 166, 119)"
              v-model="filterWorkTasksByDateText"
              @click="selectDate()"
              label="Дата"
              clearable
              @click:clear="clearDate()"
            />
          </v-col>
          <v-col cols="2" class="pt-6">
              <v-btn icon @click="openFilters = !openFilters">
                <v-badge
                  v-if="filtersSelected"
                  color="orange"
                  overlap
                  :content="filtersSelected"
                >
                  <v-icon>mdi-tune</v-icon>
                </v-badge>
                <v-icon v-else>mdi-tune</v-icon>
              </v-btn>
          </v-col>
          <v-col cols="2" class="pt-6">
            <v-btn icon @click="createTask()">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-expand-transition>
          <v-card flat tile v-show="openFilters">
            <v-divider />
            <v-row class="page_filterTable ma-0 px-1">
              <v-col cols="12" class="pt-0">
                <v-autocomplete
                  color="rgb(224, 166, 119)"
                  v-model="filterWorkTasksByClient"
                  @change="loadData()"
                  label="Клиент"
                  :items="filterClients"
                  item-text="name"
                  item-value="id"
                  return-object
                  clearable
                />
              </v-col>
              <v-col v-if="user.role != 'manager'" cols="12" class="pt-0">
                <v-select
                  color="rgb(224, 166, 119)"
                  v-model="filterWorkTasksByManager"
                  @change="loadData()"
                  label="Менеджер"
                  :items="managers"
                  item-text="displayName"
                  return-object
                  clearable
                />
              </v-col>
            </v-row>
          </v-card>
        </v-expand-transition>
        <v-divider />
      </div>
      <div v-else class="d-flex align-center px-4">
        <v-row class="page_filterTable ma-0 pt-2 pb-3">
          <v-col cols="2" class="py-0">
            <v-text-field
              dense
              color="rgb(224, 166, 119)"
              v-model="filterWorkTasksByDateText"
              @click="selectDate()"
              label="Дата"
              clearable
              @click:clear="clearDate()"
            />
          </v-col>
          <v-col cols="3" class="py-0">
            <v-autocomplete
              dense
              color="rgb(224, 166, 119)"
              v-model="filterWorkTasksByClient"
              @change="loadData()"
              label="Клиент"
              :items="filterClients"
              item-text="name"
              item-value="id"
              return-object
              clearable
            />
          </v-col>
          <v-col cols="3" class="py-0" v-if="user.role != 'manager'">
            <v-autocomplete
              dense
              color="rgb(224, 166, 119)"
              v-model="filterWorkTasksByManager"
              @change="loadData()"
              label="Менеджер"
              :items="managers"
              item-text="displayName"
              return-object
              clearable
            />
          </v-col>
        </v-row>
        <v-spacer></v-spacer>
        <v-btn icon small @click="createTask()">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </div>
    </v-card>
    <v-expansion-panels v-if="isMobile">
      <v-expansion-panel
        v-if="['admin', 'manager'].includes(user.role) || user.canConfirmTask"
        class="mobTasks_container_task"
      >
        <v-expansion-panel-header>
          Не назначенные
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <TasksTableComp :tasksLoaded="inProcessTasksLoaded" :tasks="inProcessTasks.confirmation" />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel class="mobTasks_container_task">
        <v-expansion-panel-header>
          У нач. производства
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <TasksTableComp :tasksLoaded="inProcessTasksLoaded" :tasks="inProcessTasks.confirmationProd" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      
      <v-expansion-panel class="mobTasks_container_task">
        <v-expansion-panel-header>
          На производстве
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <TasksTableComp :tasksLoaded="inProcessTasksLoaded" :tasks="inProcessTasks.work" />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel class="mobTasks_container_task">
        <v-expansion-panel-header>
          Выполняются
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <TasksTableComp :tasksLoaded="inProcessTasksLoaded" :tasks="inProcessTasks.produced" />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel class="mobTasks_container_task">
        <v-expansion-panel-header>
          Готовые
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <TasksTableComp :tasksLoaded="inProcessTasksLoaded" :tasks="inProcessTasks.ready" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <template v-else>
      <TasksTableComp v-if="['admin', 'manager'].includes(user.role) || user.canConfirmTask" class="mb-3" title="Не назначенные" :tasksLoaded="inProcessTasksLoaded" :tasks="inProcessTasks.confirmation" />
      <TasksTableComp class="mb-3" title="У нач. производства" :tasksLoaded="inProcessTasksLoaded" :tasks="inProcessTasks.confirmationProd" />
      <TasksTableComp class="mb-3" title="На производстве" :tasksLoaded="inProcessTasksLoaded" :tasks="inProcessTasks.work" />
      <TasksTableComp class="mb-3" title="Выполняются" :tasksLoaded="inProcessTasksLoaded" :tasks="inProcessTasks.produced" />
      <TasksTableComp class="mb-3" title="Готовые" :tasksLoaded="inProcessTasksLoaded" :tasks="inProcessTasks.ready" />
    </template>
  </div>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
import TasksTableComp from '@/components/Manufactory/TasksTableComp.vue'
import ManufactoryTasksModal from  '@/components/Modals/Manufactory/Task'
import SelectDate from '@/components/Modals/SelectDate'
let socket
export default {
  name: 'ManufactoryTasks',
  components: {
    TasksTableComp
  },
  data: () => ({
    isMobile: isMobile,
    filterWorkTasksByDateText: '',
    openFilters: false
  }),
  created() {
    this.loadData()
    // if (!this.clients || this.clients.length === 0) {
      this.$store.dispatch('clients/loadFilterClients')
    // }
    // if (!this.managers || this.managers.length === 0) {
      this.$store.dispatch('users/loadManagers')
    // }
  },
  async beforeRouteLeave(to, from, next) {
    this.$store.commit('manufactory/resetAllFilterTasks')
    this.loadData()
    next()
  },
  methods: {
    // sendSoc() { 
    //   socket.send('test')
    // },
    loadData() {
      if (this.user.role === 'manager') {
        this.filterWorkTasksByManager = this.user
      }
      this.$store.dispatch('manufactory/loadInProcessTasks')
    },
    clearDate() {
      this.filterWorkTasksByDate = null
      this.filterWorkTasksByDateText = ''
      this.loadData()
    },
    async selectDate() {
      let modalDate = await this.$showModal({
        component: SelectDate,
        isPersistent: true,
        props: {
          title: 'Дата',
          selectDate: this.filterWorkTasksByDate ? this.filterWorkTasksByDate.toISOString().substr(0, 10) : new Date().toISOString().substr(0, 10)
        }
      })
      if (modalDate) {
        this.filterWorkTasksByDate = new Date(modalDate)
        this.filterWorkTasksByDateText = new Date(modalDate).toLocaleString('ru', {weekday: 'short', day: 'numeric', month: 'short'})
        this.loadData()
      }
    },
    async createTask() {
      await this.$showModal({
        component: ManufactoryTasksModal,
        isPersistent: true,
        props: {
          edit: false
        }
      })
      
    }
  },
  computed: {
    filtersSelected() {
      let filters = [this.filterWorkTasksByClient, this.filterWorkTasksByManager]
      filters = filters.filter(x => !!x)
      return filters.length
    },
    // loaded
    inProcessTasksLoaded() {
      return this.$store.getters['manufactory/getInProcessTasksLoaded']
    },
    // tasks
    inProcessTasks() {
      let tasks = this.$store.getters['manufactory/getInProcessTasks']
      let obj = {
        confirmation: [],
        confirmationProd: [],
        work: [],
        produced: [],
        ready: []
      }
      for (let key in obj) {
        obj[key] = tasks.filter(x => x.status === key)
      }
      return obj
    },
    // other
    user() {
      return this.$store.getters['user/getUser']
    },
    filterClients() {
      return this.$store.getters['clients/getFilterClients']
    },
    managers() {
      return this.$store.getters['users/getManagers']
    },
    // filter
    filterWorkTasksByDate: {
      get() { return this.$store.getters['manufactory/getFilterWorkTasksByDate'] },
      set(val) { this.$store.commit('manufactory/setFilterWorkTasksByDate', val) }
    },
    filterWorkTasksByClient: {
      get() { return this.$store.getters['manufactory/getFilterWorkTasksByClient'] },
      set(val) { this.$store.commit('manufactory/setFilterWorkTasksByClient', val) }
    },
    filterWorkTasksByManager: {
      get() { return this.$store.getters['manufactory/getFilterWorkTasksByManager'] },
      set(val) { this.$store.commit('manufactory/setFilterWorkTasksByManager', val) }
    }
  }
}
</script>

<style lang="scss">
.mobTasks {
  &_container {
    border-radius: 0 !important;
    
    &_task {
      border-radius: 0 !important;
      position: relative;

      .v-expansion-panel-content__wrap {
        padding: 0 !important;
      }
    }
  }
}
</style>
