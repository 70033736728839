<template>
  <div class="iframe iframe_widget iframeWidgetAssignedMontagesInBrig pa-1">
    <SocketWatch  :tables="['manufactory']" @loadData="loadData" />
    <v-card>
       <v-card-title>
				<v-icon class="mr-2">mdi-archive-cog</v-icon>
        Задания в производство
        <v-spacer></v-spacer>
        <v-btn icon small @click="createTask()">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-skeleton-loader
        v-if="!tasksLoaded && (!tasks || tasks.length === 0)"
        class="pl-3 pr-3"
        type="table-thead, table-row-divider@3"
        :types="skeletonTypesIframe"
      />
      <div 
        v-if="tasksLoaded && (!tasks || tasks.length === 0)"
        class="infoNoItems"
      >
        Нет заданий
      </div>
      <v-simple-table :dense="!isMobile" v-if="tasks && tasks.length > 0">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Дедлайн</th>
              <th class="text-left">Клиент</th>
              <th class="text-left">Менеджер</th>
            </tr>
          </thead>
          <tbody>
            <tr 
              v-for="task in tasks"
              :key="task.id"
              @click="editTask(task)"
              class="tableItemList"
            >
              <td style="min-width: 80px;">{{ $formatDate(new Date(task.date)) }}</td>
              <td style="min-width: 145px;">
								{{ task.client.name }}
								<!-- <v-menu bottom right :close-on-content-click="false">
									<template v-slot:activator="{ on, attrs }">
										<v-btn icon x-small v-bind="attrs" v-on="on" style="cursor: context-menu;">
											<v-icon>mdi-information-outline</v-icon>
										</v-btn>
									</template>
									<my-info-client :id="task.client.id" />
								</v-menu> -->
							</td>
              <td>{{ task.manager.displayName }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </div>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
import { manufactoryTaskStatus } from '@/helpers'
import ManufactoryTaskModal from  '@/components/Modals/Manufactory/Task'

  export default {
    name: 'ConfirmationTasks',
    data: () => ({
      isMobile: isMobile,
      skeletonTypesIframe: {
        'table-thead': 'heading@4',
        'table-row': 'table-cell@4'
      },
      manufactoryTaskStatus: manufactoryTaskStatus
    }),
    created() {
      this.loadData()
    },
    computed: {
      
      tasksLoaded() {
        return this.$store.getters['manufactory/getConfirmationTasksLoaded']
      },
      tasks() {
        return this.$store.getters['manufactory/getConfirmationTasks']
      }
    },
    methods: {
      loadData() {
        this.$store.dispatch('manufactory/loadConfirmationTasks')
      },
      async createTask() {
        await this.$showModal({
          component: ManufactoryTaskModal,
          isPersistent: true,
          props: {
            edit: false
          }
        })
      },
      async editTask(task) {
        await this.$showModal({
          component: ManufactoryTaskModal,
          isPersistent: true,
          props: {
            edit: true,
            data: task
          }
        })
      }
    }
  }
</script>
