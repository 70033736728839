import { tasksCollection, usersCollection } from '@/plugins/firebase'
import api from '@/utils/api'
import store from '@/stores/store.js'
export default {
  namespaced: true,
  state: {
    tasks: [],
    limit: 5,
    offset: 0,
  },
  getters: {
    getTasks: state => {
      const sortedTasks = state.tasks.sort(function (a, b) {
        return new Date(a.deadline) - new Date(b.deadline);
      })
      return sortedTasks
    },
  },
  mutations: {
    setTasks(state, payload) {
      state.tasks = payload
    },
    SET_LIMIT(state, payload) {
      state.limit = payload
    },
    SET_OFFSET(state, payload) {
      state.offset = payload
    }
  },
  actions: {
    async loadTasks({ commit, rootState, state }) {
      // alert('meTasks')
      commit('templ/setLoading', true, { root: true })

      // if (snapshots.loadTasks) {
      //   snapshots.loadTasks()
      //   snapshots.loadTasks = null
      // }

      let user = rootState.user.user
      // console.log('meTasks',user)
      // let queryStore = tasksCollection
      // .where('del', '!=', true)
      // .where('archive', '==', false)
      // .where('whoTo', 'array-contains', user.uid)

      // let filterByManager = getters.getFilterTasksByManager


      // if (filterByManager && filterByManager.id) {
      //   filterByManager = filterByManager.id
      // }

      let queryStore = await api.post('tasks/get-list', {
        // limit: state.limit,
        offset: state.offset,

        query: [
          { input: "who_to", value: `,${user.id},` },
          { input: "who_to", value: `[${user.id}]` },
          { input: "who_to", value: `,${user.id}]` },
          { input: "who_to", value: `[${user.id},` },
        ],

        strict: [
          // { input: 'from_id', filterByManager },
          { input: 'del', value: false },
          { input: 'archive', value: false },
          user.role === 'designer' || user.role === 'prodDir' ? { input: 'ready', value: false } : null
        ],

      })

      // if () {
      //   queryStore = await api.post('tasks/get-list',{ 
      //     limit: state.limit,
      //     offset: state.offset,
      //     query_and: [
      //       // { input: 'del', value: false }, 
      // 		  // { input: 'archive', value: false},
      //       // { input: 'ready', value: false },
      //       { input: 'who_to_id' , value: user.id }
      //     ]
      //   })
      console.log(user.id)
      // }

      // if (user.role === 'prodDir') {
      //   // queryStore = queryStore.where('ready', '==', false)
      // }
      // if(queryStore.length > 1){
      queryStore = queryStore.data.data.map(el => {
        // el.from = users.find(user => user.id == el.from)
        el.from_data ? el.from = el.from_data : ''
        el.whoTo = el.who_to_data || []
        el.whoToData = el.who_to_data || []
        el.color ? el.color = JSON.parse(el.color) : ''
        el.images ? el.images = el.images.split(',') : ''
        el.whoTo = el.whoTo.map(whoTo => {
          whoTo = whoTo.id
          return whoTo
        })
        // el.whoToData = users.find(user => user.id == el.whoToData);
        return el
      })
      // } else if(queryStore.length != 0){
      //     queryStore.from_data? queryStore.from = queryStore.from_data : ''
      //     queryStore.who_to_data? queryStore.whoTo = queryStore.who_to_data : ''
      //     queryStore.who_to_data? queryStore.whoToData = queryStore.who_to_data : ''
      //     queryStore.color? queryStore.color = JSON.parse(queryStore.color) : ''
      //     queryStore.images? queryStore.images = queryStore.images.split(',') : ''
      //     queryStore = [queryStore]
      // }
      // console.log(queryStore)
      commit('setTasks', queryStore)
      commit('templ/setLoading', false, { root: true })

      // snapshots.loadTasks = queryStore.onSnapshot(async snapshot => {
      //   let tasksArr = []

      //   for (let doc of snapshot.docs) {
      //     let task = { ...doc.data(), id: doc.id }
      //     tasksArr.push(task)
      //   }

      //   commit('setTasks', tasksArr)
      //   commit('templ/setLoading', false, { root: true })
      // })
    },
    async createTask({ commit, rootState, dispatch }, data) {
      commit('templ/setLoading', true, { root: true })
      let user = rootState.user.user
      // let fromRef = await usersCollection.doc(user.uid)
      const obj = {
        ...data,
        // from: fromRef,
        accepted: true,
        archive: false,
        del: false,
        meTask: true,
        ready: false,
        date: new Date(),
      }

      if (!obj.client) {
        obj.client = null
      }

      if (!obj.numberOrder) {
        let number = await dispatch('tasks/getNewNumberTask', null, { root: true })
        obj.numberOrder = `z${number}`
      }

      obj.images = String(obj.images)
      // await tasksCollection.add(obj)
      await api.post('tasks/create', {
        ...obj
      }).then(async response => {
        console.log(response)
        if (response.status == 200) {
          response.data.tasks.from = response.data.tasks.from_data
          response.data.tasks.whoTo = response.data.tasks.who_to_data || []
          response.data.tasks.whoToData = response.data.tasks.who_to_data || []
          response.data.tasks.whoTo = response.data.tasks.whoTo.map(whoTo => {
            whoTo = whoTo.id
            return whoTo
          })
          dispatch('socket/sendSocket', ['tasks'], { root: true })
          store.commit('tasks/addTask', response.data.tasks)
          commit('templ/setLoading', false, { root: true })


        }
      })
    }
  }
}