import { isMobile } from "mobile-device-detect";
import Vue from "vue";
import VueRouter from "vue-router";
import store from "../stores/store";
import baseRoutes from "./routes";

Vue.use(VueRouter);

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes: baseRoutes,
});
// console.log('script start')
let unsubscribe = store.subscribe(async (mutation) => {
    // console.log('mutation',mutation)
    if (mutation.type === "user/setUser") {
        let user = mutation.payload;
        let routes = await require(`./routesByRole/${user.role || "admin"}.js`)
            .default;
        for (let route of routes) {
            await router.addRoute(route);
        }

        if (user.role === "admin") {
            if (isMobile) {
                routes = await require(`./routesByRole/adminMob.js`).default;
                for (let route of routes) {
                    await router.addRoute(route);
                }
            } else {
                routes = await require(`./routesByRole/adminPc.js`).default;
                for (let route of routes) {
                    await router.addRoute(route);
                }
            }
        }

        // if (user.role === 'prodDir' && user.canAssignMontage) {
        //   routes = await require(`./routesByRole/prodDirMontages.js`).default
        //   for (let route of routes) {
        //     await router.addRoute(route)
        //   }
        // }

        let allRoutes = await router.getRoutes();
        store.commit("templ/setRoutes", allRoutes);

        console.log('routes: ', allRoutes)
        router.push({ path: "/" }).catch((err) => {
            console.log("Error catched in router.push: " + err);
        });

        unsubscribe();
    }
});

// auth.onAuthStateChanged(async (stateUser) => {
//   if (stateUser) {
//     let unsubscribe = store.subscribe(async mutation => {
//       if (mutation.type === 'user/setUser') {
//         let user = mutation.payload
//         let routes = await require(`./routesByRole/${user.role}.js`).default
//         for (let route of routes) {
//           await router.addRoute(route)
//         }

//         if (user.role === 'admin') {
//           if (isMobile) {
//             routes = await require(`./routesByRole/adminMob.js`).default
//             for (let route of routes) {
//               await router.addRoute(route)
//             }
//           } else {
//             routes = await require(`./routesByRole/adminPc.js`).default
//             for (let route of routes) {
//               await router.addRoute(route)
//             }
//           }
//         }

//         // if (user.role === 'prodDir' && user.canAssignMontage) {
//         //   routes = await require(`./routesByRole/prodDirMontages.js`).default
//         //   for (let route of routes) {
//         //     await router.addRoute(route)
//         //   }
//         // }

//         let allRoutes = await router.getRoutes()
//         store.commit('templ/setRoutes', allRoutes)
//         unsubscribe()
//       }
//     })
//   }
// })

router.beforeEach(async (to, from, next) => {
    // const user = store.getters['user/getUser']
    const user = await store.dispatch("user/getUserInfo").catch(function (error) {
        if (error.response && error.response.status === 401) {
            return next({ path: "/login", query: { returnUrl: to.path } });
        }
    });

    // console.log('user',user)
    // console.log('to',to)
    if (user && to.name === "Login") {
        return next({ path: "/" });
    }

    if (!user && to.name != "Login") {
        return next({ path: "/login", query: { returnUrl: to.path } });
    }

    next();
});

export default router;
