import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('SocketWatch',{attrs:{"tables":[
      'notify'
      ]},on:{"loadData":_vm.loadData}}),_c(VMenu,{attrs:{"close-on-content-click":false,"offset-y":""},on:{"input":_vm.setIsOpenNotifList},scopedSlots:_vm._u([{key:"activator",fn:function(scope){return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',scope.attrs,false),scope.on),[(_vm.items.length > 0)?_c(VBadge,{attrs:{"color":"red","content":_vm.items.length,"overlap":"","right":""}},[_c(VIcon,{attrs:{"color":"rgba(0,0,0,.54)"}},[_vm._v("mdi-bell-outline")])],1):_c(VIcon,{attrs:{"color":"rgba(0,0,0,.54)"}},[_vm._v("mdi-bell-outline")])],1)]}}])},[_c(VCard,{attrs:{"width":"500","max-height":"600"}},[(_vm.isOpenNotifList)?[(_vm.items.length > 0)?_c(VList,{attrs:{"two-line":""}},[_c('div',{staticClass:"pa-2"},[_c(VBtn,{staticStyle:{"right":"-8px"},attrs:{"icon":"","color":"grey"},on:{"click":function($event){$event.stopPropagation();return _vm.acceptedAllNotify.apply(null, arguments)}}},[_c(VIcon,[_vm._v(" mdi-notification-clear-all ")])],1)],1),_c(VDivider),_vm._l((_vm.items),function(item,index){return [_c('ItemDropList',{key:item.id,attrs:{"item":item}}),(index < _vm.items.length - 1)?_c(VDivider,{key:`k${item.id}`}):_vm._e()]})],2):_c(VListItemTitle,{staticClass:"pa-3 text-center"},[_vm._v(" Пока пусто :) ")])]:_vm._e()],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }