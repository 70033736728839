<template>
  <div class="pageAllMontages" :class="{ 'pa-3': !isMobile }">
    <SocketWatch :tables="['montages']" @loadData="loadData" />
    <v-card :tile="isMobile">
      <div v-if="isMobile">
        <v-row class="page_filterTable ma-0 px-1">
          <v-col cols="8">
            <v-text-field
              dense
              color="rgb(224, 166, 119)"
              :value="filterUnassignedMontagesByDateText"
              label="Дата"
              clearable
              @click="selectDate()"
              @click:clear="clearDate()"
            />
          </v-col>
          <v-col cols="2" class="pt-6">
            <v-btn icon @click="openFilters = !openFilters">
              <v-badge
                v-if="filtersSelected"
                color="orange"
                overlap
                :content="filtersSelected"
              >
                <v-icon>mdi-tune</v-icon>
              </v-badge>
              <v-icon v-else>mdi-tune</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="2" class="pt-6">
            <v-btn icon @click="createMontage()">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-expand-transition>
          <v-card v-show="openFilters" flat tile>
            <v-divider />
            <v-row class="page_filterTable ma-0 px-1">
              <v-col cols="12" class="pt-0">
                <v-autocomplete
                  v-model="filterUnassignedMontagesByClient"
                  color="rgb(224, 166, 119)"
                  label="Клиент"
                  :items="filterClients"
                  item-text="name"
                  item-value="id"
                  return-object
                  clearable
                  @change="$store.dispatch('montages/loadUnassignedMontages')"
                />
              </v-col>
              <v-col cols="12" class="pt-0">
                <v-select
                  v-model="filterUnassignedMontagesByManager"
                  color="rgb(224, 166, 119)"
                  label="Менеджер"
                  :items="managers"
                  item-text="displayName"
                  return-object
                  clearable
                  @change="$store.dispatch('montages/loadUnassignedMontages')"
                />
              </v-col>
            </v-row>
          </v-card>
        </v-expand-transition>
      </div>
      <div v-else class="d-flex align-center px-4">
        <v-row class="page_filterTable mt-3">
          <v-col cols="2">
            <v-text-field
              dense
              color="rgb(224, 166, 119)"
              :value="filterUnassignedMontagesByDateText"
              label="Дата"
              clearable
              @click="selectDate()"
              @click:clear="clearDate()"
            />
          </v-col>
          <v-col cols="3">
            <v-autocomplete
              v-model="filterUnassignedMontagesByClient"
              dense
              color="rgb(224, 166, 119)"
              label="Клиент"
              :items="filterClients"
              item-text="name"
              item-value="id"
              return-object
              clearable
              @change="$store.dispatch('montages/loadUnassignedMontages')"
            />
          </v-col>
          <v-col cols="3">
            <v-autocomplete
              v-model="filterUnassignedMontagesByManager"
              dense
              color="rgb(224, 166, 119)"
              label="Менеджер"
              :items="managers"
              item-text="displayName"
              return-object
              clearable
              @change="$store.dispatch('montages/loadUnassignedMontages')"
            />
          </v-col>
        </v-row>
        <v-spacer></v-spacer>
        <v-btn icon small @click="createMontage()">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </div>
      <v-divider />
      <v-skeleton-loader
        v-if="!montagesLoaded && (!montages || montages.length === 0)"
        class="pl-3 pr-3"
        type="table-thead, table-row-divider@3"
        :types="skeletonTypesIframe"
      />
      <div
        v-if="montagesLoaded && (!montages || montages.length === 0)"
        class="pa-3 d-flex justify-center"
        :class="{ infoNoItems: !isMobile }"
      >
        Нет монтажей
      </div>
      <v-simple-table v-if="montages && montages.length > 0" :dense="!isMobile">
        <template #default>
          <thead>
            <tr>
              <th class="text-left">Дедлайн</th>
              <th class="text-left">Клиент</th>
              <th class="text-left">Номер</th>
              <th class="text-left">Адрес</th>
              <th class="text-left">Что делать</th>
              <th class="text-left">Статус</th>
              <th class="text-left">Менеджер</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="montage in montages"
              :key="montage.id"
              class="tableItemList"
              @click="editMontage(montage)"
            >
              <td style="min-width: 80px">
                {{ $formatDate(new Date(montage.date)) }}
              </td>
              <td
                :style="`min-width: 125px; ${
                  isMobile ? 'min-width: 150px;' : ''
                }`"
              >
                {{ montage.client.name }}
              </td>
              <td style="min-width: 80px">
                <span v-if="montage.numberOrder">{{
                  montage.numberOrder
                }}</span>
                <span v-else>Без номера</span>
              </td>
              <td style="min-width: 125px">{{ montage.address }}</td>
              <td
                :style="`max-width: 280px; ${
                  isMobile ? 'min-width: 150px;' : ''
                }`"
              >
                {{ $sliceStr($getStrTasks(montage.tasks), 125) }}
              </td>
              <td :style="`${isMobile ? 'min-width: 120px;' : ''}`">
                {{ montagesStatus[montage.status] }}
              </td>
              <td>{{ montage.manager && montage.manager.displayName }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </div>
</template>

<script>
import MontageModal from "@/components/Modals/Montage/Index";
import SelectDate from "@/components/Modals/SelectDate";
import { montagesStatus, montagesStatusArr } from "@/CONSTS/montages";
import { PaginationMixin } from "@/mixins/pagination";
import { isMobile } from "mobile-device-detect";

export default {
  name: "UnassignedMontages",
  mixins: [PaginationMixin],
  async beforeRouteLeave(to, from, next) {
    this.$store.commit("montages/resetAllFilterUnassignedMontages");
    this.$store.dispatch("montages/loadUnassignedMontages");
    next();
  },
  data: () => ({
    isMobile: isMobile,
    skeletonTypesIframe: {
      "table-thead": "heading@4",
      "table-row": "table-cell@4",
    },
    montagesStatus: montagesStatus,
    montagesStatusArr: montagesStatusArr,
    openFilters: false,
  }),
  computed: {
    filtersSelected() {
      let filters = [
        this.filterUnassignedMontagesByClient,
        this.filterUnassignedMontagesByManager,
      ];
      filters = filters.filter((x) => !!x);
      return filters.length;
    },
    montagesLoaded() {
      return this.$store.getters["montages/getUnassignedMontagesLoaded"];
    },
    montages() {
      return this.$store.getters["montages/getUnassignedMontages"];
    },
    filterClients() {
      return this.$store.getters["clients/getFilterClients"];
    },
    managers() {
      return this.$store.getters["users/getManagers"];
    },
    filterUnassignedMontagesByDateText() {
      let date = this.filterUnassignedMontagesByDate;
      if (date) {
        return new Date(date).toLocaleString("ru", {
          weekday: "long",
          day: "numeric",
          month: "short",
        });
      }
      return "";
    },
    filterUnassignedMontagesByDate: {
      get() {
        return this.$store.getters[
          "montages/getFilterUnassignedMontagesByDate"
        ];
      },
      set(val) {
        this.$store.commit("montages/setFilterUnassignedMontagesByDate", val);
      },
    },
    filterUnassignedMontagesByClient: {
      get() {
        return this.$store.getters[
          "montages/getFilterUnassignedMontagesByClient"
        ];
      },
      set(val) {
        this.$store.commit("montages/setFilterUnassignedMontagesByClient", val);
      },
    },
    filterUnassignedMontagesByManager: {
      get() {
        return this.$store.getters[
          "montages/getFilterUnassignedMontagesByManager"
        ];
      },
      set(val) {
        this.$store.commit(
          "montages/setFilterUnassignedMontagesByManager",
          val
        );
      },
    },
    // other
    user() {
      return this.$store.getters["user/getUser"];
    },
  },
  created() {
    if (this.user.role === "manager") {
      this.filterUnassignedMontagesByManager = this.user;
    }
    // Mixins
    const _this = this;
    this.PMProps = {
      setLimit(val) {
        _this.$store.commit("montages/SET_LIMIT", val);
      },
      setOffset(val) {
        _this.$store.commit("montages/SET_OFFSET", val);
      },
      load() {
        _this.$store.dispatch("montages/loadMontages");
      },
      onInit() {
        _this.loadData();
      },
      itemsPerPage: 10000,
    };
    //
    // this.loadData();
  },
  methods: {
    loadData() {
      this.$store.dispatch("montages/loadUnassignedMontages");
      this.$store.dispatch("clients/loadFilterClients");
      this.$store.dispatch("users/loadManagers");
    },
    clearDate() {
      this.filterUnassignedMontagesByDate = null;
      this.$store.dispatch("montages/loadUnassignedMontages");
    },
    async selectDate() {
      let modalDate = await this.$showModal({
        component: SelectDate,
        isPersistent: true,
        props: {
          title: "Дата заказа",
          selectDate: this.filterUnassignedMontagesByDate
            ? this.filterUnassignedMontagesByDate.toISOString().substr(0, 10)
            : new Date().toISOString().substr(0, 10),
        },
      });
      if (modalDate) {
        this.filterUnassignedMontagesByDate = new Date(modalDate);
        this.$store.dispatch("montages/loadUnassignedMontages");
      }
    },
    goUrl(url) {
      window.open(url, "_blank").focus();
    },
    async createMontage() {
      await this.$showModal({
        component: MontageModal,
        isPersistent: true,
        props: {
          edit: false,
        },
      });
      this.$store.dispatch("montages/loadUnassignedMontages");
    },
    async editMontage(montage) {
      await this.$showModal({
        component: MontageModal,
        isPersistent: true,
        props: {
          edit: true,
          data: montage,
        },
      });
      this.$store.dispatch("montages/loadUnassignedMontages");
    },
  },
};
</script>