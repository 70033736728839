import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataFooter } from 'vuetify/lib/components/VDataIterator';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"iframe iframe_full pa-1"},[_c('SocketWatch',{attrs:{"tables":['tasks']},on:{"loadData":_vm.loadData}}),_c(VCard,[_c(VCardTitle,{staticClass:"d-flex py-0"},[_c(VIcon,{staticClass:"mr-2"},[_vm._v("mdi-message-text-clock-outline")]),_c('div',[_c(VTabs,{attrs:{"color":"rgb(224, 166, 119)"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabs),function(item){return _c(VTab,{key:item.key,attrs:{"show-arrows":false}},[_vm._v(" "+_vm._s(item.title)+" ")])}),1)],1),_c(VSpacer),(_vm.options[_vm.tab] && _vm.paginations[_vm.tab])?_c(VDataFooter,{staticClass:"rl-data-footer",staticStyle:{"width":"310px","padding":"0"},attrs:{"options":_vm.options[_vm.tab],"pagination":_vm.paginations[_vm.tab],"items-per-page-text":"","items-per-page-options":[5, 10, 15, 20, 30, 40]},on:{"update:options":function($event){return _vm.$set(_vm.options, _vm.tab, $event)}}}):_vm._e()],1),_c(VDivider),_c(VTabsItems,{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabs),function(item,index_item){return _c(VTabItem,{key:item.key},[(item.key === 'meTask')?_c('WidgetMeTask',{attrs:{"options":_vm.options[_vm.tab]},on:{"updOptions":function($event){return _vm.updOptions($event, index_item)},"updPagination":function($event){return _vm.updPagination($event, index_item)}}}):_vm._e(),(item.key === 'fromMeTask')?_c('WidgetFromMeTask',{attrs:{"options":_vm.options[_vm.tab]},on:{"updOptions":function($event){return _vm.updOptions($event, index_item)},"updPagination":function($event){return _vm.updPagination($event, index_item)}}}):_vm._e(),(item.key === 'designTask')?_c('WidgetDesignTask',{attrs:{"options":_vm.options[_vm.tab]},on:{"updOptions":function($event){return _vm.updOptions($event, index_item)},"updPagination":function($event){return _vm.updPagination($event, index_item)}}}):_vm._e()],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }