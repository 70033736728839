import { Role } from '@/helpers';
import { func, storage, usersCollection, brigsCollection } from '@/plugins/firebase';
import Vue from 'vue';

import api from '@/utils/api.js';

import axios from 'axios';
const url = process.env.VUE_APP_SERVICE_URL;

export default {
  namespaced: true,
  state: {
    users: [],
    managers: [],
    filterUserDataByMonth: new Date().toISOString().substr(0, 7),
    searchUsers: '',
    filterUserByRole: '',
    curUser: {},
    fetchedUser: {},
  },
  getters: {
    getManagers: (state) => {
      const sortedUsers = state.managers.sort(function(a, b) {
        return new Date(b.created) - new Date(a.created);
      });
      return sortedUsers;
    },
    getUser: (state) => (ident, by) => {
      let user = null;
      if (by === 'email') {
        user = state.users.find((x) => x.email === ident);
      } else {
        user = state.users.find((x) => x.id === ident);
      }
      return user;
    },
    getUsers: (state) => {
      let users = state.users;
      let search = state.searchUsers.toLowerCase();
      let byRole = state.filterUserByRole;

      if (byRole) {
        users = users.filter((x) => {
          if (x.role && x.role.includes(byRole)) {
            return true;
          }
          return false;
        });
      }

      if (search) {
        users = users.filter((x) => {
          if (x.displayName) {
            let displayName = x.displayName.toLowerCase();
            if (displayName.includes(search)) {
              return true;
            }
          }
          if (x.email) {
            let email = x.email.toLowerCase();
            if (email.includes(search)) {
              return true;
            }
          }
          return false;
        });
      }
      const sortedUsers = users.sort(function(a, b) {
        return new Date(b.created) - new Date(a.created);
      });
      return sortedUsers;
    },

    getUsersObj: (state) => {
      let users = state.users;
      let search = state.searchUsers.toLowerCase();
      let byRole = state.filterUserByRole;

      if (byRole) {
        users = users.filter((x) => {
          if (x.role && x.role.includes(byRole)) {
            return true;
          }
          return false;
        });
      }

      if (search) {
        users = users.filter((x) => {
          if (x.displayName) {
            let displayName = x.displayName.toLowerCase();
            if (displayName.includes(search)) {
              return true;
            }
          }
          if (x.email) {
            let email = x.email.toLowerCase();
            if (email.includes(search)) {
              return true;
            }
          }
          return false;
        });
      }
      const sortedUsers = users.sort(function(a, b) {
        return new Date(b.created) - new Date(a.created);
      });
      return _.keyBy(sortedUsers, 'id');
    },
    getWorkers: (state, getters) => {
      const users = getters.getUsers;
      const workers = users.filter((x) => x.role === 'master' || x.role === 'prodDir');
      return workers;
    },
    getOfficeUsers: (state, getters, rootState, rootGetters) => {
      const user = rootGetters['user/getUser'];
      const users = getters.getUsers;
      // console.log(user)
      let officeUsers = users
        .filter((x) => x.role != 'master')
        .filter((x) => x.role != 'designer')
        .filter((x) => x.id != user.id);
      return officeUsers;
    },
    getDesignerUsers: (state, getters) => {
      const users = getters.getUsers;
      const designerUsers = users.filter((x) => x.role === 'designer');
      // console.log('designer users',users)

      return designerUsers;
    },
    getFilterUserDataByMonth: (state) => {
      return state.filterUserDataByMonth;
    },
    getSearchUsers: (state) => {
      return state.searchUsers;
    },
    getFilterUserByRole: (state) => {
      return state.filterUserByRole;
    },
    getCurUser: (state) => {
      return state.curUser;
    },
    getFetchedUser: (state) => {
      return state.fetchedUser;
    },
    getProdDirs: (state, getters) => {
      const users = getters.getUsers;
      const workers = users.filter((x) => x.role === 'prodDir');
      return workers;
    },
  },
  mutations: {
    setManagers(state, payload) {
      state.managers = payload;
    },
    setUsers(state, payload) {
      state.users = payload;
    },
    pushUser(state, user) {
      state.users.push(user);
    },
    updateUser(state, user) {
      const userIndex = state.users.findIndex((x) => x.id === user.id);
      if (userIndex != -1) {
        Vue.set(state.users, userIndex, user);
      }
    },
    deleteUser(state, id) {
      const indexUser = state.users.findIndex((x) => x.id === id);
      if (indexUser != -1) {
        state.users.splice(indexUser, 1);
      }
    },
    setFilterUserDataByMonth(state, payload) {
      state.filterUserDataByMonth = payload;
    },
    setSearchUsers(state, payload) {
      state.searchUsers = payload;
    },
    setFilterUserByRole(state, payload) {
      state.filterUserByRole = payload;
    },
    resetAllFilterUser(state, payload) {
      state.searchUsers = '';
      state.filterUserByRole = '';
    },
    setCurUser(state, payload) {
      state.curUser = payload;
      // console.log('updateUser')
    },
    setFetchedUser(state, payload) {
      state.fetchedUser = payload;
    },
  },
  actions: {
    async loadManagers({ commit, dispatch }) {
      commit('templ/setLoading', true, { root: true });

      // await axios.post(`${url}/users/get-list`,{
      //     limit: 10000,
      //     offset: 0,
      //     // query: [{input:'del',value: false},{input:'role', value: 'manager'},{input:'role', value: 'admin'}]
      //     // query: [{input:"role", value:"manager"}]
      // }).then(async (response) =>{
      //     if(response.status == 200){
      //         let usersArr = []

      //         for (let userData of response.data.data || []){
      //             // let user = await dispatch('userSerializer', {...userData, id: userData.id})
      //             // usersArr.push(user)
      //             usersArr.push(userData)
      //         }
      //         commit('setManagers',usersArr)
      //         commit('templ/setLoading', false, { root: true })
      //     }
      // })
      await api
        .post('users/get-list', {
          limit: 10000,
          offset: 0,
          query: [
            { input: 'role', value: 'manager' },
            { input: 'role', value: 'admin' },
          ],
        })
        .then((response) => {
          response.data.data.filter((el) => el.del != true);
          commit('setManagers', response.data.data || []);
          commit('templ/setLoading', false, { root: true });
        });
      // usersCollection.where('del', '!=', true).where('role', 'in', ['manager', 'admin']).onSnapshot(async snapshot => {
      //     let usersArr = []

      //     for (let userDoc of snapshot.docs) {
      //         let user = await dispatch('userSerializer', userDoc.data())
      //         usersArr.push(user)
      //     }

      //     commit('setManagers', usersArr)
      //     commit('templ/setLoading', false, { root: true })
      // })
    },
    async loadUsers({ commit, dispatch }) {
      let limit = 10000,
        offset = 0;
      commit('templ/setLoading', true, { root: true });
      // return await usersCollection.where('del', '!=', true).onSnapshot(async snapshot => {
      //     let usersArr = []

      //     for (let userDoc of snapshot.docs) {
      //         let user = await dispatch('userSerializer', userDoc.data())
      //         usersArr.push(user)
      //     }

      //     commit('setUsers', usersArr)
      //     commit('templ/setLoading', false, { root: true })
      // })
      // return await axios.post(`${url}/users/get-list`,{
      //     limit,
      //     offset,
      // }).then(async (response) => {
      //     if(response.status == 200){
      //         commit('setUsers', response.data.data)
      //         commit('templ/setLoading',false, { root: true})
      //     }
      // })
      return await api
        .post('users/get-list', {
          limit,
          offset,
          query: [{ input: 'del', value: false }],
        })
        .then(async (response) => {
          if (response.status == 200) {
            commit('setUsers', response.data.data);
            commit('templ/setLoading', false, { root: true });
          }
        });
    },
    async createUser({ commit, dispatch }, payload) {
      commit('templ/setLoading', true, { root: true });
      // var createUser = func.httpsCallable('createUser');
      let userData = {
        avatar: payload.avatar,
        email: payload.email,
        password: payload.password,
        displayName: payload.displayName,
        location: payload.location,
        rate: Number(payload.rate),
        rateOnManufactory: Number(payload.rateOnManufactory),
        rateOnMontage: Number(payload.rateOnMontage),
        rateOverwork: Number(payload.rateOverwork),
        monthlyPayments: JSON.stringify(payload.monthlyPayments),
        //   customClaims: {
        role: payload.role,
        //   }
      };
      // createUser(userData)
      //   .then(async result => {
      //     // commit('pushUser', result.data)
      //     console.log('Пользователь создан')
      //   }).catch((err) => {
      //     commit('templ/setError', 'Ошибка создания пользователя, попробуйте позже.', { root: true })
      //     console.log(err)
      //   }).finally(() => {
      //     commit('templ/setLoading', false, { root: true })
      //   })
      await axios.post(`${url}/users/create`, userData).then((response) => {
        if (response.status == 200) {
          commit('pushUser', response.data.users);
          commit('templ/setLoading', false, { root: true });
        }
      });
      dispatch('socket/sendSocket', ['users'], {
        root: true,
      });
    },
    async updateUser({ commit, dispatch }, payload) {
      commit('templ/setLoading', true, { root: true });
      if (Object.keys(payload).length > 0) {
        let data = { ...payload.data };

        // var updateUser = func.httpsCallable('updateUser');
        // updateUser({ uid: payload.uid, data: data })
        //     .then(async result => {
        //         console.log('Пользователь обновлён')
        //     }).catch((err) => {
        //         commit('templ/setError', 'Ошибка обновления пользователя, попробуйте позже.', { root: true })
        //         console.log(err)
        //     }).finally(() => {
        //         commit('templ/setLoading', false, { root: true })
        //     })
        if (data.monthlyPayments) {
          data.monthlyPayments = JSON.stringify(data.monthlyPayments);
        }
        await api
          .put('users/update', {
            ...data,
            id: payload.id,
          })
          .then((response) => {
            if (response.status == 200 && response.data.status) {
              commit('updateUser', response.data.users);
              commit('setFetchedUser', response.data.users);
              commit('templ/setLoading', false, { root: true });
            }
          });
        dispatch('socket/sendSocket', ['users'], {
          root: true,
        });
      }
    },
    async deleteUser({ commit, dispatch }, payload) {
      commit('templ/setLoading', true, { root: true });
      // var deleteUser = func.httpsCallable('deleteUser');
      // deleteUser({ uid: payload.uid })
      //     .then((result) => {
      //         if (result) {
      //             commit('deleteUser', payload.uid)
      //         }
      //     }).catch((err) => {
      //         commit('templ/setError', 'Ошибка удаления пользователя, попробуйте позже.')
      //         console.log(err)
      //     }).finally(() => {
      //         commit('templ/setLoading', false, { root: true })
      //     })
      await api
        .put('users/update', {
          del: true,
          id: payload.id,
        })
        .then((response) => {
          console.log(response, 'deleteUser');
          commit('deleteUser', payload.id);
          commit('templ/setLoading', false, { root: true });
        });
      dispatch('socket/sendSocket', ['users'], {
        root: true,
      });
    },
    checkExistsEmail({ state }, email) {
      let users = state.users;
      let user = users.find((x) => x.email === email);
      return user ? false : true;
    },
    async getCurUser({ commit }, id) {
      console.log(id, typeof id);
      const response = await api.get('users/get', id);
      if (response.status == 200) {
        commit('setCurUser', response.data.data);
      }
      return response;
    },
    async userSerializer({ state }, ref) {
      let user = ref;
      // if (ref.uid) {
      //   user = { ...ref }
      // } else {
      //   let res = await ref.get()
      //   user = { ...res.data() }
      // }
      if (!user.location) {
        user = { ...user, location: 'Не указан' };
      }
      if (user.monthlyPayments) {
        user.monthlyPayments = JSON.parse(user.monthlyPayments);
      }
      if (user.avatar && !user.avatar.includes('https')) {
        // let avatar = await storage.ref(user.avatar).getDownloadURL()
        let avatar = '';
        user = { ...user, avatar: avatar };
      }

      return user;
    },
  },
};
