import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.stateNavDrawer)?_c(VNavigationDrawer,{staticStyle:{"border":"solid #ffffff1f","border-width":"0 thin 0 0"},attrs:{"mini-variant":_vm.miniDrawer && !_vm.$vuetify.breakpoint.mobile,"temporary":_vm.$vuetify.breakpoint.mobile,"dark":"","app":"","fixed":"","color":"#424242","width":_vm.isMobile ? 256 : 190},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[(!_vm.$vuetify.breakpoint.mobile)?_c(VListItem,{staticClass:"px-2",staticStyle:{"background-color":"rgb(224, 166, 119)"},on:{"click":function($event){_vm.miniDrawer = !_vm.miniDrawer}}},[_c(VListItemAvatar,{attrs:{"size":"30"}},[(_vm.miniDrawer)?_c(VIcon,[_vm._v("mdi-chevron-right")]):_c(VIcon,[_vm._v("mdi-chevron-left")])],1),_c(VListItemContent,{staticClass:"text-truncate"},[_vm._v(" M4 CRM ")])],1):_vm._e(),_c(VList,{attrs:{"nav":"","dense":""}},[_vm._l((_vm.routes),function(route,index_route){return [(route.meta && route.meta.navDrawer)?_c(VListItem,{key:index_route,staticClass:"d-flex align-center",staticStyle:{"min-height":"30px !important"},attrs:{"to":route.path ? route.path : '/',"link":""}},[_c(VListItemIcon,{staticClass:"my-0 mr-3",staticStyle:{"align-self":"center"}},[_c(VIcon,{attrs:{"dense":!_vm.isMobile}},[_vm._v(_vm._s(route.meta.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(route.meta.text))])],1)],1):_vm._e()]})],2)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }