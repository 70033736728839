import {
  metaInfoCollection,
  manufactoryCollection,
  usersCollection,
  clientsCollection,
} from '@/plugins/firebase';

import loadedTasks from './loaded';
import filterTasks from './filter';
import api from '@/utils/api.js';

const snapshots = {
  loadTasksForMaster: null,
  loadTasksProducedForMaster: null,
  loadTasksArchiveForMaster: null,
  loadTasksWorkForMaster: null,
  loadConfirmationTasks: null,
  loadManagerTasks: null,
  loadTasks: null,
  maxTaskPaginationCount: null,
  loadInProcessTasks: null,
};

export default {
  namespaced: true,
  state: {
    // tasks
    tasks: [],
    confirmationTasks: [],
    managerTasks: [],
    tasksConfirmProd: [],
    tasksWorkForMaster: [],
    tasksProducedForMaster: [],
    tasksArchiveForMaster: [],
    tasksForMaster: [],
    inProcessTasks: [],
    // filters
    ...filterTasks.state,
    // loaded tasks
    ...loadedTasks.state,
    // pagination
    tasksPage: 1,
    tasksPrev: null,
    tasksNext: null,
    maxTaskCount: 0,
    // pagination user tasks
    tasksForMasterPage: 1,
    tasksForMasterLastPage: 1,
    limit: 0,
    offset: 0,
  },
  getters: {
    // tasks
    getTasks: (state) => {
      const sortedTasks = state.tasks.sort(function(a, b) {
        return new Date(b.date) - new Date(a.date);
      });
      return sortedTasks;
    },
    getConfirmationTasks: (state) => {
      const sortedTasks = state.confirmationTasks.sort(function(a, b) {
        return new Date(a.date) - new Date(b.date);
      });
      return sortedTasks;
    },
    getManagerTasks: (state) => {
      const sortedTasks = state.managerTasks.sort(function(a, b) {
        return new Date(a.date) - new Date(b.date);
      });
      return sortedTasks;
    },
    getTasksWorkForMaster: (state) => {
      const sortedTasks = state.tasksWorkForMaster.sort(function(a, b) {
        return new Date(a.date) - new Date(b.date);
      });
      return sortedTasks;
    },
    getTasksProducedForMaster: (state) => {
      const sortedTasks = state.tasksProducedForMaster.sort(function(a, b) {
        return new Date(a.date) - new Date(b.date);
      });
      return sortedTasks;
    },
    getTasksArchiveForMaster: (state) => {
      const sortedTasks = state.tasksArchiveForMaster.sort(function(a, b) {
        return new Date(a.date) - new Date(b.date);
      });
      return sortedTasks;
    },
    getTasksForMaster: (state) => {
      const sortedTasks = state.tasksForMaster.sort(function(a, b) {
        return new Date(b.date) - new Date(a.date);
      });
      return sortedTasks;
    },
    getListTasksPageForMaster: (state, getters) => {
      let tasks = getters.getTasksForMaster;
      let pageTasks = state.tasksForMasterPage;

      let tasksPages = [];
      for (let i = 0; i < tasks.length; i += 10) {
        tasksPages.push(tasks.slice(i, i + 10));
      }

      let tasksArr = tasksPages[pageTasks - 1];

      return tasksArr ? tasksArr : [];
    },
    getInProcessTasks: (state) => {
      const sortedTasks = state.inProcessTasks.sort(function(a, b) {
        // return a.date.toDate() - b.date.toDate()
        return new Date(a.date) - new Date(b.date);
      });
      return sortedTasks;
    },
    // filters
    ...filterTasks.getters,
    // loaded tasks
    ...loadedTasks.getters,
    // pagination archive tasks
    getLimit: (state) => {
      return state.limit;
    },
    getOffset: (state) => {
      return state.offset;
    },
    getTasksPage: (state) => {
      return state.tasksPage;
    },
    getTasksPrev: (state) => {
      return state.tasksPrev;
    },
    getTasksNext: (state) => {
      return state.tasksNext;
    },
    getMaxTaskCount: (state) => {
      return state.maxTaskCount;
    },
    // pagination user tasks
    getTasksForMasterPage: (state) => {
      return state.tasksForMasterPage;
    },
    getTasksForMasterLastPage: (state) => {
      return state.tasksForMasterLastPage;
    },
  },
  mutations: {
    // tasks
    setTasks(state, payload) {
      state.tasks = payload;
    },
    setInProcessTasks(state, payload) {
      state.inProcessTasks = payload;
    },
    setConfirmationTasks(state, payload) {
      state.confirmationTasks = payload;
    },
    setManagerTasks(state, payload) {
      state.managerTasks = payload;
    },
    setTasksWorkForMaster(state, payload) {
      state.tasksWorkForMaster = payload;
    },
    setTasksProducedForMaster(state, payload) {
      state.tasksProducedForMaster = payload;
    },
    setTasksArchiveForMaster(state, payload) {
      state.tasksArchiveForMaster = payload;
    },
    setTasksForMaster(state, payload) {
      state.tasksForMaster = payload;
    },
    updTask(state, payload) {
      if (payload.doc && payload.doc.id && payload.updState) {
        const item = state[payload.updState].find((item) => item.id === payload.doc.id);
        Object.assign(item, payload.doc);
      }
    },
    pushTask(state, payload) {
      if (payload.doc && payload.doc.id && payload.pushTo) {
        if (!state[payload.pushTo].find((item) => item.id === payload.doc.id)) {
          state[payload.pushTo].push(payload.doc);
        }
      }
    },
    removeTask(state, payload) {
      if (payload.id && payload.removeFrom) {
        let index = state[payload.removeFrom].findIndex((item) => item.id === payload.id);
        if (index != -1) {
          state[payload.removeFrom].splice(index, 1);
        }
      }
    },
    // filters
    ...filterTasks.mutations,
    // loaded tasks
    ...loadedTasks.mutations,
    // pagination archive tasks
    setTasksPaginationPage(state, payload) {
      state.tasksPage = payload;
    },
    setTasksPagination(state, payload) {
      state[`tasks${payload.action}`] = payload.cursor;
    },
    setMaxTaskPaginationCount(state, payload) {
      state.maxTaskCount = payload;
    },
    // pagination user tasks
    setTasksForMasterPaginationPage(state, payload) {
      state.tasksForMasterPage = payload;
    },
    setTasksForMasterPaginationLastPage(state, payload) {
      state.tasksForMasterLastPage = payload;
    },
    SET_LIMIT(state, payload) {
      state.limit = payload;
    },
    SET_OFFSET(state, payload) {
      state.offset = payload;
    },
  },
  actions: {
    async loadTaskById({ commit, dispatch, getters }, id) {
      commit('templ/setLoading', true, { root: true });

      // let queryStore = manufactoryCollection.doc(id)
      // let doc = await queryStore.get()
      let task = await api.get('manufactory/get', id).then((response) => {
        if (response.status == 200) {
          return response.data.data;
        }
      });
      // doc = await dispatch('taskSerializer', { ...doc.data(), id: doc.id })
      task = await dispatch('taskSerializer', task);

      commit('templ/setLoading', false, { root: true });
      return task;
    },
    async loadTasksForMaster({ commit, dispatch, getters, state }, data) {
      commit('templ/setLoading', true, { root: true });

      // if (snapshots.loadTasksForMaster) {
      //   snapshots.loadTasksForMaster()
      //   snapshots.loadTasksForMaster = null
      // }

      // let queryStore = manufactoryCollection.where('status', 'in', ['archive', 'ready'])
      let queryStore = await api.post('manufactory/get-list', {
        limit: state.limit,
        offset: state.offset,
        query_and: [
          { input: 'del', value: false },
          { input: 'status', value: 'archive' },
          { input: 'status', value: 'ready' },
          data.id ? { input: 'montagers', value: String(data.id) } : null,
          // data.month? { input: 'readyDate', value: data.month } : null
        ],
      });

      if (data.month) {
        let date = new Date(data.month),
          y = date.getFullYear(),
          m = date.getMonth();
        let firstDay = new Date(y, m, 1, 0);
        let lastDay = new Date(y, m + 1, 0, 23, 59);
        // queryStore = queryStore.orderBy('readyDate').startAt(firstDay).endAt(lastDay);
      }

      if (data.uid || data.id) {
        // queryStore = queryStore.where('montagers', 'array-contains', data.uid)
        // queryStore = queryStore.data.data.find(el => el.montagers.includes(data.uid || data.id))
      }
      let manufactoryTasksArr = [];

      queryStore.data.data
        ? queryStore.data.data.forEach(async (el) => {
            let task = await dispatch('taskSerializer', el);
            manufactoryTasksArr.push(task);
          })
        : queryStore.forEach(async (el) => {
            let task = await dispatch('taskSerializer', el);
            manufactoryTasksArr.push(task);
          });
      manufactoryTasksArr = manufactoryTasksArr.filter((x) => !x.del);
      let newTasksArr = [];
      for (let i = 0; i < manufactoryTasksArr.length; i += 10) {
        newTasksArr.push(manufactoryTasksArr.slice(i, i + 10));
      }

      commit('setTasksForMasterPaginationLastPage', newTasksArr.length);
      commit('setTasksForMasterLoaded', true);
      commit('setTasksForMaster', manufactoryTasksArr);
      commit('templ/setLoading', false, { root: true });

      // snapshots.loadTasksForMaster = await queryStore.onSnapshot(async snapshot => {
      //   let manufactoryTasksArr = []
      //   for (let doc of snapshot.docs) {
      //     let task = await dispatch('taskSerializer', { ...doc.data(), id: doc.id })
      //     manufactoryTasksArr.push(task)
      //   }
      //   manufactoryTasksArr = manufactoryTasksArr.filter(x => !x.del)

      //   let newTasksArr = [];
      //   for (let i = 0; i < manufactoryTasksArr.length; i += 10) {
      //     newTasksArr.push(manufactoryTasksArr.slice(i, i + 10));
      //   }
      //   commit('setTasksForMasterPaginationLastPage', newTasksArr.length)

      //   commit('setTasksForMasterLoaded', true)
      //   commit('setTasksForMaster', manufactoryTasksArr)
      //   commit('templ/setLoading', false, { root: true })
      // })
    },
    async loadTasksProducedForMaster({ commit, dispatch, getters, state }, data) {
      commit('templ/setLoading', true, { root: true });

      // if (snapshots.loadTasksProducedForMaster) {
      //   snapshots.loadTasksProducedForMaster()
      //   snapshots.loadTasksProducedForMaster = null
      // }

      // let queryStore = manufactoryCollection.where('del', '!=', true).where('status', 'in', ['produced', 'ready'])
      let queryStore = await api.post('manufactory/get-list', {
        limit: state.limit,
        offset: state.offset,
        query: [
          { input: 'status', value: 'produced' },
          { input: 'status', value: 'ready' },
        ],
        query_and: [
          { input: 'del', value: false },
          data.id ? { input: 'montagers', value: String(data.id) } : null,
        ],
      });
      let manufactoryTasksArr = [];

      console.log(queryStore, 'loadTasksProducedForMaster');

      queryStore.data.data
        ? queryStore.data.data.forEach(async (el) => {
            let task = await dispatch('taskSerializer', el);
            manufactoryTasksArr.push(task);
          })
        : queryStore.forEach(async (el) => {
            let task = await dispatch('taskSerializer', el);
            manufactoryTasksArr.push(task);
          });

      commit('setTasksProducedForMasterLoaded', true);
      commit('setTasksProducedForMaster', manufactoryTasksArr);
      commit('templ/setLoading', false, { root: true });

      // snapshots.loadTasksProducedForMaster = queryStore.onSnapshot(async snapshot => {
      //   let manufactoryTasksArr = []

      //   for (let doc of snapshot.docs) {
      //     let task = await dispatch('taskSerializer', { ...doc.data(), id: doc.id })
      //     manufactoryTasksArr.push(task)
      //   }

      //   commit('setTasksProducedForMasterLoaded', true)
      //   commit('setTasksProducedForMaster', manufactoryTasksArr)
      //   commit('templ/setLoading', false, { root: true })
      // })
    },
    async loadTasksArchiveForMaster({ commit, dispatch, getters, state }, data) {
      commit('templ/setLoading', true, { root: true });

      // if (snapshots.loadTasksArchiveForMaster) {
      //   snapshots.loadTasksArchiveForMaster()
      //   snapshots.loadTasksArchiveForMaster = null
      // }

      // let queryStore = manufactoryCollection.where('del', '!=', true).where('status', '==', 'archive')
      let queryStore = await api.post('manufactory/get-list', {
        limit: state.limit,
        offset: state.offset,
        query_and: [
          { input: 'del', value: false },
          { input: 'status', value: 'archive' },
          data.id ? { input: 'montagers', value: String(data.id) } : null,
        ],
      });
      if (data.uid || data.id) {
        // queryStore = queryStore.where('montagers', 'array-contains', data.uid)
        // queryStore = queryStore.data.data.find(el => el.montagers.includes(data.uid || data.id))
      }
      let manufactoryTasksArr = [];
      queryStore.data.data
        ? queryStore.data.data.forEach(async (el) => {
            let task = await dispatch('taskSerializer', el);
            manufactoryTasksArr.push(task);
          })
        : queryStore.forEach(async (el) => {
            let task = await dispatch('taskSerializer', el);
            manufactoryTasksArr.push(task);
          });

      commit('setTasksArchiveForMaster', manufactoryTasksArr);
      commit('templ/setLoading', false, { root: true });
      // snapshots.loadTasksArchiveForMaster = queryStore.onSnapshot(async snapshot => {
      //   let manufactoryTasksArr = []

      //   for (let doc of snapshot.docs) {
      //     let task = await dispatch('taskSerializer', { ...doc.data(), id: doc.id })
      //     manufactoryTasksArr.push(task)
      //   }

      //   // commit('setTasksArchiveForMasterLoaded', true)
      //   commit('setTasksArchiveForMaster', manufactoryTasksArr)
      //   commit('templ/setLoading', false, { root: true })
      // })
    },
    async loadTasksWorkForMaster({ commit, dispatch, getters, state }, data) {
      commit('templ/setLoading', true, { root: true });

      // if (snapshots.loadTasksWorkForMaster) {
      //   snapshots.loadTasksWorkForMaster()
      //   snapshots.loadTasksWorkForMaster = null
      // }

      // let queryStore = manufactoryCollection.where('del', '!=', true).where('status', '==', 'work')
      let queryStore = await api.post('manufactory/get-list', {
        limit: state.limit,
        offset: state.offset,
        query_and: [
          { input: 'del', value: false },
          { input: 'status', value: 'work' },
          data.id ? { input: 'montagers', value: String(data.id) } : null,
        ],
      });

      if (data.uid || data.id) {
        // queryStore = queryStore.where('montagers', 'array-contains', data.uid)
        // queryStore = queryStore.data.data.find(el => el.montagers.includes(data.uid || data.id))
      }
      let manufactoryTasksArr = [];
      queryStore.data.data
        ? queryStore.data.data.forEach(async (el) => {
            let task = await dispatch('taskSerializer', el);
            manufactoryTasksArr.push(task);
          })
        : queryStore.forEach(async (el) => {
            let task = await dispatch('taskSerializer', el);
            manufactoryTasksArr.push(task);
          });

      commit('setTasksWorkForMasterLoaded', true);
      commit('setTasksWorkForMaster', manufactoryTasksArr);
      commit('templ/setLoading', false, { root: true });
      // snapshots.loadTasksWorkForMaster = queryStore.onSnapshot(async snapshot => {
      //   let manufactoryTasksArr = []

      //   for (let doc of snapshot.docs) {
      //     let task = await dispatch('taskSerializer', { ...doc.data(), id: doc.id })
      //     manufactoryTasksArr.push(task)
      //   }

      //   commit('setTasksWorkForMasterLoaded', true)
      //   commit('setTasksWorkForMaster', manufactoryTasksArr)
      //   commit('templ/setLoading', false, { root: true })
      // })
    },
    async loadInProcessTasks({ commit, getters, dispatch, rootGetters, state }) {
      commit('templ/setLoading', true, { root: true });

      const queryAnd = [];
      let filterByDate = getters.getFilterWorkTasksByDate;

      if (filterByDate) {
        filterByDate = filterByDate.toISOString().split('T')[0];
        queryAnd.push({ input: 'date', value: filterByDate });
      }

      let filterByClient = getters.getFilterWorkTasksByClient;
      if (filterByClient && filterByClient.id) {
        queryAnd.push({ input: 'client_id', value: filterByClient.id });
      }

      let filterByManager = getters.getFilterWorkTasksByManager;
      if ((filterByManager && filterByManager.uid) || (filterByManager && filterByManager.id)) {
        queryAnd.push({ input: 'manager_id', value: filterByManager.uid || filterByManager.id });
      }
      // console.log(queryAnd, filterByDate, filterByManager, )
      let queryStore = await api.post('manufactory/get-list', {
        limit: state.limit,
        offset: state.offset,
        query: [
          { input: 'status', value: 'confirmation' },
          { input: 'status', value: 'confirmationProd' },
          { input: 'status', value: 'work' },
          { input: 'status', value: 'produced' },
          { input: 'status', value: 'ready' },
        ],
        query_and: [{ input: 'del', value: false }, ...queryAnd],
      });

      let manufactoryTasksArr = [];
      queryStore = queryStore.data.data || [];
      queryStore.forEach(async (el) => {
        let task = await dispatch('taskSerializer', el);
        manufactoryTasksArr.push(task);
      });

      commit('setInProcessTasksLoaded', true);
      commit('setInProcessTasks', manufactoryTasksArr);
      commit('templ/setLoading', false, { root: true });
    },
    async loadConfirmationTasks({ commit, dispatch, state }, obj) {
      commit('templ/setLoading', true, { root: true });

      // if (snapshots.loadConfirmationTasks) {
      //   snapshots.loadConfirmationTasks()
      //   snapshots.loadConfirmationTasks = null
      // }

      // let queryStore = manufactoryCollection.where('del', '!=', true).where('status', '==', 'confirmation')
      let queryStore = await api.post('manufactory/get-list', {
        limit: state.limit,
        offset: state.offset,
        query_and: [
          { input: 'del', value: false },
          { input: 'status', value: 'confirmation' },
        ],
      });
      if (obj && obj.manager) {
        // let userRef = usersCollection.doc(obj.manager)
        // queryStore = queryStore.where('manager', '==', userRef)
        queryStore = await api.post('manufactory/get-list', {
          limit: state.limit,
          offset: state.offset,
          query: [{ input: 'manager', value: obj.manager }],
        });
      }
      let manufactoryTasksArr = [];
      queryStore = queryStore.data.data || [];

      queryStore.forEach(async (el) => {
        let task = await dispatch('taskSerializer', el);
        manufactoryTasksArr.push(task);
      });

      commit('setConfirmationTasksLoaded', true);
      commit('setConfirmationTasks', manufactoryTasksArr);
      commit('templ/setLoading', false, { root: true });
      // snapshots.loadConfirmationTasks = queryStore.onSnapshot(async snapshot => {
      //   let manufactoryTasksArr = []

      //   for (let doc of snapshot.docs) {
      //     let task = await dispatch('taskSerializer', { ...doc.data(), id: doc.id })
      //     manufactoryTasksArr.push(task)
      //   }

      //   commit('setConfirmationTasksLoaded', true)
      //   commit('setConfirmationTasks', manufactoryTasksArr)
      //   commit('templ/setLoading', false, { root: true })
      // })
    },
    async loadManagerTasks({ commit, dispatch, getters, state }) {
      commit('templ/setLoading', true, { root: true });

      // if (snapshots.loadManagerTasks) {
      //   snapshots.loadManagerTasks()
      //   snapshots.loadManagerTasks = null
      // }

      let manager = this.getters['user/getUser'];
      // let managerRef = usersCollection.doc(manager.uid)
      // let queryStore = manufactoryCollection.where('del', '!=', true)
      let queryStore = await api.post('manufactory/get-list', {
        limit: state.limit,
        offset: state.offset,
        query: [
          { input: 'status', value: 'confirmation' },
          { input: 'status', value: 'confirmationProd' },
          { input: 'status', value: 'work' },
          { input: 'status', value: 'produced' },
          { input: 'status', value: 'ready' },
        ],
        query_and: [
          { input: 'manager', value: manager.id },
          { input: 'del', value: false },
        ],
      });
      // queryStore = queryStore.where('manager', '==', managerRef)
      // queryStore = queryStore.where('status', 'in', ['confirmation', 'confirmationProd', 'work', 'produced', 'ready'])
      let manufactoryTasksArr = [];
      queryStore = queryStore.data.data || [];
      queryStore.forEach(async (el) => {
        let task = await dispatch('taskSerializer', el);
        manufactoryTasksArr.push(task);
      });
      commit('setManagerTasksLoaded', true);
      commit('setManagerTasks', manufactoryTasksArr);
      commit('templ/setLoading', false, { root: true });
      // snapshots.loadManagerTasks = queryStore.onSnapshot(async snapshot => {
      //   let manufactoryTasksArr = []

      //   for (let doc of snapshot.docs) {
      //     let task = await dispatch('taskSerializer', { ...doc.data(), id: doc.id })
      //     manufactoryTasksArr.push(task)
      //   }

      //   commit('setManagerTasksLoaded', true)
      //   commit('setManagerTasks', manufactoryTasksArr)
      //   commit('templ/setLoading', false, { root: true })
      // })
    },
    async loadTasks({ commit, dispatch, getters, state }, pagination) {
      // alert('Tasks')
      commit('templ/setLoading', true, { root: true });

      // if (snapshots.loadTasks) {
      //   snapshots.loadTasks()
      //   snapshots.loadTasks = null
      // }
      // if (snapshots.maxTaskPaginationCount) {
      //   snapshots.maxTaskPaginationCount()
      //   snapshots.maxTaskPaginationCount = null
      // }

      // let tomorrow = new Date()
      // tomorrow.setDate(tomorrow.getDate()+1)
      // let toDay = new Date(tomorrow.toISOString().substr(0, 10))

      let queryStore;
      const queryAnd = [];
      // let queryStore = await api.post('manufactory/get-list',{
      //   limit: state.limit,
      //   offset: state.offset,
      // })

      // queryStore = queryStore.where('del', '==', false).where('status', '==', 'archive')

      let filterByDate = getters.getFilterTasksByDate;
      if (filterByDate) {
        filterByDate = filterByDate.toISOString().split('T')[0];
        queryAnd.push({ input: 'date', value: filterByDate });

        // // queryStore = queryStore.where('date', '==', filterByDate)

        // queryStore = await api.post('manufactory/get-list',{
        //   limit: state.limit,
        //   offset: state.offset,
        //   query: [{input: 'date', value: filterByDate }]
        // })
        // console.log(queryStore, 'query date')
      }
      let filterByMonth = getters.getFilterTasksByMonth;
      if (filterByMonth) {
        queryAnd.push({ input: 'date', value: filterByMonth });
      }

      let filterByClient = getters.getFilterTasksByClient;
      if (filterByClient && filterByClient.id) {
        queryAnd.push({ input: 'client_id', value: filterByClient.id });
        // let clientRef = clientsCollection.doc(filterByClient.id)
        // queryStore = queryStore.where('client', '==', clientRef)
        // queryStore = await api.post('manufactory/get-list',{
        //   limit: state.limit,
        //   offset: state.offset,
        //   query_and: [
        //     {input: 'del', value: false},
        //     {input: 'client_id', value:filterByClient.id},
        //     {input: 'status', value: 'archive'}
        //   ]
        // })
      }

      let filterByManager = getters.getFilterTasksByManager;
      if ((filterByManager && filterByManager.uid) || (filterByManager && filterByManager.id)) {
        // let managerRef = usersCollection.doc(filterByManager.uid)
        // queryStore = queryStore.where('manager', '==', managerRef)
        queryAnd.push({ input: 'manager_id', value: filterByManager.uid || filterByManager.id });
        // queryStore = await api.post('manufactory/get-list',{
        //   limit: state.limit,
        //   offset: state.offset,
        //   query_and: [
        //     { input: 'manager_id', value: filterByManager.uid || filterByManager.id},
        //     {input: 'status', value: 'archive'}
        //   ]
        // })
      }

      queryStore = await api.post('manufactory/get-list', {
        limit: state.limit,
        offset: state.offset,
        query_and: [
          { input: 'del', value: false },
          { input: 'status', value: 'archive' },
          ...queryAnd,
        ],
      });

      commit('setMaxTaskPaginationCount', queryStore.data.count);
      if (pagination) {
        let page = getters.getTasksPage;
        let prev = getters.getTasksPrev;
        let next = getters.getTasksNext;

        if (pagination === 'prev' && prev) {
          commit('setTasksPaginationPage', page - 1);
          // queryStore = queryStore.endBefore(prev).limitToLast(20)
        }
        if (pagination === 'next' && next) {
          commit('setTasksPaginationPage', page + 1);
          // queryStore = queryStore.startAfter(next).limit(20)
        }
      } else {
        commit('setTasksPaginationPage', 1);
        // queryStore = queryStore.limit(20)
      }
      let manufactoryTasksArr = [];
      queryStore.data ? (queryStore = queryStore.data.data) : queryStore;
      queryStore.forEach(async (el) => {
        let task = await dispatch('taskSerializer', el);
        manufactoryTasksArr.push(task);
      });
      commit('setTasksLoaded', true);
      commit('setTasks', manufactoryTasksArr);
      commit('templ/setLoading', false, { root: true });
      // snapshots.loadTasks = queryStore.onSnapshot(async snapshot => {
      //   // pagination
      //   // if (snapshot.docs.length > 0 && snapshot.docs[0]) {
      //   //   commit('setTasksPagination', { action: 'Prev', cursor: snapshot.docs[0] })
      //   // }
      //   // if (snapshot.docs.length === 20 && snapshot.docs[snapshot.docs.length-1]) {
      //   //   commit('setTasksPagination', { action: 'Next', cursor: snapshot.docs[snapshot.docs.length-1] })
      //   // }

      //   for (let doc of snapshot.docs) {
      //     let task = await dispatch('taskSerializer', { ...doc.data(), id: doc.id })
      //     manufactoryTasksArr.push(task)
      //   }

      //   commit('setTasksLoaded', true)
      //   commit('setTasks', manufactoryTasksArr)
      //   commit('templ/setLoading', false, { root: true })
      // })

      // snapshots.maxTaskPaginationCount = metaInfoCollection.doc('manufactory').onSnapshot(async snapshot => {
      //   let metaInfo = snapshot.data()
      //   commit('setMaxTaskPaginationCount', metaInfo.countDocArchive)
      // })
    },
    async createTask({ commit, rootState, rootGetters, dispatch, state }, data) {
      console.log(data, 'DATA');
      commit('templ/setLoading', true, { root: true });
      const obj = { ...data, del: false };
      let manager;
      var client;
      if (!obj.numberOrder) {
        let number = await dispatch('getNewNumberManufactory');
        obj.numberOrder = `c${number}`;
      }
      if (obj.client) {
        obj.client_id = obj.client.id;
        client = { ...obj.client };
        delete obj.client;
      }
      if (obj.manager) {
        obj.manager_id = Number(obj.manager);
        manager = { ...obj.manager };
        delete obj.manager;
      }
      obj.costForProd = Number(obj.costForProd);
      obj.images = String(obj.images || '');
      // client
      // if (obj.client) {
      //   let client = await obj.client.get()
      //   obj.clientData = { id: client.id, ...client.data() }
      // }

      // manager
      // if (obj.manager) {
      //   let manager = await obj.manager.get()
      //   obj.managerData = { ...manager.data() }
      // }
      console.log(obj, 'OBJ');
      await api.post('manufactory/create', obj).then(async (response) => {
        if (response.status == 200) {
          console.log(response.data.manufactory, 'createTask');
          await dispatch('loadInProcessTasks');
        }
        const data = response.data.manufactory;

        // отправка уведомления Начальнику производства
        if (data.status === 'confirmationProd') {
          let prodDirs = rootGetters['users/getProdDirs'];
          if (prodDirs && prodDirs.length > 0) {
            let date = obj.date;
            date = date.toLocaleString('ru', { day: 'numeric', month: 'short' });
            let title = `Производство на ${date}`;
            let text = `Для ${client.name}`;
            for (let prodDirId of prodDirs) {
              // alert(prodDirId.id)
              await dispatch(
                'notify/notify',
                {
                  to: prodDirId.id,
                  title: title,
                  text: text,
                  action: `confirmProdManufactoryTask`,
                  payload: {
                    taskId: data.id,
                  },
                },
                { root: true },
              );
            }
          }
        }
      });
      // await manufactoryCollection.add(obj)
      commit('templ/setLoading', false, { root: true });
    },
    async updateTask({ commit, dispatch, rootGetters }, obj) {
      commit('templ/setLoading', true, { root: true });
      let user = rootGetters['user/getUser'];
      let doc = await api.get('manufactory/get', obj.id);
      let managerTask = obj.manager || null;
      let task = obj.task || null;

      if (obj.data) {
        obj = { ...obj, ...obj.data };
        delete obj.data;
      }

      if (obj.client) {
        obj.client_id = obj.client.id;
      }

      if (obj.images) obj.images = String(obj.images);
      if (obj.montagers) obj.montagers = String(obj.montagers);
      if (obj.montagersInfo) obj.montagersInfo = JSON.stringify(obj.montagersInfo);

      if (obj.costForProd) {
        obj.costForProd = Number(obj.costForProd);
      } else {
        obj.costForProd = 0;
      }

      doc = await api.put('manufactory/update', obj).then(async (response) => {
        if (response.status == 200) {
          const data = response.data.manufactory;

          let prodDirs = rootGetters['users/getProdDirs'];
          let user = rootGetters['user/getUser'];
          let users = new Set();
          users.add(Number(data.manager_id));
          if (data.montagers) users.add(Number(data.montagers));
          if (prodDirs.length) prodDirs.forEach(({ id }) => users.add(Number(id)));
          users.delete(user.id);
          users = [...users];

          // отправка уведомления Начальнику производства
          if (data.status === 'confirmationProd') {
            // if (prodDirs && prodDirs.length > 0) {
            let date = obj.date;
            date = date.toLocaleString('ru', { day: 'numeric', month: 'short' });
            let title = `Производство на ${date}`;
            let text = `Для ${obj.client.name}`;
            for (let to of users) {
              // alert(prodDirId.id)
              await dispatch(
                'notify/notify',
                {
                  to,
                  title: title,
                  text: text,
                  action: `confirmProdManufactoryTask`,
                  payload: {
                    taskId: data.id,
                  },
                },
                { root: true },
              );
              // }
            }
          }

          if (data.status === 'confirmation') {
            let date = data.date;
            date = date.toLocaleString('ru', { day: 'numeric', month: 'short' });
            let title = `Производство на доработку`;
            let text = `Для ${data.client_data.name}`;
            for (let to of users) {
              await dispatch(
                'notify/notify',
                {
                  to,
                  title: title,
                  text: text,
                  action: `confirmProdManufactoryTask`,
                  payload: {
                    taskId: obj.id,
                  },
                },
                { root: true },
              );
            }
          }

          // отправка уведомлений Мастерам когда на них назначили
          if (data.status === 'work') {
            let date = new Date(data.date);
            date = date.toLocaleString('ru', { day: 'numeric', month: 'short' });
            let title = `Мастерам Новое производство на ${date}`;
            let text = `Для ${data.client_data.name}`;
            console.log(data.montagers, 'data.montagers');
            // for (let masterId of data.montagers) {
            for (let to of users) {
              await dispatch(
                'notify/notify',
                {
                  to,
                  title: title,
                  text: text,
                  action: `workManufactoryTask`,
                  payload: {
                    taskId: obj.id,
                  },
                },
                { root: true },
              );
            }
          }

          // отправка уведомлений Менеджеру когда задание готово
          if (data.status === 'ready') {
            let title = `Задание для ${data.client_data.name} `;
            let text = `Статус: готво`;
            for (let to of users) {
              await dispatch(
                'notify/notify',
                {
                  to,
                  title: title,
                  text: text,
                  action: `readyManufactoryTask`,
                  payload: {
                    taskId: obj.id,
                  },
                },
                { root: true },
              );
            }
          }
          return response.data.manufactory;
        }
      });

      // отправка уведомления Менеджеру если вернули на доработку

      if (obj.removeFrom) {
        commit('removeTask', { id: obj.id, removeFrom: obj.removeFrom });
      }

      if (obj.pushTo || obj.updState) {
        // doc = await doc.get()
        // doc = await dispatch('taskSerializer', { ...doc.data(), id: doc.id })
        if (obj.pushTo) {
          commit('pushTask', { doc: doc, pushTo: obj.pushTo });
        }

        if (obj.updState) {
          commit('updTask', { doc: doc, updState: obj.updState });
        }
      }
      if (user.role != 'master') {
        await dispatch('loadInProcessTasks');
      } else {
        await dispatch('loadTasksProducedForMaster', { id: user.id });
      }
      commit('templ/setLoading', false, { root: true });

      dispatch('socket/sendSocket', ['manufactory'], { root: true });
    },
    async deleteTask({ commit, rootState, dispatch }, data) {
      commit('templ/setLoading', true, { root: true });
      // await manufactoryCollection.doc(data.id).update({ del: true })
      data.del = true;
      await api
        .put('manufactory/update', {
          id: data.id,
          del: true,
        })
        .then(async () => {
          console.log('Задание удалено');
        })
        .catch((err) => {
          commit('templ/setError', 'Ошибка удаления задания, попробуйте позже.');
          console.log(err);
        })
        .finally(() => {
          dispatch('socket/sendSocket', ['manufactory'], { root: true });
          commit('templ/setLoading', false, { root: true });
        });
    },
    async taskSerializer({ state, dispatch }, ref) {
      // let task = {}
      let task = ref;
      // if (ref.id) {
      //   task = ref
      // } else {
      //   let res = await ref.get()
      //   task = { ...res.data(), id: res.id }
      // }

      if (task.managerData) {
        task = { ...task, manager: task.managerData };
        delete task.managerData;
      }

      if (task.client_data) {
        let clientObj = {
          id: task.client_data.id,
          surname: task.client_data.surname,
          phone: task.client_data.phone,
          name: task.client_data.name,
        };
        task = { ...task, client: clientObj };
        delete task.client_data;
      }
      if (task.montagersInfo) {
        task.montagersInfo = JSON.parse(task.montagersInfo);
      }
      if (task.images) {
        task.images = task.images.split(',');
      }
      // if (task.montagers && task.montagers.length > 0) {
      //   let montagers = []
      //   for(let uid of task.montagers) {
      //     let ref = await usersCollection.doc(uid)
      //     let montager = await dispatch('users/userSerializer', ref, { root: true })
      //     montagers.push(montager)
      //   }
      //   task = { ...task, montagersLocalInfo: montagers }
      // }
      // console.log('task',task)
      return task;
    },
    async getNewNumberManufactory({ state }) {
      let toDay = new Date();
      let year = String(toDay.getFullYear()).substring(2);
      let month = toDay.getMonth() + 1;

      if (month < 10) {
        month = `0${month}`;
      }

      let date = new Date(),
        y = date.getFullYear(),
        m = date.getMonth();
      let firstDay = new Date(y, m, 1, 0);
      let lastDay = new Date(y, m + 1, 0, 23, 59);

      // console.log(`Дата по UTC: ${date}`)
      // console.log(`Первый день(${firstDay}), последний день(${lastDay})`)

      let numberInMonth = '01';
      // let QueryStore = await manufactoryCollection.orderBy('date').startAt(firstDay).endAt(lastDay).get()
      let QueryStore = await api
        .post('manufactory/get-list', {
          limit: state.limit,
          offset: state.offset,
          sort: 'date',
        })
        .then((response) => {
          if ((response.status = 200)) {
            return response.data.count;
          }
        });
      // let lengthList = QueryStore.docs
      let lengthList = QueryStore + 1;

      if (lengthList < 10) {
        numberInMonth = `0${lengthList}`;
      } else {
        numberInMonth = lengthList;
      }

      return `${year}${month}${numberInMonth}`;
    },
  },
};
