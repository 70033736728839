import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,[_c(VCol,{staticClass:"mb-2",attrs:{"cols":"4"}},[_c(VCard,{attrs:{"dark":""}},[_c(VCardTitle,{staticClass:"text-h6"},[_vm._v(" Количество заказов ")]),_c(VCardSubtitle,{staticClass:"text-h2 green--text font-weight-black"},[_vm._v(" "+_vm._s(_vm.ordersAll)+" ")]),_c(VCardText,[_vm._v(" Сумма за месяц: "),_c('span',{staticClass:"font-weight-bold white--text"},[_vm._v(" "+_vm._s(_vm.ordersReadySum)+" руб.")])])],1)],1),_c(VCol,{staticClass:"mb-2",attrs:{"cols":"4"}},[_c(VCard,{attrs:{"dark":""}},[_c(VCardTitle,{staticClass:"text-h6"},[_vm._v(" Cейчас в работе ")]),_c(VCardSubtitle,{staticClass:"text-h2 green--text font-weight-black"},[_vm._v(" "+_vm._s(_vm.ordersWorking)+" ")]),_c(VCardText,[_vm._v(" Сумма: "),_c('span',{staticClass:"font-weight-bold white--text"},[_vm._v(" "+_vm._s(_vm.ordersWorkingSum)+" руб.")])])],1)],1),_c(VCol,{staticClass:"mb-2",attrs:{"cols":"4"}},[_c(VCard,{attrs:{"dark":""}},[_c(VCardTitle,{staticClass:"text-h6"},[_vm._v(" Bыполненых заказов ")]),_c(VCardSubtitle,{staticClass:"text-h2 green--text font-weight-black"},[_vm._v(" "+_vm._s(_vm.ordersReady)+" ")]),_c(VCardText,[_vm._v(" Сумма за месяц: "),_c('span',{staticClass:"font-weight-bold white--text"},[_vm._v(" "+_vm._s(_vm.ordersReadySum)+" руб.")])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }