<template>
  <v-card dark class="h-100">
    <v-card-title class="text-h6">
      Монтажи
    </v-card-title>
    <DateFilterAssignedMontagesDark @updDate="updDate" />
    <SocketWatch :tables="['montages']" @loadData="loadData" />
    <div class="pa-0 ma-0" v-for="(data, index_data) in montages" :key="index_data" cols="6">
      <AssignedMontagesInBrigDark :data="data" />
    </div>
  </v-card>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
import DateFilterAssignedMontagesDark from '@/components/Montages/DateFilterAssignedMontagesDark.vue'
import AssignedMontagesInBrigDark from '@/components/Montages/AssignedMontagesInBrigDark.vue'
import Skeleton from '@/components/Montages/Skeleton.vue'

export default {
  name: 'AssignedMontages',
  components: {
    DateFilterAssignedMontagesDark,
    AssignedMontagesInBrigDark,
    Skeleton
  },
  data: () => ({
    isMobile: isMobile,
    skeletonTypesIframe: {
      'table-thead': 'heading@4',
      'table-row': 'table-cell@4'
    },
    tab: 0
  }),
  created() {
    this.loadData()
  },
  async beforeRouteLeave(to, from, next) {
    let toDay = new Date().toISOString().substr(0, 10)
    this.updDate(new Date(toDay))
    next()
  },
  methods: {
    loadData() {
      this.$store.dispatch('montages/loadAssignedMontages')
    },
    updDate(date) {
      this.filterMontagesByDate = date
      this.$store.dispatch('montages/loadAssignedMontages')
    }
  },
  computed: {
    montagesLoaded() {
      return this.$store.getters['montages/getAssignedMontagesLoaded']
    },
    montages() {
      return this.$store.getters['montages/getAssignedMontages']
    },
    filterMontagesByDate: {
      get() { return this.$store.getters['montages/getFilterAssignedMontagesByDate'] },
      set(val) { this.$store.commit('montages/setFilterAssignedMontagesByDate', val) }
    }
  }
}
</script>

<style lang="scss">
.mobAssignedMontages {
  .v-tabs-items {
    background-color: #424242 !important;
  }

  &_tabs {
    position: sticky;
    top: 56px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    z-index: 2;

    .v-slide-group__prev, .v-slide-group__next {
      display: none !important;
    }
  }
}
</style>
