<template>
  <div>
    <MobPage v-if="isMobile" />
    <PcPage v-else />
  </div>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
const MobPage = () => import('./MobPage.vue')
const PcPage = () => import('./PcPage.vue')

export default {
  name: 'MontagesPageForMaster',
  data: () => ({
    isMobile: isMobile
  }),
  components: {
    MobPage,
    PcPage
  }
}
</script>
