// Home
import ViewHome from '@/views/home/ViewHome.vue'
import Home from '@/components/PagesByRole/Master/Montages/AssignedMontages/Index.vue'

// Tasks
import ViewTasks from '@/views/manufactory/ViewTasks.vue'
import Tasks from '@/components/PagesByRole/Master/Manufactory/Tasks/Index.vue'
import ArchiveTasks from '@/components/PagesByRole/Master/Manufactory/ArchiveTasks/Index.vue'

// Users
import ViewUsers from '@/views/users/ViewUsers.vue'
import User from '@/components/Users/Pages/User/Index.vue'

// Hourly Works
import ViewHourlyWorks from '@/views/hourlyWorks/ViewHourlyWorks.vue'
import HourlyWorks from '@/components/PagesByRole/Master/HourlyWorks/Index.vue'

// Faq
import ViewFaq from '@/views/faq/ViewFaq.vue'
import Faq from '@/components/Faq/Pages/Index.vue'

// warehouse
import warehouse from '@/components/Warehouse/Pages/index.vue'
const masterRoutes = [
  {
    path: '/profile',
    component: ViewUsers,
    meta: {
      order: 0,
      navDrawer: true,
      text: 'Финансы',
      icon: 'mdi-account'
    },
    children: [
      { path: '', name: 'Profile', component: User },
    ]
  },
  {
    path: '/',
    component: ViewHome,
    meta: {
      order: 1,
      navDrawer: true,
      text: 'Монтажи',
      icon: 'mdi-truck-minus'
    },
    children: [
      { path: '', name: 'Home', component: Home },
    ]
  },
  {
    path: '/manufactory',
    component: ViewTasks,
    meta: {
      order: 2,
      navDrawer: true,
      text: 'Производство',
      icon: 'mdi-archive-cog',
      tabs: [
        { path: '/manufactory', text: 'Задания' },
        { path: '/manufactory/archive', text: 'Архив' }
      ]
    },
    children: [
      { path: '', name: 'Manufactory', component: Tasks },
      { path: 'archive', component: ArchiveTasks }
    ]
  },
  {
    path: '/hourly-works',
    component: ViewHourlyWorks,
    meta: {
      order: 3,
      navDrawer: true,
      text: 'Почасовая работа',
      icon: 'mdi-clock-outline'
    },
    children: [
      { path: '', name: 'HourlyWorks', component: HourlyWorks },
    ]
  },
	{
    path: '/faq',
    component: ViewFaq,
    meta: {
      order: 6,
      navDrawer: true,
      text: 'Помощникус',
      icon: 'mdi-book-open-variant'
    },
    children: [
      { path: '', name: 'Faq', component: Faq }
    ]
  },
  {
    path: '/warehouse',
    component: warehouse,
    name: 'Warehouse',
    meta: {
      order: 7,
      navDrawer: true,
      text: 'Склад',
      icon: 'mdi-shipping-pallet',
			// tabs: [
			// 	{ path: '/tasks', text: 'Задачи мне' },
      // ]
    },
  },
]

export default masterRoutes
