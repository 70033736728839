export default {
  state: {
    filterClientByManager: null
  },
  getters: {
    getFilterClientByManager: state => {
      return state.filterClientByManager
    }
  },
  mutations: {
    resetAllFilterClient(state) {
      state.filterClientByManager = null
    },
    setFilterClientByManager(state, payload) {
      state.filterClientByManager = payload;
    }
  }
}