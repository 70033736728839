<template>
  <v-row class="ma-0 px-1 py-2">
    <v-col cols="9" class="pa-0 px-4">
      <v-row>
        <v-col cols="12" class="mb-2 pa-0">
          <WidgetTabsTask tabsTasks="['meTask', fromMeTask]" />
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="3" class="pa-0 px-4">
			<v-row>
        <!-- <v-col cols="12" class="pa-0">
          <AllNotification />
        </v-col> -->
					<v-col cols="12" class="pa-0">
          <WidgetAssignedMontagesInBrig />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import WidgetTabsTask from '@/components/Tasks/Widgets/TabsTask.vue'
// import AllNotification from '@/components/Notification/Widget/AllNotification.vue'
import WidgetAssignedMontagesInBrig from '@/components/Montages/WidgetAssignedMontagesInBrig.vue'

export default {
  name: 'ProdDirHome',
  components: {
    WidgetTabsTask,
		// AllNotification,
		WidgetAssignedMontagesInBrig
  }
}
</script>
