// Manufactory
import ManufactoryViewTasks from '@/views/manufactory/ViewTasks.vue'
import ManufactoryTasks from '@/components/Manufactory/Pages/Tasks.vue'
import ManufactoryArchiveTasks from '@/components/Manufactory/Pages/ArchiveTasks.vue'

// Users
import ViewUsers from '@/views/users/ViewUsers.vue'
import Users from '@/components/Users/Pages/Users/Index.vue'
import User from '@/components/Users/Pages/User/Index.vue'

import Brigs from '@/components/Users/Pages/Brigs/Index.vue'

// Clients
import ViewClients from '@/views/clients/ViewClients.vue'
import Clients from '@/components/Clients/Pages/Index.vue'

// Tasks
import ViewTasks from '@/views/tasks/ViewTasks.vue'
import Tasks from '@/components/Tasks/Pages/Index.vue'
import TasksArchive from '@/components/Tasks/Pages/TasksArchive.vue'

// Orders
import ViewOrders from '@/views/orders/ViewOrders.vue'
import Orders from '@/components/Orders/Pages/Index.vue'
import OrdersDraft from '@/components/Orders/Pages/OrdersDraft.vue'
import OrdersArchive from '@/components/Orders/Pages/OrdersArchive.vue'

// Settings
import ViewSettings from '@/views/settings/ViewSettings.vue'
import Settings from '@/components/Settings/Pages/Index.vue'

// Faq
import ViewFaq from '@/views/faq/ViewFaq.vue'
import Faq from '@/components/Faq/Pages/Index.vue'
// warehouse
import warehouse from '@/components/Warehouse/Pages/index.vue'
const adminRoutes = [
	{
    path: '/tasks',
    component: ViewTasks,
    meta: {
      order: 1,
      navDrawer: true,
      text: 'Задачи',
      icon: 'mdi-message-text-clock-outline',
			tabs: [
				{ path: '/tasks', text: 'Задачи мне' },
				{ path: '/tasks/from-me', text: 'От меня' },
				{ path: '/tasks/designer', text: 'Дизайнерам' },
        { path: '/tasks/archive', text: 'Архив' },
      ]
    },
    children: [
			{ path: '', name: 'TasksMe', component: Tasks },
      { path: 'from-me', name: 'TasksFromMe', component: Tasks },
      { path: 'designer', name: 'TasksDesigner', component: Tasks },
      { path: 'archive', name: 'TasksArchive', component: TasksArchive }
    ]
  },
	{
    path: '/orders',
    component: ViewOrders,
    meta: {
      order: 2,
      navDrawer: true,
      text: 'Заказы',
      icon: 'mdi-clipboard-multiple-outline',
      tabs: [
				{ path: '/orders', text: 'Текущие' },
        // { path: '/orders/draft', text: 'Предварительные' },
        { path: '/orders/archive', text: 'Архив' },
      ]
    },
    children: [
      { path: '', name: 'Orders', component: Orders },
      { path: 'draft', name: 'OrdersDraft', component: OrdersDraft },
      { path: 'archive', name: 'OrdersArchive', component: OrdersArchive }
    ]
  },
  {
    path: '/manufactory',
    component: ManufactoryViewTasks,
    meta: {
      order: 3,
      navDrawer: true,
      text: 'Производство',
      icon: 'mdi-archive-cog',
      tabs: [
        { path: '/manufactory', text: 'В процессе' },
        { path: '/manufactory/archive', text: 'Архив' },
      ]
    },
    children: [
      { path: '', name: 'Manufactory', component: ManufactoryTasks },
      { path: 'archive', name: 'ManufactoryArchive', component: ManufactoryArchiveTasks },
    ]
  },
  {
    path: '/clients',
    component: ViewClients,
    meta: {
      order: 5,
      navDrawer: true,
      text: 'Клиенты',
      icon: 'mdi-account-cash'
    },
    children: [
      { path: '', name: 'Clients', component: Clients }
    ]
  },
	{
    path: '/users',
    component: ViewUsers,
    meta: {
      order: 6,
      navDrawer: true,
      text: 'Сотрудники',
      icon: 'mdi-account-group',
      tabs: [
        { path: '/users', text: 'Сотрудники' },
        { path: '/users/brigs', text: 'Бригады' }
      ]
    },
    children: [
      { path: '', name: 'Users', component: Users },
      { path: 'brigs', name: 'Brigs', component: Brigs },
      { path: 'profile/:uid', name: 'User', component: User },
    ]
  },
  {
    path: '/warehouse',
    component: warehouse,
    name: 'Warehouse',
    meta: {
      order: 7,
      navDrawer: true,
      text: 'Склад',
      icon: 'mdi-shipping-pallet',
			// tabs: [
			// 	{ path: '/tasks', text: 'Задачи мне' },
      // ]
    },
  },
	{
    path: '/faq',
    component: ViewFaq,
    meta: {
      order: 8,
      navDrawer: true,
      text: 'Помощникус',
      icon: 'mdi-book-open-variant'
    },
    children: [
      { path: '', name: 'Faq', component: Faq }
    ]
  },
  {
    path: '/settings',
    component: ViewSettings,
    meta: {
      order: 9,
      navDrawer: true,
      text: 'Настройки',
      icon: 'mdi-cog'
    },
    children: [
      { path: '', name: 'Settings', component: Settings }
    ]
  }
]

export default adminRoutes
