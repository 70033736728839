import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c(VCard,{staticClass:"mx-auto",attrs:{"flat":""}},[(_vm.image !== 'deleted')?_c(VImg,{staticClass:"grey lighten-2",attrs:{"src":_vm.$formatFileUrl(_vm.image),"aspect-ratio":"1.2"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c(VRow,{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c(VProgressCircular,{attrs:{"indeterminate":"","color":"grey lighten-5","size":"22","width":"3"}})],1)]},proxy:true}],null,true)},[(hover)?_c('div',{staticClass:"d-flex previewImage"},[_c(VBtn,{attrs:{"small":"","icon":"","size":"sm","color":"grey"},on:{"click":function($event){_vm.isMobile ? _vm.showImageMob(_vm.image, _vm.images) : _vm.showImage(_vm.image, _vm.images)}}},[_c(VIcon,{staticClass:"pr-1",attrs:{"size":"20","color":"grey"}},[_vm._v("mdi-magnify-plus-outline")])],1),(_vm.canDownload)?_c(VBtn,{attrs:{"small":"","icon":"","color":"grey"},on:{"click":function($event){return _vm.downloadImg(_vm.image)}}},[_c(VIcon,{staticClass:"pl-1",attrs:{"size":"20","color":"grey"}},[_vm._v("mdi-download")])],1):_vm._e(),(!_vm.disTrach)?_c(VBtn,{attrs:{"small":"","icon":"","color":"#bf4037"},on:{"click":function($event){return _vm.$emit('delete', _vm.path)}}},[_c(VIcon,{staticClass:"pl-1",attrs:{"size":"20","color":"#bf4037"}},[_vm._v("mdi-trash-can-outline")])],1):_vm._e()],1):_vm._e()]):_vm._e(),(_vm.image === 'deleted')?_c(VImg,{staticClass:"grey lighten-2",attrs:{"src":"https://api.m4crm.ru/get-file/19580a2d3fde7be304f3370939d0ce3f3804678731.jpg","aspect-ratio":"1.2"}}):_vm._e()],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }