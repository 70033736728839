<template>
  <div class="page faq">
    <v-row class="fill-height" no-gutters>
      <v-col cols="3" class="faq_col-menu">
        <Menu @selectCategory="selectCategory($event)" />
      </v-col>
      <v-col cols="9" class="faq_col-content">
        <Content :selected-category="selectedCategory" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Content from "./components/Content.vue";
import Menu from "./components/Menu.vue";

export default {
  components: {
    Menu,
    Content,
  },
  data: () => ({
    selectedCategory: null,
  }),
  methods: {
    selectCategory(item) {
      this.selectedCategory = item;
    },
  },
};
</script>

<style lang="scss">
.faq {

}
</style>
