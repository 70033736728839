<template>
  <div class="pageAllMontages" :class="{ 'pa-3': !isMobile }">
    <SocketWatch :tables="['orders']" @loadData="loadData" />
    <v-card :tile="isMobile">
      <div class="d-flex align-center px-4">
        <v-row class="page_filterTable mt-3">
          <v-col cols="2">
            <v-text-field
              dense
              color="rgb(224, 166, 119)"
              v-model="filterOrdersByMonthText"
              @change="$store.dispatch('orders/loadOrders')"
              @click="selectMonth()"
              label="Месяц"
              clearable
            />
          </v-col>
          <v-col cols="1">
            <v-text-field
              dense
              color="rgb(224, 166, 119)"
              v-model="filterOrdersByNumberOrder"
              @change="$store.dispatch('orders/loadOrders')"
              label="Номер заказа"
            />
          </v-col>
          <v-col cols="2">
            <v-autocomplete
              dense
              color="rgb(224, 166, 119)"
              v-model="filterOrdersByClient"
              @change="$store.dispatch('orders/loadOrders')"
              label="Клиент"
              :items="filterClients"
              item-text="name"
              item-value="id"
              return-object
              clearable
            />
          </v-col>
          <v-col cols="3">
            <!-- <v-autocomplete
              dense
              color="rgb(224, 166, 119)"
              v-model="filterOrdersByStatus"
              @change="$store.dispatch('orders/loadOrders')"
              label="Статус"
              :items="currentOrderStatusArr"
              clearable
            /> -->
            <v-select
              dense
              v-model="filterOrdersByStatus"
              :items="currentOrderStatusArr"
              label="Статус"
              multiple
              color="rgb(224, 166, 119)"
              item-color="rgb(224, 166, 119)"
              clearable
              @change="$store.dispatch('orders/loadOrders')"
            >
              <template v-slot:selection="{ item, index }">
                <span v-if="index < 1">
                  <span>{{ item.text }}</span>
                  <span
                    v-if="filterOrdersByStatus && index < filterOrdersByStatus.length - 1"
                    class="mr-1"
                    >,</span
                  >
                </span>
                <span v-if="index === 1"> (+{{ filterOrdersByStatus.length - 1 }}) </span>
              </template>
              <template v-slot:item="{ item, attrs, on }">
                <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                  <v-list-item-action>
                    <v-checkbox color="rgb(224, 166, 119)" :ripple="false" :input-value="active" />
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-row no-gutters align="center">
                        {{ item.text }}
                      </v-row>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-select>
          </v-col>
          <v-col v-if="currentUser && currentUser.role != 'manager'" cols="2">
            <v-autocomplete
              dense
              color="rgb(224, 166, 119)"
              v-model="filterOrdersByManager"
              @change="$store.dispatch('orders/loadOrders')"
              label="Менеджер"
              :items="managers"
              item-text="displayName"
              return-object
              clearable
            />
          </v-col>
        </v-row>
        <v-spacer />
        <v-btn icon small @click="createOrder()">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </div>
      <v-divider />
      <v-skeleton-loader
        v-if="!ordersLoaded && (!orders || orders.length === 0)"
        class="pl-3 pr-3"
        type="table-thead, table-row-divider@3"
        :types="skeletonTypesIframe"
      />
      <div
        v-if="ordersLoaded && (!orders || orders.length === 0)"
        class="pa-3 d-flex justify-center"
      >
        Нет заказов
      </div>

      <v-data-table
        v-if="orders && orders.length > 0"
        :footer-props="{
          'items-per-page-options': [5, 10, 15, 20, 30, 40],
        }"
        :headers="tableHeadres"
        :items="orders"
        :items-per-page="30"
        :dense="!isMobile"
        :server-items-length="count"
        @pagination="changePagination"
      >
        <template v-slot:item="{ item }">
          <tr
            :style="item.color && !disabledColorBtn(item) ? `background-color: ${item.color}` : ''"
            @click="editOrder(item)"
          >
            <td>
              <span v-if="item.client && item.client.id != 0">
                {{ item.client.name }}
                <!-- <v-menu bottom right :close-on-content-click="false">
									<template v-slot:activator="{ on, attrs }">
										<v-btn icon x-small v-bind="attrs" v-on="on" style="cursor: context-menu;">
											<v-icon>mdi-information-outline</v-icon>
										</v-btn>
									</template>
									<my-info-client :id="item.client.id" />
								</v-menu> -->
              </span>
              <span v-else>Не указан</span>
            </td>
            <td>
              {{ item.number }}
            </td>
            <td>
              {{ currentOrderStatus[item.status] || archiveOrderStatus[item.status] }}
            </td>
            <td>
              <!-- <span
								:style="
									`${$colorDeadline(item.deadline.toDate())}`
								"
							> -->
              <span :style="`${$colorDeadline(new Date(item.deadline))}`">
                <!-- {{ $formatDateTime(item.deadline.toDate()) }} -->
                {{ $formatDateTime(new Date(item.deadline)) }}
              </span>
            </td>
            <td>
              <span v-if="item.description">{{ $sliceStr(item.description, 60) }}</span>
              <span v-else>Не задано</span>
            </td>
            <td>
              <span>{{ fullCost(item) }}р.</span>
            </td>
            <td>
              <span>{{ debt(item) }}р.</span>
            </td>
            <td v-if="currentUser.role === 'admin'">
              {{ item.creator.displayName }}
            </td>
            <td class="text-center">
              <v-menu left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon :disabled="disabledColorBtn(item)">
                    <v-icon>mdi-format-color-fill</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="item.status === 'draft' || currentUser.role === 'admin'"
                    v-bind="attrs"
                    @click.stop="deleteOrder(item)"
                    color="red"
                    icon
                  >
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </template>

                <div style="background-color: white;">
                  <div class="pt-2 px-5">
                    <v-btn
                      v-if="item.color"
                      :color="item.color || null"
                      block
                      @click="setColorItem('#FFF', item)"
                    >
                      Сбросить цвет
                    </v-btn>
                  </div>
                  <v-color-picker
                    @input="setColorItem($event, item)"
                    :value="item.color || null"
                    hide-canvas
                    hide-inputs
                    show-swatches
                    swatches-max-height="230"
                    mode="hexa"
                    class="rl-table-color-picker"
                  />
                </div>
              </v-menu>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
import { currentOrderStatus, currentOrderStatusArr, archiveOrderStatus } from '@/helpers';
import OrderModal from '@/components/Modals/Order/Index';
import SelectMonth from '@/components/Modals/SelectMonth';
import Confirm from '@/components/Modals/Confirm';
import { mapGetters } from 'vuex';

export default {
  name: 'AllOrders',
  data: () => ({
    isMobile: isMobile,
    skeletonTypesIframe: {
      'table-thead': 'heading@4',
      'table-row': 'table-cell@4',
    },
    currentOrderStatus: currentOrderStatus,
    currentOrderStatusArr: currentOrderStatusArr,
    archiveOrderStatus: archiveOrderStatus,
    filterMontagesByDateText: '',
    openFilters: false,
    lastPag: {},
  }),
  async beforeRouteLeave(to, from, next) {
    this.$store.commit('orders/resetAllFilterOrders');
    if (this.currentUser.role === 'admin') {
      this.filterOrdersByManager = this.currentUser;
    }
    this.$store.commit('orders/SET_LIMIT', 0);
    // this.$store.dispatch('orders/loadOrders');
    next();
  },
  async created() {
    if (this.currentUser.role === 'admin') {
      this.filterOrdersByManager = this.currentUser;
    }
    this.loadData();
  },
  computed: {
    tableHeadres() {
      let headers = [
        {
          text: 'Клиент',
          align: 'start',
          sortable: false,
          value: 'client',
          order: 0,
        },
        {
          text: 'Номер',
          align: 'start',
          sortable: false,
          value: 'number',
          order: 1,
        },
        {
          text: 'Статус',
          align: 'start',
          sortable: false,
          value: 'status',
          order: 2,
        },
        {
          text: 'Дедлайн',
          align: 'start',
          sortable: false,
          value: 'deadline',
          order: 3,
        },
        {
          text: 'Описание',
          align: 'start',
          sortable: false,
          value: 'description',
          order: 4,
        },
        {
          text: 'Стоимость',
          align: 'start',
          sortable: false,
          value: 'cost',
          order: 5,
        },
        {
          text: 'Долг',
          align: 'start',
          sortable: false,
          value: 'debt',
          order: 6,
        },
        {
          text: 'Цвет',
          align: 'center',
          sortable: false,
          value: 'color',
          order: 8,
        },
      ];

      if (this.currentUser.role === 'admin') {
        headers = [
          ...headers,
          {
            text: 'Менеджер',
            align: 'start',
            sortable: false,
            value: 'creator',
            order: 7,
          },
        ];
      }

      headers = headers.sort(function(a, b) {
        return a.order - b.order;
      });

      return headers;
    },
    currentUser() {
      return this.$store.getters['user/getUser'];
    },
    managers() {
      return this.$store.getters['users/getManagers'];
    },
    ordersLoaded() {
      return this.$store.getters['orders/getOrdersLoaded'];
    },
    orders() {
      return this.$store.getters['orders/getOrders'];
    },
    filterClients() {
      return this.$store.getters['clients/getFilterClients'];
    },
    filterOrdersByMonthText: {
      get() {
        let month = this.filterOrdersByMonth;
        if (month) {
          return String(new Date(month).toLocaleString('ru', { month: 'long', year: 'numeric' }));
        }
        return null;
      },
      set(val) {
        this.filterOrdersByMonth = val;
      },
    },
    filterOrdersByMonth: {
      get() {
        return this.$store.getters['orders/getFilterOrdersByMonth'];
      },
      set(val) {
        this.$store.commit('orders/setFilterOrdersByMonth', val);
      },
    },
    filterOrdersByClient: {
      get() {
        return this.$store.getters['orders/getFilterOrdersByClient'];
      },
      set(val) {
        this.$store.commit('orders/setFilterOrdersByClient', val);
      },
    },
    filterOrdersByStatus: {
      get() {
        return this.$store.getters['orders/getFilterOrdersByStatus'];
      },
      set(val) {
        this.$store.commit('orders/setFilterOrdersByStatus', val);
      },
    },
    filterOrdersByManager: {
      get() {
        return this.$store.getters['orders/getFilterOrdersByManager'];
      },
      set(val) {
        this.$store.commit('orders/setFilterOrdersByManager', val);
      },
    },
    filterOrdersByNumberOrder: {
      get() {
        return this.$store.getters['orders/getFilterOrdersByNumberOrder'];
      },
      set(val) {
        this.$store.commit('orders/setFilterOrdersByNumberOrder', val);
      },
    },
    count() {
      return this.$store.getters['orders/count'];
    },
  },
  methods: {
    async loadData() {
      await this.$store.dispatch('orders/loadOrders');
      await this.$store.dispatch('clients/loadFilterClients');
      await this.$store.dispatch('users/loadManagers');
    },
    fullCost(item) {
      let works = item.works || [];
      // console.log(works)
      let summ = 0;
      works.forEach((work) => {
        summ = summ + Number(work.cost);
      });
      return summ;
    },
    debt(item) {
      let cost = this.fullCost(item);

      let paid = item.paid;
      let summ = 0;
      paid.forEach((pay) => {
        summ = summ + Number(pay.amount);
      });

      return cost - summ;
    },
    async setColorItem(color, item) {
      if (color === '#FF0000FF' || (color && color.hexa === '#FF0000FF')) {
        color = null;
      }
      await this.$store.dispatch('orders/updateOrder', {
        id: item.id,
        data: { color: color && color.hexa ? color.hexa : color },
      });
    },
    disabledColorBtn(item) {

      let itemCreatorId = item.creator_id || item.creator.uid || item.creator.id || item.creator_id;
      if (itemCreatorId != this.currentUser.id) {
        return true;
      }
      return false;
    },
    async selectMonth() {
      let modalMonth = await this.$showModal({
        component: SelectMonth,
        isPersistent: true,
        props: {
          title: 'Выберите месяц',
          selectMonth: this.filterOrdersByMonth,
        },
      });
      if (modalMonth) {
        this.filterOrdersByMonth = modalMonth;
        this.$store.dispatch('orders/loadOrders');
      }
    },
    async createOrder() {
      let order = await this.$store.dispatch('orders/createOrder', this.$route);
      this.editOrder(order);
    },
    async editOrder(order) {
      await this.$showModal({
        component: OrderModal,
        isPersistent: true,
        props: {
          edit: true,
          data: order,
        },
      });
      await this.$store.dispatch('orders/loadOrders');
    },
    async deleteOrder(order) {
      const res = await this.$showModal({
        component: Confirm,
        isPersistent: true,
        props: {
          title: `Удаление заказ`,
          text: `Действительно хотите удалить заказ "${order.number}"?`,
        },
      });

      if (res) {
        await this.$store.dispatch('orders/deleteOrder', order);
        await this.$store.dispatch('orders/loadOrders');
      }
    },
    async changePagination(e) {
      this.$store.commit('orders/SET_LIMIT', e.itemsPerPage);
      this.$store.commit('orders/SET_OFFSET', e.pageStart);
      await this.$store.dispatch('orders/loadOrders');
    },
  },
};
</script>
