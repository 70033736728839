import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pages-warehouse"},[_c('SocketWatch',{attrs:{"tables":['categorys', 'products']},on:{"loadData":_vm.loadData}}),_c(VRow,{staticClass:"ma-0 px-1 py-2"},[_c(VCol,{staticClass:"pa-0 px-4 mb-4",attrs:{"md":"3","cols":"12"}},[_c('Menu')],1),_c(VCol,{staticClass:"pa-0 px-4",attrs:{"md":"9","cols":"12"}},[_c(VCard,{attrs:{"tile":_vm.$isMobile}},[((_vm.user.role === 'admin' || _vm.user.warehouseManager) && _vm.selectedCategory)?_c('div',{staticClass:"d-flex align-center px-4 py-4"},[((_vm.user.role === 'admin' || _vm.user.warehouseManager) && _vm.selectedCategory)?_c(VBtn,{attrs:{"small":"","icon":""},on:{"click":function($event){_vm.sort = !_vm.sort}}},[_c(VIcon,{attrs:{"size":"22"}},[_vm._v("mdi-sort")])],1):_vm._e(),_c(VBtn,{staticClass:"ml-auto",attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.addProduct()}}},[_c(VIcon,{attrs:{"size":"21"}},[_vm._v("mdi-plus")])],1)],1):_vm._e(),(!_vm.selectedCategory)?_c('div',{staticClass:"px-4 pt-4"},[_c(VRow,{staticClass:"ma-0 pa-0"},[_c(VCol,{staticClass:"pa-0",attrs:{"cols":"4"}},[_c(VSelect,{attrs:{"color":"rgb(224, 166, 119)","label":"Тип","items":[
                                {
                                    label: 'Списания',
                                    value: 'minus',
                                },
                                {
                                    label: 'Поступления',
                                    value: 'plus',
                                },
                            ],"item-text":"label","clearable":""},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1)],1)],1):_vm._e(),_c(VDivider),(!_vm.selectedCategory)?_c('Positions',{attrs:{"table":"","type":_vm.type}}):[(_vm.sort)?_c('draggable',_vm._b({staticClass:"tableMontage",on:{"start":function($event){_vm.drag = true},"end":function($event){_vm.drag = false}},model:{value:(_vm.productsList),callback:function ($$v) {_vm.productsList=$$v},expression:"productsList"}},'draggable',_vm.dragOptions,false),[_c('transition-group',{attrs:{"type":"transition","name":"flip-list"}},_vm._l((_vm.productsList),function(item){return _c(VListItem,{key:item.id},[_c(VListItemIcon,{staticClass:"my-3"},[_c(VIcon,[_vm._v("mdi-drag")])],1),_c(VListItemContent,[_c(VListItemSubtitle,[_vm._v(_vm._s(item.name))])],1)],1)}),1)],1):_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.products,"single-expand":"","expanded":_vm.expanded,"show-expand":"","dense":"","item-class":() => 'stableActions',"footer-props":{
                            'items-per-page-options': [5, 10, 15, 20, 30, 40],
                        },"items-per-page":40},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:`item.name`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:`item.category_id`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.categorysObj[item.category_id] ? _vm.categorysObj[item.category_id].name : 'Без категории')+" ")]}},{key:`item.count`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.count)+" "+_vm._s(item.unit)+" ")]}},{key:`item.comment`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.comment)+" ")]}},{key:`item.action`,fn:function({ item }){return [(_vm.user.role !== 'manager')?[(['admin'].includes(_vm.user.role) || _vm.user.warehouseManager)?_c(VBtn,{attrs:{"x-small":"","outlined":""},on:{"click":function($event){return _vm.countPlusProduct(item)}}},[_vm._v(" Добавить ")]):_vm._e(),(['admin'].includes(_vm.user.role) || _vm.user.warehouseManager)?_c(VBtn,{staticClass:"ml-2",attrs:{"x-small":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.countMinusProduct(item)}}},[_vm._v(" Списать ")]):_vm._e(),(_vm.user.role === 'admin' || _vm.user.warehouseManager)?_c(VBtn,{attrs:{"small":"","icon":"","color":"#424242"},on:{"click":function($event){return _vm.editProduct(item)}}},[_c(VIcon,{attrs:{"size":"18"}},[_vm._v("mdi-pencil")])],1):_vm._e()]:_vm._e()]}},{key:"expanded-item",fn:function({ item, headers }){return [(_vm.expanded.length && _vm.expanded[0].id === item.id)?_c('td',{attrs:{"colspan":headers.length}},[_c('Positions',{attrs:{"product":item}})],1):_vm._e()]}}],null,true)})]],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }