<template>
  <div class="page pageManufactoryTasks" :class="{ 'pa-3': !isMobile }">
    <SocketWatch :tables="['manufactory']" @loadData="loadData" />
    <v-card :tile="isMobile">
      <div v-if="isMobile">
        <v-row class="page_filterTable ma-0 px-1">
          <v-col cols="8">
            <v-text-field
              color="rgb(224, 166, 119)"
              v-model="filterTasksByMonth"
              @click="selectDate()"
              label="Дата"
              clearable
              @click:clear="clearDate()"
            />
            <v-text-field
              color="rgb(224, 166, 119)"
              v-model="filterTasksByMonthText"
              @click="selectMonth()"
              @click:clear="clearMonth()"
              clearable
              placeholder="Месяц"
              dense
            />
          </v-col>
          <v-col cols="2" class="pt-6">
            <v-btn icon @click="openFilters = !openFilters">
              <v-badge v-if="filtersSelected" color="orange" overlap :content="filtersSelected">
                <v-icon>mdi-tune</v-icon>
              </v-badge>
              <v-icon v-else>mdi-tune</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="2" class="pt-6">
            <v-btn icon @click="createTask()">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-expand-transition>
          <v-card flat tile v-show="openFilters">
            <v-divider />
            <v-row class="page_filterTable ma-0 px-1">
              <v-col cols="12" class="pt-0">
                <v-autocomplete
                  color="rgb(224, 166, 119)"
                  v-model="filterTasksByClient"
                  @change="$store.dispatch('manufactory/loadTasks')"
                  label="Клиент"
                  :items="filterClients"
                  item-text="name"
                  item-value="id"
                  return-object
                  clearable
                />
              </v-col>
              <v-col cols="12" class="pt-0">
                <v-select
                  color="rgb(224, 166, 119)"
                  v-model="filterTasksByManager"
                  @change="$store.dispatch('manufactory/loadTasks')"
                  label="Менеджер"
                  :items="managers"
                  item-text="displayName"
                  return-object
                  clearable
                />
              </v-col>
            </v-row>
          </v-card>
        </v-expand-transition>
      </div>
      <div v-else class="d-flex align-center px-4">
        <v-row class="page_filterTable mt-3">
          <v-col cols="2">
            <v-text-field
              class="ma-0 pa-0"
              color="rgb(224, 166, 119)"
              v-model="filterTasksByMonth"
              @click="selectMonth()"
              @click:clear="clearMonth()"
              clearable
              placeholder="Месяц"
              dense
            />

            <v-text-field
              dense
              color="rgb(224, 166, 119)"
              v-model="filterTasksByDateText"
              @click="selectDate()"
              label="Дата"
              clearable
              @click:clear="clearDate()"
            />
          </v-col>
          <v-col cols="3">
            <v-autocomplete
              dense
              color="rgb(224, 166, 119)"
              v-model="filterTasksByClient"
              @change="$store.dispatch('manufactory/loadTasks')"
              label="Клиент"
              :items="filterClients"
              item-text="name"
              item-value="id"
              return-object
              clearable
            />
          </v-col>
          <v-col cols="3">
            <v-autocomplete
              dense
              color="rgb(224, 166, 119)"
              v-model="filterTasksByManager"
              @change="$store.dispatch('manufactory/loadTasks')"
              label="Менеджер"
              :items="managers"
              item-text="displayName"
              return-object
              clearable
            />
          </v-col>
          <!-- <v-col cols="2" class="d-flex align-center justify-center mt-1">
            <v-btn 
              icon
              small
              @click="tasksPage > 1 ? $store.dispatch('manufactory/loadTasks', 'prev') : null"
              :disabled="tasksPage === 1"
            >
              <v-icon size="22">mdi-chevron-left</v-icon>
            </v-btn>
            <span style="font-size: 15px;">{{ tasksPage }} стр.</span>
            <v-btn 
              icon
              small
              @click="tasks.length > 0 && (20 * tasksPage - (20 - tasks.length)) < maxTaskCount ? $store.dispatch('manufactory/loadTasks', 'next') : null"
              :disabled="(20 * tasksPage - (20 - tasks.length)) >= maxTaskCount"
            >
              <v-icon size="22">mdi-chevron-right</v-icon>
            </v-btn>
          </v-col> -->
        </v-row>
        <v-spacer></v-spacer>
        <v-btn icon small @click="createTask()">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </div>
      <v-divider />
      <v-skeleton-loader
        v-if="!tasksLoaded && (!tasks || tasks.length === 0)"
        class="pl-3 pr-3"
        type="table-thead, table-row-divider@3"
        :types="skeletonTypesIframe"
      />
      <div v-if="tasksLoaded && (!tasks || tasks.length === 0)" class="infoNoItems">
        Нет заданий
      </div>

      <!-- <v-data-table
				:headers="tableHeadres"
				:footer-props="{
					'items-per-page-options': [5, 10, 15, 20, 30, 40]
				}"
				:items="clients"
				:items-per-page="30"
				:server-items-length="count"
        @pagination="changePagination"
 				dense
			>
				<template v-slot:[`item.surname`]="{ item }">
					<span v-if="item.surname">{{ item.surname }}</span>
					<span v-else>Не указана</span>
				</template>
				<template v-slot:[`item.phonenumber`]="{ item }">
					<span v-if="item.phone">{{ item.phone }}</span>
					<span v-else>Не указан</span>
				</template>
				<template v-slot:[`item.creatorData`]="{ item }">
					<span v-if="item.creatorData">{{ item.creatorData.displayName }}</span>
					<span v-else>Не задан</span>
				</template>
				<template v-slot:[`item.montages`]="{ item }">
					<span v-if="item.montages && item.montages.length > 0">{{ item.montages.length }}</span>
					<span v-else>Нет монтажей</span>
				</template>
				<template v-slot:[`item.manufactory`]="{ item }">
					<span v-if="item.manufactory && item.manufactory.length > 0">{{ item.manufactory.length }}</span>
					<span v-else>Нет задач в производство</span>
				</template>
				<template v-slot:[`item.action`]="{ item }">
					<v-btn small @click="editClient(item)" icon color="#424242">
						<v-icon size="18">mdi-pencil</v-icon>
					</v-btn>
					<v-btn small v-if="user.role === 'admin'" @click="deleteClient(item)" icon color="#bf4037">
						<v-icon size="18">mdi-trash-can-outline</v-icon>
					</v-btn>
				</template>
			</v-data-table> -->

      <v-data-table
        :dense="!isMobile"
        v-if="tasks && tasks.length > 0"
        :headers="[
          {
            text: 'Дедлайн',
            align: 'start',
            sortable: false,
            value: 'date',
          },
          {
            text: 'Клиент',
            align: 'start',
            sortable: false,
            value: 'client.name',
          },
          {
            text: 'Комментарий',
            align: 'start',
            sortable: false,
            value: 'comment',
          },
          {
            text: 'Тех. задание',
            align: 'start',
            sortable: false,
            value: 'techTask',
          },
          {
            text: 'Статус',
            align: 'start',
            sortable: false,
            value: 'status',
          },
          {
            text: 'Менеджер',
            align: 'start',
            sortable: false,
            value: 'manager.displayName',
          },
          {
            text: 'Действие',
            align: 'start',
            sortable: false,
            value: 'actions',
          },
        ]"
        :footer-props="{
          'items-per-page-options': [5, 10, 15, 20, 30, 40],
        }"
        :items="tasks"
        @update:options="options = $event"
        :server-items-length="count"
        :page="options.page"
        :items-per-page="options.itemsPerPage"
        @click:row="editTask"
      >
        <!-- <v-btn small @click="editTask(item)" icon color="#424242">
						<v-icon size="18">mdi-pencil</v-icon>
					</v-btn> -->

        <template v-slot:[`item.date`]="{ item }">
          {{ $formatDate(new Date(item.date)) }}
        </template>

        <template v-slot:[`item.comment`]="{ item }">
          <span v-if="item.comment">
            {{ $sliceStr(item.comment, 60) }}
          </span>
          <span v-else>Нет комментария</span>
        </template>
        <template v-slot:[`item.techTask`]="{ item }">
          <a v-if="item.techTask" @click.stop="goUrl(item.techTask)" href="#">Открыть</a>
          <span v-else>Нет тех. задания</span>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          {{ manufactoryTaskStatus[item.status] }}
        </template>
        <!-- <template v-slot:[`item.status`]="{ item }">
					{{ manufactoryTaskStatus[item.status] }}
				</template> -->

        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Дедлайн</th>
              <th class="text-left">Клиент</th>
              <th class="text-left">Комментарий</th>
              <th class="text-left">Тех. задание</th>
              <th class="text-left">Статус</th>
              <th class="text-left">Менеджер</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="task in tasks" :key="task.id" @click="editTask(task)" class="tableItemList">
              <td style="min-width: 80px;">{{ $formatDate(new Date(task.date)) }}</td>
              <td style="min-width: 125px;">
                {{ task.client.name }}
              </td>
              <td style="min-width: 125px; max-width: 280px;">
                <span v-if="task.comment">
                  {{ $sliceStr(task.comment, 60) }}
                </span>
                <span v-else>Нет комментария</span>
              </td>
              <td>
                <a v-if="task.techTask" @click.stop="goUrl(task.techTask)" href="#">Открыть</a>
                <span v-else>Нет тех. задания</span>
              </td>
              <td>{{ manufactoryTaskStatus[task.status] }}</td>
              <td>{{ task.manager.displayName }}</td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
      <v-divider v-if="isMobile && tasks.length > 0" />
      <v-row v-if="isMobile && tasks.length > 0" class="ma-0">
        <v-col cols="12" class="d-flex align-center justify-center mt-1">
          <v-btn
            icon
            large
            @click="tasksPage > 1 ? $store.dispatch('manufactory/loadTasks', 'prev') : null"
            :disabled="tasksPage === 1"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <span>{{ tasksPage }} стр.</span>
          <v-btn
            icon
            large
            @click="
              tasks.length > 0 && 20 * tasksPage - (20 - tasks.length) < maxTaskCount
                ? $store.dispatch('manufactory/loadTasks', 'next')
                : null
            "
            :disabled="20 * tasksPage - (20 - tasks.length) >= maxTaskCount"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
import { manufactoryTaskStatus, manufactoryTaskStatusArr } from '@/helpers';
import ManufactoryTasksModal from '@/components/Modals/Manufactory/Task';
import SelectDate from '@/components/Modals/SelectDate';
import SelectMonth from '@/components/Modals/SelectMonth';

export default {
  name: 'ConfirmationManufactoryTask',
  data: () => ({
    isMobile: isMobile,
    skeletonTypesIframe: {
      'table-thead': 'heading@4',
      'table-row': 'table-cell@4',
    },
    manufactoryTaskStatus: manufactoryTaskStatus,
    manufactoryTaskStatusArr: manufactoryTaskStatusArr,
    filterTasksByDateText: '',
    openFilters: false,
    options: {
      page: 1,
      itemsPerPage: 30,
    },
  }),
  async beforeRouteLeave(to, from, next) {
    this.$store.commit('manufactory/SET_LIMIT', 0);
    this.$store.commit('manufactory/SET_OFFSET', 0);
    this.$store.commit('manufactory/resetAllFilterTasks');
    // this.$store.dispatch('manufactory/loadTasks');
    next();
  },
  created() {
    this.loadData();
  },
  beforeDestroy() {
    this.filterTasksByMonth = null;
  },
  watch: {
    options(newValue, oldValue) {
      console.log(oldValue, newValue);
      if (oldValue.itemsPerPage !== newValue.itemsPerPage || oldValue.page !== newValue.page) {
        this.$store.commit('manufactory/SET_LIMIT', newValue.itemsPerPage);
        this.$store.commit('manufactory/SET_OFFSET', (newValue.page - 1) * newValue.itemsPerPage);
        this.$store.dispatch('manufactory/loadTasks');
      }
    },
  },
  computed: {
    filterTasksByMonthText() {
      let month = this.filterTasksByMonth;
      return String(new Date(month).toLocaleString('ru', { month: 'long', year: 'numeric' }));
    },
    maxTaskCount() {
      return this.$store.getters['manufactory/getMaxTaskCount'];
    },
    count() {
      return this.$store.getters['manufactory/getMaxTaskCount'];
    },
    tasksPage() {
      return this.$store.getters['manufactory/getTasksPage'];
    },
    filtersSelected() {
      let filters = [this.filterTasksByClient, this.filterTasksByManager];
      filters = filters.filter((x) => !!x);
      return filters.length;
    },
    managers() {
      return this.$store.getters['users/getManagers'];
    },
    tasksLoaded() {
      return this.$store.getters['manufactory/getTasksLoaded'];
    },
    tasks() {
      return this.$store.getters['manufactory/getTasks'];
    },
    filterClients() {
      return this.$store.getters['clients/getFilterClients'];
    },
    filterTasksByDate: {
      get() {
        return this.$store.getters['manufactory/getFilterTasksByDate'];
      },
      set(val) {
        this.$store.commit('manufactory/setFilterTasksByDate', val);
      },
    },
    filterTasksByMonth: {
      get() {
        return this.$store.getters['manufactory/getFilterTasksByMonth'];
      },
      set(val) {
        this.$store.commit('manufactory/setFilterTasksByMonth', val);
      },
    },
    filterTasksByClient: {
      get() {
        return this.$store.getters['manufactory/getFilterTasksByClient'];
      },
      set(val) {
        this.$store.commit('manufactory/setFilterTasksByClient', val);
      },
    },
    filterTasksByManager: {
      get() {
        return this.$store.getters['manufactory/getFilterTasksByManager'];
      },
      set(val) {
        this.$store.commit('manufactory/setFilterTasksByManager', val);
      },
    },
    limit() {
      return this.$store.getters['manufactory/getLimit'];
    },
    offset() {
      return this.$store.getters['manufactory/getOffset'];
    },
  },
  methods: {
    loadData() {
      this.$store.commit('manufactory/SET_LIMIT', 30);
      this.$store.dispatch('manufactory/loadTasks');
      this.$store.dispatch('clients/loadFilterClients');
      this.$store.dispatch('users/loadManagers');
    },
    async selectMonth() {
      let modalMonth = await this.$showModal({
        component: SelectMonth,
        isPersistent: true,
        props: {
          title: 'Выберите месяц',
          selectMonth: this.filterTasksByMonth,
        },
      });
      if (modalMonth) {
        this.filterTasksByMonth = modalMonth;
        this.$store.dispatch('manufactory/loadTasks');
      }
    },

    clearMonth() {
      // this.filterTasksByMonth = new Date().toISOString().substr(0, 7)
      this.$store.commit('manufactory/setFilterTasksByMonth', '');
      this.$store.dispatch('manufactory/loadTasks');
    },

    clearDate() {
      this.filterTasksByDate = null;
      this.filterTasksByDateText = '';
      this.$store.dispatch('manufactory/loadTasks');
    },
    async selectDate() {
      let modalDate = await this.$showModal({
        component: SelectDate,
        isPersistent: true,
        props: {
          title: 'Дата заказа',
          selectDate: this.filterTasksByDate
            ? this.filterTasksByDate.toISOString().substr(0, 10)
            : new Date().toISOString().substr(0, 10),
        },
      });
      if (modalDate) {
        this.filterTasksByDate = new Date(modalDate);
        this.filterTasksByDateText = new Date(modalDate).toLocaleString('ru', {
          weekday: 'short',
          day: 'numeric',
          month: 'short',
        });
        this.$store.dispatch('manufactory/loadTasks');
      }
    },
    goUrl(url) {
      window.open(url, '_blank').focus();
    },
    async createTask() {
      await this.$showModal({
        component: ManufactoryTasksModal,
        isPersistent: true,
        props: {
          edit: false,
        },
      });
    },
    async editTask(task) {
      await this.$showModal({
        component: ManufactoryTasksModal,
        isPersistent: true,
        props: {
          edit: true,
          data: task,
        },
      });
    },
  },
};
</script>
