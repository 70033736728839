import { prepaymentsCollection, usersCollection } from '@/plugins/firebase'

import api from '@/utils/api'

import axios from 'axios'
const url = process.env.VUE_APP_SERVICE_URL
export default {
    namespaced: true,
    state: {
        prepayments: []
    },
    getters: {
        getPrepayments: state => {
            const sortedPrepayments = state.prepayments.sort(function(a,b){
                return new Date(b.date) - new Date(a.date);
            })
            return sortedPrepayments
        }
    },
    mutations: {
        setPrepayments(state, payload) {
            state.prepayments = payload;
        },
        addPrepayment(state, payload) {
            let prepayments = state.prepayments
            prepayments.push(payload)
            state.prepayments = prepayments
        },
        delPrepayment(state, id) {
            let prepayments = state.prepayments
            prepayments = prepayments.filter(el => el.id != id)
            state.prepayments = prepayments
        },
    },
    actions: {
        async deletePrepayments({ commit, rootState }, data) {
            commit('templ/setLoading', true, { root: true })
            // await prepaymentsCollection.doc(data.id).update({ del: true })
            // data.del = true
            await api.put('prepayments/update', { id: data.id, del: true })
                .then(() => {
                    console.log('Выплата удалена')
                    commit('delPrepayment',data.id)
                }).catch((err) => {
                    commit('templ/setError', 'Ошибка удаления выплаты, попробуйте позже.')
                    console.log(err)
                }).finally(() => {
                    commit('templ/setLoading', false, { root: true })
                })
        },
        async createPrepayments({ commit, dispatch, rootGetters }, data) {
            commit('templ/setLoading', true, { root: true })
            let filterDate = rootGetters['users/getFilterUserDataByMonth']
            let date = new Date(filterDate), y = date.getFullYear(), m = date.getMonth()
            filterDate = new Date(y, m + 1, 0, 23, 59)
            let toDay = new Date()

            if (filterDate.getMonth() != toDay.getMonth()) {
                date = new Date(filterDate.toISOString().substr(0, 10))
            } else {
                date = new Date(toDay.toISOString().substr(0, 10))
            }
            const obj = { 
                ...data,
                date: date
            }
            // await prepaymentsCollection.add(obj)
            await api.post('prepayments/create',obj).then(response => {
                if(response.status == 200){
                    commit('addPrepayment',response.data.prepayments)
                }
            })
            commit('templ/setLoading', false, { root: true })
        },
        async loadPrepayments({ commit, dispatch }, data) {
            commit('templ/setLoading', true, { root: true })
            // console.log(data)

            let 
                limit = 10000,
                offset = 0
            let queryStore = await api.post('prepayments/get-list',{
                limit,
                offset,
                query: [data.month ? { input: "date", value: data.month } : null],
                query_and: [
                    { input: 'user_id', value: data.id },
                    { input: 'del', value: false },
                    // 
                ]
            }).then(response => {
                if(response.status === 200){
                    return response.data.data
                }
            })
                
            commit('setPrepayments', queryStore)
            commit('templ/setLoading', false, { root: true })

            // // let userRef = await usersCollection.doc(data.uid)

            // let queryStore = prepaymentsCollection.where('user', '==', userRef)
            // let queryStore = await api.post('prepayments/get-list',{

            // })
            if (data.month) {
                let date = new Date(data.month), y = date.getFullYear(), m = date.getMonth()
                let firstDay = new Date(y, m, 1, 0)
                let lastDay = new Date(y, m + 1, 0, 23, 59)
                // queryStore = queryStore.orderBy('date').startAt(firstDay).endAt(lastDay);
            }
            let prepaymentsArr = []
            queryStore.forEach(async (el) => {
                if(el.user){
                    console.log(el.user)
                }
                prepaymentsArr.push(el)
            })
            prepaymentsArr = prepaymentsArr.filter(x => !x.del)
            commit('setPrepayments', prepaymentsArr)
            commit('templ/setLoading', false, { root: true })
            
            // queryStore.onSnapshot(async snapshot => {
            //     let prepaymentsArr = []
            //     for (let prepaymentDoc of snapshot.docs) {
            //         let prepayment = { ...prepaymentDoc.data(), id: prepaymentDoc.id }
            //         if (prepayment.user) {
            //             let user = await dispatch('users/userSerializer', prepayment.user, { root: true })
            //             prepayment.user = user
            //         }
            //         prepaymentsArr.push(prepayment)
            //     }
            //     prepaymentsArr = prepaymentsArr.filter(x => !x.del)
                
                // commit('setPrepayments', prepaymentsArr)
                // commit('templ/setLoading', false, { root: true })
            // })
        },
    }
}