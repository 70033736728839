<template>
  <CommandButton
    slot="reference"
    :command="openAddLinkDialog"
    :enable-tooltip="true"
    tooltip="Добавить ссылку"
    icon="link"
  />
</template>

<script>
import "element-ui/lib/theme-chalk/popover.css";
import { Popover } from "element-ui";
import CommandButton from "@/components/Faq/Pages/components/TextEditor/extensions/components/CommandButton.vue";
import DialogAddLink from "./DialogAddLink.vue";

export default {
  name: "ImageMenuButton",
  components: {
    "el-popover": Popover,
    CommandButton,
  },
  props: ["editorContext"],
  methods: {
    async openAddLinkDialog() {
      const res = await this.$showModal({
        component: DialogAddLink,
        isPersistent: true,
        props: {
          edit: false,
          data: null,
        },
      });
      if (res && res.url) {
        this.editorContext.commands.link({ href: res.url, openInNewTab: true });
      }
    },
  },
};
</script>
