<template>
  <div class="pageAllMontages" :class="{ 'pa-3': !isMobile }">
    <SocketWatch :tables="['montages']" @loadData="loadData" />
    <v-card :tile="isMobile">
      <div v-if="isMobile">
        <v-row class="page_filterTable ma-0 px-1">
          <v-col cols="8">
            <v-text-field
              v-model="filterMontagesByDateText"
              color="rgb(224, 166, 119)"
              label="Дата"
              clearable
              @click="selectDate()"
              @click:clear="clearDate()"
            />
          </v-col>
          <v-col cols="2" class="pt-6">
            <v-btn icon @click="openFilters = !openFilters">
              <v-badge
                v-if="filtersSelected"
                color="orange"
                overlap
                :content="filtersSelected"
              >
                <v-icon>mdi-tune</v-icon>
              </v-badge>
              <v-icon v-else>mdi-tune</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="2" class="pt-6">
            <v-btn icon @click="createMontage()">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-expand-transition>
          <v-card v-show="openFilters" flat tile>
            <v-divider />
            <v-row class="page_filterTable ma-0 px-1">
              <v-col cols="12" class="pt-0">
                <v-autocomplete
                  v-model="filterMontagesByClient"
                  color="rgb(224, 166, 119)"
                  label="Клиент"
                  :items="filterClients"
                  item-text="name"
                  item-value="id"
                  return-object
                  clearable
                  @change="$store.dispatch('montages/loadMontages')"
                />
              </v-col>
              <v-col cols="12" class="pt-0">
                <v-select
                  v-model="filterMontagesByStatus"
                  color="rgb(224, 166, 119)"
                  label="Статус"
                  :items="montagesStatusArr"
                  clearable
                  @change="$store.dispatch('montages/loadMontages')"
                />
              </v-col>
              <v-col cols="12" class="pt-0">
                <v-select
                  v-model="filterMontagesByManager"
                  color="rgb(224, 166, 119)"
                  label="Менеджер"
                  :items="managers"
                  item-text="displayName"
                  return-object
                  clearable
                  @change="$store.dispatch('montages/loadMontages')"
                />
              </v-col>
            </v-row>
          </v-card>
        </v-expand-transition>
      </div>
      <div v-else class="d-flex align-center px-4">
        <v-row class="page_filterTable mt-3">
          <v-col cols="2">
            <v-text-field
              v-model="filterMontagesByDateText"
              dense
              color="rgb(224, 166, 119)"
              label="Дата"
              clearable
              @click="selectDate()"
              @click:clear="clearDate()"
            />
          </v-col>
          <v-col cols="3">
            <v-autocomplete
              v-model="filterMontagesByClient"
              dense
              color="rgb(224, 166, 119)"
              label="Клиент"
              :items="filterClients"
              item-text="name"
              item-value="id"
              return-object
              clearable
              @change="$store.dispatch('montages/loadMontages')"
            />
          </v-col>
          <v-col cols="2">
            <v-autocomplete
              v-model="filterMontagesByStatus"
              dense
              color="rgb(224, 166, 119)"
              label="Статус"
              :items="montagesStatusArr"
              clearable
              @change="$store.dispatch('montages/loadMontages')"
            />
          </v-col>
          <v-col cols="3">
            <v-autocomplete
              v-model="filterMontagesByManager"
              dense
              color="rgb(224, 166, 119)"
              label="Менеджер"
              :items="managers"
              item-text="displayName"
              return-object
              clearable
              @change="$store.dispatch('montages/loadMontages')"
            />
          </v-col>
        </v-row>
        <v-spacer></v-spacer>
        <v-btn small icon @click="createMontage()">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </div>
      <v-divider />
      <v-skeleton-loader
        v-if="!montagesLoaded && (!montages || montages.length === 0)"
        class="pl-3 pr-3"
        type="table-thead, table-row-divider@3"
        :types="skeletonTypesIframe"
      />
      <div
        v-if="montagesLoaded && (!montages || montages.length === 0)"
        class="pa-3 d-flex justify-center"
      >
        Нет монтажей
      </div>

      <v-data-table
        v-if="montages && montages.length > 0"
        :footer-props="{
          'items-per-page-options': [5, 10, 15, 20, 30, 40],
        }"
        :headers="[
          {
            text: 'Дедлайн',
            align: 'start',
            sortable: false,
            value: 'date',
          },
          {
            text: 'Клиент',
            align: 'start',
            sortable: false,
            value: 'client',
          },
          {
            text: 'Номер',
            align: 'start',
            sortable: false,
            value: 'numberOrder',
          },
          {
            text: 'Бригада',
            align: 'start',
            sortable: false,
            value: 'brig',
          },
          {
            text: 'Что делать',
            align: 'start',
            sortable: false,
            value: 'tasks',
          },
          {
            text: 'Статус',
            align: 'start',
            sortable: false,
            value: 'status',
          },
          {
            text: 'Менеджер',
            align: 'start',
            sortable: false,
            value: 'manager',
          },
        ]"
        :items="montages"
        :dense="!isMobile"
        :server-items-length="maxMontagesCount"
        :page="paginationOptions.page"
        :items-per-page="paginationOptions.itemsPerPage"
        @update:options="paginationOptions = $event"
        @click:row="editMontage"
      >
        <template #[`item.date`]="{ item }">
          {{ $formatDate(new Date(item.date)) }}
        </template>
        <template #[`item.client`]="{ item }">
          {{ item.client.name }}
        </template>
        <template #[`item.numberOrder`]="{ item }">
          {{ item.numberOrder || "Без номера" }}
        </template>
        <template #[`item.brig`]="{ item }">
          {{ item.brig ? item.brig.displayName : "Не указана" }}
        </template>
        <template #[`item.tasks`]="{ item }">
          {{ $sliceStr($getStrTasks(item.tasks), 125) }}
        </template>
        <template #[`item.status`]="{ item }">
          {{ montagesStatus[item.status] }}
        </template>
        <template #[`item.manager`]="{ item }">
          {{ item.manager.displayName }}
        </template>
      </v-data-table>

      <v-divider v-if="isMobile && montages.length > 0" />
      <v-row v-if="isMobile && montages.length > 0" class="ma-0">
        <v-col cols="12" class="d-flex align-center justify-center mt-1">
          <v-btn
            icon
            large
            :disabled="paginationOptions.page === 1"
            @click="
              paginationOptions.page > 1
                ? (options = {
                    ...paginationOptions,
                    page: paginationOptions.page - 1,
                  })
                : null
            "
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <span>{{ paginationOptions.page }} стр.</span>
          <v-btn
            icon
            large
            :disabled="
              paginationOptions.itemsPerPage * paginationOptions.page -
                (paginationOptions.itemsPerPage - montages.length) >=
              maxMontagesCount
            "
            @click="
              montages.length > 0 &&
              paginationOptions.itemsPerPage * paginationOptions.page -
                (paginationOptions.itemsPerPage - montages.length) <
                maxMontagesCount
                ? (options = {
                    ...paginationOptions,
                    page: paginationOptions.page + 1,
                  })
                : null
            "
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import MontageModal from "@/components/Modals/Montage/Index";
import SelectDate from "@/components/Modals/SelectDate";
import { montagesStatus, montagesStatusArr } from "@/CONSTS/montages";
import { PaginationMixin } from "@/mixins/pagination";
import { isMobile } from "mobile-device-detect";

export default {
  name: "AllMontages",
  mixins: [PaginationMixin],
  async beforeRouteLeave(to, from, next) {
    this.$store.commit("montages/resetAllFilterMontages");
    next();
  },
  data: () => ({
    isMobile: isMobile,
    skeletonTypesIframe: {
      "table-thead": "heading@4",
      "table-row": "table-cell@4",
    },
    montagesStatus: montagesStatus,
    montagesStatusArr: montagesStatusArr,
    filterMontagesByDateText: "",
    openFilters: false,
  }),
  computed: {
    maxMontagesCount() {
      return this.$store.getters["montages/getMaxMontagesCount"];
    },
    montagesPage() {
      return this.$store.getters["montages/getMontagesPage"];
    },
    filtersSelected() {
      let filters = [
        this.filterMontagesByClient,
        this.filterMontagesByStatus,
        this.filterMontagesByManager,
      ];
      filters = filters.filter((x) => !!x);
      return filters.length;
    },
    managers() {
      const managers = this.$store.getters["users/getManagers"];
      return managers;
    },
    montagesLoaded() {
      return this.$store.getters["montages/getMontagesLoaded"];
    },
    montages() {
      return this.$store.getters["montages/getMontages"];
    },
    filterClients() {
      console.log(
        this.$store.getters["clients/getFilterClients"].filter((x) =>
          x.name.includes("Бу")
        )
      );
      return this.$store.getters["clients/getFilterClients"];
    },
    filterMontagesByDate: {
      get() {
        return this.$store.getters["montages/getFilterMontagesByDate"];
      },
      set(val) {
        this.$store.commit("montages/setFilterMontagesByDate", val);
      },
    },
    filterMontagesByClient: {
      get() {
        return this.$store.getters["montages/getFilterMontagesByClient"];
      },
      set(val) {
        this.$store.commit("montages/setFilterMontagesByClient", val);
      },
    },
    filterMontagesByStatus: {
      get() {
        return this.$store.getters["montages/getFilterMontagesByStatus"];
      },
      set(val) {
        this.$store.commit("montages/setFilterMontagesByStatus", val);
      },
    },
    filterMontagesByManager: {
      get() {
        return this.$store.getters["montages/getFilterMontagesByManager"];
      },
      set(val) {
        this.$store.commit("montages/setFilterMontagesByManager", val);
      },
    },
    // other
    user() {
      return this.$store.getters["user/getUser"];
    },
  },
  created() {
    if (this.user.role === "manager") {
      this.filterMontagesByManager = this.user;
    }
    // Mixins
    const _this = this;
    this.PMProps = {
      setLimit(val) {
        _this.$store.commit("montages/SET_LIMIT", val);
      },
      setOffset(val) {
        _this.$store.commit("montages/SET_OFFSET", val);
      },
      load() {
        _this.$store.dispatch("montages/loadMontages");
      },
      onInit() {
        _this.loadData();
      },
      itemsPerPage: 10,
    };
    //
  },
  methods: {
    loadData() {
      this.$store.dispatch("montages/loadMontages");
      this.$store.dispatch("clients/loadFilterClients");
      this.$store.dispatch("users/loadManagers");
    },
    hc(e) {
      console.log(e);
    },
    clearDate() {
      this.filterMontagesByDate = null;
      this.filterMontagesByDateText = "";
      this.$store.dispatch("montages/loadMontages");
    },
    async selectDate() {
      let modalDate = await this.$showModal({
        component: SelectDate,
        isPersistent: true,
        props: {
          title: "Дата заказа",
          selectDate: this.filterMontagesByDate
            ? this.filterMontagesByDate.toISOString().substr(0, 10)
            : new Date().toISOString().substr(0, 10),
        },
      });
      if (modalDate) {
        this.filterMontagesByDate = new Date(modalDate);
        this.filterMontagesByDateText = new Date(modalDate).toLocaleString(
          "ru",
          {
            weekday: "short",
            day: "numeric",
            month: "short",
          }
        );
        this.$store.dispatch("montages/loadMontages");
      }
    },
    goUrl(url) {
      window.open(url, "_blank").focus();
    },
    async createMontage() {
      const res = await this.$showModal({
        component: MontageModal,
        isPersistent: true,
        props: {
          edit: false,
        },
      });
      this.$store.dispatch("montages/loadMontages");
    },
    async editMontage(montage) {
      const res = await this.$showModal({
        component: MontageModal,
        isPersistent: true,
        props: {
          edit: true,
          data: montage,
        },
      });
      this.$store.dispatch("montages/loadMontages");
    },
  },
};
</script>