<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" scrollable width="290" persistent>
        <v-card class="selectPerformers" width="290">
          <v-card-title>
            <span>Выберите исполнителя</span>
          </v-card-title>

          <v-card-text style="max-height: 300px;">
            <v-radio-group :key="updateKey" column label="Мастера:" class="selectPerformers_radio ma-0 mt-4 pa-0">
              <template v-for="(montager, index_montager) in users">
                <div class="mb-2" :key="index_montager">
                  <v-checkbox
                    v-model="montagers"
                    :disabled="montagers.length > 0 && !montagers.includes(montager.id)"
                    @change="changeMontagers(montager)"
                    :key="`check_${index_montager}`"
                    color="rgb(224, 166, 119)"
                    :label="`${montager.displayName}${montager.brig ? ` (${montager.brig.displayName})` : ''}`"
                    :value="montager.id"
                    class="pa-0 ma-0"
                  />
                </div>
              </template>
            </v-radio-group>
          </v-card-text>

          <v-card-actions>
            <v-btn color="orange" text @click="close()">Отмена</v-btn>
            <v-spacer />
            <v-btn color="blue" text @click="go()">Продолжить</v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { RoleText } from '@/helpers'

export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    data: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    roleText: RoleText,
    dialog: true,
    montagers: [],
    montagersInfo: {},
    updateKey: 0,
  }),
  computed: {
    users() {
      return this.$store.getters['users/getWorkers']
    }
  },
  methods: {
    changeMontagers(montager) {
      let id = montager.id
      let name = montager.displayName
      if (!this.montagers.includes(id) && this.montagersInfo[id]) {
        delete this.montagersInfo[id]
      } else if (this.montagers.includes(id)) {
        let cost = this.data && this.data.costForProd ? this.data.costForProd : 0
        if (this.montagersInfo[id]) {
          this.montagersInfo[id] = { ...this.montagersInfo[id], name: name, costWork: cost, boss: true }
        } else {
          this.montagersInfo[id] = { name: name, costWork: cost, boss: true }
        }
      }
    },
    go() {
      this.$emit('close', { 
        montagers: this.montagers,
        montagersInfo: this.montagersInfo
      })
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">
.selectPerformers {
  &_radio {
    .v-input--checkbox {
      .v-messages {
        display: none;
      }
    }
    .v-input__slot {
      margin: 0;
    }
    .v-text-field__details {
      display: none;
    }
  }
}
</style>