<template>
    <v-row>
        <v-col cols="12" class="pt-0">
            <NewComment v-if="!canEdit && canEdit !== undefined ? false : true" :item="item" :responseTo="response_to"
                :resetResponseTo="resetResponseTo" :item_id="item_id" :item_type="item_type" @load="loadComments" />
        </v-col>
        <v-col v-for="comment in filtredComments" :key="comment.id" cols="12" class="d-flex align-center">
            <v-row>
                <v-col>
                    <v-row>
                        <v-col cols="2">
                            <my-mini-avatar :user="comment.fromData" />
                        </v-col>
                        <v-col cols="10" style="margin-left: -4.5%">
                            <v-row>
                                <v-col cols="12" class="pa-0">
                                    <v-subheader class="pa-0" style="height: 23px">
                                        <span style="font-weight: 500">{{
                                            comment.fromData.displayName
                                        }}</span>
                                        <span v-if="comment.program" class="ml-2">Авто. создан</span>
                                        <span class="ml-2">{{
                                            $formatDateTime(new Date(comment.date))
                                        }}</span>
                                        <span v-if="user.role == roles.admin || user.id === comment.fromData.id &&
                                                !comment.program &&
                                                !comment.del
                                                " class="ml-1">
                                            <v-btn icon small @click="deleteComment(comment)">
                                                <v-icon size="20" color="red">
                                                    mdi-trash-can-outline
                                                </v-icon>
                                            </v-btn>
                                        </span>

                                        <span v-if="!canEdit && canEdit !== undefined ? false : true" class="ml-1">
                                            <v-btn icon small @click="() => (response_to = comment)">
                                                <v-icon size="20" color="#eec374">
                                                    mdi-send
                                                </v-icon>
                                            </v-btn>
                                        </span>
                                    </v-subheader>
                                </v-col>
                                <v-col cols="12" class="pa-0">
                                    <v-row>
                                        <v-col cols="12" v-if="comment.response_to" style="{
                        border-radius: 8px;
                    }">
                                            <div style="{
                          display: normal;
                          border-radius: 8px;
                        margin-bottom: 10px;
                        margin-top: 4px;
                        box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
                        display: flex;
                        justify: flex-start;
                      }">
                                                <div
                                                    style="{ display: normal; width: 8px; background-color: rgb(238, 195, 116); border-radius: 4px  0 0 4px;}">
                                                </div>
                                                <div style="{width: 95%; padding-bottom: 4px;}">
                                                    <v-subheader class="pa-0 pl-1 " style="height: 23px">
                                                        <span style="font-weight: 500">{{
                                                            getComment(comment.response_to).fromData
                                                                .displayName
                                                        }}</span>
                                                        <span v-if="getComment(comment.response_to).program"
                                                            class="ml-2">Авто. создан</span>
                                                        <span class="ml-2">{{
                                                            $formatDateTime(
                                                                new Date(getComment(comment.response_to).date)
                                                            )
                                                        }}</span>
                                                    </v-subheader>
                                                    <v-col v-if="getComment(comment.response_to).text" :class="[
                                                        { del: getComment(comment.response_to).del },
                                                    ]" style="{   display: normal; white-space: pre-wrap;}"
                                                        v-html="getComment(comment.response_to).text" />
                                                </div>
                                            </div>
                                        </v-col>
                                        <v-col v-if="comment.text" :class="[ { del: comment.del }]"
                                            v-html="comment.text" style="{   display: normal; white-space: pre-wrap;}" />
                                        <v-col v-if="!comment.text &&
                                            (!comment.files || comment.files.length === 0)
                                            " :class="[ { del: comment.del }]">
                                            Пустой комментарий
                                        </v-col>
                                        <v-col v-if="comment.files && comment.files.length > 0" cols="12">
                                            <v-container fluid class="pa-0">
                                                <v-row>
                                                    <v-col v-for="(image, index_image) in comment.files" :key="index_image"
                                                        class="d-flex child-flex" cols="4">
                                                        <my-preview-image :can-download="true" :fb-path="image"
                                                            :images="comment.files" @delete="deleteImage(image, comment)" />
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import NewComment from "@/components/Modals/components/NewComment";
import api, { apiRoutes as aR } from "@/utils/api/api";
import { sortByDateDesc } from "@/utils/helpers/sorts";
import { Role, RoleText } from "@/CONSTS/roles";


export default {
    components: {
        NewComment,
    },
    props: ["item_id", "item", "showProgramComment", "canEdit", "item_type"],
    data: () => ({
        comments: [],
        response_to: null,
        roles: Role
    }),
    computed: {
        user() {
            return this.$store.getters["user/getUser"];
        },
        users() {
            return this.$store.getters["users/getUsers"];
        },
        filtredComments() {
            let comments = this.comments;

            if (!this.showProgramComment) {
                comments = comments.filter((x) => !x.program);
                comments = comments.filter((x) => !x.del);
            }

            // Nesting level view preparing
            // let h = {},
            //   r = []; // result in r
            // comments.forEach((x) => ((h[x.id] = x), (x.children = [])));
            // comments.forEach((x) =>
            //   x.response_to && comments.findIndex((c) => c.id == x.response_to) !== -1
            //     ? h[x.response_to].children.push(x)
            //     : r.push(x)
            // );
            // console.log(r);
            // const plainPreparedResult = [];

            // let currentNestLevel = 0;

            // function prepare(root) {
            //   if (root.children.length) {
            //     plainPreparedResult.push({ ...root, level: currentNestLevel });
            //     currentNestLevel++;

            //     root.children.forEach((c) => prepare(c));
            //   } else {
            //     plainPreparedResult.push({ ...root, level: currentNestLevel });
            //     currentNestLevel == 0 ? null : currentNestLevel--;
            //   }
            // }

            // r.forEach(prepare);

            // console.log(plainPreparedResult);
            return comments;
        },
    },
    created() {
        this.loadComments();
    },
    methods: {
        getCommentCreatorName(id) {
            let comments = this.comments;
            const creatorId = comments.find((c) => c.id == id).fromData.id;

            const name = this.users.find((user) => user.id == creatorId).displayName;

            return name;
        },
        getComment(id) {
            return this.comments.find((c) => c.id == id);
        },

        resetResponseTo() {
            this.response_to = null;
        },
        async deleteComment(comment) {
            await api
                .put("comments/update", { id: comment.id, del: true })
                .then((response) => {
                    if (response.status == 200 && response.data.status) {
                        this.loadComments();
                    }
                });
        },
        async deleteImage(image, comment) {
            comment.files = comment.files.filter(function (file) {
                return file !== image;
            });
            await api
                .put("comments/update", {
                    id: comment.id,
                    files: comment.files.join(","),
                })
                .then((response) => {
                    if (response.status == 200 && response.data.status) {
                        this.$emit("delete-image");
                        setTimeout(() => this.loadComments(), 1000);
                    }
                });
        },
        async loadComments() {
            if (this.item_id) {
                let route;

                switch (this.item_type) {
                    case "montage":
                        route = aR.montagesGet;
                        break;
                    case "manufactory":
                        route = aR.manufactoryGet;
                        break;
                    case "order":
                        route = aR.ordersGet;
                        break;
                    case "task":
                        route = aR.tasksGet;
                        break;
                    default:
                        console.log(
                            "Illegal item_type in NewComment component:",
                            this.item_type
                        );
                        return;
                }

                let data = await api.get(route, this.item_id).then((response) => {
                    if (response.status == 200) {
                        return response.data.data.comments || [];
                    }
                });

                data.forEach((el) => {
                    if (el.files) {
                        el.files = el.files.split(",");
                    }
                });

                data = sortByDateDesc(data);

                this.comments = data || [];
            }
        },
    },
};
</script>