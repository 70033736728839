export function sortByDeadlineAsc(arr) {
  return arr.sort(function (a, b) {
    return new Date(a.deadline) - new Date(b.deadline);
  });
}

export function sortByDeadlineDesc(arr) {
  return arr.sort(function (a, b) {
    return new Date(b.deadline) - new Date(a.deadline);
  });
}

export function sortByDateAsc(arr) {
  return arr.sort(function (a, b) {
    return new Date(a.date) - new Date(b.date);
  });
}

export function sortByDateDesc(arr) {
  return arr.sort(function (a, b) {
    return new Date(b.date) - new Date(a.date);
  });
}


// INTL sorts
export const sortListIntl = (list, key) => {
  let engItems = [];
  let ruItems = [];
  let otherItems = [];
  if (list) {
    list.forEach((x) => {
      let itemChar0 = x[key].toLowerCase();
      itemChar0 = itemChar0.replace(/\s/g, "");
      itemChar0 = itemChar0.charAt(0);

      if (itemChar0.match(/[a-z]/i)) {
        engItems.push(x);
      } else if (itemChar0.match(/^\p{Script=Cyrillic}+$/u)) {
        ruItems.push(x);
      } else {
        otherItems.push(x);
      }
    });
  }
  let collator = new Intl.Collator();
  engItems = engItems.sort(function (a, b) {
    let nameA = a[key].toLowerCase(),
      nameB = b[key].toLowerCase();
    return collator.compare(nameA, nameB);
  });

  ruItems = ruItems.sort(function (a, b) {
    let nameA = a[key].toLowerCase(),
      nameB = b[key].toLowerCase();
    return collator.compare(nameA, nameB);
  });

  otherItems = otherItems.sort(function (a, b) {
    let nameA = a[key].toLowerCase(),
      nameB = b[key].toLowerCase();
    return collator.compare(nameA, nameB);
  });

  return [...ruItems, ...engItems, ...otherItems];
};