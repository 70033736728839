import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,{attrs:{"justify":"center"}},[_c(VDialog,{attrs:{"persistent":"","max-width":"400px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"}),(_vm.text)?_c(VCardText,{staticStyle:{"font-size":"1.2em"}},[_vm._v(" "+_vm._s(_vm.text)+" ")]):_vm._e(),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){return _vm.close()}}},[_vm._v(" Закрыть ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }