<template>
  <div
    class="itemMenu"
    :class="
      selectedCategory && selectedCategory.id === item.id
        ? 'itemMenu--active'
        : 'itemMenu--noactive'
    "
  >
    <v-list-item
      v-if="!item.categories || item.categories.length == 0"
      inactive
      @click="selectCategory(item)"
    >
      <v-list-item-content>
        <v-list-item-title v-text="item.title" />
      </v-list-item-content>
    </v-list-item>

    <v-list-group
      v-if="item.categories && item.categories.length"
      :key="item.id"
      v-model="item.active"
      :sub-group="depth > 0"
      :style="`${item.active ? `background-color: ${depthColor[depth]};` : ''}`"
      :class="
        selectedCategory && selectedCategory.id === item.id
          ? 'itemMenu-group--active'
          : 'itemMenu-group--noactive'
      "
      @click="selectCategory(item)"
    >
      <template #activator>
        <v-list-item-content>
          <v-list-item-title v-text="item.title"></v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon v-if="depth > 0">
          <v-icon v-if="item.active">mdi-chevron-up</v-icon>
          <v-icon v-else>mdi-chevron-down</v-icon>
        </v-list-item-icon>
      </template>

      <v-list-item-group>
        <ItemMenu
          v-for="child in item.categories"
          :key="`chield_${child.id}_${depth}`"
          :item="child"
          :depth="depth + 1"
          :selected-category="selectedCategory"
          @click.native.stop="selectCategory(child)"
          @selectCategory="selectCategory($event)"
        />
      </v-list-item-group>
    </v-list-group>
  </div>
</template>

<script>
import ItemMenu from "./ItemMenu.vue";

export default {
  name: "ItemMenu",
  components: {
    ItemMenu,
  },
  props: ["item", "depth", "selectedCategory"],
  data: () => ({
    depthColor: [
      "#72a2b7a3",
      "#72a2b794",
      "#72a2b7ed",
      "#72a2b775",
      "#72a2b769",
      "#72a2b757",
      "#72a2b747",
      "#72a2b73b",
      "#72a2b733",
      "#a2b3b3b5",
    ],
  }),
  methods: {
    selectCategory(val) {
      this.$emit("selectCategory", val);
    },
  },
};
</script>

<style lang="scss">
.itemMenu {
  cursor: pointer;

  .v-list-item__title {
    color: #473f3f;
  }

  .v-list-item {
    padding-left: 12px !important;
    padding: 0 12px !important;

    &:hover {
      background-color: rgba(114, 114, 114, 0.39);
    }

    .v-icon {
      color: #473f3f;
    }
  }
  .v-list-group {
    .v-list-group__header {
      padding-left: 12px !important;
      padding: 0 12px !important;
    }
  }
  .v-list-group--sub-group {
    .v-list-group__header__prepend-icon {
      display: none;
    }
  }

  &--noactive {
    .v-list-item__title {
      color: #473f3f;
    }
  }

  &--active {
    .v-list-item__title {
      color: rgb(12, 12, 12);
      font-weight: 600;
    }
    .itemMenu-group--active {
      .v-list-group__items {
        .v-list-item__title {
          color: #473f3f !important;
          font-weight: 400;
        }
      }
    }
  }
}
</style>
