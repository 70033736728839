<template>
  <div class="iframe iframe_full iframeUnassignedMontages pa-1">
    <SocketWatch :tables="['montages']" @loadData="loadData" />
    <v-card>
       <v-card-title>
				<v-icon class="mr-2">mdi-truck-minus</v-icon>
        Мои не назначенные монтажи
        <v-spacer></v-spacer>
        <v-btn icon small @click="createMontage()">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-skeleton-loader
        v-if="!montagesLoaded && (!montages || montages.length === 0)"
        class="pl-3 pr-3"
        type="table-thead, table-row-divider@3"
        :types="skeletonTypesIframe"
      />
      <div 
        v-if="montagesLoaded && (!montages || montages.length === 0)"
        class="infoNoItems"
      >
        Нет монтажей
      </div>
      <v-simple-table :dense="!isMobile" v-if="montages && montages.length > 0">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Дедлайн</th>
							<th class="text-left">Клиент</th>
              <th class="text-left">Номер</th>
              <th class="text-left">Адрес</th>
              <th class="text-left">Что делать</th>
              <th class="text-left">Менеджер</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="montage in montages"
              :key="montage.id"
              @click="editMontage(montage)"
              class="tableItemList"
            >
              <td style="min-width: 80px;">{{ $formatDate(new Date(montage.date)) }}</td>
							<td style="min-width: 125px;">
								{{ montage.client.name }}
								<!-- <v-menu bottom right :close-on-content-click="false">
									<template v-slot:activator="{ on, attrs }">
										<v-btn icon x-small v-bind="attrs" v-on="on" style="cursor: context-menu;">
											<v-icon>mdi-information-outline</v-icon>
										</v-btn>
									</template>
									<my-info-client :id="montage.client.id" />
								</v-menu> -->
							</td>
              <td style="min-width: 80px;">
								<span v-if="montage.numberOrder">{{ montage.numberOrder }}</span>
								<span v-else>Без номера</span>
							</td>
              <td>{{ montage.address }}</td>
              <td style="max-width: 280px;">
                {{ $sliceStr($getStrTasks(montage.tasks), 125) }}
              </td>
              <td>{{ montage.manager.displayName }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </div>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
import MontageModal from  '@/components/Modals/Montage/Index'

export default {
  name: 'MontagesUnassignedMontages',
  data: () => ({
    isMobile: isMobile,
    skeletonTypesIframe: {
      'table-thead': 'heading@4',
      'table-row': 'table-cell@4'
    }
  }),
  created() {
    this.loadData()
  },
  computed: {
    montagesLoaded() {
      return this.$store.getters['montages/getManagerMontagesLoaded']
    },
    montages() {
      return this.$store.getters['montages/getUnassignedManagerMontages']
    }
  },
  methods: {
    loadData() {
      this.$store.dispatch('montages/loadManagerUnassignedMontages')
    },
    async createMontage() {
      await this.$showModal({
        component: MontageModal,
        isPersistent: true,
        props: {
          edit: false
        }
      })
    },
    async editMontage(montage) {
      await this.$showModal({
        component: MontageModal,
        isPersistent: true,
        props: {
          edit: true,
          data: montage
        }
      })
    }
  }
}
</script>
