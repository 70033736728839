import { Image } from "element-tiptap";
import ImageView from "./ImageView.vue";
import MenuButton from "./MenuButton.vue";

export default class CustomImage extends Image {
  get view() {
    return ImageView;
  }

  menuBtnView(editorContext) {
    return {
      component: MenuButton,
      componentProps: {
        editorContext,
      },
    };
  }
}
