import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,[_c(VCol,{staticClass:"py-0",attrs:{"cols":"12"}},[_c(VForm,{ref:"address"},_vm._l((_vm.address),function(address,address_index){return _c('div',{key:address_index,staticClass:"d-flex"},[_c(VTextarea,{attrs:{"color":"#e0a677","value":address,"label":'Адрес ' + (address_index+1),"rows":"2"},on:{"input":function($event){return _vm.$emit('changeAddress', { index: address_index, val: $event })}}}),_c('div',{staticStyle:{"padding-bottom":"19px","margin-left":"5px"}},[_c('div',{staticStyle:{"height":"100%","display":"flex","justify-content":"center","align-items":"center","flex-wrap":"wrap","width":"35px","background":"#ebebeb"}},[_c(VBtn,{attrs:{"icon":"","color":"blue"},on:{"click":function($event){return _vm.$emit('addFieldAddress')}}},[_c(VIcon,[_vm._v("mdi-plus")])],1),(address.length > 1)?_c(VBtn,{attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.$emit('removeFieldAddress', address_index)}}},[_c(VIcon,[_vm._v("mdi-close")])],1):_vm._e()],1)])],1)}),0)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }