import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"itemMenu",class:_vm.selectedCategory && _vm.selectedCategory.id === _vm.item.id
      ? 'itemMenu--active'
      : 'itemMenu--noactive'},[(!_vm.item.categories || _vm.item.categories.length == 0)?_c(VListItem,{attrs:{"inactive":""},on:{"click":function($event){return _vm.selectCategory(_vm.item)}}},[_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(_vm.item.title)}})],1)],1):_vm._e(),(_vm.item.categories && _vm.item.categories.length)?_c(VListGroup,{key:_vm.item.id,class:_vm.selectedCategory && _vm.selectedCategory.id === _vm.item.id
        ? 'itemMenu-group--active'
        : 'itemMenu-group--noactive',style:(`${_vm.item.active ? `background-color: ${_vm.depthColor[_vm.depth]};` : ''}`),attrs:{"sub-group":_vm.depth > 0},on:{"click":function($event){return _vm.selectCategory(_vm.item)}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(_vm.item.title)}})],1),(_vm.depth > 0)?_c(VListItemIcon,[(_vm.item.active)?_c(VIcon,[_vm._v("mdi-chevron-up")]):_c(VIcon,[_vm._v("mdi-chevron-down")])],1):_vm._e()]},proxy:true}],null,false,1122828117),model:{value:(_vm.item.active),callback:function ($$v) {_vm.$set(_vm.item, "active", $$v)},expression:"item.active"}},[_c(VListItemGroup,_vm._l((_vm.item.categories),function(child){return _c('ItemMenu',{key:`chield_${child.id}_${_vm.depth}`,attrs:{"item":child,"depth":_vm.depth + 1,"selected-category":_vm.selectedCategory},on:{"selectCategory":function($event){return _vm.selectCategory($event)}},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.selectCategory(child)}}})}),1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }