import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,{attrs:{"justify":"center"}},[_c(VDialog,{attrs:{"persistent":"","max-width":"400px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(!_vm.onChangeManager)?_c(VCard,[(_vm.title)?_c(VCardTitle,{staticClass:"headline"},[_vm._v(_vm._s(_vm.title))]):_vm._e(),(_vm.text)?_c(VCardText,[_vm._v(" "+_vm._s(_vm.text)+" ")]):_vm._e(),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){return _vm.close()}}},[_vm._v(" Нет ")]),_c(VBtn,{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){return _vm.ok()}}},[_vm._v(" Да ")])],1)],1):_vm._e(),(_vm.onChangeManager)?_c(VCard,[(_vm.title)?_c(VCardTitle,{staticClass:"headline"},[_vm._v(_vm._s(_vm.title))]):_vm._e(),(_vm.text)?_c(VCardText,[_vm._v(" "+_vm._s(_vm.text)+" "),_c(VRow,{staticClass:"page_filterTable mt-3"},[_c(VCol,{attrs:{"cols":"8"}},[_c(VAutocomplete,{attrs:{"dense":"","color":"rgb(224, 166, 119)","label":"Менеджер","items":_vm.managers,"item-text":"displayName","return-object":"","clearable":"","onchange":"notErrorText"},model:{value:(_vm.selectedManager),callback:function ($$v) {_vm.selectedManager=$$v},expression:"selectedManager"}}),(this.onError)?_c('div',{staticStyle:{"color":"red"}},[_vm._v("Выберите менеджера!")]):_vm._e()],1)],1)],1):_vm._e(),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){return _vm.close()}}},[_vm._v(" Нет ")]),_c(VBtn,{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){return _vm.sendOk()}}},[_vm._v(" Да ")])],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }