<template>
  <div class="iframe iframe_widget iframeWidgetAssignedMontagesInBrig pa-1">
    <SocketWatch :tables="['montages']" @loadData="loadData" />
    <v-card>
      <v-card-title>
				<v-icon class="mr-2">mdi-truck-minus</v-icon>
				Монтажи на сегодня
        <v-spacer></v-spacer>
      </v-card-title>
      <v-divider />
      <v-skeleton-loader
        v-if="!montagesLoaded && (!montages || montages.length === 0)"
        class="px-2"
        type="card-heading"
      />
      <v-row class="ma-0 pb-2">
        <v-col
          cols="12"
          v-for="(data, index_data) in montages"
          :key="index_data"
          class="pb-0 pt-2 px-2"
        >
          <v-card>
            <v-card-title class="py-0">
              <span>{{ data.brig.displayName  }}</span>
            </v-card-title>
            <v-divider />
            <div 
              v-if="!data.montages || data.montages.length === 0"
              class="infoNoItems"
            >
              Нет монтажей
            </div>
            <div class="widgetTableMontage">
              <div
                v-for="(montage, index_montage) in data.montages"
                :key="montage.id"
                @click="editMontage(montage)"
                class="widgetTableMontage_tr"
              >
                <div class="widgetTableMontage_tr_cont">
                  <div class="widgetTableMontage_tr_cont_status">
                    <div v-if="montage.status === 'assigned'" style="background-color: orange;" />
                    <div v-if="montage.status === 'work'" style="background-color: blue;" />
                    <div v-if="montage.status === 'ready'" style="background-color: green;" />
                    <div v-if="montage.status === 'no_work'" style="background-color: #e8443c;" />
                  </div>
                  <div class="px-2 py-1">
                    <div class="">
                      <span style="font-weight: 400; font-size: 0.84em;">{{ montage.client.name }}</span>
                    </div>
                  </div>
                </div>
                <v-divider v-if="index_montage + 1 < data.montages.length" />
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import MontageModal from  '@/components/Modals/Montage/Index'

export default {
  name: 'WidgetAssignedMontages',
  components: {},
  data: () => ({}),
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.$store.dispatch('montages/loadAssignedMontages')
    },
    async editMontage(montage) {
      await this.$showModal({
        component: MontageModal,
        isPersistent: true,
        props: {
          edit: true,
          data: montage
        }
      })
    }
  },
  computed: {
    montagesLoaded() {
      return this.$store.getters['montages/getAssignedMontagesLoaded']
    },
    montages() {
      return this.$store.getters['montages/getAssignedMontages']
    },
  }
}
</script>

<style lang="scss" scoped>
.widgetTableMontage {
  &_tr {
    &_cont {
      display: flex;
      &:hover {
        background-color: rgba(199, 199, 199, 0.26);
        cursor: pointer;
      }

      &_status {
        width: 1%;

        div {
          width: 100%;
          height: 100%;
        }
      }

      &_info {
        width: 92.3%;
        display: flex;
        align-items: center;

        &:hover {
          cursor: pointer;
        }

        &_container {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &_edit {
        width: 7%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &:last-child {
      .widgetTableMontage_tr_cont .widgetTableMontage_tr_cont_status div {
        border-bottom-left-radius: 5px;
      }
    }
  }
}
</style>
