<template>
	<div class="">
		<SocketWatch :tables="[
      'notify'
      ]" @loadData="loadData" />
			<v-menu
		@input="setIsOpenNotifList"
		:close-on-content-click="false"
		offset-y
	>
		<template v-slot:activator="scope">
			<v-btn icon v-bind="scope.attrs" v-on="scope.on">
				<v-badge
					v-if="items.length > 0"
					color="red"
					:content="items.length"
					overlap
					right
				>
					<v-icon color="rgba(0,0,0,.54)">mdi-bell-outline</v-icon>
				</v-badge>
				<v-icon v-else color="rgba(0,0,0,.54)">mdi-bell-outline</v-icon>
			</v-btn>
		</template>

		<v-card width="500" max-height="600">
			<template v-if="isOpenNotifList">
				<v-list v-if="items.length > 0" two-line>
					<div class="pa-2">
						<v-btn
						icon 
						style="right: -8px;" color="grey"
						@click.stop="acceptedAllNotify"
					>
						<v-icon>
							mdi-notification-clear-all
						</v-icon>
					</v-btn>
					</div>
					
					
					
					<v-divider />
					<template v-for="(item, index) in items" >
						<ItemDropList :item="item"  :key="item.id" />
						<v-divider v-if="index < items.length - 1" :key="`k${item.id}`" />
					</template>
				</v-list>
				<v-list-item-title
					v-else
					class="pa-3 text-center"
				>
					Пока пусто :)
				</v-list-item-title>
			</template>
		</v-card>
	</v-menu>
	</div>
	
</template>

<script>
import ItemDropList from "./ItemDropList.vue"
import { mapState, mapActions, } from 'vuex';
export default {
	components: {
		ItemDropList
	},
	data: () => ({
		actionAvailable: ['newTask']
	}),

	created() {
    this.loadData()
  },

	methods: {
		...mapActions('notify', ['loadNotify', 'notify', 'acceptedAllNotify']),
    loadData() {
      this.loadNotify()
    },
		setIsOpenNotifList(val) {
			this.isOpenNotifList = val
		}
	},
	computed: {
		...mapState('notify', ['notifications']),
		user() {
      return this.$store.getters['user/getUser']
    },
		items() {
			return this.notifications
		},
		isOpenNotifList: {
			get() {
				return []
			},
			set(val) {
				
			}
		}
	},
}
</script>