import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"iframe iframe_full pa-0"},[_c('SocketWatch',{attrs:{"tables":['tasks']},on:{"loadData":_vm.loadData}}),_c(VCard,[_c(VCardTitle,{staticClass:"py-0 pt-3"},[_c('NewMeTask')],1),_c(VDivider),(!_vm.tasksLoaded && (!_vm.tasks || _vm.tasks.length === 0))?_c(VSkeletonLoader,{staticClass:"pl-3 pr-3",attrs:{"type":"table-thead, table-row-divider@3","types":_vm.skeletonTypesIframe}}):_vm._e(),(_vm.tasksLoaded && (!_vm.tasks || _vm.tasks.length === 0))?_c('div',{staticClass:"infoNoItems"},[_vm._v(" Нет задач 5 ")]):_vm._e(),(_vm.tasks && _vm.tasks.length > 0)?_c(VDataTable,{attrs:{"headers":_vm.tableHeadres,"items":_vm.tasks,"items-per-page":15,"dense":!_vm.isMobile,"options":_vm.options,"hide-default-footer":""},on:{"update:options":function($event){return _vm.$emit('updOptions', $event)},"pagination":function($event){return _vm.$emit('updPagination', $event)}},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('tr',{style:(item.color && item.color[_vm.currentUser.id] ?
								 `background-color: ${item.color[_vm.currentUser.id]}` : ''),on:{"click":function($event){return _vm.openTask(item)}}},[_c('td',{staticStyle:{"width":"47%"}},[_vm._v(" "+_vm._s(_vm.$sliceStr(item.title || item.description, 60))+" ")]),_c('td',[(item.numberOrder)?_c('span',[_vm._v(_vm._s(item.numberOrder))]):_c('span',[_vm._v("Не указан")])]),_c('td',[_c('div',{staticClass:"d-flex align-center"},[_c('my-mini-avatar',{key:item.from_id,attrs:{"user":item.from_data}})],1)]),_c('td',[_c('span',{style:(`${_vm.$colorDeadline(new Date(item.deadline))}`)},[_vm._v(" "+_vm._s(_vm.$formatDateTime(new Date(item.deadline)))+" ")])]),_c('td',[_c('span',[(item && item.archive)?[_vm._v("В архиве")]:(item && item.print)?[_vm._v("В печать")]:(!item || !item.accepted)?[_vm._v("Ожидает")]:(item && item.ready)?[_vm._v("Готово")]:[_vm._v("В работе")]],2)]),_c('td',{staticClass:"text-center"},[_c(VMenu,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","disabled":_vm.disabledColorBtn(item)}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-format-color-fill")])],1)]}}],null,true)},[_c('div',{staticStyle:{"background-color":"white"}},[_c('div',{staticClass:"pt-2 px-5"},[(item.color && item.color[_vm.currentUser.id])?_c(VBtn,{attrs:{"color":item.color[_vm.currentUser.id] || null,"block":""},on:{"click":function($event){return _vm.setColorItem('#FFF', item)}}},[_vm._v(" Сбросить цвет ")]):_vm._e()],1),_c(VColorPicker,{staticClass:"rl-table-color-picker",attrs:{"value":item.color && item.color[_vm.currentUser.id] || null,"hide-canvas":"","hide-inputs":"","show-swatches":"","swatches-max-height":"230","mode":"hexa"},on:{"input":function($event){return _vm.setColorItem($event, item)}}})],1)])],1)])]}}],null,false,2415707054)}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }