<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      max-width="600px"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Добавить изображение по ссылке</span>
          <v-spacer />
          <v-btn icon color="rgb(224, 166, 119)" @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="formAddImageByUrl">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="form.url"
                    color="rgb(224, 166, 119)"
                    label="Ссылка на изображение*"
                    :rules="$fieldRules.field"
                    required
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="orange" text @click="save()">
            <span>Добавить</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data: () => ({
    dialog: true,
    errors: {},
    form: {
      url: "",
    },
  }),
  methods: {
    async save() {
      const errors = [];
      const validate = await this.$refs.formAddImageByUrl.validate();
      errors.push(validate);

      if (!errors.includes(false)) {
        this.$emit("close", this.form);
      }
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.$emit("close");
      }, 200);
    },
  },
};
</script>
