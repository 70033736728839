<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" :fullscreen="isMobile" :max-width="!isMobile ? '600px' : ''"
            transition="dialog-bottom-transition" :scrollable="!isMobile" :persistent="!isMobile">
            <v-card :tile="isMobile" class="rl-modal">
                <v-toolbar v-if="isMobile" color="rgb(224, 166, 119)" tile style="position: sticky; top: 0px; z-index: 20;">
                    <v-toolbar-title v-if="edit" class="white--text">Редактировaние задания</v-toolbar-title>
                    <v-toolbar-title v-else class="white--text">Новый задание</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="close()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-title v-else class="rl-modal_header">
                    <span v-if="edit" class="headline">Редактирование задания</span>
                    <span v-else class="headline">Новое задание</span>
                    <v-spacer />
                    <v-btn icon color="rgb(224, 166, 119)" @click="close()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text class="pb-0" :class="{ 'px-2 pt-3': isMobile }">
                    <div v-if="form.numberOrder" style="font-size: 1.1em; color: black;">
                        <span style="font-weight: 500;">Номер заказа:</span>
                        <span v-if="!/[a-zA-Z]/g.test(form.numberOrder) &&
                            edit &&
                            (user.role === 'admin' ||
                                (data && data.manager_id === user.id))
                            " @click="$openOrder(form.numberOrder)" class="rl-link">{{ form.numberOrder }}</span>
                        <span v-else>{{ form.numberOrder }}</span>
                    </div>
                    <div v-if="edit" class="pb-3" style="font-size: 1.1em; color: black;">
                        <span style="font-weight: 500;">Менеджер: </span>
                        {{ data.manager.displayName }}
                        <span>
                            <span style="font-weight: 500;">Дата: </span>
                            <span>{{
                                $formatDate(
                                    form.date.seconds ? new Date(form.date) : new Date(form.date)
                                )
                            }}</span>
                            <v-btn v-if="canEdit" icon color="black" small @click="editDate()" class="ml-1">
                                <v-icon small>mdi-pencil</v-icon>
                            </v-btn>
                        </span>
                        <span v-if="data.status">
                            <br />
                            <span style="font-weight: 500;">Статус: </span>
                            {{ manufactoryTaskStatus[data.status] }}
                        </span>
                        <span v-if="form.montagers &&
                            form.montagersInfo &&
                            Object.keys(form.montagersInfo).length > 0
                            ">
                            <br />
                            <span style="font-weight: 500;">Исполнители:</span>
                            <span v-for="(montager, index_montager) in form.montagersInfo" :key="index_montager">
                                <span :style="montager.boss ? 'font-weight: 500;' : ''">
                                    {{ montager.name }}
                                </span>
                                <!-- <span v-if="user.role != 'manager'">({{ montager.costWork }}р.)</span> -->
                                <span v-if="index_montager !=
                                    Object.keys(form.montagersInfo)[
                                    Object.keys(form.montagersInfo).length - 1
                                    ]
                                    ">,</span>
                            </span>
                            <v-btn v-if="['admin', 'prodDir'].includes(user.role)" icon color="black" small
                                @click="editPerformers()" class="ml-1">
                                <v-icon small>mdi-pencil</v-icon>
                            </v-btn>
                        </span>
                        <span v-if="data.readyDate">
                            <br />
                            <span style="font-weight: 500;">Готово:</span>
                            {{ $formatDateTime(new Date(data.readyDate)) }}
                        </span>
                        <span v-if="data.archiveDate">
                            <br />
                            <span style="font-weight: 500;">Архив:</span>
                            {{ $formatDateTime(new Date(data.archiveDate)) }}
                        </span>
                    </div>
                    <v-container class="py-0">
                        <v-form ref="formCreateTask">
                            <v-row>
                                <v-col cols="12" class="d-flex pt-0">
                                    <v-autocomplete :class="{
                                        'mr-2': ['admin', 'manager'].includes(user.role),
                                    }" :disabled="user.role === 'prodDir' ||
    (user.role === 'manager' &&
        Boolean(data.status) &&
        ['produced', 'ready', 'archive'].includes(
            data.status
        ))
    " ref="clientName" :dense="!isMobile" color="#e0a677" v-model="form.filterClient" placeholder="Клиент"
                                        :items="filterClients" item-text="name" return-object @change="setClient()">
                                        <template v-slot:no-data>
                                            <v-row no-gutters class="px-2">
                                                <v-col cols="12" class="d-flex align-center justify-center">
                                                    <v-btn @click="newClient()" color="#e0a677" text small>
                                                        Добавить клиента
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </template>
                                    </v-autocomplete>
                                    <v-btn v-if="['admin', 'manager'].includes(user.role)" :disabled="!form.client ||
                                        (form.client && !form.client.name === undefined)
                                        " @click="showClientInfo()" icon>
                                        <v-icon>mdi-account-eye-outline</v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col cols="12" class="py-0">
                                    <v-text-field :disabled="!form.techTask &&
                                        edit &&
                                        data.status != 'confirmationProd' &&
                                        data.status != 'confirmation'
                                        " :readonly="user.role === 'prodDir' ||
        (user.role === 'manager' &&
            Boolean(data.status) &&
            ['produced', 'ready', 'archive'].includes(
                data.status
            )) ||
        data.status === 'archive'
        " color="rgb(224, 166, 119)" v-model="form.techTask" :rules="$fieldRules.isUrl" label="Ссылка на ТЗ"
                                        :append-outer-icon="form.techTask ? 'mdi-open-in-new' : null
                                            " @click:append-outer="goUrl(form.techTask)" />
                                </v-col>
                                <v-col cols="12" class="py-0">
                                    <v-textarea :readonly="user.role === 'prodDir' ||
                                        (user.role === 'manager' &&
                                            Boolean(data.status) &&
                                            ['produced', 'ready', 'archive'].includes(
                                                data.status
                                            )) ||
                                        data.status === 'archive'
                                        " color="rgb(224, 166, 119)" v-model="form.comment" label="Комментарий"
                                        rows="2" />
                                </v-col>
                                <!-- <v-col v-if="!getHourPayWorkMasters && (user.role === 'admin' || user.role === 'prodDir')" cols="12" class="py-0">
                  <v-text-field
                    :disabled="disCostField"
                    color="rgb(224, 166, 119)"
                    type="number"
                    suffix="руб."
                    v-model="form.costForProd"
                    label="Стоимость для производства"
                  />
                </v-col> -->
                                <!-- <v-col v-if="['prodDir', 'master', 'admin'].includes(user.role)" cols="12" class="py-0">
                  <v-textarea
                    color="rgb(224, 166, 119)"
                    v-model="form.commentProd"
                    label="Комментарий производства"
                    rows="2"
                  />
                </v-col> -->
                                <v-col cols="12" class="py-0">
                                    <v-btn :disabled="!canEdit" @click="uploadImage()" width="100%"
                                        :loading="uploadingImage">
                                        <v-icon>mdi-cloud-upload</v-icon>
                                        <span class="pl-2">Загрузить фото</span>
                                    </v-btn>
                                </v-col>
                                <v-col cols="12" class="py-0">
                                    <v-container fluid class="pa-0">
                                        <v-row>
                                            <v-col v-for="(image, index_image) in form.images" :key="index_image"
                                                class="d-flex child-flex" cols="4">
                                                <ImageView :key="index_image + form.images.length" :disTrach="!canEdit"
                                                    :path="images[index_image]" :images="images"
                                                    @delete="deleteImage(image, index_image)" />
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-col>
                                <!-- <v-col cols="12" class="py-0">
                  <v-btn :disabled="!canEdit" @click="downloadImage(form.images)" width="100%" :loading="uploadingImage">
                    <v-icon>mdi-cloud-upload</v-icon>
                    <span class="pl-2">Скачать фото</span>
                  </v-btn>
                </v-col> -->
                            </v-row>
                        </v-form>

                        <div style="min-height: 70px;"></div>

                        <Comments v-if="edit" :item_id="data.id" :item="data"
                            :canEdit="canEdit || this.data.status === 'archive'" :showProgramComment="showProgramComments"
                            item_type="manufactory" />
                    </v-container>
                </v-card-text>
                <v-card-actions class="px-7">
                    <!-- <v-btn v-if="taskBoss && ['master'].includes(user.role) && (data.status && !['ready', 'archive'].includes(data.status))" color="orange" text @click="addAssistant()">Помошники</v-btn>
          <v-btn v-if="edit && ((['advance'].includes(data.status) && user.role === 'manager') || user.role === 'admin')" :disabled="user.role === 'admin' ? false : !canEdit" color="red" text @click="deleteTask()">{{ isMobile ? 'Удал.' :  'Удалить' }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn v-if="edit && data.status === 'confirmationProd' && ['admin', 'prodDir'].includes(user.role)" color="black" text @click="save('confirmation', 'revision')">{{ isMobile ? 'На. доработ.' :  'На доработку' }}</v-btn>
          <v-btn v-if="edit && data.status === 'confirmationProd' && ['admin', 'prodDir'].includes(user.role)" color="orange" text @click="save('work')">{{ isMobile ? 'Наз. мастера' :  'Назначить мастера' }}</v-btn>
          <v-btn v-if="(user.role === 'admin' || user.canConfirmTask) && ['confirmation'].includes(data.status)" color="blue" text @click="save('confirmationProd')">В производство</v-btn>
          <v-btn v-if="user.role === roles.admin && !data.status" color="blue" text @click="save('confirmationProd')">В производство</v-btn>
          <v-btn v-if="user.role === 'admin' && ['produced', 'work'].includes(data.status) || taskBoss && ['master', 'prodDir'].includes(user.role) && (data.status && data.status === 'produced')" color="green" text @click="save('ready')">Готово</v-btn>
          <v-btn v-if="taskBoss && ['master', 'prodDir'].includes(user.role) && (data.status && data.status === 'work')" color="blue" text @click="save('produced')">В работу</v-btn>
          <v-btn v-if="['admin', 'manager'].includes(user.role) && (data.manager && data.manager.id === user.id || user.role === 'admin') && (data.status && data.status === 'ready')" color="blue" text @click="save('archive')">В архив</v-btn>
          <v-btn v-if="['manager', 'admin'].includes(user.role) && !data.status" :disabled="!canEdit" color="green" text @click="save('confirmation')">
            <span v-if="user.role === 'admin'">Сохранить</span>
            <span v-else>На подтверждение</span>
          </v-btn>
          <v-btn v-if="user.role === 'admin' && (data.status && data.status != 'archive') || (user.role === 'manager' && edit && canEdit) || user.role === 'prodDir'" color="blue" text @click="save()">Сохранить</v-btn> -->
                    <v-btn v-if="taskBoss &&
                        ['master'].includes(user.role) &&
                        data.status &&
                        !['ready', 'archive'].includes(data.status)
                        " color="orange" text @click="addAssistant()">Помошники</v-btn>
                    <v-btn v-if="edit &&
                        ((['advance'].includes(data.status) &&
                            user.role === 'manager') ||
                            user.role === 'admin')
                        " :disabled="user.role === 'admin' ? false : !canEdit" color="red" text
                        @click="deleteTask()">{{ isMobile ?
                            "Удал." : "Удалить" }}</v-btn>
                    <v-btn v-if="edit &&
                        data.status === 'confirmationProd' &&
                        ['admin', 'prodDir'].includes(user.role)
                        " color="black" text @click="save('confirmation', 'revision')">{{ isMobile ? "На. доработ." :"На доработку" }}</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn v-if="edit &&
                        data.status === 'confirmationProd' &&
                        ['admin', 'prodDir'].includes(user.role)
                        " color="orange" text @click="save('work')">{{ isMobile ? "Наз. мастера" : "Назначить мастера"
    }}</v-btn>
                    <v-btn v-if="['confirmation'].includes(data.status) || !data.status" color="blue" text
                        @click="save('confirmationProd')">На производство</v-btn>
                    <v-btn v-if="(user.role === 'admin' &&
                        ['produced', 'work'].includes(data.status)) ||
                        (taskBoss &&
                            ['master', 'prodDir'].includes(user.role) &&
                            data.status &&
                            data.status === 'produced')
                        " color="green" text @click="save('ready')">Готово</v-btn>
                    <v-btn v-if="taskBoss &&
                        ['master', 'prodDir'].includes(user.role) &&
                        data.status &&
                        data.status === 'work'
                        " color="blue" text @click="save('produced')">В работу</v-btn>
                    <v-btn v-if="['admin', 'manager'].includes(user.role) &&
                        ((data.manager && data.manager.uid === user.uid) ||
                            user.role === 'admin') &&
                        data.status &&
                        data.status === 'ready'
                        " color="blue" text @click="save('archive')">В архив</v-btn>
                    <v-btn v-if="['manager', 'admin'].includes(user.role) && !data.status" :disabled="!canEdit"
                        color="green" text @click="save('confirmation')">Сохранить</v-btn>
                    <v-btn v-if="(user.role === 'admin' &&
                        data.status &&
                        data.status != 'archive') ||
                        (user.role === 'manager' && edit && canEdit) ||
                        (user.role === 'prodDir' && data.status != 'confirmationProd')
                        " color="blue" text @click="save()">Сохранить</v-btn>
                </v-card-actions>
            </v-card>
            <div style="display: none;" ref="file"></div>
        </v-dialog>
    </v-row>
</template>

<script>
import ImageView from "@/components/Image.vue";
import Client from "@/components/Modals/Clients/Index";
import Comments from "@/components/Modals/components/Comments";
import Confirm from "@/components/Modals/Confirm";
import SelectAssistantForMaster from "@/components/Modals/SelectAssistantForMaster";
import SelectDate from "@/components/Modals/SelectDate";
import SelectMasterForProd from "@/components/Modals/SelectMasterForProd";
import {
    manufactoryTaskStatus,
    manufactoryTaskStatusArr,
    Role,
} from "@/helpers";
import api from "@/utils/api";
import loadImage from "blueimp-load-image";
import imageCompression from "browser-image-compression";
import { isMobile } from "mobile-device-detect";
var cloneDeep = require("lodash.clonedeep");
const fileUrl = process.env.VUE_APP_FILE_URL;

export default {
    components: {
        ImageView,
        Comments,
        Client,
    },
    props: {
        edit: {
            type: Boolean,
            default: false,
        },
        data: {
            type: Object,
            default: () => {
                return {};
            },
        },
        nmbrOrder: {
            type: String,
            default: null,
        },
        clientOrder: {
            type: Object,
            default: null,
        },
    },
    data: () => ({
        isMobile: isMobile,
        roles: Role,
        manufactoryTaskStatus: manufactoryTaskStatus,
        manufactoryTaskStatusArr: manufactoryTaskStatusArr,
        dialog: true,
        form: {
            client: "",
            filterClient: "",
            comment: "",
            techTask: "",
            commentProd: "",
            costForProd: 0,
            montagers: null,
            montagersInfo: null,
            images: [],
            date: new Date().toISOString().substr(0, 10),
            numberOrder: null,
        },
        images: [],
        uploadingImage: false,
        showProgramComments: false,
    }),
    computed: {
        clientContacts() {
            const client = this.form.client;
            if (client && client.contacts) {
                return client.contacts;
            }
            return [];
        },
        disCostField() {
            if (this.user.role === "admin") {
                return false;
            }
            if (this.user.role === "prodDir") {
                return (
                    this.data.status === "archive" ||
                    !["admin", "prodDir"].includes(this.user.role) ||
                    (this.user.role === "prodDir" && !this.user.canConfirmTask) ||
                    ["work", "produced", "ready"].includes(this.data.status)
                );
            }
            return true;
        },
        canEdit() {
            if (this.data.status === "archive") {
                return false;
            } else {
                if (!this.edit) {
                    return true;
                } else if (this.user.role === "admin") {
                    return true;
                } else if (this.user.role === "prodDir") {
                    return true;
                } else if (
                    this.user.id === this.data.manager.id &&
                    (!this.data.status ||
                        ["confirmation", "confirmationProd", "work"].includes(
                            this.data.status
                        ))
                ) {
                    return true;
                }
            }
            return false;
        },
        user() {
            return this.$store.getters["user/getUser"];
        },
        users() {
            return this.$store.getters["users/getWorkers"];
        },
        filterClients() {
            return this.$store.getters["clients/getFilterClients"];
        },
        taskBoss() {
            let task = { ...this.data };

            if (this.user.role === "master") {
                if (
                    task.montagersInfo &&
                    task.montagersInfo[this.user.id] &&
                    task.montagersInfo[this.user.id].boss
                ) {
                    return true;
                }
                return false;
            }
            if (this.user.role === "prodDir") {
                return true;
            }

            return false;
        },
        getHourPayWorkMasters() {
            return this.$store.getters["settings/getHourPayWorkMasters"];
        },
    },
    async created() {
        // if (!this.filterClients || this.filterClients.length === 0) {
        this.$store.dispatch("clients/loadFilterClients");
        // }
        // if (!this.users || this.users.length === 0) {
        this.$store.dispatch("users/loadUsers");
        // }

        if (this.nmbrOrder) {
            this.form.numberOrder = this.nmbrOrder;
        }

        if (this.clientOrder) {
            this.form.client = this.clientOrder;
            this.form.filterClient = {
                name: this.clientOrder.name,
                id: this.clientOrder.id,
                surname: this.clientOrder.surname,
            };
        }

        this.setDefVal(this.data);
        console.log("this.data", this.data);
    },
    watch: {
        form: {
            deep: true,
            async handler(newValue) {
                // console.log('handler',newValue)
                if (newValue.images.length > this.images.length) {
                    // let images = []
                    // if (this.form.images && this.form.images.length > 0) {
                    //   for (let key in this.form.images) {
                    //     let imgUrl = await this.$getFbFileUrl(this.form.images[key])
                    //     images.push(imgUrl)
                    //   }

                    // }
                    this.images = newValue.images;
                }
            },
        },
    },
    methods: {
        async update(data) {
            console.log("updateData", data);
            // console.log(this.order)
            let obj = {
                ...data,
            };
            if (obj.archiveDate) {
                obj.archive_date = obj.archiveDate;
                delete obj.archiveDate;
            }
            let updatedManufactory = await this.$store.dispatch(
                "manufactory/updateTask",
                {
                    id: this.data.id,
                    data: obj,
                }
            );

            this.setDefVal(updatedManufactory);
            if (this.nmbrOrder) {
                this.form.numberOrder = this.nmbrOrder;
            }

            if (this.clientOrder) {
                this.form.client = this.clientOrder;
                this.form.filterClient = {
                    name: this.clientOrder.name,
                    id: this.clientOrder.id,
                    surname: this.clientOrder.surname,
                };
            }

            // if (data.archive) {
            // 	this.programNewComment(null, null, '<div class="comment_program_text">Отправил заказ в архив</div>')
            // }
        },

        async setDefVal(data) {
            if (data && data.id) {
                let client = null;

                if (data.client.id) {
                    await this.$store.dispatch("clients/loadClient", data.client.id);
                    client = this.$store.getters["clients/getClient"];
                }

                let obj = {
                    client: await cloneDeep(client),
                    filterClient: await cloneDeep(client),
                    montagers: data.montagers ? await cloneDeep(data.montagers) : [],
                    montagersInfo: data.montagersInfo
                        ? await cloneDeep(data.montagersInfo)
                        : [],
                    comment: data.comment,
                    techTask: data.techTask,
                    date: data.date,
                    commentProd: data.commentProd ? data.commentProd : "",
                    costForProd: data.costForProd ? data.costForProd : "",
                    images: data.images ? await cloneDeep(data.images) : [],
                    numberOrder: data.numberOrder || null,
                };
                this.form = obj;
            }
        },
        async setClient() {
            await this.$store.dispatch(
                "clients/loadClient",
                this.form.filterClient.id
            );
            const client = this.$store.getters["clients/getClient"];
            this.update({ client: client });
            this.setMainClientContact();
        },
        async showClientInfo() {
            await this.$store.dispatch(
                "clients/loadClient",
                this.form.filterClient.id
            );
            const currentClient = this.$store.getters["clients/getClient"];

            console.log(currentClient);

            const contacts =
                typeof currentClient.contacts === "string"
                    ? JSON.parse(currentClient.contacts)
                    : currentClient.contacts;

            const client = await this.$showModal({
                component: Client,
                isPersistent: true,
                props: {
                    edit: true,
                    data: { ...currentClient, contacts },
                },
            });

            if (client) {
                this.form.client = { ...client.data, id: client.id };
            }
        },
        findClient(val) {
            if (val && !val.id) {
                let clients = this.filterClients;
                let client = clients.find(
                    (x) => x.name.toLowerCase() === val.toLowerCase()
                );
                if (client && client.id) {
                    this.form.filterClient = client;
                }
            }
        },
        async editDate() {
            let date = await this.$showModal({
                component: SelectDate,
                isPersistent: true,
                props: {
                    title: "Укажите дату",
                    selectDate: this.form.date.seconds
                        ? new Date(this.form.date).toISOString().substr(0, 10)
                        : this.form.date,
                },
            });

            if (date) {
                this.form.date = date;
            }
        },
        async editPerformers() {
            let performers = await this.$showModal({
                component: SelectAssistantForMaster,
                isPersistent: true,
                props: {
                    task: this.form,
                },
            });

            if (performers) {
                // await this.$store.dispatch('montages/updateMontage', {
                //   id: this.data.id,
                //   data: {
                //     ...performers
                //   }
                // })
                this.form = { ...this.form, ...performers };
            }
        },
        deleteImage(path, index) {
            this.form.images.splice(index, 1);
        },
        async uploadImage() {
            const input = document.createElement("input");
            input.type = "file";
            input.accept = "image/*";
            input.multiple = "multiple";

            const compressOptions = {
                maxSizeMB: 3,
                maxWidthOrHeight: 1520,
                useWebWorker: true,
            };

            input.onchange = async (e) => {
                const _this = this;
                var files = await e.target.files;

                for (let key in files) {
                    let file = files[key];

                    if (file && file instanceof File) {
                        loadImage.parseMetaData(file, function (data) {
                            const options = {};
                            // Get the correct orientation setting from the EXIF Data
                            if (data.exif) {
                                options.orientation = data.exif.get("Orientation");
                            }

                            // Load the image from disk and inject it into the DOM with the correct orientation
                            loadImage(
                                file,
                                function (canvas) {
                                    let trueCanvas = canvas;
                                    if (canvas instanceof HTMLImageElement) {
                                        const image = canvas;
                                        trueCanvas = document.createElement("canvas");
                                        trueCanvas.width = image.width;
                                        trueCanvas.height = image.height;
                                        trueCanvas.getContext("2d").drawImage(image, 0, 0);
                                    }
                                    trueCanvas.toBlob(async function (trueFile) {
                                        let compressedFile = await imageCompression(
                                            trueFile,
                                            compressOptions
                                        );

                                        _this.uploadingImage = true;
                                        let filePath = await api
                                            .upload("upload", compressedFile)
                                            .then((response) => {
                                                if (response.status == 200) {
                                                    _this.uploadingImage = false;
                                                    return `${fileUrl}/${response.data.url}`;
                                                }
                                            });
                                        _this.form.images.push(filePath);
                                        _this.uploadingImage = false;
                                    });
                                },
                                options
                            );
                        });
                    }
                }
            };

            this.$refs.file.appendChild(input);
            input.click();
        },
        goUrl(url) {
            window.open(url, "_blank").focus();
        },
        loadData() {
            if (this.user.role === "manager") {
                this.filterWorkTasksByManager = this.user;
            }
            this.$store.dispatch("manufactory/loadInProcessTasks");
        },
        async deleteTask() {
            const res = await this.$showModal({
                component: Confirm,
                isPersistent: true,
                props: {
                    title: `Удаление заказа`,
                    text: `Действительно хотите удалить заказ для "${this.data.client.name}"?`,
                },
            });

            if (res) {
                await this.$store.dispatch("manufactory/deleteTask", this.data);
                // this.sendSocket(['manufactory'])
                this.loadData();
                this.close();
            }
        },
        async addAssistant() {
            let select = await this.$showModal({
                component: SelectAssistantForMaster,
                isPersistent: true,
                props: {
                    task: this.form,
                },
            });
            if (select) {
                this.form = { ...this.form, ...select };
                await this.$store.dispatch("manufactory/updateTask", {
                    id: this.data.id,
                    data: select,
                });
                // this.sendSocket(['manufactory'])
            }
        },
        async save(status, action = null) {
            const errors = [];
            const validate = await this.$refs.formCreateTask.validate();
            errors.push(validate);

            if (!errors.includes(false)) {
                let form = { ...this.form };
                let date = null;
                let montagers = null;
                if (
                    ["confirmation", "confirmationProd"].includes(status) &&
                    action != "revision"
                ) {
                    date = await this.$showModal({
                        component: SelectDate,
                        isPersistent: true,
                        props: {
                            title: "Дата",
                            selectDate: form.date
                                ? form.date.seconds
                                    ? form.date
                                        .toDate()
                                        .toISOString()
                                        .substr(0, 10)
                                    : form.date
                                : null,
                        },
                    });
                } else {
                    let oldDate = form.date.seconds
                        ? form.date
                            .toDate()
                            .toISOString()
                            .substr(0, 10)
                        : form.date;
                    date = new Date(oldDate);
                }

                // client
                if (
                    (this.form.filterClient &&
                        this.data.client &&
                        this.data.client.id !== this.form.filterClient.id) ||
                    !this.data.client
                ) {
                    form.client = this.form.filterClient.id;
                } else if (form.client.id) {
                    form.client.address = form.client.address || [];
                    // if (!form.client.address.includes(form.address)) {
                    // this.$store.commit("templ/setLoading", true);
                    // await api.put("clients/update", {
                    //   id: form.client.id,
                    //   address: [...form.client.address, form.address],
                    // });
                    // await clientsCollection.doc(form.client.id).update({
                    //   address: [...form.client.address, form.address]
                    // })
                    // this.$store.commit("templ/setLoading", false);
                    // }
                    // form.client = clientsCollection.doc(form.client.id)
                    // form.client = await api
                    //   .get("clients/get", form.client.id)
                    //   .then((response) => {
                    //     if (response.status == 200) {
                    //       return response.data.data.id;
                    //     }
                    //   });
                }

                // if (form.client.id) {
                //   // form.client = clientsCollection.doc(form.client.id)
                //   form.client = await api.get('clients/get',form.client.id).then(response => {
                //     if(response.status == 200){
                //       return response.data.data
                //     }
                //   })
                // } else {
                //   // this.$store.commit('templ/setLoading', true)
                //   // let newClient = await clientsCollection.add({
                //   //   del: false,
                //   //   name: form.client,
                //   //   created: new Date(),
                //   //   address: [],
                //   //   surname: this.newClient.surname,
                //   //   phonenumber: this.newClient.phonenumber,
                //   //   path: this.newClient.path
                //   // })
                //   // this.$store.commit('templ/setLoading', false)
                //   // form.client = clientsCollection.doc(newClient.id)

                // 	let newClient =  {
                //     name: form.client,
                //     address: [],
                //     surname: this.newClient.surname,
                //     phone: this.newClient.phone,
                //     path: this.newClient.path
                //   }

                // 	let res = await this.$store.dispatch('clients/createClient', newClient)
                //   // form.client = clientsCollection.doc(res.id)
                //   form.client = res.id
                // }

                if (status === "work") {
                    let select = await this.$showModal({
                        component: SelectMasterForProd,
                        isPersistent: true,
                        props: {
                            data: this.form,
                        },
                    });
                    if (select) {
                        montagers = select;
                    }
                }

                if (
                    (status === "work" && montagers != null) ||
                    (status != undefined && status != "work" && date) ||
                    status === "ready"
                ) {
                    if (this.edit) {
                        let data = {
                            ...form,
                            ...montagers,
                            date: new Date(date),
                            status: status,
                        };
                        if (status === "ready") {
                            data = {
                                ...data,
                                readyDate: new Date(),
                            };
                            // alert(data.readyDate)
                            console.log(
                                data.readyDate,
                                typeof data.readyDate,
                                "data.readyDate"
                            );
                        }
                        if (status === "archive") {
                            data = {
                                ...data,
                                archiveDate: new Date(),
                            };
                        }

                        await this.$store.dispatch("manufactory/updateTask", {
                            id: this.data.id,
                            data: {
                                ...data,
                                action: action === "revision" ? action : null,
                            },
                            manager: this.data.manager,
                            hourPayWorkMasters: this.getHourPayWorkMasters,
                        });
                        // this.sendSocket(['manufactory'])
                    } else {
                        // console.log(form)
                        // let user = usersCollection.doc(this.user.uid)
                        let user = localStorage['user'] ? JSON.parse(localStorage['user']) : await api
                            .get("users/get", this.user.id)
                            .then((response) => {
                                if (response.status == 200) {
                                    return response.data.data;
                                }
                            });
                        console.log(form.filterClient, "-----!----");
                        await this.$store.dispatch("manufactory/createTask", {
                            ...form,
                            client: { id: form.filterClient.id },
                            montagers: "",
                            montagersInfo: "",
                            date: new Date(date),
                            status: status,
                            // manager: user,
                            manager: String(user.id),
                            hourPayWorkMasters: this.getHourPayWorkMasters,
                            created: new Date(),
                        });
                        // this.sendSocket(['manufactory'])
                    }
                    this.close("newTask");
                }

                if (form.date && form.date.length === 10) {
                    form = { ...form, date: new Date(form.date) };
                }

                if (status === undefined) {
                    form.client = { id: form.filterClient.id };
                    await this.$store.dispatch("manufactory/updateTask", {
                        id: this.data.id,
                        data: form,
                    });

                    this.close();
                    // this.sendSocket(['manufactory'])
                }
            }
        },
        close(info) {
            this.dialog = false;
            setTimeout(() => {
                this.$emit("close", info);
            }, 200);
        },
        async newClient() {
            this.form.filterClient = null;
            let clientNameRef = this.$refs.clientName.internalSearch;

            const createClient = await this.$showModal({
                component: Client,
                isPersistent: true,
                props: {
                    data: {
                        name: clientNameRef || "",
                    },
                },
            });
            if (createClient) {
                let obj = { id: createClient.id, ...createClient.data };
                this.form.client = obj;
                this.form.filterClient = {
                    name: createClient.data.name,
                    surname: createClient.data.surname,
                    id: createClient.id,
                };
                this.$store.dispatch("clients/loadFilterClients");
                this.setMainClientContact();
            }
        },
        setMainClientContact() {
            if (this.form.client && this.form.client.contacts) {
                const main = this.form.client.contacts.find((x) => x.main);
                if (main) {
                    this.form.contact = main;
                }
            }
        },
    },
};
</script>
