<template>
  <div class="page assignedMontages">
    <DateFilterAssignedMontages @updDate="updDate" />
    <SocketWatch :tables="['montages']" @loadData="loadData" />
    <div>
      <Skeleton
        v-if="
          !isMobile && !montagesLoaded && (!montages || montages.length === 0)
        "
      />
      <Skeleton
        v-if="
          !isMobile && !montagesLoaded && (!montages || montages.length === 0)
        "
      />
      <template v-if="!isMobile">
        <v-row no-gutters>
          <v-col
            v-for="(data, index_data) in montages"
            :key="index_data"
            class="pa-0 ma-0"
            cols="6"
          >
            <AssignedMontagesInBrig :data="data" />
          </v-col>
        </v-row>
      </template>
      <template v-else class="mobAssignedMontages">
        <v-tabs
          v-model="tab"
          color="rgb(224, 166, 119)"
          background-color="rgb(224, 166, 119)"
          class="mobAssignedMontages_tabs"
        >
          <v-tabs-slider></v-tabs-slider>
          <v-tab v-for="(data, index_data) in montages" :key="index_data">
            {{ data.brig.displayName }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" style="background-color: #424242">
          <v-tab-item v-for="(data, index_data) in montages" :key="index_data">
            <AssignedMontagesInBrig :data="data" />
          </v-tab-item>
        </v-tabs-items>
      </template>
    </div>
  </div>
</template>

<script>
import AssignedMontagesInBrig from "@/components/Montages/AssignedMontagesInBrig.vue";
import DateFilterAssignedMontages from "@/components/Montages/DateFilterAssignedMontages.vue";
import Skeleton from "@/components/Montages/Skeleton.vue";
import { PaginationMixin } from "@/mixins/pagination";
import { isMobile } from "mobile-device-detect";

export default {
  name: "AssignedMontages",
  mixins: [PaginationMixin],
  components: {
    DateFilterAssignedMontages,
    AssignedMontagesInBrig,
    Skeleton,
  },
  async beforeRouteLeave(to, from, next) {
    this.$store.commit("montages/resetAllFilterMontages");
    let toDay = new Date().toISOString().substr(0, 10);
    this.updDate(new Date(toDay));
    next();
  },
  data: () => ({
    isMobile: isMobile,
    skeletonTypesIframe: {
      "table-thead": "heading@4",
      "table-row": "table-cell@4",
    },
    tab: 0,
  }),
  computed: {
    montagesLoaded() {
      return this.$store.getters["montages/getAssignedMontagesLoaded"];
    },
    montages() {
      return this.$store.getters["montages/getAssignedMontages"];
    },
    filterMontagesByDate: {
      get() {
        return this.$store.getters["montages/getFilterAssignedMontagesByDate"];
      },
      set(val) {
        this.$store.commit("montages/setFilterAssignedMontagesByDate", val);
      },
    },
  },
  created() {
    // Mixins
    const _this = this;
    this.PMProps = {
      setLimit(val) {
        _this.$store.commit("montages/SET_LIMIT", val);
      },
      setOffset(val) {
        _this.$store.commit("montages/SET_OFFSET", val);
      },
      load() {
        _this.$store.dispatch("montages/loadAssignedMontages");
      },
      onInit() {
        _this.loadData();
      },
      itemsPerPage: 10000,
    };
    //

    // this.loadData();
  },
  methods: {
    loadData() {
      this.$store.dispatch("montages/loadAssignedMontages");
    },
    updDate(date) {
      this.filterMontagesByDate = date;
      this.$store.dispatch("montages/loadAssignedMontages");
    },
  },
};
</script>

<style lang="scss">
.mobAssignedMontages {
  .v-tabs-items {
    background-color: #424242 !important;
  }

  &_tabs {
    position: sticky;
    top: 56px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    z-index: 2;

    .v-slide-group__prev,
    .v-slide-group__next {
      display: none !important;
    }
  }
}
</style>