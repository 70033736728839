import MontageModal from "@/components/Modals/Montage/Index";
import OrderModal from "@/components/Modals/Order/Index";
import TaskModal from "@/components/Modals/Task/Index";
import api from "@/utils/api";

// Global vue variables

// -- Functions

// Format date to locale user
function formatDate(date) {
  if (date) {
    return date.toLocaleString("ru", { day: "numeric", month: "short" });
  }
  return null;
}

// Opening order modal by it's number
async function openOrder(number) {
  let orderData = await api
    .post("orders/get-list", {
      limit: 10,
      offset: 0,
      query: [{ input: "number", value: number }],
    })
    .then((response) => {
      if (response.status == 200) {
        return response.data.data[0] || {};
      }
    });
  await this.$showModal({
    component: OrderModal,
    isPersistent: true,
    props: {
      edit: true,
      data: await this.$store.dispatch("orders/orderSerializer", orderData),
    },
  });
}

// Opening modal from Notification item

async function openModalFromNotif(item) {
  console.log(item.payload);
  const action = item.action;
  const payload = item.payload || null;
  let modalComponent = null;
  let modalData = null;
  let customProps = {};

  if (this.$isMobile) {
    return;
  }

  switch (action) {
    case "newTask":
      if (payload && payload.taskId) {
        modalData = await this.$store.dispatch(
          "tasks/loadTaskById",
          payload.taskId
        );
        customProps = { ...customProps, design: modalData.design };
        modalComponent = TaskModal;
      }
      break;
    case "updateTask":
      if (payload && payload.taskId) {
        modalData = await this.$store.dispatch(
          "tasks/loadTaskById",
          payload.taskId
        );
        customProps = { ...customProps, design: modalData.design };
        modalComponent = TaskModal;
      }
      break;
    case "newCommentToTask":
      if (payload && payload.taskId) {
        modalData = await this.$store.dispatch(
          "tasks/loadTaskById",
          payload.taskId
        );
        customProps = { ...customProps, design: modalData.design };
        modalComponent = TaskModal;
      }
      break;
    // case 'workManufactoryTask': // отправка уведомлений Мастерам когда на них назначили
    case "newCommentToTask":

    case "readyManufactoryTask":
      if (payload && payload.taskId) {
        modalData = await this.$store.dispatch(
          "manufactory/loadTaskById",
          payload.taskId
        );
        modalComponent = ManufactoryTaskModal;
      }
    case "confirmProdManufactoryTask":
      if (payload && payload.taskId) {
        modalData = await this.$store.dispatch(
          "manufactory/loadTaskById",
          payload.taskId
        );
        modalComponent = ManufactoryTaskModal;
      }
    case "newCommentToManufactory":
      if (payload && payload.taskId) {
        modalData = await this.$store.dispatch(
          "manufactory/loadTaskById",
          payload.taskId
        );
        modalComponent = ManufactoryTaskModal;
      }
      break;
    case "newCommentToOrder":
      if (payload && payload.orderId) {
        modalData = await this.$store.dispatch(
          "orders/loadOrderById",
          payload.orderId
        );
        modalComponent = OrderModal;
      }
      break;
    case "montage":
      if (payload && payload.montageId) {
        modalData = await this.$store.dispatch(
          "montages/loadMontageById",
          payload.montageId
        );
        modalComponent = MontageModal;
      }
      break;
  }

  if (modalComponent && modalData) {
    await this.$showModal({
      component: modalComponent,
      isPersistent: true,
      props: {
        edit: true,
        data: modalData,
        ...customProps,
      },
    });
  }
}

// Formating file url - substitutes baseUrl if missing
function formatFileUrl(url) {
  console.log(url);
  const Url = new URL(url, "https://fake-url.ru");

  if (Url.hostname === "fake-url.ru") {
    // relative link
    return process.env.VUE_APP_FILE_URL + Url.pathname;
  }
  // absolute link(legacy);
  return url;
}

// Formatting datetime to local
function formatDateTime(date) {
  if (date) {
    return date.toLocaleString("ru", {
      day: "numeric",
      month: "short",
      hour: "numeric",
      minute: "numeric",
    });
  }
  return null;
}

// Returns diff in days
export function fDiffDays(date1, date2) {
  let diffTime = Math.abs(date2 - date1);
  let diffDay = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  if (date2.getTime() < date1.getTime()) {
    return -diffDay;
  }
  return diffDay;
}

// Returns css color rule according to deadline and current date
function colorDeadline(date) {
  let diffDays = fDiffDays(new Date(), date);
  if (diffDays <= 0) {
    // Deadline is today or overdued
    return "color: red;";
  }
  if (diffDays <= 3) {
    return "color: #9a5b0d;";
  }
  return "";
}

//  Returns formatted tasks-about string(IUser tasks)
function getStrTasks(tasks) {
  if (tasks && tasks.length > 0) {
    let strTasks = "";
    for (let taskKey in tasks) {
      strTasks = `${strTasks} ${Number(taskKey) + 1}.${tasks[taskKey].value}. `;
    }
    return strTasks;
  }
  return "Нет задач";
}

// Slicing string by count, adding '...' if not fit
function sliceStr(str, count) {
  var sliced = str.slice(0, count);
  if (sliced.length < str.length) {
    sliced += "...";
  }
  return sliced;
}

// Returns word accordingly to number(word declensions)
function numWord(value, words) {
  value = Math.abs(value) % 100;
  var num = value % 10;
  if (value > 10 && value < 20) return words[2];
  if (num > 1 && num < 5) return words[1];
  if (num == 1) return words[0];
  return words[2];
}

// Calculate master's salary per Hour-rate-task
function ifHourPayWorkMasters(task, user) {
  let date = new Date(task.date);
  let dayWeek = date.getDay();
  let dayOff = dayWeek === 0 || dayWeek === 6;
  let rateOnMontage =
    task.rateOnMontage !== undefined
      ? Number(task.rateOnMontage || 0)
      : Number(user.rateOnMontage || 0);
  let rateOnManufactory =
    task.rateOnManufactory !== undefined
      ? Number(task.rateOnManufactory || 0)
      : Number(user.rateOnManufactory || 0);
  let rateOverwork =
    task.rateOverwork !== undefined
      ? Number(task.rateOverwork || 0)
      : Number(user.rateOverwork || 0);

  let obj = {
    numberOfHoursOnMontage: Number(task.numberOfHoursOnMontage || 0),
    numberOfHoursOnManufactory: Number(task.numberOfHoursOnManufactory || 0),
    allHour: 0,
    overHour: 0,
    cost: 0,
  };

  obj.allHour = obj.numberOfHoursOnMontage + obj.numberOfHoursOnManufactory;
  if (dayOff) {
    obj.overHour = obj.allHour;
  } else {
    obj.overHour = obj.allHour > 9 ? obj.allHour - 9 : 0;
  }

  if (obj.overHour > 0) {
    obj.cost = obj.cost + rateOverwork * obj.overHour;
  }

  if (!dayOff && obj.numberOfHoursOnMontage > 0) {
    let numberOfHoursOnMontage = obj.numberOfHoursOnMontage;
    if (obj.numberOfHoursOnManufactory < obj.overHour) {
      numberOfHoursOnMontage = numberOfHoursOnMontage - obj.overHour;
    }
    obj.cost = obj.cost + rateOnMontage * numberOfHoursOnMontage;
  }

  if (!dayOff && obj.numberOfHoursOnManufactory > 0) {
    let numberOfHoursOnManufactory = obj.numberOfHoursOnManufactory;
    if (obj.overHour > 0 && obj.numberOfHoursOnManufactory > obj.overHour) {
      numberOfHoursOnManufactory = numberOfHoursOnManufactory - obj.overHour;
    }
    obj.cost = obj.cost + rateOnManufactory * numberOfHoursOnManufactory;
  }

  return obj;
}

// Check if string URL
function isURL(str) {
  let url;
  try {
    url = new URL(str);
  } catch (_) {
    return false;
  }
  let isHttp = url.protocol === "http:" || url.protocol === "https:";
  let noSpace = !str.includes(" ");
  return noSpace && isHttp;
}

// Objects

// Validate inputs
const fieldRules = {
  field: [(v) => v === 0 || !!v || "Надо что то написать"],
  cost: [(v) => v === 0 || !!v || "Укажите"],
  select: [(v) => !v || v.length > 0 || "Надо выбрать"],
  date: [(v) => v === 0 || !!v || "Надо указать"],
  phonenumber: [(v) => (!!v && v.length === 17) || "Номер не коректен"],
  email: [
    (v) => !!v || "E-mail обязателен",
    (v) => /.+@.+\..+/.test(v) || "E-mail не валиден",
  ],
  isUrl: [(v) => v.length === 0 || isURL(v) || "URL не валидный"],
};

export {
  formatDate,
  openOrder,
  openModalFromNotif,
  formatFileUrl,
  formatDateTime,
  colorDeadline,
  getStrTasks,
  sliceStr,
  numWord,
  ifHourPayWorkMasters,

  // objects
  fieldRules,
};
