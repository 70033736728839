<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" :fullscreen="isMobile" :max-width="!isMobile ? '400px' : ''"
            transition="dialog-bottom-transition" :scrollable="!isMobile" :persistent="!isMobile">
            <v-card :tile="isMobile">
                <v-toolbar v-if="isMobile" color="rgb(224, 166, 119)" tile style="position: sticky; top: 0px; z-index: 20;">
                    <v-toolbar-title v-if="type === 'plus'" class="white--text">{{
                        data.name
                    }}</v-toolbar-title>
                    <v-toolbar-title v-else class="white--text">{{ data.name }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="close()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-title v-else>
                    <span v-if="type === 'plus'" class="headline">{{ data.name }}</span>
                    <span v-else class="headline">{{ data.name }}</span>
                    <v-spacer />
                    <v-btn icon color="rgb(224, 166, 119)" @click="close()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-container :class="{ 'pa-0': isMobile }">
                        <v-form ref="formCategory">
                            <v-row>
                                <v-col cols="12" v-if="type === 'minus'">
                                    Кому: <my-mini-avatar v-if="form.whomid" :user="users[form.whomid]" />
                                    <v-menu bottom left :close-on-content-click="false" content-class="scrollable-menu">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon small v-bind="attrs" v-on="on">
                                                <v-icon small>mdi-pencil</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item v-for="(item, index_item) in users" :key="index_item" dense>
                                                <v-list-item-avatar class="my-0">
                                                    <my-mini-avatar :user="item" />
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-title v-text="item.displayName" />
                                                </v-list-item-content>
                                                <v-list-item-action>
                                                    <v-checkbox color="rgb(224, 166, 119)"
                                                        :input-value="form.whomid === item.id"
                                                        @change="form.whomid = item.id" />
                                                </v-list-item-action>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </v-col>
                                <v-col cols="6" class="py-0">
                                    <v-text-field color="rgb(224, 166, 119)" v-model.number="form.count" label="Количество*"
                                        type="number" step="1" :prefix="type === 'plus' ? '+' : '-'"
                                        :rules="[...$fieldRules.field, rulesCount]" required :suffix="data.unit" />
                                </v-col>
                                <v-col cols="6" class="py-0">
                                    <v-autocomplete :items="orders" item-value="number" item-text="number"
                                        color="rgb(224, 166, 119)" v-model="form.orderNumber" label="Номер заказа"
                                        type="number" step="1" :disabled="type === 'plus' || form.internal">
                                        <template v-slot:item="data">
                                            <v-list-item-content>
                                                <v-list-item-title v-html="data.item.client_data.name"></v-list-item-title>
                                                <v-list-item-subtitle v-html="data.item.number"></v-list-item-subtitle>
                                            </v-list-item-content>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" class="py-0">
                                    <v-checkbox :disabled="type === 'plus'" label="Для внутреннего использования"
                                        v-model="form.internal" />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" class="py-0">
                                    <v-text-field color="rgb(224, 166, 119)" v-model="form.comment" label="Комментарии" />
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-card-text>Всего: {{ data.count }}</v-card-text>
                    <v-spacer></v-spacer>
                    <v-btn color="orange" text @click="save()">
                        <span v-if="type === 'plus'">Добавить</span>
                        <span v-if="type === 'minus'">Списать</span>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
import { mapState, mapActions } from 'vuex';
export default {
    props: {
        who_id: Number,
        type: {
            type: String,
            default: 'plus',
        },
        data: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    data: () => ({
        isMobile: isMobile,
        dialog: true,
        errors: {},
        form: {
            count: 0,
            comment: '',
            whomid: 0,
            orderNumber: '',
            internal: false,
        },
    }),
    computed: {
        ...mapState('warehouse', ['orders']),
        users() {
            return this.$store.getters['users/getUsersObj'];
        },
    },
    created() {
        this.loadOrders();
    },
    methods: {
        ...mapActions('warehouse', ['loadOrders']),

        rulesCount(v) {
            if (this.type === 'minus') {
                return !(v > this.data.count) || 'Больше';
            }
        },
        async save() {
            const errors = [];
            const validate = await this.$refs.formCategory.validate();
            errors.push(validate);

            if (!errors.includes(false)) {
                this.$emit('close', {
                    ...this.form,
                    type: this.type,
                    id: this.data.id,
                    who_id: this.who_id,
                    name: this.data.name,
                    unit: `${this.data.unit}----${this.data.name}`,
                });
            }
        },
        close() {
            this.dialog = false;
            setTimeout(() => {
                this.$emit('close');
            }, 200);
        },
    },
};
</script>

<style>
.scrollable-menu {
    max-height: calc(100vh - 12px);
}
</style>
