<template>
  <div class="iframe iframe_full iframeUnassignedMontages pa-1">
		<SocketWatch  :tables="['orders']" @loadData="loadData" />
    <v-card>
       <v-card-title>
				<v-icon class="mr-2">mdi-clipboard-multiple-outline</v-icon>
        Мои заказы
        <v-spacer />
				<v-data-footer
					v-if="options && pagination"
					:options.sync="options"
					:pagination="pagination"
					items-per-page-text=""
					:items-per-page-options="[5, 10, 15, 20, 30, 40]"
					style="width: 310px; padding: 0;"
					class="rl-data-footer"
				/>
        <v-btn icon small @click="createOrder()">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <div 
        v-if="ordersLoaded && (!orders || orders.length === 0)"
        class="infoNoItems"
      >
        Нет заказов
      </div>
			<v-data-table
				v-if="orders && orders.length > 0"
				:headers="tableHeadres"
				:items="orders"
				:items-per-page="15"
 				:dense="!isMobile"
				:options="options"
				@update:options="options = $event"
				@pagination="pagination = $event"
				hide-default-footer
			>
				<template v-slot:item="{ item }">
					<tr 
						:style="
							item.color && !disabledColorBtn(item) ?
								 `background-color: ${item.color}` : ''"
						@click="editOrder(item)"
					>
						<td>
							<span v-if="item.client">
								{{ item.client.name }}
								<!-- <v-menu bottom right :close-on-content-click="false">
									<template v-slot:activator="{ on, attrs }">
										<v-btn icon x-small v-bind="attrs" v-on="on" style="cursor: context-menu;">
											<v-icon>mdi-information-outline</v-icon>
										</v-btn>
									</template>
									<my-info-client :id="item.client.id" />
								</v-menu> -->
							</span>
							<span v-else>Не указан</span>
						</td>
						<td>
							{{ item.number }}
						</td>
						<td>
							{{ orderStatus[item.status] }}
						</td>
						<td>
							<span
								:style="
									`${$colorDeadline(new Date(item.deadline))}`"
							>
								{{ $formatDateTime(new Date(item.deadline)) }}
							</span>
						</td>
						<td>
							<span v-if="item.description">{{ $sliceStr(item.description, 60) }}</span>
							<span v-else>Не задано</span>
						</td>
						<td class="text-center">
							<v-menu left>
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										v-bind="attrs"
										v-on="on"
										icon
										:disabled="disabledColorBtn(item)"
									>
										<v-icon>mdi-format-color-fill</v-icon>
									</v-btn>
								</template>

								<div style="background-color: white;">
									<div class="pt-2 px-5">
										<v-btn 
											v-if="item.color"
											:color="item.color || null"
											block
											@click="setColorItem('#FFF', item)"
										>
											Сбросить цвет
										</v-btn>
									</div>
									<v-color-picker
										@input="setColorItem($event, item)"
										:value="item.color || null"
										hide-canvas
										hide-inputs
										show-swatches
										swatches-max-height="230"
										mode="hexa"
										class="rl-table-color-picker"
									/>
								</div>
							</v-menu>
						</td>
					</tr>
				</template>
			</v-data-table>
    </v-card>
  </div>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
import OrderModal from  '@/components/Modals/Order/Index'
import { orderStatus } from '@/helpers'

export default {
  name: 'MyOrders',
  data: () => ({
    isMobile: isMobile,
    skeletonTypesIframe: {
      'table-thead': 'heading@4',
      'table-row': 'table-cell@4'
    },
		orderStatus: orderStatus,
		options: null,
		pagination: null,
  }),
  created() {
    this.loadData()
  },
  computed: {
		tableHeadres() {
			return [
				{
					text: 'Клиент',
					align: 'start',
					sortable: false,
					value: 'client'
				},
				{
					text: 'Номер',
					align: 'start',
					sortable: false,
					value: 'number'
				},
				{
					text: 'Статус',
					align: 'start',
					sortable: false,
					value: 'status'
				},
				{
					text: 'Дедлайн',
					align: 'start',
					sortable: false,
					value: 'deadline'
				},
				{
					text: 'Описание',
					align: 'start',
					sortable: false,
					value: 'description'
				},
				{
					text: 'Цвет',
					align: 'center',
					sortable: false,
					value: 'color'
				}
			]
		},
		currentUser() {
			return this.$store.getters['user/getUser']
		},
    ordersLoaded() {
      // return this.$store.getters['montages/getManagerMontagesLoaded']
      return true
    },
    orders() {
      return this.$store.getters['orders/getMyOrders']
    }
  },
  methods: {
		loadData() {
			this.$store.dispatch('orders/loadMyOrders')
		},
		async setColorItem(color, item) {
			if (color === '#FF0000FF' || (color && color.hexa === '#FF0000FF')) {
				color = null
			}
			await this.$store.dispatch('orders/updateOrder', {
				id: item.id,
				data: { color: color && color.hexa ? color.hexa : color }
			})
		},
		disabledColorBtn(item) {
			let itemCreatorId = item.creator.uid || item.creator.id || item.creator_id
			if (itemCreatorId != this.currentUser.id) {
				return true
			}
			return false
		},
    async createOrder() {
			let order = await this.$store.dispatch('orders/createOrder',{name: 'MyOrders'})
			this.editOrder(order)
    },
    async editOrder(order) {
			// console.log(order)
			// console.log(this.currentUser)
      await this.$showModal({
        component: OrderModal,
        isPersistent: true,
        props: {
          edit: true,
          data: order
        }
      })
    }
  }
}
</script>
