import { arrayBufferToBase64 } from "@/utils/helpers/file";
import axios from "axios";
const serverURL = process.env.VUE_APP_SERVICE_URL;
const prodServerURL = process.env.VUE_APP_SERVICE_URL_PROD;

export async function forceDownload(urll) {
  const url = urll.replace("get-file", "down-file");
  // Yandex disc returns response data as binary

  let base64;
  let blob;
  let link;
  console.log(url, "urrl");
  if (url.includes(serverURL) || url.includes(prodServerURL)) {
    // Not yandex
    const { data } = await axios.get(url);
    base64 = await fetch(`data:image/jpeg;base64,${data.data}`);
    blob = await base64.blob();
    link = window.URL.createObjectURL(blob);
    let anchor = document.createElement("a");
    anchor.href = link;
    anchor.download = url.split("down-file/")[1];
    anchor.click();
  } else {
    // Yandex
    //**Binary to dataURL**
    const URLO = new URL(urll);
    const fileName = URLO.searchParams.get("filename");

    const { data } = await axios.get(url, { responseType: "arraybuffer" });
    let anchor = document.createElement("a");
    const dataUrl = `data:image/jpeg;base64,` + arrayBufferToBase64(data);
    console.log(data);
    anchor.href = dataUrl;
    console.log(dataUrl, "url");
    console.log(fileName);
    anchor.download = fileName;
    console.log(anchor.download, "downlaod");
    anchor.click();
  }
}
