import { VImg } from 'vuetify/lib/components/VImg';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{class:_vm.imageViewClass},[_c('div',{staticClass:"image-view__body",class:{
            'image-view__body--focused': _vm.selected,
            'image-view__body--resizing': _vm.resizing,
        }},[_c('div',{staticClass:"pa-3 d-flex justify-center align-center",style:(`
					width: ${_vm.width}px;
                                                                                                                                                                                                                                                                          					height: ${_vm.height}px;
					min-width: 230px;
					min-height: 170px;
				`),on:{"click":function($event){_vm.isMobile ? _vm.showImageMob(_vm.srcUrl) : _vm.showImage(_vm.srcUrl)}}},[(_vm.srcUrl)?_c(VImg,{staticClass:"image-view__body__image",attrs:{"src":_vm.$formatFileUrl(_vm.srcUrl),"title":_vm.node.attrs.title,"alt":_vm.node.attrs.alt,"aspect-ratio":_vm.width / _vm.height,"contain":_vm.editableMode,"width":_vm.width - 15,"height":_vm.height - 15},on:{"click":_vm.selectImage}}):_c('div',{staticClass:"d-flex justify-center align-center",style:(`
						width: 100%;
						height: 100%;
						border: 1px solid grey;
						border-radius: 5px;
					`)},[_c('span',[_vm._v("Загрузка...")])])],1),(_vm.view.editable)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selected || _vm.resizing),expression:"selected || resizing"}],staticClass:"image-resizer"},_vm._l((_vm.resizeDirections),function(direction){return _c('span',{key:direction,staticClass:"image-resizer__handler",class:`image-resizer__handler--${direction}`,on:{"mousedown":function($event){return _vm.onMouseDown($event, direction)}}})}),0):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }