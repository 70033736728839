// Montages
import ViewMontages from '@/views/montages/ViewMontages.vue'
import UnassignedMontages from '@/components/Montages/Pages/UnassignedMontages.vue'
import AssignedMontages from '@/components/Montages/Pages/AssignedMontages.vue'
import Montages from '@/components/Montages/Pages/Montages.vue'

const prodDirRoutes = [
  {
    path: '/montages',
    component: ViewMontages,
    meta: {
      navDrawer: true,
      text: 'Монтажи',
      icon: 'mdi-truck-minus',
      tabs: [
        { path: '/montages', text: 'Не назначенные' },
        { path: '/montages/assigned', text: 'Назначенные' },
        { path: '/montages/all', text: 'Все монтажи' }
      ]
    },
    children: [
      { path: '', name: 'UnassignedMontages', component: UnassignedMontages },
      { path: 'assigned', component: AssignedMontages },
      { path: 'all', component: Montages },
    ]
  },
]

export default prodDirRoutes
