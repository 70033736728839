import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,{attrs:{"justify":"center"}},[_c(VDialog,{attrs:{"fullscreen":"","transition":"dialog-bottom-transition"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{attrs:{"tile":_vm.isMobile}},[_c(VCardText,[_c(VContainer,{staticClass:"pa-0"},[_c(VForm,{ref:"formCategory"},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('TextEditor',{model:{value:(_vm.form.material),callback:function ($$v) {_vm.$set(_vm.form, "material", $$v)},expression:"form.material"}})],1)],1)],1)],1)],1),_c(VCardActions,[_c(VContainer,{staticClass:"pa-0 d-flex"},[_c(VSpacer),_c(VBtn,{attrs:{"color":"grey","text":""},on:{"click":function($event){return _vm.close()}}},[_c('span',[_vm._v("Отмена")])]),_c(VBtn,{attrs:{"color":"orange","text":""},on:{"click":function($event){return _vm.save()}}},[_c('span',[_vm._v("Сохранить")])])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }