import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"px-5 pb-1 companyActiv"},[_c(VRow,[_c(VCol,{staticClass:"pt-0 pb-0",attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"dense":"","color":"#e0a677","label":"Вид деятельности..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VCol,{staticClass:"py-0 d-flex",attrs:{"cols":"12"}},[_c(VTextField,{staticClass:"mr-2",attrs:{"dense":"","color":"#e0a677","label":"Занимается..."},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;_vm.edit ? _vm.saveEdit() : _vm.addItem()}},model:{value:(_vm.itemField),callback:function ($$v) {_vm.itemField=$$v},expression:"itemField"}}),(_vm.edit)?_c(VBtn,{attrs:{"disabled":!_vm.itemField,"icon":"","small":""},on:{"click":function($event){return _vm.saveEdit()}}},[_c(VIcon,{attrs:{"size":"18"}},[_vm._v("mdi-check")])],1):_c(VBtn,{attrs:{"disabled":!_vm.itemField,"icon":"","small":""},on:{"click":function($event){return _vm.addItem()}}},[_c(VIcon,{attrs:{"size":"22"}},[_vm._v("mdi-keyboard-return")])],1)],1),_c(VCol,{staticClass:"py-0",attrs:{"cols":"12"}},[_c(VDataTable,{attrs:{"dense":"","headers":_vm.tableHeadres,"items":_vm.items,"items-per-page":5,"hide-default-header":"","footer-props":{
					'items-per-page-text': 'По:',
					'show-current-page': true
				}},scopedSlots:_vm._u([{key:`item.action`,fn:function({ item }){return [_c('div',{staticClass:"d-flex justify-end"},[_c(VBtn,{attrs:{"icon":"","small":"","color":"#bf4037"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c(VIcon,{attrs:{"size":"18"}},[_vm._v("mdi-trash-can-outline")])],1),_c(VBtn,{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_c(VIcon,{attrs:{"size":"18"}},[_vm._v("mdi-pencil")])],1)],1)]}}],null,true)})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }