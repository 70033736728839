<template>
    <v-row>
        <v-col v-if="responseTo" cols="12" class="d-flex align-center pb-0">
            Ответ пользователю {{ responseTo.fromData.displayName }}:
            <span class="ml-2">
                <v-btn icon small @click="resetResponseTo()">
                    <v-icon size="20" color="red">
                        mdi-close
                    </v-icon>
                </v-btn>
            </span>
        </v-col>
        <v-col cols="12" class="d-flex align-center pb-0">
            <my-mini-avatar :user="user" />
            <v-textarea v-model="commentField" dense color="rgb(224, 166, 119)" class="ml-3 pr-4"
                placeholder="Напишите комментарий..." append-icon="mdi-attachment" append-outer-icon="mdi-keyboard-return"
                @keyup.enter.exact="newComment" @keyup.enter.alt="commentField += '\n'"
                @click:append="() => uploadImage(null, itemNumber)" @click:append-outer="newComment"
                :loading="uploadingImage" auto-grow rows="1" row-height="1" />
        </v-col>
        <div ref="file" style="display: none"></div>
        <v-col cols="12" class="py-0">
            <v-container fluid class="pa-0">
                <v-row>
                    <v-col v-for="(image, index_image) in filesCommentField" :key="index_image" class="d-flex child-flex"
                        cols="3">
                        <my-preview-image :images="filesCommentField" :fb-path="image" @delete="deleteImage" />
                    </v-col>
                </v-row>
            </v-container>
        </v-col>
    </v-row>
</template>

<script>
import api from "@/utils/api/api";
import { mapActions } from "vuex";
const fileUrl = process.env.VUE_APP_FILE_URL;
// Mixins
import { UploadImageMixin } from "@/mixins/uploadImage";
import {
    sendNotifNewCommentManufactory,
    sendNotifNewCommentMontage,
    sendNotifNewCommentOrder,
    sendNotifNewCommentTask,
} from "./methods";
//

export default {
    mixins: [UploadImageMixin],
    props: ["item", "item_type", "responseTo", "resetResponseTo"],
    data: () => ({
        commentField: "",
        filesCommentField: [],
        uploadingImage: false,
    }),
    created() {
        // Mixins
        const _this = this;
        this.UIMprops = {
            multiple: true,
            files: true,
            compressedFile: true,
            uploadingImage: true,
            onUpload: (filePath) => {
                _this.filesCommentField.push({
                    uploading: true,
                    filePath: filePath,
                });
                let fileIndex = _this.filesCommentField.findIndex(
                    (x) => x.uploading && x.filePath === filePath
                );
                if (fileIndex != -1) {
                    _this.$set(_this.filesCommentField, fileIndex, filePath);
                }
            },
        };
    },
    watch: {
        async commentField(v) {
            console.log(v);
        },
    },
    methods: {
        ...mapActions("notify", ["notify"]),
        async deleteImage(path) {
            let fileIndex = this.filesCommentField.findIndex((x) => x === path);
            if (fileIndex != -1) {
                this.filesCommentField.splice(fileIndex, 1);
            }
        },
        async newComment() {
            let obj = {
                text: this.commentField,
                files: String(this.filesCommentField),
                date: new Date(),
                from_id: this.user.id,
                program: false,
                response_to: null
            };

            if (this.responseTo) {
                obj.response_to = this.responseTo.id;
            }

            switch (this.item_type) {
                case "order":
                    obj["order_id"] = this.item.id;
                    break;
                case "manufactory":
                    obj["manufacturer_id"] = this.item.id;
                    break;
                case "montage":
                    obj["montage_id"] = this.item.id;
                    break;
                case "task":
                    obj["tasks_id"] = this.item.id;
                    break;
                default:
                    console.log(
                        "Illegal item_type in NewComment component:",
                        this.item_type
                    );
            }

            await api.post("comments/create", obj).then((response) => {
                if (response.status == 200 && response.data.status) {
                    let comment = response.data.comments;
                    comment.files = comment.files ? comment.files.split(",") : [];
                    this.$store.commit("comments/ADD_COMMENT", comment);
                    this.$emit("load", comment);
                    this.sendNotif(this.commentField);
                }
            });
            this.commentField = "";
            this.filesCommentField = [];

            this.resetResponseTo();
        },

        sendNotif(comment) {
            switch (this.item_type) {
                case "order":
                    return sendNotifNewCommentOrder(this, comment);
                case "manufactory":
                    return sendNotifNewCommentManufactory(this, comment);
                case "montage":
                    return sendNotifNewCommentMontage(this, comment);
                case "task":
                    return sendNotifNewCommentTask(this, comment);
                default:
                    console.log(
                        "Illegal item_type in NewComment component:",
                        this.item_type
                    );
            }
        },
    },
    computed: {
        itemNumber() {
            let number;

            switch (this.item_type) {
                case "order":
                    return this.item.number;
                case "manufactory":
                case "montage":
                case "task":
                    return this.item.numberOrder;
                default:
                    console.log(
                        "Illegal item_type in NewComment component:",
                        this.item_type
                    );
            }
        },
        user() {
            return this.$store.getters["user/getUser"];
        },
    },
};
</script>
