<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" scrollable width="290" persistent>
            <!-- <pre>{{ data }}</pre> -->
            <v-card class="selectPerformers" width="290">
                <v-card-title>
                    <span>Выберите исполнителей</span>
                </v-card-title>
                <v-card-text style="max-height: 300px;">
                    <v-radio-group :error-messages="errorBrig" label="Бригады:" class="selectPerformers_radio ma-0 pa-0"
                        column>
                        <template v-for="(brigItem, index_brig) in brigs">
                            <div class="mb-2" :key="index_brig">
                                <v-checkbox v-model="brig" @change="changeBrig(brigItem)" color="rgb(224, 166, 119)"
                                    :label="brigItem.displayName" :value="brigItem.id" class="pa-0 ma-0"></v-checkbox>
                                <!-- <v-text-field
                    v-if="brig === brigItem.id && !getHourPayWorkMasters"
                    :key="`cost_${index_brig}`"
                    v-model="priceForBrig"
                    :placeholder="`Стоимость для ${brigItem.displayName}`"
                    @change="changePriceBrig(brigItem, priceForBrig)"
                    type="number"
                    suffix="р."
                    class="pa-0"
                    color="rgb(224, 166, 119)"
                  /> -->
                            </div>
                        </template>
                    </v-radio-group>

                    <v-radio-group :key="updateKey" column label="Монтажники:"
                        class="selectPerformers_radio ma-0 mt-4 pa-0">
                        <template v-for="(montager, index_montager) in users">
                            <div class="mb-2" :key="index_montager">
                                <v-checkbox v-model="montagers" @change="changeMontagers(montager)"
                                    :key="`check_${index_montager}`" color="rgb(224, 166, 119)"
                                    :label="`${montager.displayName}${montager.brig ? `(${montager.brig.displayName})` : ''}`"
                                    :value="montager.id" class="pa-0 ma-0" />
                                <!-- :disabled="montager.brig && montager.brig.id === brig" -->
                                <!-- <v-text-field v-if="montagers.includes(montager.id) && !getHourPayWorkMasters"
                                    :key="`cost_${index_montager}`" v-model="montagersInfo[montager.id].costMontage"
                                    :placeholder="`Стоимость для ${montager.displayName}`" type="number" suffix="р."
                                    class="pa-0" color="rgb(224, 166, 119)" /> -->
                            </div>
                        </template>
                    </v-radio-group>
                </v-card-text>

                <v-card-actions>
                    <v-btn color="orange" text @click="close()">Отмена</v-btn>
                    <v-spacer />
                    <v-btn color="blue" text @click="go()">Продолжить</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { brigsCollection } from '@/plugins/firebase'
import { RoleText } from '@/helpers'
import api from '@/utils/api'
var cloneDeep = require('lodash.clonedeep');

export default {
    props: {
        data: {
            type: Object,
            default: null
        },
    },
    data: () => ({
        roleText: RoleText,
        dialog: true,
        brig: null,
        montagers: [],
        montagersInfo: {},
        priceForBrig: 0,
        updateKey: 0,
        errorBrig: []
    }),
    created() {
        if (this.data) {
            console.log(this.data, 'this.data')
            if (this.data.montagers) this.montagers = cloneDeep(this.data.montagers.map(el => Number(el)))
            this.montagersInfo = cloneDeep(this.data.montagersInfo)
            if (this.data.brig) this.brig = this.data.brig
            this.priceForBrig = this.data.priceForBrig
        }
    },
    computed: {
        users() {
            return this.$store.getters['users/getWorkers']
        },
        brigs() {
            return this.$store.getters['brigs/getBrigs']
        },
        getHourPayWorkMasters() {
            return this.$store.getters['settings/getHourPayWorkMasters']
        }
    },
    methods: {
        changePriceBrig(brig, price) {
            if (brig.users) {
                let priceForMontager = price / brig.users_data.length
                console.log('brig', brig)
                console.log('montagersInfo', this.montagersInfo)
                brig.users_data.forEach(user => {
                    this.montagersInfo[user.id].costMontage = priceForMontager
                })
                this.updateKey = this.updateKey + 1
            }
        },
        changeBrig(brig) {
            if (this.brig === brig.id) {
                this.errorBrig = []
                if (brig.users) {
                    console.log('brig', brig)
                    brig.users_data.forEach(user => {
                        if (!this.montagers.includes(user.id)) {
                            this.montagers.push(user.id)
                        }
                        if (this.montagersInfo[user.id]) {
                            this.montagersInfo[user.id] = { ...this.montagersInfo[user.id], name: user.displayName, costMontage: 0 }
                        } else {
                            this.montagersInfo[user.id] = { costMontage: 0, name: user.displayName }
                        }
                    })
                }
            } else {
                if (brig.users) {
                    this.priceForBrig = 0
                    brig.users_data.forEach(user => {
                        console.log('brig', brig)
                        console.log('montagersInfo', this.montagersInfo)
                        if (this.montagers.includes(user.id)) {
                            let index = this.montagers.findIndex(x => x === user.id)
                            if (index != -1) {
                                this.montagers.splice(index, 1)
                            }
                        }
                        if (this.montagersInfo[user.id]) {
                            delete this.montagersInfo[user.id]
                        }
                    })
                }
            }
        },
        changeMontagers(montager) {
            let id = montager.id
            let name = montager.displayName
            if (!this.montagers.includes(id) && this.montagersInfo[id]) {
                delete this.montagersInfo[id]
            } else if (this.montagers.includes(id)) {
                if (this.montagersInfo[id]) {
                    this.montagersInfo[id] = { ...this.montagersInfo[id], name: name, costMontage: 0 }
                } else {
                    this.montagersInfo[id] = { name: name, costMontage: 0 }
                }
            }
        },
        async go() {
            setTimeout(async () => {
                let brig = null
                if (this.brig) {
                    // brig = brigsCollection.doc(this.brig)
                    brig = await api.get('brigs/get', this.brig).then(response => {
                        if (response.status == 200) {
                            return response.data.data
                        }
                    })
                    this.$emit('close', {
                        brig: brig,
                        priceForBrig: this.priceForBrig,
                        montagers: this.montagers,
                        montagersInfo: this.montagersInfo,
                        hourPayWorkMasters: this.getHourPayWorkMasters
                    })
                    this.dialog = false
                } else {
                    this.errorBrig = 'Выберите бригаду'
                }
            }, 200)
        },
        close() {
            setTimeout(() => {
                this.$emit('close')
                this.dialog = false
            }, 200)
        }
    }
}
</script>

<style lang="scss">
.selectPerformers {
    &_radio {
        .v-input--checkbox {
            .v-messages {
                display: none;
            }
        }

        .v-input__slot {
            margin: 0;
        }

        .v-text-field__details {
            display: none;
        }
    }
}
</style>