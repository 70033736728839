<template>
  <div class="pageAllMontages" :class="{ 'pa-3': !isMobile }">
    <SocketWatch  :tables="['orders']" @loadData="loadData" />
    <v-card :tile="isMobile">
      <div class="d-flex align-center px-4">
        <v-row class="page_filterTable mt-3">
          <v-col cols="2">
            <v-text-field
              dense
              color="rgb(224, 166, 119)"
              v-model="filterOrdersByMonthText"
              @click="selectMonth()"
							@change="$store.dispatch('orders/loadArchiveOrders')"
              label="Месяц"
							clearable
            />
          </v-col>
					<v-col cols="2">
            <v-text-field
              dense
              color="rgb(224, 166, 119)"
              v-model="filterOrdersByNumberOrder"
							@change="$store.dispatch('orders/loadArchiveOrders')"
              label="Номер заказа"
            />
          </v-col>
          <v-col cols="3">
            <v-autocomplete
              dense
              color="rgb(224, 166, 119)"
              v-model="filterOrdersByClient"
              @change="$store.dispatch('orders/loadArchiveOrders')"
              label="Клиент"
              :items="filterClients"
              item-text="name"
              item-value="id"
              return-object
              clearable
            />
          </v-col>
          <v-col cols="2">
            <v-autocomplete
              dense
              color="rgb(224, 166, 119)"
              v-model="filterOrdersByStatus"
              @change="$store.dispatch('orders/loadArchiveOrders')"
              label="Статус"
              :items="archiveOrderStatusArr"
              clearable
            />
          </v-col>
          <v-col v-if="currentUser && currentUser.role != 'manager'" cols="3">
            <v-autocomplete
              dense
              color="rgb(224, 166, 119)"
              v-model="filterOrdersByManager"
              @change="$store.dispatch('orders/loadArchiveOrders')"
              label="Менеджер"
              :items="managers"
              item-text="displayName"
              return-object
              clearable
            />
          </v-col>
        </v-row>
				<v-spacer />
				<v-btn icon small @click="createOrder()">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </div>
      <v-divider />
      <v-skeleton-loader
        v-if="!ordersLoaded && (!orders || orders.length === 0)"
        class="pl-3 pr-3"
        type="table-thead, table-row-divider@3"
        :types="skeletonTypesIframe"
      />
      <div 
        v-if="ordersLoaded && (!orders || orders.length === 0)"
        class="pa-3 d-flex justify-center"
      >
        Нет заказов
      </div>
			<v-data-table
				v-if="orders && orders.length > 0"
				:footer-props="{
					'items-per-page-options': [5, 10, 15, 20, 30, 40]
				}"
				:headers="tableHeadres"
				:items="orders"
				:items-per-page="30"
 				:dense="!isMobile"
				@click:row="editOrder($event)"
			>
				<template v-slot:[`item.client`]="{ item }">
					<span v-if="item.client">
						{{ item.client.name }}
						<!-- <v-menu bottom right :close-on-content-click="false">
							<template v-slot:activator="{ on, attrs }">
								<v-btn icon x-small v-bind="attrs" v-on="on" style="cursor: context-menu;">
									<v-icon>mdi-information-outline</v-icon>
								</v-btn>
							</template>
							<my-info-client :id="item.client.id" />
						</v-menu> -->
					</span>
					<span v-else>Не указан</span>
				</template>
				<template v-slot:[`item.status`]="{ item }">
					{{ archiveOrderStatus[item.status] }}
				</template>
				<template v-slot:[`item.deadline`]="{ item }">
					<span
						:style="
							`${$colorDeadline(new Date(item.deadline))}`"
					>
						{{ $formatDateTime(new Date(item.deadline)) }}
					</span>
				</template>
				<template v-slot:[`item.creator`]="{ item }">
					{{ item.creator.displayName }}
				</template>
				<template v-slot:[`item.description`]="{ item }">
					<span v-if="item.description">{{ $sliceStr(item.description, 60) }}</span>
					<span v-else>Не задано</span>
				</template>
			</v-data-table>
    </v-card>
  </div>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
import { archiveOrderStatus, archiveOrderStatusArr } from '@/helpers'
import OrderModal from  '@/components/Modals/Order/Index'
import SelectMonth from '@/components/Modals/SelectMonth'

export default {
  name: 'ArchiveOrders',
  data: () => ({
    isMobile: isMobile,
    skeletonTypesIframe: {
      'table-thead': 'heading@4',
      'table-row': 'table-cell@4'
    },
    archiveOrderStatus: archiveOrderStatus,
    archiveOrderStatusArr: archiveOrderStatusArr,
    filterMontagesByDateText: '',
    openFilters: false
  }),
  async beforeRouteLeave(to, from, next) {
    this.$store.commit('orders/resetAllFilterArchiveOrders')
		if (this.currentUser.role === 'admin') {
			this.filterOrdersByManager = this.currentUser
		}
    // this.$store.dispatch('orders/loadArchiveOrders')
    next()
  },
  created() {
		if (this.currentUser.role === 'admin') {
			this.filterOrdersByManager = this.currentUser
		}
    this.loadData()
  },
  computed: {
		tableHeadres() {
			let headers = [
				{
					text: 'Клиент',
					align: 'start',
					sortable: false,
					value: 'client'
				},
				{
					text: 'Номер',
					align: 'start',
					sortable: false,
					value: 'number'
				},
				{
					text: 'Статус',
					align: 'start',
					sortable: false,
					value: 'status'
				},
				{
					text: 'Дедлайн',
					align: 'start',
					sortable: false,
					value: 'deadline'
				},
				{
					text: 'Описание',
					align: 'start',
					sortable: false,
					value: 'description'
				},
			]
			
			if (this.currentUser.role === 'admin') {
				headers = [
					...headers,
					{
						text: 'Менеджер',
						align: 'start',
						sortable: false,
						value: 'creator'
					}
				]
			}

			return headers
		},
		currentUser() {
			return this.$store.getters['user/getUser']
		},
    managers() {
      return this.$store.getters['users/getManagers']
    },
    ordersLoaded() {
      return this.$store.getters['orders/getArchiveOrdersLoaded']
    },
    orders() {
      return this.$store.getters['orders/getArchiveOrders']
    },
    filterClients() {

      return this.$store.getters['clients/getFilterClients']
    },
		filterOrdersByMonthText: {
			get() {
				let month = this.filterOrdersByMonth
				if (month) {
					return String(new Date(month).toLocaleString('ru', {month: 'long', year: 'numeric'}))
				}
				return null
			},
			set(val) {
				this.filterOrdersByMonth = val
			}
    },
    filterOrdersByMonth: {
      get() { return this.$store.getters['orders/getFilterArchiveOrdersByMonth'] },
      set(val) { this.$store.commit('orders/setFilterArchiveOrdersByMonth', val) }
    },
    filterOrdersByClient: {
      get() { return this.$store.getters['orders/getFilterArchiveOrdersByClient'] },
      set(val) { this.$store.commit('orders/setFilterArchiveOrdersByClient', val) }
    },
    filterOrdersByStatus: {
      get() { return this.$store.getters['orders/getFilterArchiveOrdersByStatus'] },
      set(val) { this.$store.commit('orders/setFilterArchiveOrdersByStatus', val) }
    },
    filterOrdersByManager: {
      get() { return this.$store.getters['orders/getFilterArchiveOrdersByManager'] },
      set(val) { this.$store.commit('orders/setFilterArchiveOrdersByManager', val) }
    },
		filterOrdersByNumberOrder: {
      get() { return this.$store.getters['orders/getFilterArchiveOrdersByNumberOrder'] },
      set(val) { this.$store.commit('orders/setFilterArchiveOrdersByNumberOrder', val) }
    },
  },
  methods: {
    async loadData() {
      this.$store.dispatch('orders/loadArchiveOrders')
      this.$store.dispatch('clients/loadFilterClients')
      this.$store.dispatch('users/loadManagers')
    },
		async selectMonth() {
      let modalMonth = await this.$showModal({
        component: SelectMonth,
        isPersistent: true,
        props: {
          title: 'Выберите месяц',
          selectMonth: this.filterOrdersByMonth
        }
      })
      if (modalMonth) {
        this.filterOrdersByMonth = modalMonth
				this.$store.dispatch('orders/loadArchiveOrders')
      }
    },
		async createOrder() {
			let order = await this.$store.dispatch('orders/createOrder',this.$route)
			this.editOrder(order)
    },
		async editOrder(order) {
      await this.$showModal({
        component: OrderModal,
        isPersistent: true,
        props: {
          edit: true,
          data: order
        }
      })
      this.$store.dispatch('orders/loadArchiveOrders')
    }
  }
}
</script>
