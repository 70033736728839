import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"iframe iframe_widget iframeWidgetAssignedMontagesInBrig pa-1"},[_c('SocketWatch',{attrs:{"tables":['montages']},on:{"loadData":_vm.loadData}}),_c(VCard,[_c(VCardTitle,[_c(VIcon,{staticClass:"mr-2"},[_vm._v("mdi-truck-minus")]),_vm._v(" Монтажи на сегодня "),_c(VSpacer)],1),_c(VDivider),(!_vm.montagesLoaded && (!_vm.montages || _vm.montages.length === 0))?_c(VSkeletonLoader,{staticClass:"px-2",attrs:{"type":"card-heading"}}):_vm._e(),_c(VRow,{staticClass:"ma-0 pb-2"},_vm._l((_vm.montages),function(data,index_data){return _c(VCol,{key:index_data,staticClass:"pb-0 pt-2 px-2",attrs:{"cols":"12"}},[_c(VCard,[_c(VCardTitle,{staticClass:"py-0"},[_c('span',[_vm._v(_vm._s(data.brig.displayName))])]),_c(VDivider),(!data.montages || data.montages.length === 0)?_c('div',{staticClass:"infoNoItems"},[_vm._v(" Нет монтажей ")]):_vm._e(),_c('div',{staticClass:"widgetTableMontage"},_vm._l((data.montages),function(montage,index_montage){return _c('div',{key:montage.id,staticClass:"widgetTableMontage_tr",on:{"click":function($event){return _vm.editMontage(montage)}}},[_c('div',{staticClass:"widgetTableMontage_tr_cont"},[_c('div',{staticClass:"widgetTableMontage_tr_cont_status"},[(montage.status === 'assigned')?_c('div',{staticStyle:{"background-color":"orange"}}):_vm._e(),(montage.status === 'work')?_c('div',{staticStyle:{"background-color":"blue"}}):_vm._e(),(montage.status === 'ready')?_c('div',{staticStyle:{"background-color":"green"}}):_vm._e(),(montage.status === 'no_work')?_c('div',{staticStyle:{"background-color":"#e8443c"}}):_vm._e()]),_c('div',{staticClass:"px-2 py-1"},[_c('div',{},[_c('span',{staticStyle:{"font-weight":"400","font-size":"0.84em"}},[_vm._v(_vm._s(montage.client.name))])])])]),(index_montage + 1 < data.montages.length)?_c(VDivider):_vm._e()],1)}),0)],1)],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }