<template>
	<v-container class="px-5 pb-1 companyWhereFrom">
		<v-row>
			<v-col cols="12" class="pt-0 pb-0">
				<v-text-field
					dense
					v-model="search"
					color="#e0a677"
					label="Откуда пришёл..."
				/>
			</v-col>
			<v-col cols="12" class="py-0 d-flex">
				<v-text-field
					class="mr-2"
					dense
					v-model="itemField"
					color="#e0a677"
					label="Пришёл..."
					@keyup.enter="edit ? saveEdit() : addItem()"
				/>
				<v-btn v-if="edit" :disabled="!itemField" icon small @click="saveEdit()">
					<v-icon size="18">mdi-check</v-icon>
				</v-btn>
				<v-btn v-else :disabled="!itemField" icon small @click="addItem()">
					<v-icon size="22">mdi-keyboard-return</v-icon>
				</v-btn>
			</v-col>
			<v-col cols="12" class="py-0">
				<v-data-table
					dense
					:headers="tableHeadres"
					:items="items"
					:items-per-page="5"
					hide-default-header
					:footer-props="{
						'items-per-page-text': 'По:',
						'show-current-page': true
					}"
				>
					<template v-slot:[`item.action`]="{ item }">
						<div class="d-flex justify-end">
							<v-btn @click="deleteItem(item)" icon small color="#bf4037">
								<v-icon size="18">mdi-trash-can-outline</v-icon>
							</v-btn>
							<v-btn @click="editItem(item)" icon small>
								<v-icon size="18">mdi-pencil</v-icon>
							</v-btn>
						</div>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import Confirm from  '@/components/Modals/Confirm'

export default {
	data() {
		return {
			itemField: '',
			search: '',
			edit: null
		}
	},
	computed: {
		tableHeadres() {
			return [
				{
					text: 'Откуда',
					align: 'start',
					sortable: false,
					value: 'title',
					width: '90%'
				},
				{
					text: 'Действия',
					align: 'end',
					sortable: false,
					value: 'action'
				}
			]
		},
		items() {
			let items = this.$store.getters['settings/getWhereFromCompany']
			let search = this.search
			if (search) {
				items = items.filter(x => {
					let title = x.title.toLowerCase()
					if (title.includes(search)) { return true }
					return false
				})
			}
			return items
		}
	},
	methods: {
		async saveEdit() {
			let value = { id: this.edit, title: this.itemField }
			await this.$store.dispatch('settings/updateCompanyWhereFrom', value)
			this.edit = null
			this.itemField = ''
		},
		async addItem() {
			if (this.itemField) {
				await this.$store.dispatch('settings/checkCompanyWhereFrom', this.itemField)
				this.edit = null
				this.itemField = ''
			}
		},
		async deleteItem(item) {
			if (item && item.id) {
				const resConfirm = await this.$showModal({
					component: Confirm,
					isPersistent: true,
					props: {
						title: `Удаление элемента`,
						text: `Действительно хотите удалить "${item.title}"?`
					}
				})

				if (resConfirm) {
					await this.$store.dispatch('settings/delCompanyWhereFrom', item.id)
				}
			}
		},
		editItem(item) {
			if (item && item.title) {
				this.edit = item.id
				this.itemField = item.title
			}
		}
	}
}
</script>

<style lang="scss">
.companyWhereFrom {
	.v-data-footer {
		display: flex;
		justify-content: flex-end;
	}
	.v-data-footer__select {
		margin-right: 0px;
		.v-select {
			margin-left: 10px !important;
		}
	}
	.v-data-footer__pagination {
		display: none;
	}
}
</style>
