<template>
	<v-row justify="center">
		<v-dialog v-model="dialog" persistent max-width="400px">
			<v-card v-if="!onChangeManager">
				<v-card-title v-if="title" class="headline">{{ title }}</v-card-title>

				<v-card-text v-if="text">
					{{ text }}
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>

					<v-btn color="red darken-1" text @click="close()">
						Нет
					</v-btn>

					<v-btn color="green darken-1" text @click="ok()">
						Да
					</v-btn>
				</v-card-actions>

			</v-card>
			<v-card v-if="onChangeManager">
				<v-card-title v-if="title" class="headline">{{ title }}</v-card-title>

				<v-card-text v-if="text">
					{{ text }}
					<v-row class="page_filterTable mt-3">
						<v-col cols="8">
							<v-autocomplete v-model="selectedManager" dense color="rgb(224, 166, 119)" label="Менеджер"
								:items="managers" item-text="displayName" return-object clearable onchange="notErrorText" />
							<div v-if="this.onError" style="color:red">Выберите менеджера!</div>
						</v-col>
					</v-row>
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>

					<v-btn color="red darken-1" text @click="close()">
						Нет
					</v-btn>

					<v-btn color="green darken-1" text @click="sendOk()">
						Да
					</v-btn>
				</v-card-actions>

			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
import api from '@/utils/api.js';
export default {
	props: {
		onChangeManager: {
			type: Boolean,
			default: false
		},
		title: {
			type: String,
			default: false
		},
		text: {
			type: String,
			default: false
		},
        delUserId: {
            type: Number,
            default: () => null,
        }
	},
	data: () => ({
		dialog: true,
        selectedManager: '',
        onError: false
	}),
	methods: {
		changeUser(event) {
            event.id ? this.newUserId = event.id : null
		},
		ok() {
            
			this.$emit('close', true)
		},
		notErrorText() {
			this.onError = false;
		},
		async sendOk() {
			if (this.selectedManager) {
				if(this.delUserId != null){
                    try {
                        await api.get(`users/reform/${this.delUserId}/${this.selectedManager.id}`).then(response => {
                            console.log(response)
                            this.$emit('close', true)
                        })
                    } catch(e){
                        console.log('smthg went wrong ', e)
                    }
                }
			} else {
				this.onError = true;
			}
		},
		close() {
			this.$emit('close', false)
		}
	},
	computed: {
		managers() {
			return this.$store.getters['users/getUsers'].filter(el => el.role == 'manager' && el.id != this.delUserId)
		}
	}
}
</script>
