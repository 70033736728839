<template>
  <v-container class="pr-1">
    <v-card style="min-height: 4em" class="d-flex align-center justify-center">
      <div style="width: 100%">
        <template v-if="currentUser.role === 'admin'">
          <v-card-title class="py-2 pl-3 pr-2">
            <v-list-item-title style="max-width: calc(100% - 130px)">
              <span v-if="selectedCategory">{{ selectedCategory.title }}</span>
              <span v-else>Категории</span>
            </v-list-item-title>
            <v-spacer />
            <v-btn
              class="mr-1"
              small
              icon
              :disabled="!selectedCategory"
              @click="closeCategory()"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-btn
              class="mr-1"
              small
              icon
              :disabled="!selectedCategory || isEditOrder"
              @click="editCategory()"
            >
              <v-icon size="20">mdi-pencil</v-icon>
            </v-btn>

            <v-btn
              v-if="!isEditOrder"
              :disabled="selectedCategory && !selectedCategory.categories"
              class="mr-1"
              small
              icon
              @click="editOrderCategory()"
            >
              <v-icon size="22">mdi-sort</v-icon>
            </v-btn>
            <v-btn
              v-else
              class="mr-1"
              small
              icon
              color="green"
              @click="saveOrderCategory()"
            >
              <v-icon size="22">mdi-check</v-icon>
            </v-btn>

            <v-menu bottom left>
              <template #activator="{ on, attrs }">
                <v-btn small icon v-bind="attrs" v-on="on">
                  <v-icon size="22">mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item :disabled="isEditOrder" @click="addCategory()">
                  <v-list-item-icon>
                    <v-icon :disabled="isEditOrder">mdi-plus</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Новая категория</v-list-item-title>
                </v-list-item>
                <v-list-item
                  :disabled="!selectedCategory || isEditOrder"
                  @click="deleteCategory()"
                >
                  <v-list-item-icon>
                    <v-icon
                      :disabled="!selectedCategory || isEditOrder"
                      color="red"
                      >mdi-trash-can-outline</v-icon
                    >
                  </v-list-item-icon>
                  <v-list-item-title>Удалить</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-card-title>
          <v-divider />
        </template>
        <template v-if="items && items.length > 0">
          <v-list
            v-show="!isEditOrder"
            :key="redrowItems"
            style="border-radius: inherit"
          >
            <ItemMenu
              v-for="item in items"
              :key="`${item.id}_${redrowItems}`"
              :item="item"
              :selected-category="selectedCategory"
              :depth="0"
              @selectCategory="selectCategory($event, $event)"
            />
          </v-list>
          <draggable
            v-if="isEditOrder"
            v-model="orderList"
            v-bind="dragOptions"
            class="py-2"
          >
            <transition-group type="transition" name="flip-list">
              <v-list-item
                v-for="item in orderList"
                :key="item.id"
                class="menuDraggableItem"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon class="my-3">
                  <v-icon>mdi-drag</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </transition-group>
          </draggable>
        </template>
        <div
          v-else
          style="color: grey; font-size: 1.2em"
          class="d-flex justify-center py-3"
        >
          <span v-if="loadItems">Загрузка...</span>
          <span v-else>Нет элементов</span>
        </div>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import Confirm from "@/components/Modals/Confirm";
import api, { apiRoutes as aR } from "@/utils/api/api";
import draggable from "vuedraggable";
import ItemMenu from "./ItemMenu.vue";
import ModalCategory from "./modals/Category.vue";
export default {
  components: {
    ItemMenu,
    draggable,
  },
  data: () => ({
    loadItems: true,
    categorys: {},
    items: [],
    selectedCategory: null,
    redrowItems: 0,
    isEditOrder: false,
    orderList: [],
    drag: false,
  }),
  computed: {
    dragOptions() {
      return {
        animation: 200,
        disabled: !this.isEditOrder,
      };
    },
    currentUser() {
      return this.$store.getters["user/getUser"];
    },
  },
  async created() {
    await this.loadMenuItems();
  },
  methods: {
    async loadMenuItems() {
      let queryStore = await api.post(aR.faqCategoriesGetList, {
        limit: 1000,
        offset: 0,
        query: [{ input: "del", value: false }],
      });
      queryStore = queryStore.data.data || [];
      queryStore.forEach((el) => {
        if (el.available) {
          el.available = JSON.parse(el.available);
        }

        if (el.order) {
          el.order = Number(el.order);
        }
        el.categories = queryStore.filter((elem) => elem.cat_id == el.id);
      });
      if (
        this.currentUser.role.length == 0 ||
        (this.currentUser && this.currentUser.role != "admin")
      ) {
        queryStore = queryStore.filter((el) =>
          el.available.includes(this.currentUser.role)
        );
      }

      queryStore = this.sortItemsMenu(queryStore);
      this.items = queryStore.filter((el) => !el.is_depth);

      this.loadItems = false;
    },
    async setCategory(item, obj, newItem = null) {
      if (item) {
        if (newItem) {
          newItem.data ? (newItem = newItem.data) : newItem;
          if (newItem.available) {
            newItem.available = JSON.stringify(newItem.available);
          }
          newItem.order ? (newItem.order = String(newItem.order)) : "";
          if (newItem.order == 0) {
            newItem.order = "0";
          }
          if (newItem.categories) {
            newItem.categories = JSON.stringify(newItem.categories);
          }
          await api
            .post(aR.faqCategoriesCreate, newItem)
            .then(async (response) => {
              if (response.status == 200 && response.data) {
                await this.loadMenuItems();
              }

              [];
            });
        } else {
          if (obj.available) {
            obj.available = JSON.stringify(obj.available);
          }
          if (obj.categories) {
            obj.categories = JSON.stringify(obj.categories);
          }
          await api
            .put(aR.faqCategoriesUpdate, { id: item.id, ...obj })
            .then(async (response) => {
              if (response.status == 200 && response.data.status) {
                await this.loadMenuItems();
              }
            });
        }
        return true;
      } else if (!item && newItem) {
        newItem.data ? (newItem = newItem.data) : {};

        newItem.order = String(newItem.order);

        if (newItem.available) {
          newItem.available = JSON.stringify(newItem.available);
        }
        if (newItem.categories) {
          newItem.categories = JSON.stringify(newItem.categories);
        }

        await api
          .post(aR.faqCategoriesCreate, { ...newItem, del: false })
          .then(async (response) => {
            if (response.status == 200 && response.data) {
              await this.loadMenuItems();
            }
          });
        return true;
      }
      return false;
    },
    async saveOrderCategory() {
      this.isEditOrder = false;
      if (this.selectedCategory && this.selectedCategory.categories) {
        this.selectedCategory.categories = this.orderList;
      } else {
        this.items = this.orderList;
      }
      for (let index_item in this.orderList) {
        let item = this.orderList[index_item];
        let obj = { order: index_item };
        console.log(item, index_item)
        await this.setCategory(item, obj);
      }
      this.orderList = [];
    },
    editOrderCategory() {
      this.isEditOrder = true;
      if (this.selectedCategory && this.selectedCategory.categories) {
        this.orderList = this.selectedCategory.categories;
      } else {
        this.orderList = this.items;
      }
    },
    async deleteCategory() {
      const resConfirm = await this.$showModal({
        component: Confirm,
        isPersistent: true,
        props: {
          title: `Удаление категории`,
          text: `Действительно хотите удалить категорию "${this.selectedCategory.title}"?`,
        },
      });

      if (resConfirm) {
        if (this.selectedCategory.is_depth) {
          let finded = this.items.find(
            (el) => el.id == this.selectedCategory.cat_id
          );
          if (finded.categories) {
            finded.categories = finded.categories.filter(
              (el) => el.id != this.selectedCategory.id
            );
          }
          if (finded.categories.length == 0) {
            finded.categories = [];
          }
          if (finded.available) {
            finded.available = JSON.stringify(finded.available);
          }
          if (finded.categories) {
            finded.categories = JSON.stringify(finded.categories);
          }
          finded.order = String(finded.order);
          await api.put(aR.faqCategoriesUpdate, finded);
        }
        if (
          this.selectedCategory.categories &&
          this.selectedCategory.categories.length > 0
        ) {
          this.selectedCategory.categories.map(async (el) => {
            await api.delete(aR.faqCategoriesDelete, {
              id: el.id,
            });
          });
        }
        this.$store.commit("templ/setLoading", true);
        await api.delete(aR.faqCategoriesDelete, {
          id: this.selectedCategory.id,
        });

        await this.loadMenuItems();
        this.$store.commit("templ/setLoading", false);
      }
    },
    async addCategory() {
      let res = await this.$showModal({
        component: ModalCategory,
        isPersistent: false,
        props: {
          edit: false,
          data: {
            available: this.selectedCategory
              ? this.selectedCategory.available
              : null,
          },
        },
      });
      if (res) {
        let order = 0;

        if (this.selectedCategory && this.selectedCategory.categories) {
          order = this.selectedCategory.categories.length; // в order хранятся индексы а length на 1 больше
        } else if (!this.selectedCategory) {
          order = this.items.length; // в order хранятся индексы а length на 1 больше
        }

        let obj = {
          ...res,
          order: order,
        };
        if (this.selectedCategory) {
          console.log(this.selectedCategory);
          obj.is_depth = true;
          obj.cat_id = this.selectedCategory.id;
          if (this.selectedCategory.categories) {
            this.selectedCategory.categories.push({
              ...obj,
            });
          } else {
            this.selectedCategory.categories = [
              {
                ...obj,
              },
            ];
          }
        } else {
          this.items.push({
            ...obj,
          });
        }
        await this.setCategory(this.selectedCategory, res, {
          data: obj,
        });
      }
    },
    async editCategory() {
      let res = await this.$showModal({
        component: ModalCategory,
        isPersistent: false,
        props: {
          edit: true,
          data: this.selectedCategory,
        },
      });
      if (res) {
        for (let key in res) {
          this.selectedCategory[key] = res[key];
        }
        this.$emit("selectCategory", { ...this.selectedCategory, ...res });
        await this.setCategory(this.selectedCategory, res);
      }
    },
    closeCategory() {
      this.orderList = [];
      this.isEditOrder = false;
      this.selectedCategory = null;
      this.$emit("selectCategory", null);
      this.redrowItems += 1;
      this.items = this.items.map((item) => {
        return { ...item, active: false };
      });
    },
    selectCategory(item) {
      this.selectedCategory = item;
      this.$emit("selectCategory", item);
    },

    sortItemsMenu(items) {
      const _this = this;

      let sortedItems = items.sort(function(a, b) {

        return a.order - b.order;
      });

      sortedItems.forEach(item => {
        if(item.categories){
          item.categories =  _this.sortItemsMenu(item.categories);
        }
      })

      return sortedItems;
    },
  },
};
</script>

<style lang="scss">
.menuDraggableItem {
  padding-left: 12px !important;
  padding: 0 12px !important;

  &:hover {
    cursor: grab;
    background-color: rgba(114, 114, 114, 0.39);
  }

  .v-list-item__title {
    color: #473f3f;
  }
}
</style>
