import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
import { VDataFooter } from 'vuetify/lib/components/VDataIterator';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"iframe iframe_full iframeUnassignedMontages pa-1"},[_c('SocketWatch',{attrs:{"tables":['orders']},on:{"loadData":_vm.loadData}}),_c(VCard,[_c(VCardTitle,[_c(VIcon,{staticClass:"mr-2"},[_vm._v("mdi-clipboard-multiple-outline")]),_vm._v(" Мои заказы "),_c(VSpacer),(_vm.options && _vm.pagination)?_c(VDataFooter,{staticClass:"rl-data-footer",staticStyle:{"width":"310px","padding":"0"},attrs:{"options":_vm.options,"pagination":_vm.pagination,"items-per-page-text":"","items-per-page-options":[5, 10, 15, 20, 30, 40]},on:{"update:options":function($event){_vm.options=$event}}}):_vm._e(),_c(VBtn,{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.createOrder()}}},[_c(VIcon,[_vm._v("mdi-plus")])],1)],1),_c(VDivider),(_vm.ordersLoaded && (!_vm.orders || _vm.orders.length === 0))?_c('div',{staticClass:"infoNoItems"},[_vm._v(" Нет заказов ")]):_vm._e(),(_vm.orders && _vm.orders.length > 0)?_c(VDataTable,{attrs:{"headers":_vm.tableHeadres,"items":_vm.orders,"items-per-page":15,"dense":!_vm.isMobile,"options":_vm.options,"hide-default-footer":""},on:{"update:options":function($event){_vm.options = $event},"pagination":function($event){_vm.pagination = $event}},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('tr',{style:(item.color && !_vm.disabledColorBtn(item) ?
								 `background-color: ${item.color}` : ''),on:{"click":function($event){return _vm.editOrder(item)}}},[_c('td',[(item.client)?_c('span',[_vm._v(" "+_vm._s(item.client.name)+" ")]):_c('span',[_vm._v("Не указан")])]),_c('td',[_vm._v(" "+_vm._s(item.number)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.orderStatus[item.status])+" ")]),_c('td',[_c('span',{style:(`${_vm.$colorDeadline(new Date(item.deadline))}`)},[_vm._v(" "+_vm._s(_vm.$formatDateTime(new Date(item.deadline)))+" ")])]),_c('td',[(item.description)?_c('span',[_vm._v(_vm._s(_vm.$sliceStr(item.description, 60)))]):_c('span',[_vm._v("Не задано")])]),_c('td',{staticClass:"text-center"},[_c(VMenu,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","disabled":_vm.disabledColorBtn(item)}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-format-color-fill")])],1)]}}],null,true)},[_c('div',{staticStyle:{"background-color":"white"}},[_c('div',{staticClass:"pt-2 px-5"},[(item.color)?_c(VBtn,{attrs:{"color":item.color || null,"block":""},on:{"click":function($event){return _vm.setColorItem('#FFF', item)}}},[_vm._v(" Сбросить цвет ")]):_vm._e()],1),_c(VColorPicker,{staticClass:"rl-table-color-picker",attrs:{"value":item.color || null,"hide-canvas":"","hide-inputs":"","show-swatches":"","swatches-max-height":"230","mode":"hexa"},on:{"input":function($event){return _vm.setColorItem($event, item)}}})],1)])],1)])]}}],null,false,4107343754)}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }