<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" width="290" persistent>
        <v-card class="selectMonth" width="290">
          <v-card-title class="selectMonth_header" v-if="title">
            <span>{{ title }}</span>
          </v-card-title>

          <v-date-picker type="month" class="selectMonth_datePicker" no-title v-model="month" locale="ru" color="rgb(224, 166, 119)" />

          <v-card-actions>
            <v-btn color="orange" text @click="close()">Отмена</v-btn>
            <v-spacer />
            <v-btn color="blue" text @click="go()">Продолжить</v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    selectMonth: {
      type: String,
      default: null
    },
  },
  data: () => ({
    dialog: true,
    month: new Date().toISOString().substr(0, 7)
  }),
  created() {
    if (this.selectMonth) {
      this.month = this.selectMonth
    }
  },
  methods: {
    go() {
      this.$emit('close', this.month)
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">
.selectMonth {
  &_header {
    font-size: 1.2em !important;
  }

  &_datePicker {
    .v-date-picker-table {
      height: 100%;
    }
  }
}
</style>