<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-avatar color="grey" :size="size" v-bind="attrs" v-on="on">
        <v-img v-if="avatar && !loading" :src="avatar"></v-img>
        <span
          v-if="!avatar && !loading && userData && userData.displayName"
          class="white--text"
          style="font-size: 18px;"
        >
          {{ userData.displayName.charAt(0) }}
        </span>
        <v-progress-circular
          v-if="loading"
          :size="size - 11"
          width="3"
          indeterminate
          color="#aaaeaf"
        ></v-progress-circular>
      </v-avatar>
    </template>
    <span v-if="userData && userData.displayName">{{ userData.displayName }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'MiniAvatar',
  props: {
    user: {
      type: Object,
      default: () => {},
    },
    size: {
      type: Number,
      default: 33,
    },
  },
  data: () => ({
    avatar: null,
    loading: false,
  }),
  created() {
    this.loadAvatar();
  },
  updated() {
    this.loadAvatar();
  },
  methods: {
    loadAvatar() {
      if (this.user && this.user.avatar) {
        this.avatar = this.user.avatar;
        return;
      } else if (this.user && !this.user.avatar) {
        this.avatar = '';
        return;
      }

      if (this.userData && this.userData.avatar) {
        this.loading = true;
        this.avatar = this.userData.avatar;

        let img = new Image();
        img.addEventListener(
          'load',
          () => {
            this.loading = false;
          },
          false,
        );
        img.src = this.avatar;
      } else if (this.userData && !this.userData.avatar) {
        let img = new Image();
        img.src = '';
      }
    },
  },
  computed: {
    userData() {
      if (this.user) {
        let ident = null;
        let by = null;
        if (this.user.id) {
          ident = this.user.id;
          by = 'id';
        } else {
          ident = this.user.email;
          by = 'email';
        }
        return this.$store.getters['users/getUser'](ident, by);
      }
      return null;
    },
  },
  watch: {
    userData(val) {
      if (val && !this.avatar) {
        this.loadAvatar();
      }
    },
  },
};
</script>
