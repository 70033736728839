<template>
  <v-row class="ma-0 px-1 py-2">
    <v-col cols="9" class="pa-0 px-4">
      <v-row>
        <v-col cols="12" class="mb-2 pa-0">
					<WidgetTabsTask tabsTasks="['meTask', fromMeTask]" />
          <!-- <WidgetMeTask /> -->
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="3" class="pa-0 px-4">
    </v-col>
  </v-row>
</template>

<script>
import WidgetTabsTask from '@/components/Tasks/Widgets/TabsTask.vue'
// import WidgetMeTask from '@/components/Tasks/Widgets/MeTask.vue'

export default {
  name: 'DesignerHome',
  components: {
		WidgetTabsTask,
    // WidgetMeTask
  },
  created(){
    console.log('DesignerHome created')
  }
}
</script>
