import axios from "axios";
const url = process.env.VUE_APP_SERVICE_URL;
const token = localStorage["jwt"];

// Axios bug - not updating token even when using interceptors(must use inline headers)

export default {
  async post(service, data) {
    let response = await axios.post(`${url}/${service}`, data, {
      headers: {
        Authorization: localStorage["jwt"],
      },
    });
    return response;
  },
  async get(service, data) {
    let response;
    if (data) {
      response = await axios.get(`${url}/${service}/${data}`, {
        headers: {
          Authorization: localStorage["jwt"],
        },
      });
    } else {
      response = await axios.get(`${url}/${service}`, {
        headers: {
          Authorization: localStorage["jwt"],
        },
      });
    }

    return response;
  },
  async put(service, data) {
    let response = await axios.put(`${url}/${service}`, data, {
      headers: {
        Authorization: localStorage["jwt"],
      },
    });
    return response;
  },
  async delete(service, data) {
    let response = await axios.delete(`${url}/${service}`, {
      headers: {
        Authorization: localStorage["jwt"],
      },
      data,
    });
    return response;
  },
  // Uploading files
  async upload(service, data) {
    let form_data = new FormData();
    form_data.append("file", data);
    let response = await axios.post(`${url}${service}`, form_data, {
      headers: {
        Authorization: localStorage["jwt"],
      },
    });
    return response;
  },
};

const aRBase = {
  // base actions
  getList: "get-list",
  create: "create",
  update: "update",
  get: "get",
  delete: "delete",

  // extra actions
  login: "login",
  getListTable: "get-list-table",
  getListFilter: "get-list-filter",

  // Abstract routes
  upload: "upload",
  uploadYandex: "upload-yandex/",
  disc: "disc",

  // Entity routes

  orders: "orders/",
  manufactory: "manufactory/",
  montages: "montages/",
  manufactoryLogs: "log-manufactory/",
  manufactoryYandex: "manufactory/yandex/",
  tasks: "tasks/",
  users: "users/",
  clients: "clients/",
  // -- Warehouse
  categories: "category/",
  positions: "position/",
  products: "product/",
  // --
  notifications: "notifications/",
  brigs: "brigs/",
  //  -- Finance
  prepayments: "prepayments/",
  premiums: "premiums/",
  deductions: "deductions/",
  // --
  hourlyWorks: "hourly-works/",
  locations: "locations/",
  //  -- Settings
  settings: "settings",
  companyActivities: "company-activitys/",
  companyWhereFrom: "company-where-from/",
  materials: "materials/",
  additionalProcessings: "additional-processings/",

  // Faq
  faqMaterials: "faq-materials/",
  faqCategories: "faq-categories/",
};

// Aliase
const r = aRBase;

const apiRoutes = {
  ...aRBase,
  // Composed routes

  // Users
  usersGetList: r.users + r.getList,
  usersUpdate: r.users + r.update,
  usersCreate: r.users + r.create,
  usersDelete: r.users + r.delete,
  usersGet: r.users + r.get,
  usersLogin: r.users + r.login,

  // Clients
  clientsGetList: r.clients + r.getList,
  clientsGet: r.clients + r.get,
  clientsUpdate: r.clients + r.update,
  clientsCreate: r.clients + r.create,
  clientsDelete: r.clients + r.delete,
  clientsGetListTable: r.clients + r.getListTable,
  clientsGetListFilter: r.clients + r.getListFilter,

  // Orders
  ordersGetList: r.orders + r.getList,
  ordersGet: r.orders + r.get,
  ordersUpdate: r.orders + r.update,
  ordersCreate: r.orders + r.create,
  ordersDelete: r.orders + r.delete,

  // Manufactory
  manufactoryGet: r.manufactory + r.get,
  manufactoryGetList: r.manufactory + r.getList,
  manufactoryUpdate: r.manufactory + r.update,
  manufactoryCreate: r.manufactory + r.create,
  manufactoryDelete: r.manufactory + r.delete,
  // --logs
  manufactoryLogsGetList: r.manufactoryLogs + r.getList,
  manufactoryLogsCreate: r.manufactoryLogs + r.create,

  // Tasks
  tasksGet: r.tasks + r.get,
  tasksGetList: r.tasks + r.getList,
  tasksUpdate: r.tasks + r.update,
  tasksCreate: r.tasks + r.create,
  tasksDelete: r.tasks + r.delete,

  // Montages
  montagesGet: r.montages + r.get,
  montagesGetList: r.montages + r.getList,
  montagesUpdate: r.montages + r.update,
  montagesCreate: r.montages + r.create,
  montagesDelete: r.montages + r.delete,

  /// Warehouse

  // -- Categories
  categoriesGet: r.categories + r.get,
  categoriesGetList: r.categories + r.getList,
  categoriesUpdate: r.categories + r.update,
  categoriesCreate: r.categories + r.create,
  categoriesDelete: r.categories + r.delete,

  // -- Positions
  positionsGet: r.positions + r.get,
  positionsGetList: r.positions + r.getList,
  positionsUpdate: r.positions + r.update,
  positionsCreate: r.positions + r.create,
  positionsDelete: r.positions + r.delete,

  // -- Products
  productsGet: r.products + r.get,
  productsGetList: r.products + r.getList,
  productsUpdate: r.products + r.update,
  productsCreate: r.products + r.create,
  productsDelete: r.products + r.delete,

  // Notifications
  notificationsGet: r.notifications + r.get,
  notificationsGetList: r.notifications + r.getList,
  notificationsUpdate: r.notifications + r.update,
  notificationsCreate: r.notifications + r.create,
  notificationsDelete: r.notifications + r.delete,

  // Brigs
  brigsGet: r.brigs + r.get,
  brigsGetList: r.brigs + r.getList,
  brigsUpdate: r.brigs + r.update,
  brigsCreate: r.brigs + r.create,
  brigsDelete: r.brigs + r.delete,

  /// Finance

  // -- Prepayments
  prepaymentsGet: r.prepayments + r.get,
  prepaymentsGetList: r.prepayments + r.getList,
  prepaymentsUpdate: r.prepayments + r.update,
  prepaymentsCreate: r.prepayments + r.create,
  prepaymentsDelete: r.prepayments + r.delete,

  // -- Premiums
  premiumsGet: r.premiums + r.get,
  premiumsGetList: r.premiums + r.getList,
  premiumsUpdate: r.premiums + r.update,
  premiumsCreate: r.premiums + r.create,
  premiumsDelete: r.premiums + r.delete,

  // -- Deductions
  deductionsGet: r.deductions + r.get,
  deductionsGetList: r.deductions + r.getList,
  deductionsUpdate: r.deductions + r.update,
  deductionsCreate: r.deductions + r.create,
  deductionsDelete: r.deductions + r.delete,

  // Hourly Works
  hourlyWorksGet: r.hourlyWorks + r.get,
  hourlyWorksGetList: r.hourlyWorks + r.getList,
  hourlyWorksUpdate: r.hourlyWorks + r.update,
  hourlyWorksCreate: r.hourlyWorks + r.create,
  hourlyWorksDelete: r.hourlyWorks + r.delete,

  // Locations
  locationsGet: r.locations + r.get,
  locationsGetList: r.locations + r.getList,
  locationsUpdate: r.locations + r.update,
  locationsCreate: r.locations + r.create,
  locationsDelete: r.locations + r.delete,

  /// Settings

  // -- Settings
  settingsGet: "get-" + r.settings,
  settingsUpdate: "update-" + r.settings,

  // -- Company Activities
  companyActivitiesGet: r.companyActivities + r.get,
  companyActivitiesGetList: r.companyActivities + r.getList,
  companyActivitiesUpdate: r.companyActivities + r.update,
  companyActivitiesCreate: r.companyActivities + r.create,
  companyActivitiesDelete: r.companyActivities + r.delete,

  // -- Company Where From
  companyWhereFromGet: r.companyWhereFrom + r.get,
  companyWhereFromGetList: r.companyWhereFrom + r.getList,
  companyWhereFromUpdate: r.companyWhereFrom + r.update,
  companyWhereFromCreate: r.companyWhereFrom + r.create,
  companyWhereFromDelete: r.companyWhereFrom + r.delete,

  // -- Materials
  materialsGet: r.materials + r.get,
  materialsGetList: r.materials + r.getList,
  materialsUpdate: r.materials + r.update,
  materialsCreate: r.materials + r.create,
  materialsDelete: r.materials + r.delete,

  // -- Additional Processings
  additionalProcessingsGet: r.additionalProcessings + r.get,
  additionalProcessingsGetList: r.additionalProcessings + r.getList,
  additionalProcessingsUpdate: r.additionalProcessings + r.update,
  additionalProcessingsCreate: r.additionalProcessings + r.create,
  additionalProcessingsDelete: r.additionalProcessings + r.delete,

  /// Faq
  // -- Faq Materials
  faqMaterialsGet: r.faqMaterials + r.get,
  faqMaterialsGetList: r.faqMaterials + r.getList,
  faqMaterialsUpdate: r.faqMaterials + r.update,
  faqMaterialsCreate: r.faqMaterials + r.create,
  faqMaterialsDelete: r.faqMaterials + r.delete,

  // -- Faq Categories
  faqCategoriesGet: r.faqCategories + r.get,
  faqCategoriesGetList: r.faqCategories + r.getList,
  faqCategoriesUpdate: r.faqCategories + r.update,
  faqCategoriesCreate: r.faqCategories + r.create,
  faqCategoriesDelete: r.faqCategories + r.delete,
};

export { apiRoutes };
