<template>
  <v-form ref="formNewTask" class="d-flex align-center" style="width: 100%;">
    <v-row>
      <v-col class="py-0">
        <v-text-field
          dense
          color="rgb(224, 166, 119)"
          v-model="title"
          label="Задача мне"
          :rules="$fieldRules.field"
          @keyup.enter="save()"
          ref="title"
        />
      </v-col>
      <v-col cols="2" class="py-0">
        <v-text-field
          dense
          color="rgb(224, 166, 119)"
          :value="deadlineText"
          @click="selectDate()"
          label="Дедлайн"
          clearable
          @click:clear="clearDate()"
          ref="deadline"
          :rules="$fieldRules.date"
        />
      </v-col>
    </v-row>
    <v-btn icon small class="ml-3" @click="save()">
      <v-icon size="22">mdi-keyboard-return</v-icon>
    </v-btn>
  </v-form>
</template>

<script>
import SelectDate from '@/components/Modals/SelectDate'
import MiniAvatar from '../../../MiniAvatar.vue'

export default {
  components: { MiniAvatar },
  name: 'NewTask',
  data: () => ({
    deadline: null,
    title: '',
    whoTo: [],
    whoToData: {}
  }),
  methods: {
    async save() {
      const errors = []
      const validate = await this.$refs.formNewTask.validate()
      errors.push(validate)

      if (!errors.includes(false)) {
        let obj = {
          // from добавляется в экшене в сторе
          // accepted добавляется в экшене в сторе
          // del добавляется в экшене в сторе
          deadline: this.deadline,
          title: this.title,
          text: '',
          whoTo: this.whoTo,
          whoToData: this.whoToData
        }

        if (this.user) {
          obj.whoTo.push(this.user.uid)
          obj.whoToData[this.user.uid] = {
            displayName: this.user.displayName,
            email: this.user.email,
            avatar: this.user.avatar || null,
            del: this.user.del,
            role: this.user.role
          }
        }

        this.$store.dispatch('meTasks/createTask', obj)

        // Надо бы проверять успешно ли добавилась задачи и только тогда чистить поля
        this.cleanFrom()
      }
    },
    cleanFrom() {
      this.deadline = null
      this.title = ''
      this.whoTo = []
      this.whoToData = {}
      this.$refs.formNewTask.reset()
    },
    clearDate() {
      this.deadline = null
    },
    async selectDate() {
      let modalDate = await this.$showModal({
        component: SelectDate,
        isPersistent: true,
        props: {
          title: 'Дедлайн',
          withTime: true,
          selectDate: new Date().toISOString().substr(0, 10)
        }
      })
      if (modalDate) {
        if (modalDate.date && modalDate.time) {
          this.deadline = new Date(`${modalDate.date}T${modalDate.time}`)
        } else {
          this.deadline = new Date(modalDate)
        }
      }
    },
  },
  computed: {
    user() {
      return this.$store.getters['user/getUser']
    },
    deadlineText() {
      if (this.deadline) {
        return new Date(this.deadline).toLocaleString('ru', {day: 'numeric', month: 'short', hour: 'numeric', minute: 'numeric' })
      }
      return ''
    }
  }
}
</script>