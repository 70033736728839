import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page assignedMontages"},[_c('DateFilterAssignedMontages',{on:{"updDate":_vm.updDate}}),_c('SocketWatch',{attrs:{"tables":['montages']},on:{"loadData":_vm.loadData}}),_c('div',[(
        !_vm.isMobile && !_vm.montagesLoaded && (!_vm.montages || _vm.montages.length === 0)
      )?_c('Skeleton'):_vm._e(),(
        !_vm.isMobile && !_vm.montagesLoaded && (!_vm.montages || _vm.montages.length === 0)
      )?_c('Skeleton'):_vm._e(),(!_vm.isMobile)?[_c(VRow,{attrs:{"no-gutters":""}},_vm._l((_vm.montages),function(data,index_data){return _c(VCol,{key:index_data,staticClass:"pa-0 ma-0",attrs:{"cols":"6"}},[_c('AssignedMontagesInBrig',{attrs:{"data":data}})],1)}),1)]:[_c(VTabs,{staticClass:"mobAssignedMontages_tabs",attrs:{"color":"rgb(224, 166, 119)","background-color":"rgb(224, 166, 119)"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabsSlider),_vm._l((_vm.montages),function(data,index_data){return _c(VTab,{key:index_data},[_vm._v(" "+_vm._s(data.brig.displayName)+" ")])})],2),_c(VTabsItems,{staticStyle:{"background-color":"#424242"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.montages),function(data,index_data){return _c(VTabItem,{key:index_data},[_c('AssignedMontagesInBrig',{attrs:{"data":data}})],1)}),1)]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }