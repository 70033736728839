export default {
  state: {
    filterMontagesByClient: null,
    filterMontagesByStatus: null,
    filterMontagesByManager: null,
    filterMontagesByDate: null,
    filterUnassignedMontagesByDate: null,
    filterUnassignedMontagesByClient: null,
    filterUnassignedMontagesByManager: null,
    filterAssignedMontagesByDate: new Date(new Date().toISOString().substr(0, 10)),
  },
  getters: {
    getFilterMontagesByClient: state => {
      return state.filterMontagesByClient
    },
    getFilterMontagesByStatus: state => {
      return state.filterMontagesByStatus
    },
    getFilterMontagesByManager: state => {
      return state.filterMontagesByManager
    },
    getFilterMontagesByDate: state => {
      return state.filterMontagesByDate
    },
    getFilterUnassignedMontagesByDate: state => {
      return state.filterUnassignedMontagesByDate
    },
    getFilterUnassignedMontagesByClient: state => {
      return state.filterUnassignedMontagesByClient
    },
    getFilterUnassignedMontagesByManager: state => {
      return state.filterUnassignedMontagesByManager
    },
    getFilterAssignedMontagesByDate: state => {
      return state.filterAssignedMontagesByDate
    },
  },
  mutations: {
    resetAllFilterMontages(state) {
      state.filterMontagesByClient = null
      state.filterMontagesByStatus = null
      state.filterMontagesByManager = null
      state.filterMontagesByDate = null
    },
    resetAllFilterUnassignedMontages(state) {
      state.filterUnassignedMontagesByClient = null
      state.filterUnassignedMontagesByManager = null
      state.filterUnassignedMontagesByDate = null
    },
    setFilterMontagesByClient(state, payload) {
      state.filterMontagesByClient = payload;
    },
    setFilterMontagesByStatus(state, payload) {
      state.filterMontagesByStatus = payload;
    },
    setFilterMontagesByManager(state, payload) {
      state.filterMontagesByManager = payload;
    },
    setFilterMontagesByDate(state, payload) {
      state.filterMontagesByDate = payload;
    },
    setFilterUnassignedMontagesByDate(state, payload) {
      state.filterUnassignedMontagesByDate = payload;
    },
    setFilterUnassignedMontagesByClient(state, payload) {
      state.filterUnassignedMontagesByClient = payload;
    },
    setFilterUnassignedMontagesByManager(state, payload) {
      state.filterUnassignedMontagesByManager = payload;
    },
    setFilterAssignedMontagesByDate(state, payload) {
      state.filterAssignedMontagesByDate = payload;
    }
  }
}