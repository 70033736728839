import store from './stores/store'
import { usersCollection } from '@/plugins/firebase'

export const orderStatus = {
  draft: 'Предварительный',
  awaitingPrepayment: 'Ожидает предоплаты',
  working: 'Выполняется',
  ready: 'Выполнен',
  readyButNotPaid: 'Выполнен, не оплачен',
  failure: 'Отказ'
}
export const orderStatusArr = [
  { value: 'draft', text: 'Предварительный' },
  { value: 'awaitingPrepayment', text: 'Ожидает предоплаты' },
  { value: 'working', text: 'Выполняется' },
  { value: 'ready', text: 'Выполнен' },
  { value: 'readyButNotPaid', text: 'Выполнен, не оплачен' },
  { value: 'failure', text: 'Отказ' },
]

export const typeTasksArr = [
  { value: 'me', text: 'Мне' },
  { value: 'fromMe', text: 'От меня' },
  { value: 'designer', text: 'Дизайнерам' },
]

export const tasksStatusArr = [
  { value: 'ready', text: 'Готов' },
  { value: 'work', text: 'В работе' },
  { value: 'printing', text: 'В печати' }
]

export const currentOrderStatus = {
  awaitingPrepayment: 'Ожидает предоплаты',
  working: 'Выполняется',
  ready: 'Выполнен',
  readyButNotPaid: 'Выполнен, не оплачен',
  failure: 'Отказ'
}
export const currentOrderStatusArr = [
  { value: 'awaitingPrepayment', text: 'Ожидает предоплаты' },
  { value: 'working', text: 'Выполняется' },
  { value: 'ready', text: 'Выполнен' },
  { value: 'readyButNotPaid', text: 'Выполнен, не оплачен' },
  { value: 'failure', text: 'Отказ' },
]

export const archiveOrderStatus = {
  draft: 'Предварительный',
  ready: 'Выполнен',
  failure: 'Отказ'
}
export const archiveOrderStatusArr = [
  { value: 'draft', text: 'Предварительный' },
  { value: 'ready', text: 'Выполнен' },
  { value: 'failure', text: 'Отказ' },
]

export const orderTypePay = {
  onAccount: 'По счету',
  cash: 'Наличными',
  toCard: 'На карту',
}
export const orderTypePayArr = [
  { value: 'onAccount', text: 'По счету' },
  { value: 'cash', text: 'Наличными' },
  { value: 'toCard', text: 'На карту' },
]

export const manufactoryTaskStatus = {
  confirmation: 'На подтверждении',
  confirmationProd: 'У нач. производства',
  work: 'На производстве',
  produced: 'Выполняется',
  ready: 'Готов',
  archive: 'В архиве'
}
export const manufactoryTaskStatusArr = [
  { value: 'confirmation', text: 'На подтверждении' },
  { value: 'confirmationProd', text: 'У нач. производства' },
  { value: 'work', text: 'На производстве' },
  { value: 'produced', text: 'Выполняется' },
  { value: 'ready', text: 'Готов' },
  { value: 'archive', text: 'В архиве' },
]

export const montagesStatus = {
  assigned: 'Назначен',
  unassigned: 'Не назначен',
  work: 'В работе',
  no_work: 'Не выполнен',
  ready: 'Готов'
}
export const montagesStatusArr = [
  { value: 'assigned', text: 'Назначен' },
  { value: 'unassigned', text: 'Не назначен' },
  { value: 'work', text: 'В работе' },
  { value: 'no_work', text: 'Не выполнен' },
  { value: 'ready', text: 'Готов' }
]

export const Role = {
  admin: 'admin',
  master: 'master',
  manager: 'manager',
  prodDir: 'prodDir',
  designer: 'designer',
}
export const RoleText = {
  [Role.admin]: 'Администратор',
  [Role.master]: 'Мастер',
  [Role.manager]: 'Менеджер',
  [Role.prodDir]: 'Начальник производства',
  [Role.designer]: 'Дизайнер',
}

export const fieldKeysTasks = {
  title: 'Заголовок',
  description: 'Описание'
}

export const fieldKeysOrder = {
  client: 'Клиент',
  description: 'Описание',
  works: 'Работа',
  deadline: 'Дедлайн',
  pay: 'Оплата',
  percentOfOrder: 'Процент менеджера'
}

export const objClientForRef = {
  name: null,
  surname: '',
  phonenumber: '',
  path: ''
}
export const sortList = (list, key) => {
  let engItems = []
  let ruItems = []
  let otherItems = []
  if (list) {
    list.forEach(x => {
      let itemChar0 = x[key].toLowerCase()
      itemChar0 = itemChar0.replace(/\s/g, '');
      itemChar0 = itemChar0.charAt(0)

      if (itemChar0.match(/[a-z]/i)) {
        engItems.push(x)
      } else if (itemChar0.match(/^\p{Script=Cyrillic}+$/u)) {
        ruItems.push(x)
      } else {
        otherItems.push(x)
      }
    })
  }
  let collator = new Intl.Collator();
  engItems = engItems.sort(function (a, b) {
    let nameA = a[key].toLowerCase(), nameB = b[key].toLowerCase()
    return collator.compare(nameA, nameB)
  })

  ruItems = ruItems.sort(function (a, b) {
    let nameA = a[key].toLowerCase(), nameB = b[key].toLowerCase()
    return collator.compare(nameA, nameB)
  })

  otherItems = otherItems.sort(function (a, b) {
    let nameA = a[key].toLowerCase(), nameB = b[key].toLowerCase()
    return collator.compare(nameA, nameB)
  })

  return [...ruItems, ...engItems, ...otherItems]
}
export const AddUserToStore = async (user) => {
  if (user) {
    // let ref = usersCollection.doc(user.uid)
    // let fullUser = await store.dispatch('users/userSerializer', ref)
    // console.log(user)
    if (user) {
      store.commit('user/setUser', user)
      await store.dispatch('user/loadUser', user)
      return true
    }
  }
  return false
}
