import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,{attrs:{"justify":"center"}},[_c(VDialog,{attrs:{"width":"290","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{staticClass:"selectMonth",attrs:{"width":"290"}},[(_vm.title)?_c(VCardTitle,{staticClass:"selectMonth_header"},[_c('span',[_vm._v(_vm._s(_vm.title))])]):_vm._e(),_c(VDatePicker,{staticClass:"selectMonth_datePicker",attrs:{"type":"month","no-title":"","locale":"ru","color":"rgb(224, 166, 119)"},model:{value:(_vm.month),callback:function ($$v) {_vm.month=$$v},expression:"month"}}),_c(VCardActions,[_c(VBtn,{attrs:{"color":"orange","text":""},on:{"click":function($event){return _vm.close()}}},[_vm._v("Отмена")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"blue","text":""},on:{"click":function($event){return _vm.go()}}},[_vm._v("Продолжить")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }