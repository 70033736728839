import api, { apiRoutes as aR } from "@/utils/api/api";
import loadImage from "blueimp-load-image";
import imageCompression from "browser-image-compression";
const serverUrl = process.env.VUE_APP_SERVICE_URL;
const testServerUrl = process.env.VUE_APP_TEST_SERVICE_URL;

// Compressing, uploading images to server. Providing cross platform functionality

const UploadImageMixin = {
  data: () => ({
    UIM: {},
  }),
  mounted() {
    this.UIMInitRoot();
  },
  methods: {
    UIMInitRoot() {
      // Init UIM Namespaced variables
      const _this = this;
      const props = _this.UIMprops;
      const root = _this.UIM;

      // methods
      root.compressAndUploadToServer = async function(files, id) {
        const compressOptions = props.compressOptions || {
          maxSizeMB: 3,
          maxWidthOrHeight: 1520,
          useWebWorker: true,
          fileType: "image/png",
        };

        for (let key in files) {
          let file = files[key];

          if (file && file instanceof File) {
            loadImage.parseMetaData(file, function(data) {
              const options = {};
              // Get the correct orientation setting from the EXIF Data
              if (data.exif) {
                options.orientation = data.exif.get("Orientation");
              }

              // Load the image from disk and inject it into the DOM with the correct orientation
              loadImage(
                file,
                async function(canvas) {
                  let trueCanvas = canvas;
                  if (canvas instanceof HTMLImageElement) {
                    const image = canvas;
                    trueCanvas = document.createElement("canvas");
                    trueCanvas.width = image.width;
                    trueCanvas.height = image.height;
                    trueCanvas.getContext("2d").drawImage(image, 0, 0);
                  }
                  trueCanvas.toBlob(async function(trueFile) {
                    let compressedFile = await imageCompression(
                      trueFile,
                      compressOptions
                    );
                    compressedFile.fileName = file.name;
                    console.log(file.name);
                    root.support.compressedFile(compressedFile);
                    root.support.loading(true);

                    try {
                      // upload to server
                      const filePath = await api
                        .upload(aR.upload, compressedFile)
                        .then((response) => {
                          if (response.status == 200) {
                            return response.data.url;
                          }
                        });
                      console.log("filepath", filePath);

                      if (filePath) {
                        props.onUpload(filePath);
                        root.support.loading(false);
                      }
                    } catch (error) {
                      console.log("error when uploading image", error);
                      root.support.loading(false);
                    }
                  });
                },
                options
              );
            });
          }
        }
      };

      // supporting client (side effects)
      root.support = {
        files: function(files) {
          if (props.files) {
            _this.files = files;
          }
        },
        compressedFile: function(file) {
          if (props.compressedFile) {
            _this.compressedFile = file;
          }
        },
        loading: function(flag) {
          if (props.uploadingImage) {
            _this.uploadingImage = flag;
          }
        },
      };
    },

    // opened variable
    async uploadImage(clientFilesProp, id = 0) {
      // Transforming props
      let clientFiles;

      if (
        (clientFilesProp && clientFilesProp instanceof File) ||
        (clientFilesProp && clientFilesProp[1] instanceof File)
      ) {
        clientFiles = Array.isArray(clientFilesProp)
          ? clientFilesProp
          : [clientFilesProp];
      }

      const _this = this;
      const props = _this.UIMprops;
      const root = _this.UIM;

      if (clientFiles) {
        root.compressAndUploadToServer(clientFiles, id);
        root.support.files(clientFiles);
      } else {
        const input = document.createElement("input");
        input.type = "file";
        input.accept = "image/*";

        if (props.multiple) {
          input.multiple = "multiple";
        }

        input.onchange = async function(e) {
          root.support.loading(true);
          const files = await e.target.files;

          root.compressAndUploadToServer(files, id);
        };

        input.click();
      }
    },
  },
};

export { UploadImageMixin };
