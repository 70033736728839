import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,[_c(VCol,{staticClass:"pt-0",attrs:{"cols":"12"}},[(!_vm.canEdit && _vm.canEdit !== undefined ? false : true)?_c('NewComment',{attrs:{"item":_vm.item,"responseTo":_vm.response_to,"resetResponseTo":_vm.resetResponseTo,"item_id":_vm.item_id,"item_type":_vm.item_type},on:{"load":_vm.loadComments}}):_vm._e()],1),_vm._l((_vm.filtredComments),function(comment){return _c(VCol,{key:comment.id,staticClass:"d-flex align-center",attrs:{"cols":"12"}},[_c(VRow,[_c(VCol,[_c(VRow,[_c(VCol,{attrs:{"cols":"2"}},[_c('my-mini-avatar',{attrs:{"user":comment.fromData}})],1),_c(VCol,{staticStyle:{"margin-left":"-4.5%"},attrs:{"cols":"10"}},[_c(VRow,[_c(VCol,{staticClass:"pa-0",attrs:{"cols":"12"}},[_c(VSubheader,{staticClass:"pa-0",staticStyle:{"height":"23px"}},[_c('span',{staticStyle:{"font-weight":"500"}},[_vm._v(_vm._s(comment.fromData.displayName))]),(comment.program)?_c('span',{staticClass:"ml-2"},[_vm._v("Авто. создан")]):_vm._e(),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.$formatDateTime(new Date(comment.date))))]),(_vm.user.role == _vm.roles.admin || _vm.user.id === comment.fromData.id &&
                                            !comment.program &&
                                            !comment.del
                                            )?_c('span',{staticClass:"ml-1"},[_c(VBtn,{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.deleteComment(comment)}}},[_c(VIcon,{attrs:{"size":"20","color":"red"}},[_vm._v(" mdi-trash-can-outline ")])],1)],1):_vm._e(),(!_vm.canEdit && _vm.canEdit !== undefined ? false : true)?_c('span',{staticClass:"ml-1"},[_c(VBtn,{attrs:{"icon":"","small":""},on:{"click":() => (_vm.response_to = comment)}},[_c(VIcon,{attrs:{"size":"20","color":"#eec374"}},[_vm._v(" mdi-send ")])],1)],1):_vm._e()])],1),_c(VCol,{staticClass:"pa-0",attrs:{"cols":"12"}},[_c(VRow,[(comment.response_to)?_c(VCol,{staticStyle:{"{\n                    border-radius":"8px"},attrs:{"cols":"12"}},[_c('div',{staticStyle:{"{\n                      display":"normal","border-radius":"8px","margin-bottom":"10px","margin-top":"4px","box-shadow":"0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)","display":"flex","justify":"flex-start"}},[_c('div',{staticStyle:{"{ display":"normal","width":"8px","background-color":"rgb(238, 195, 116)","border-radius":"4px  0 0 4px"}}),_c('div',{staticStyle:{"{width":"95%","padding-bottom":"4px"}},[_c(VSubheader,{staticClass:"pa-0 pl-1",staticStyle:{"height":"23px"}},[_c('span',{staticStyle:{"font-weight":"500"}},[_vm._v(_vm._s(_vm.getComment(comment.response_to).fromData .displayName))]),(_vm.getComment(comment.response_to).program)?_c('span',{staticClass:"ml-2"},[_vm._v("Авто. создан")]):_vm._e(),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.$formatDateTime( new Date(_vm.getComment(comment.response_to).date) )))])]),(_vm.getComment(comment.response_to).text)?_c(VCol,{class:[
                                                    { del: _vm.getComment(comment.response_to).del },
                                                ],staticStyle:{"{   display":"normal","white-space":"pre-wrap"},domProps:{"innerHTML":_vm._s(_vm.getComment(comment.response_to).text)}}):_vm._e()],1)])]):_vm._e(),(comment.text)?_c(VCol,{class:[ { del: comment.del }],staticStyle:{"{   display":"normal","white-space":"pre-wrap"},domProps:{"innerHTML":_vm._s(comment.text)}}):_vm._e(),(!comment.text &&
                                        (!comment.files || comment.files.length === 0)
                                        )?_c(VCol,{class:[ { del: comment.del }]},[_vm._v(" Пустой комментарий ")]):_vm._e(),(comment.files && comment.files.length > 0)?_c(VCol,{attrs:{"cols":"12"}},[_c(VContainer,{staticClass:"pa-0",attrs:{"fluid":""}},[_c(VRow,_vm._l((comment.files),function(image,index_image){return _c(VCol,{key:index_image,staticClass:"d-flex child-flex",attrs:{"cols":"4"}},[_c('my-preview-image',{attrs:{"can-download":true,"fb-path":image,"images":comment.files},on:{"delete":function($event){return _vm.deleteImage(image, comment)}}})],1)}),1)],1)],1):_vm._e()],1)],1)],1)],1)],1)],1)],1)],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }