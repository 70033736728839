import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,{attrs:{"justify":"center"}},[_c(VDialog,{attrs:{"fullscreen":_vm.isMobile,"max-width":!_vm.isMobile ? '600px' : '',"transition":"dialog-bottom-transition","scrollable":!_vm.isMobile,"persistent":!_vm.isMobile},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{staticClass:"rl-modal",attrs:{"tile":_vm.isMobile}},[(_vm.isMobile)?_c(VToolbar,{staticStyle:{"position":"sticky","top":"0px","z-index":"20"},attrs:{"color":"rgb(224, 166, 119)","tile":""}},[(_vm.edit)?_c(VToolbarTitle,{staticClass:"white--text"},[_vm._v("Редактировaние задания")]):_c(VToolbarTitle,{staticClass:"white--text"},[_vm._v("Новый задание")]),_c(VSpacer),_c(VBtn,{attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.close()}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1):_c(VCardTitle,{staticClass:"rl-modal_header"},[(_vm.edit)?_c('span',{staticClass:"headline"},[_vm._v("Редактирование задания")]):_c('span',{staticClass:"headline"},[_vm._v("Новое задание")]),_c(VSpacer),_c(VBtn,{attrs:{"icon":"","color":"rgb(224, 166, 119)"},on:{"click":function($event){return _vm.close()}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,{staticClass:"pb-0",class:{ 'px-2 pt-3': _vm.isMobile }},[(_vm.form.numberOrder)?_c('div',{staticStyle:{"font-size":"1.1em","color":"black"}},[_c('span',{staticStyle:{"font-weight":"500"}},[_vm._v("Номер заказа:")]),(!/[a-zA-Z]/g.test(_vm.form.numberOrder) &&
                        _vm.edit &&
                        (_vm.user.role === 'admin' ||
                            (_vm.data && _vm.data.manager_id === _vm.user.id))
                        )?_c('span',{staticClass:"rl-link",on:{"click":function($event){return _vm.$openOrder(_vm.form.numberOrder)}}},[_vm._v(_vm._s(_vm.form.numberOrder))]):_c('span',[_vm._v(_vm._s(_vm.form.numberOrder))])]):_vm._e(),(_vm.edit)?_c('div',{staticClass:"pb-3",staticStyle:{"font-size":"1.1em","color":"black"}},[_c('span',{staticStyle:{"font-weight":"500"}},[_vm._v("Менеджер: ")]),_vm._v(" "+_vm._s(_vm.data.manager.displayName)+" "),_c('span',[_c('span',{staticStyle:{"font-weight":"500"}},[_vm._v("Дата: ")]),_c('span',[_vm._v(_vm._s(_vm.$formatDate( _vm.form.date.seconds ? new Date(_vm.form.date) : new Date(_vm.form.date) )))]),(_vm.canEdit)?_c(VBtn,{staticClass:"ml-1",attrs:{"icon":"","color":"black","small":""},on:{"click":function($event){return _vm.editDate()}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1):_vm._e()],1),(_vm.data.status)?_c('span',[_c('br'),_c('span',{staticStyle:{"font-weight":"500"}},[_vm._v("Статус: ")]),_vm._v(" "+_vm._s(_vm.manufactoryTaskStatus[_vm.data.status])+" ")]):_vm._e(),(_vm.form.montagers &&
                        _vm.form.montagersInfo &&
                        Object.keys(_vm.form.montagersInfo).length > 0
                        )?_c('span',[_c('br'),_c('span',{staticStyle:{"font-weight":"500"}},[_vm._v("Исполнители:")]),_vm._l((_vm.form.montagersInfo),function(montager,index_montager){return _c('span',{key:index_montager},[_c('span',{style:(montager.boss ? 'font-weight: 500;' : '')},[_vm._v(" "+_vm._s(montager.name)+" ")]),(index_montager !=
                                Object.keys(_vm.form.montagersInfo)[
                                Object.keys(_vm.form.montagersInfo).length - 1
                                ]
                                )?_c('span',[_vm._v(",")]):_vm._e()])}),(['admin', 'prodDir'].includes(_vm.user.role))?_c(VBtn,{staticClass:"ml-1",attrs:{"icon":"","color":"black","small":""},on:{"click":function($event){return _vm.editPerformers()}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1):_vm._e()],2):_vm._e(),(_vm.data.readyDate)?_c('span',[_c('br'),_c('span',{staticStyle:{"font-weight":"500"}},[_vm._v("Готово:")]),_vm._v(" "+_vm._s(_vm.$formatDateTime(new Date(_vm.data.readyDate)))+" ")]):_vm._e(),(_vm.data.archiveDate)?_c('span',[_c('br'),_c('span',{staticStyle:{"font-weight":"500"}},[_vm._v("Архив:")]),_vm._v(" "+_vm._s(_vm.$formatDateTime(new Date(_vm.data.archiveDate)))+" ")]):_vm._e()]):_vm._e(),_c(VContainer,{staticClass:"py-0"},[_c(VForm,{ref:"formCreateTask"},[_c(VRow,[_c(VCol,{staticClass:"d-flex pt-0",attrs:{"cols":"12"}},[_c(VAutocomplete,{ref:"clientName",class:{
                                    'mr-2': ['admin', 'manager'].includes(_vm.user.role),
                                },attrs:{"disabled":_vm.user.role === 'prodDir' ||
(_vm.user.role === 'manager' &&
    Boolean(_vm.data.status) &&
    ['produced', 'ready', 'archive'].includes(
        _vm.data.status
    )),"dense":!_vm.isMobile,"color":"#e0a677","placeholder":"Клиент","items":_vm.filterClients,"item-text":"name","return-object":""},on:{"change":function($event){return _vm.setClient()}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c(VRow,{staticClass:"px-2",attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"d-flex align-center justify-center",attrs:{"cols":"12"}},[_c(VBtn,{attrs:{"color":"#e0a677","text":"","small":""},on:{"click":function($event){return _vm.newClient()}}},[_vm._v(" Добавить клиента ")])],1)],1)]},proxy:true}]),model:{value:(_vm.form.filterClient),callback:function ($$v) {_vm.$set(_vm.form, "filterClient", $$v)},expression:"form.filterClient"}}),(['admin', 'manager'].includes(_vm.user.role))?_c(VBtn,{attrs:{"disabled":!_vm.form.client ||
                                    (_vm.form.client && !_vm.form.client.name === undefined),"icon":""},on:{"click":function($event){return _vm.showClientInfo()}}},[_c(VIcon,[_vm._v("mdi-account-eye-outline")])],1):_vm._e()],1),_c(VCol,{staticClass:"py-0",attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"disabled":!_vm.form.techTask &&
                                    _vm.edit &&
                                    _vm.data.status != 'confirmationProd' &&
                                    _vm.data.status != 'confirmation',"readonly":_vm.user.role === 'prodDir' ||
    (_vm.user.role === 'manager' &&
        Boolean(_vm.data.status) &&
        ['produced', 'ready', 'archive'].includes(
            _vm.data.status
        )) ||
    _vm.data.status === 'archive',"color":"rgb(224, 166, 119)","rules":_vm.$fieldRules.isUrl,"label":"Ссылка на ТЗ","append-outer-icon":_vm.form.techTask ? 'mdi-open-in-new' : null},on:{"click:append-outer":function($event){return _vm.goUrl(_vm.form.techTask)}},model:{value:(_vm.form.techTask),callback:function ($$v) {_vm.$set(_vm.form, "techTask", $$v)},expression:"form.techTask"}})],1),_c(VCol,{staticClass:"py-0",attrs:{"cols":"12"}},[_c(VTextarea,{attrs:{"readonly":_vm.user.role === 'prodDir' ||
                                    (_vm.user.role === 'manager' &&
                                        Boolean(_vm.data.status) &&
                                        ['produced', 'ready', 'archive'].includes(
                                            _vm.data.status
                                        )) ||
                                    _vm.data.status === 'archive',"color":"rgb(224, 166, 119)","label":"Комментарий","rows":"2"},model:{value:(_vm.form.comment),callback:function ($$v) {_vm.$set(_vm.form, "comment", $$v)},expression:"form.comment"}})],1),_c(VCol,{staticClass:"py-0",attrs:{"cols":"12"}},[_c(VBtn,{attrs:{"disabled":!_vm.canEdit,"width":"100%","loading":_vm.uploadingImage},on:{"click":function($event){return _vm.uploadImage()}}},[_c(VIcon,[_vm._v("mdi-cloud-upload")]),_c('span',{staticClass:"pl-2"},[_vm._v("Загрузить фото")])],1)],1),_c(VCol,{staticClass:"py-0",attrs:{"cols":"12"}},[_c(VContainer,{staticClass:"pa-0",attrs:{"fluid":""}},[_c(VRow,_vm._l((_vm.form.images),function(image,index_image){return _c(VCol,{key:index_image,staticClass:"d-flex child-flex",attrs:{"cols":"4"}},[_c('ImageView',{key:index_image + _vm.form.images.length,attrs:{"disTrach":!_vm.canEdit,"path":_vm.images[index_image],"images":_vm.images},on:{"delete":function($event){return _vm.deleteImage(image, index_image)}}})],1)}),1)],1)],1)],1)],1),_c('div',{staticStyle:{"min-height":"70px"}}),(_vm.edit)?_c('Comments',{attrs:{"item_id":_vm.data.id,"item":_vm.data,"canEdit":_vm.canEdit || this.data.status === 'archive',"showProgramComment":_vm.showProgramComments,"item_type":"manufactory"}}):_vm._e()],1)],1),_c(VCardActions,{staticClass:"px-7"},[(_vm.taskBoss &&
                    ['master'].includes(_vm.user.role) &&
                    _vm.data.status &&
                    !['ready', 'archive'].includes(_vm.data.status)
                    )?_c(VBtn,{attrs:{"color":"orange","text":""},on:{"click":function($event){return _vm.addAssistant()}}},[_vm._v("Помошники")]):_vm._e(),(_vm.edit &&
                    ((['advance'].includes(_vm.data.status) &&
                        _vm.user.role === 'manager') ||
                        _vm.user.role === 'admin')
                    )?_c(VBtn,{attrs:{"disabled":_vm.user.role === 'admin' ? false : !_vm.canEdit,"color":"red","text":""},on:{"click":function($event){return _vm.deleteTask()}}},[_vm._v(_vm._s(_vm.isMobile ? "Удал." : "Удалить"))]):_vm._e(),(_vm.edit &&
                    _vm.data.status === 'confirmationProd' &&
                    ['admin', 'prodDir'].includes(_vm.user.role)
                    )?_c(VBtn,{attrs:{"color":"black","text":""},on:{"click":function($event){return _vm.save('confirmation', 'revision')}}},[_vm._v(_vm._s(_vm.isMobile ? "На. доработ." :"На доработку"))]):_vm._e(),_c(VSpacer),(_vm.edit &&
                    _vm.data.status === 'confirmationProd' &&
                    ['admin', 'prodDir'].includes(_vm.user.role)
                    )?_c(VBtn,{attrs:{"color":"orange","text":""},on:{"click":function($event){return _vm.save('work')}}},[_vm._v(_vm._s(_vm.isMobile ? "Наз. мастера" : "Назначить мастера"))]):_vm._e(),(['confirmation'].includes(_vm.data.status) || !_vm.data.status)?_c(VBtn,{attrs:{"color":"blue","text":""},on:{"click":function($event){return _vm.save('confirmationProd')}}},[_vm._v("На производство")]):_vm._e(),((_vm.user.role === 'admin' &&
                    ['produced', 'work'].includes(_vm.data.status)) ||
                    (_vm.taskBoss &&
                        ['master', 'prodDir'].includes(_vm.user.role) &&
                        _vm.data.status &&
                        _vm.data.status === 'produced')
                    )?_c(VBtn,{attrs:{"color":"green","text":""},on:{"click":function($event){return _vm.save('ready')}}},[_vm._v("Готово")]):_vm._e(),(_vm.taskBoss &&
                    ['master', 'prodDir'].includes(_vm.user.role) &&
                    _vm.data.status &&
                    _vm.data.status === 'work'
                    )?_c(VBtn,{attrs:{"color":"blue","text":""},on:{"click":function($event){return _vm.save('produced')}}},[_vm._v("В работу")]):_vm._e(),(['admin', 'manager'].includes(_vm.user.role) &&
                    ((_vm.data.manager && _vm.data.manager.uid === _vm.user.uid) ||
                        _vm.user.role === 'admin') &&
                    _vm.data.status &&
                    _vm.data.status === 'ready'
                    )?_c(VBtn,{attrs:{"color":"blue","text":""},on:{"click":function($event){return _vm.save('archive')}}},[_vm._v("В архив")]):_vm._e(),(['manager', 'admin'].includes(_vm.user.role) && !_vm.data.status)?_c(VBtn,{attrs:{"disabled":!_vm.canEdit,"color":"green","text":""},on:{"click":function($event){return _vm.save('confirmation')}}},[_vm._v("Сохранить")]):_vm._e(),((_vm.user.role === 'admin' &&
                    _vm.data.status &&
                    _vm.data.status != 'archive') ||
                    (_vm.user.role === 'manager' && _vm.edit && _vm.canEdit) ||
                    (_vm.user.role === 'prodDir' && _vm.data.status != 'confirmationProd')
                    )?_c(VBtn,{attrs:{"color":"blue","text":""},on:{"click":function($event){return _vm.save()}}},[_vm._v("Сохранить")]):_vm._e()],1)],1),_c('div',{ref:"file",staticStyle:{"display":"none"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }