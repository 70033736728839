<template>
  <div class="page brig" :class="{ 'pa-3': !$isMobile }">
    <SocketWatch :tables="['clients']" @loadData="loadData" />
    <v-card :tile="$isMobile">
      <div v-if="$isMobile">
        <v-row class="page_filterTable ma-0 mx-2 px-1 pt-5">
          <v-col cols="8">
            <v-text-field
              dense
              v-model="searchClients"
              color="rgb(224, 166, 119)"
              label="Фамилия/имя/телефон клиента"
              placeholder="Иванов/Иван/79884317765"
            />
          </v-col>
          <v-col cols="2" class="pt-4">
            <v-btn icon @click="openFilters = !openFilters">
              <v-badge
                v-if="filtersSelected"
                color="orange"
                overlap
                :content="filtersSelected"
              >
                <v-icon>mdi-tune</v-icon>
              </v-badge>
              <v-icon v-else>mdi-tune</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="2" class="pt-5">
            <v-btn icon small @click="createClient()">
              <v-icon size="21">mdi-account-plus-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-expand-transition>
          <v-card flat tile v-show="openFilters">
            <v-divider />
            <v-row class="page_filterTable ma-0 mx-3 px-1 pt-5">
              <v-col cols="12" class="pt-0">
                <v-autocomplete
                  dense
                  color="rgb(224, 166, 119)"
                  v-model="filterTasksByManager"
                  @change="$store.dispatch('clients/loadTableClients')"
                  label="Менеджер"
                  :items="managers"
                  item-text="displayName"
                  return-object
                  clearable
                />
              </v-col>
            </v-row>
          </v-card>
        </v-expand-transition>
      </div>
      <div v-else class="d-flex align-center px-4">
        <v-row class="page_filterTable mt-3">
          <v-col cols="6">
            <v-text-field
              dense
              v-model="searchClients"
              color="rgb(224, 166, 119)"
              label="Фамилия/имя/телефон клиента"
              placeholder="Иванов/Иван/79884317765"
            />
          </v-col>
          <v-col cols="4">
            <v-autocomplete
              dense
              color="rgb(224, 166, 119)"
              v-model="filterTasksByManager"
              @change="$store.dispatch('clients/loadTableClients')"
              label="Менеджер"
              :items="managers"
              item-text="displayName"
              return-object
              clearable
            />
          </v-col>
        </v-row>
        <v-spacer></v-spacer>
        <v-btn icon small @click="createClient()">
          <v-icon size="21">mdi-account-plus-outline</v-icon>
        </v-btn>
      </div>
      <v-divider />
      <v-data-table
        :headers="tableHeadres"
        :footer-props="{
          'items-per-page-options': [5, 10, 15, 20, 30, 40],
        }"
        :items="clients"
        :items-per-page="30"
        :server-items-length="count"
        @pagination="changePagination"
        dense
      >
        <template v-slot:[`item.surname`]="{ item }">
          <span v-if="item.surname">{{ item.surname }}</span>
          <span v-else>Не указана</span>
        </template>
        <template v-slot:[`item.phonenumber`]="{ item }">
          <span v-if="item.contacts && item.contacts.length > 0">
            {{ getMainContact(item.contacts) }}
          </span>
          <span v-else>Не указан</span>
        </template>
        <template v-slot:[`item.creatorData`]="{ item }">
          <span v-if="item.creatorData">{{
            item.creatorData.displayName
          }}</span>
          <span v-else>Не задан</span>
        </template>
        <template v-slot:[`item.montages`]="{ item }">
          <span v-if="item.count_montages && item.count_montages > 0">{{
            item.count_montages
          }}</span>
          <span v-else>Нет монтажей</span>
        </template>
        <template v-slot:[`item.manufactory`]="{ item }">
          <span v-if="item.count_manufactory && item.count_manufactory > 0">{{
            item.count_manufactory
          }}</span>
          <span v-else>Нет задач в производство</span>
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-btn small @click="editClient(item)" icon color="#424242">
            <v-icon size="18">mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            small
            v-if="user.role === 'admin'"
            @click="deleteClient(item)"
            icon
            color="#bf4037"
          >
            <v-icon size="18">mdi-trash-can-outline</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import Client from "@/components/Modals/Clients/Index";
import Confirm from "@/components/Modals/Confirm";
import { RoleText } from "@/helpers";
import { parseIfString } from "@/utils/json";
import { debounce } from "lodash";

export default {
  name: "Clients",
  components: {},
  data: () => ({
    roleText: RoleText,
    error: null,
    openFilters: false,
    parseIfString,
  }),
  async beforeRouteLeave(to, from, next) {
    this.searchClients = "";
    this.filterTasksByManager = null;
    next();
  },
  created() {
    this.loadData();
  },
  computed: {
    tableHeadres() {
      return [
        {
          text: "Клиент",
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: "Фамилия",
          align: "start",
          sortable: false,
          value: "surname",
        },
        {
          text: "Номер телефона",
          align: "start",
          sortable: false,
          value: "phonenumber",
        },
        {
          text: "Создал",
          align: "start",
          sortable: false,
          value: "creatorData",
        },
        {
          text: "Монтажи",
          align: "start",
          sortable: false,
          value: "montages",
        },
        {
          text: "Производство",
          align: "start",
          sortable: false,
          value: "manufactory",
        },
        {
          text: "Действия",
          align: "end",
          sortable: false,
          value: "action",
        },
      ];
    },
    count() {
      return this.$store.getters["clients/count"];
    },
    searchClients: {
      get() {
        return this.$store.getters["clients/getSearchClients"];
      },
      set(val) {
        this.$store.commit("clients/setSearchClients", val);
      },
    },
    filterTasksByManager: {
      get() {
        return this.$store.getters["clients/getFilterClientByManager"];
      },
      set(val) {
        this.$store.commit("clients/setFilterClientByManager", val);
      },
    },
    filtersSelected() {
      let filters = [this.searchClients, this.filterTasksByManager];
      filters = filters.filter((x) => !!x);
      return filters.length;
    },
    clients() {
      return this.$store.getters["clients/getTableClients"];
    },
    filterClients() {
      return this.$store.getters["clients/getFilterClients"];
    },
    managers() {
      return this.$store.getters["users/getManagers"];
    },
    user() {
      return this.$store.getters["user/getUser"];
    },
  },
  watch: {
    searchClients: debounce(function(oldSearch, newSearch) {
      if (oldSearch === newSearch) return;
      this.$store.dispatch("clients/loadTableClients");
    }, 300),
  },
  methods: {
    loadData() {
      // alert('load')
      // console.log(2222)
      // this.$store.dispatch('clients/loadTableClients')
      this.$store.dispatch("clients/loadFilterClients");

      if (!this.managers || this.managers.length === 0) {
        this.$store.dispatch("users/loadManagers");
      }
    },
    getMainContact(itemRaw) {
      const item = parseIfString(itemRaw);

      if (item && item.length > 0 && Array.isArray(item)) {
        let main = item.find((x) => x.main);
        if (main) {
          return `${main.name}: ${main.phonenumber}`;
        }
        return null;
      }
      return null;
    },
    async editClient(client) {
      // console.log(client.id)
      const fullClient = await this.$store.dispatch(
        "clients/loadClient",
        client.id
      );

      const editClient = await this.$showModal({
        component: Client,
        isPersistent: true,
        props: {
          edit: true,
          data: {
            ...fullClient,
            contacts: parseIfString(fullClient.contacts),
          },
        },
      });
      if (editClient) {
        await this.$store.dispatch("clients/updateClient", {
          id: client.id,
          data: editClient,
        });
        this.loadData();
        this.$store.dispatch("clients/loadTableClients");
      }
    },
    async createClient() {
      const createClient = await this.$showModal({
        component: Client,
        isPersistent: true,
      });

      if (createClient) {
        await this.$store.dispatch("clients/createClient", createClient);
        this.loadData();
      }
    },
    async deleteClient(client) {
      const res = await this.$showModal({
        component: Confirm,
        isPersistent: true,
        props: {
          title: `Удаление клиента`,
          text: `Действительно хотите удалить клиента "${client.name}"?`,
        },
      });

      if (res) {
        this.$store.dispatch("clients/deleteClient", client);
        this.$store.dispatch("clients/loadTableClients");
      }
    },
    async changePagination(e) {
      this.$store.commit("clients/SET_LIMIT", e.itemsPerPage);
      this.$store.commit("clients/SET_OFFSET", e.pageStart);
      this.$store.dispatch("clients/loadTableClients");

      if (!this.managers || this.managers.length === 0) {
        this.$store.dispatch("users/loadManagers");
      }
    },
  },
};
</script>
