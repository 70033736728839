<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      :fullscreen="isMobile"
      :max-width="!isMobile ? '700px' : ''"
      transition="dialog-bottom-transition"
      :scrollable="!isMobile"
      :persistent="!isMobile"
    >
      <v-card :tile="isMobile">
        <v-toolbar v-if="isMobile" color="rgb(224, 166, 119)" tile style="position: sticky; top: 0px; z-index: 20;">
          <v-toolbar-title v-if="edit" class="white--text">Редактировние материала</v-toolbar-title>
          <v-toolbar-title v-else class="white--text">Новый материал</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-title v-else>
          <span v-if="edit" class="headline">Редактировние материала</span>
          <span v-else class="headline">Новый материал</span>
          <v-spacer />
          <v-btn icon color="rgb(224, 166, 119)" @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container :class="{ 'pa-0': isMobile }">
            <v-form ref="formCategory">
              
              <v-row>
                <v-col cols="6">
                  <v-autocomplete
                    color="rgb(224, 166, 119)"
                    v-model="form.category_id"
                    label="Категория"
                    :items="categorys"
                    item-text="name"
                    item-value="id"
                    :rules="$fieldRules.field"
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    color="rgb(224, 166, 119)"
                    v-model="form.name"
                    label="Название*"
                    :rules="$fieldRules.field"
                    required
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6">
                  <v-text-field
                    color="rgb(224, 166, 119)"
                    v-model.number="form.count"
                    label="Количество*"
                    type="number"
                    step="1"
                    :rules="$fieldRules.field"
                    required
                    :disabled="data.id"
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    color="rgb(224, 166, 119)"
                    v-model.number="form.unit"
                    label="Единицы измерения*"
                    :rules="$fieldRules.field"
                    required
                  />
                </v-col>
              </v-row>

              <v-row>
                
                <v-col cols="12">
                  <v-text-field
                    color="rgb(224, 166, 119)"
                    v-model="form.comment"
                    :rules="$fieldRules.field"
                    required
                  />
                </v-col>
              </v-row>

              
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn  v-if="user.role === 'admin' && data.id " @click="delProduct()"  text color="#bf4037">
            Удалить
          </v-btn>
          <v-btn color="orange" text @click="save()">
            <span v-if="edit">Сохранить</span>
            <span v-else>Создать</span>
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
  </v-row>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
import { Role, RoleText } from '@/helpers'
import { mapActions, mapState } from 'vuex'
import Confirm from  '@/components/Modals/Confirm'
export default {
  components: {
    Confirm
  },
  props: {
    edit: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data: () => ({
    isMobile: isMobile,
    role: Role,
    roleText: RoleText,
    dialog: true,
    errors: {},
    form: {
      unit: '',
      name: '',
      count: 0,
      category_id: null,
      comment: '',
    },
  }),
  computed: {
    ...mapState('warehouse', [
      'categorys',
      'positions',
      
    ]),
    user() {
      return this.$store.getters['user/getUser']
    },
  },
  created() {
   
    if (this.data && this.data.id) {
      this.form.name = this.data.name
      this.form.category_id = this.data.category_id
      this.form.count = this.data.count
      this.form.unit = this.data.unit
      this.form.comment = this.data.comment
    } else if (this.data && this.data.category_id) {
      this.form.category_id = this.data.category_id
    }
  },
  methods: {
    ...mapActions('warehouse', [
        'deleteProduct',
      ]),
    async save() {
      const errors = []
      const validate = await this.$refs.formCategory.validate()
      errors.push(validate)

      if (!errors.includes(false)) {
				this.$emit('close', this.form)
      }
    },
    async delProduct() {
      const res = await this.$showModal({
        component: Confirm,
        isPersistent: true,
        props: {
          title: `Удалить`,
          text: `Действительно хотите удалить "${this.data.name}"?`
        }
      })

      if(res) {
        this.deleteProduct({...this.data, who_id: this.user.id })
        this.close()
      }
      
    },
    close() {
      this.dialog = false
      setTimeout(() => {
        this.$emit('close')
      }, 200)
    }
  }
}
</script>
