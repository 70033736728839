export default {
    namespaced: true,
    state: {
        comments:[]
    },
    getters: {
        comments: state => state.comments
    },
    mutations: {
        SET_COMMENTS(state,payload){
            state.comments = payload;
        },
        ADD_COMMENT(state,payload){
            if (typeof payload.files === 'string' ) {
                payload.files = payload.files.split(',')
            }
            let comments = state.comments
            comments.unshift(payload)
            state.comments = comments
        },
        DELETE_COMMENT(state,payload) {
            let comments = state.comments
            comments = comments.filter(comment => comment.id != payload)
            state.comments = comments
        }
    }
}